import { ReactElement, forwardRef, createElement } from 'react'
import CustomInputPicker from './CustomInputPicker';
import DatePicker from 'react-datepicker';


const CustomDatePicker = ({ value, setValue, placeholder, background = '', minDateToday = false }): ReactElement => {
  
  const InputRef = forwardRef((props, ref) => <CustomInputPicker innerRef={ref} props={props} placeholder={placeholder} background={background}/>);
  const today = new Date()

  const handleSetDate = (date): void => {
    setValue(date)
  }

  return (
    <div>
      <DatePicker minDate={minDateToday ? today : ''} selected={value} onChange={handleSetDate} dateFormat="dd-MM-yyyy" customInput={createElement(InputRef)} />
    </div>
  )
}

export default CustomDatePicker