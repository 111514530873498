import { useQuery } from '@apollo/client';
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ALL_ORIENTED } from '../../../queries/Oriented';
import { setCurrentSection } from '../../../slices/navSlice';
import OrientedCard from '../../Asignaciones/OrientedCard';
import Button from '../../UI/Button';
import Pagination from '../../UI/Pagination';
import TittleInProgress from '../../UI/TittleInProgress';
import News from '../News';
import OrientedsSearch from '../../UI/OrientedsSearch';
import LoadingSpinner from '../../../utils/spinner';
import { useHistory } from 'react-router-dom';

const BeginningAdmin = (): ReactElement => {
  const dispatch = useDispatch()
  const history = useHistory()
  const getOrienteds = useQuery(ALL_ORIENTED)
  const [allOrienteds, setallOrienteds] = useState<Array<any>>([])
  
  const handleShowEdit = (oriented): void => {
    dispatch(setCurrentSection('Inicio'))
    history.push(`/admin-oriented-info/${oriented.id}`)
  };

  const item = (i, index): ReactElement => (
    <OrientedCard userInfo={i} key={index} select={handleShowEdit} />
  )

  useEffect(() => {
    getOrienteds.refetch()
  }, [])

  useEffect(() => {
    if (getOrienteds.data) {
      setallOrienteds(getOrienteds.data.allOriented)
    }
  }, [getOrienteds])

  const handleGoToNewIngresoForm = ():void => {
    dispatch(setCurrentSection('Ingresos'))
    history.push('/admissions/form')
  }

  return(
    <div className='h-auto lg:max-w-5xl md:ml-11 mb-20'>
      <div className='grid grid-cols-2'>
        <div className="pb-1">
          <TittleInProgress text={'Orientados'} width="w-36" />
        </div>
        <div className='flex flex-wrap self-end flex-row-reverse rounded-lg -mr-4'>
          <Button text="Ingresar orientado" action={handleGoToNewIngresoForm} styles={' w-fit mr-8'}/>
        </div>
      </div>
      <div className='lg:max-w-5xl'>
        {getOrienteds.loading ? <LoadingSpinner/> : <OrientedsSearch
          oriented={allOrienteds}
          componentToShow={(data): ReactElement => <Pagination data={data} itemData={item} table={null} perPage={9} />}
        />
        }
        <div>
          <News />
        </div>
      </div>
    </div>
  )
}

export default BeginningAdmin