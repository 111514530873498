import { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import NewLayout from '../../components/Layout/NewLayout';
import OrientedPaymentsView from '../../components/Pagos/OrientedPaymentsView';
import { setCurrentSection } from '../../slices/navSlice';
import AdminPaymentsView from '../../components/Pagos/AdminPaymentsView';
import { Roles } from '../../utils/Roles';


const PaymentsPage =(): ReactElement => {
  
  const role = localStorage.getItem('role');

  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(setCurrentSection('Pagos'))
  })


  return (
    <NewLayout>
      <div className="w-full md:ml-11">
        {role == Roles.ORIENTED ? <OrientedPaymentsView /> : <AdminPaymentsView />}
      </div>
    </NewLayout>            
  )

}

export default PaymentsPage;