import { FetchResult } from '@apollo/client';
import { gql } from '@apollo/client';
import { client } from '../../client-apollo';

export const savePrivateComment = (appointmentID, comment): Promise<FetchResult<any>> => {
  return client
    .mutate({
      mutation: gql`
        mutation{
          savePrivateComment(data: {
          appointmentID: "${appointmentID}",
          privateComments:"${comment}"
          }){
            id
            comments
            interestLinks{
              id
              link
              name
            }
          }
        }
            `
    });
}

export const savePrivateCommentMutation = gql`
  mutation savePrivateComment($data:SaveAppointmentPrivateCommentInput!) {
    savePrivateComment(data:$data){
      id,
      privateComment,
    }
  }`

export const deleteInterestLink = (appointmentID, interestLinkID): Promise<FetchResult<any>> => {
  return client
    .mutate({
      mutation: gql`
      mutation{
        deleteInterestLink(data: {
        appointmentId: "${appointmentID}",
          interestLinkId:"${interestLinkID}"
        }){
          id
          comments
          interestLinks{
            id
            link
            name
          }
        }
      }
          `
    });
}

export const saveComment = (data): Promise<FetchResult<any, Record<string, any>, Record<string, any>>> => {
  return client
    .mutate({
      mutation: gql`
      mutation{
        saveComment(data: ${data}){
        id
        }
      }
        `
    })
}

export const saveCommentMutation = gql`
  mutation saveComment ($data:SaveAppointmentCommentInput!) {
    saveComment(data:$data) {
      id
    }
  }`

export const confirmAppointments = (appIds, userId): Promise<FetchResult<any>> => {
  return client
    .mutate({
      mutation: gql`
      mutation{
      confirmAppointments(data:{appIds:"${appIds}",userId:"${userId}"}){
      id
      }
      }
        `
    });
}
//TODO: eliminar cuando se migre la funcionalidad en admin
export const deleteEvent = (appointmentID): Promise<FetchResult<any>> => {
  return client
    .mutate({
      mutation: gql`
      mutation{
        deleteEvent(data:{appointmentID:"${appointmentID}"})
      }
        `
    });
}

export const deleteEventMutation = gql`
  mutation deleteEvent($data:DeleteEventInput!){
      deleteEvent(data: $data)
  }
  `

export const sendFeedback = (data): Promise<FetchResult<any>> => {
  return client
    .mutate({
      mutation: gql`
    mutation{
      sendFeedback(data: ${data})
    }
      `
    })
}


export const markEventAsFinished = (appointmentID): Promise<FetchResult<any>> => {
  return client
    .mutate({
      mutation: gql`
      mutation{
        markEventAsFinished(data: {
          appointmentID: "${appointmentID}"
        })
      }
            `
    });
}