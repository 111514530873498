import { ReactElement, useState } from 'react';
import { search as searchIcon } from '../../assets/svg/dashboardIcons';
import Icon from './Icon';

interface BackendSearchProps {
  options: any
}

const BackendSearch = ( props: BackendSearchProps) : ReactElement => {

  const [search, setSearch] = props.options
  const [text, setText] = useState()

  const handleSearchChange = (event): void => {
    setText(event.target.value)
  }

  const KeyPressHandler =(event)=>{
    if(event.key == 'Enter'){
      setSearch(text)
    }
  }

  return (
    <div className=" w-full md:mt-8 flex flex-col">
      <div className='pr-4'>
        <div className="w-4/6 flex justify-center items-center shadow-sm border rounded-lg border-gray-secundary focus:shadow-inner">
          <input
            onKeyPress={KeyPressHandler}
            type="search"
            onChange={handleSearchChange}
            defaultValue={search}
            placeholder="Buscar orientado por nombre, apellido o DNI y presione Enter"
            className="flex-1 p-3 ml-1 text-xs text-gray-principal focus:outline-none"
          />
          <span className="pr-2">
            <button onClick={()=>{setSearch(text)}} >
              <Icon path={searchIcon} color={'#2E384D'} width={22} height={18} />
            </button>
          </span>
        </div>
      </div>
    </div>
  )
}

export default BackendSearch