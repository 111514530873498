import { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import NewProgram from '../../components/Biblioteca/NewProgram';
import NewLayout from '../../components/Layout/NewLayout';
import { setCurrentSection } from '../../slices/navSlice';
import BreadCrumb from '../../components/UI/BreadCrumb';
import BreadCrumbItem from '../../components/UI/BreadCrumbItem';

const NewProgramsPage =(): ReactElement => {

  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(setCurrentSection('Biblioteca'))
  })

  return (
    <NewLayout>
      <div className='px-12'>
        <BreadCrumb>
          <BreadCrumbItem Name={'Biblioteca'} path={'/library'} isCurrentPage={false} />
          <BreadCrumbItem Name={'Programas'} path={'/library/programs'} isCurrentPage={false} />
          <BreadCrumbItem Name={'Nuevo Programa'} path={'/library/programs/new'} isCurrentPage={true} />
        </BreadCrumb>
      </div>
      <NewProgram />
    </NewLayout>            
  )

}

export default NewProgramsPage;