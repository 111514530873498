import { ReactElement, ReactNode } from 'react';

type ParentProps ={
  children: ReactNode
}

const BreadCrumb = (props: ParentProps): ReactElement => {

  return (
    <div className="mt-12 w-5/6 flex justify-between">
      <div className="w-auto flex">
        {props.children}
      </div>
    </div>
  );
};

export default BreadCrumb;
