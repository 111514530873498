import { ReactElement } from 'react';
import { search as searchIcon} from '../../assets/svg/dashboardIcons';
import Icon from './Icon';

interface SearchBoxProps {
  options: any,
  placeholder: string
}

const SearchBox = ( props: SearchBoxProps) : ReactElement => {

  const [search, setSearch] = props.options

  const handleSearch = (event): void => {
    setSearch(event.target.value)
  }
  const handleOnKeyPress = (event): void => {
    if(event.key == 'Enter'){
      setSearch(event.target.value)
    }
  }

  return (
    <div className=" w-full mt-4 flex flex-col">
      <div className='pr-4'>
        <div className="w-4/6 flex justify-center items-center shadow-sm border rounded-lg border-gray-secundary focus:shadow-inner">
          <input
            onKeyPress={handleOnKeyPress}
            type="search"
            onChange={handleSearch}
            defaultValue={search}
            placeholder={props.placeholder}
            className="flex-1 p-3 ml-1 text-xs text-gray-principal focus:outline-none"
          />
          <span className="pr-2">
            <button onClick={handleSearch} >
              <Icon path={searchIcon} color={'#2E384D'} width={22} height={18} />
            </button>
          </span>
        </div>
      </div>
    </div>
  )
}

export default SearchBox