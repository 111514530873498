import { Roles } from './Roles'

export const role = (role: string): string => {
  switch (role) {
    case Roles.ORIENTED:
      return 'Orientado'
    case Roles.ORIENTEER:
      return 'Orientador'
    case Roles.ADMIN:
      return 'Administrador'
    default:
      return ''
  }
}

export const labelsByRole = (role: string): Array<string> => {
  switch (role) {
    case Roles.ORIENTED:
      return ['dni', 'domicilio', 'email', 'colegio', 'expectativas', 'Porque se acercó a DAlfonso']
    default:
      return ['email', 'teléfono', 'linked in']
  }
}

export const valueByLabel = (label, info): string | undefined | number => {
  switch (label) {
    case 'dni':
      return info.dni
    case 'domicilio':
      return info.address
    case 'email':
      return info.user.email
    case 'colegio':
      return info.school
    case 'expectativas':
      return info.expectations
    case 'Porque se acercó a DAlfonso':
      return info.motive
    case 'teléfono':
      return info.user.phone
    case 'linked in':
      return info.socialNetwork
  }
}
