import { ReactElement } from 'react';
import NewLayout from '../../components/Layout/NewLayout';
import ProfessionLabDetail from '../../components/Inicio/Oriented/ProfessionLabDetail';
import {useParams} from 'react-router-dom'
import LoadingSpinner from '../../utils/spinner';
import BreadCrumb from '../../components/UI/BreadCrumb';
import ProgramBreadCrumb from '../../components/UI/ProgramBreadCrumb';
import BreadCrumbItem from '../../components/UI/BreadCrumbItem';
import { useSelector } from 'react-redux';
import { professionLabs } from '../../slices/labsSlice';

const ProfessionLabPage = (): ReactElement => {
  const { labId, programSlug } = useParams()
  const {labsList} = useSelector(professionLabs)

  const foundlab = labsList.find(item => item.id == labId)  
  let labTitle = foundlab ? foundlab.Title : '...'
  

  return (
    <NewLayout>
      <div className="w-full ml-11">
        {foundlab != undefined ? 
          <div>
            <BreadCrumb>
              <BreadCrumbItem Name={'Inicio'} path={'/home'} isCurrentPage={false}/>
              <ProgramBreadCrumb isCurrentPage={false}/>
              <BreadCrumbItem Name={'LAB de profesiones'} path={`/my-programs/${programSlug}/profession-labs`} isCurrentPage={false}/>
              <BreadCrumbItem Name={labTitle} isCurrentPage={true}/>
            </BreadCrumb>
            <ProfessionLabDetail lab={foundlab} />
          </div> 
          : 
          <LoadingSpinner/> }
      </div>
    </NewLayout>
  );
};

export default ProfessionLabPage;
