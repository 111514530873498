import { ReactElement, useEffect, useState } from 'react'
import { reply, resend } from '../../assets/svg/dashboardIcons'
import ButtonSecundary from '../UI/ButtonSecundary'
import Button from '../UI/Button'
import Icon from '../UI/Icon'
import StringTodayDate from '../Mensajes/UI-mensajes/StringTodayDate'
import ToSelection from '../Mensajes/UI-mensajes/ToSelection'
import { useDispatch, useSelector } from 'react-redux'
import { messageState, selectMessage, setComponentToShow } from '../../slices/messageSlice'
import { sendMessageMutation } from '../../mutations/Message'
import { session } from '../../slices/sessionSlice'
import { setResponse, setShowModal } from '../../slices/modalSlice'
import { Roles } from '../../utils/Roles'
import { useMutation } from '@apollo/client'

const ViewMessage = (): ReactElement => {
  const [actionSelected, setAction] = useState('')
  const { selectedToView } = useSelector(messageState)
  const [sendMessage] = useMutation(sendMessageMutation)
  const { user } = useSelector(session)
  const [selectedToSend, setSelectedToSend] = useState([])
  const [body, setBody] = useState('')
  const [previousMessages, setPreviousMessages] = useState<Array<any>>([])
  const dispatch = useDispatch()
  const role = localStorage.getItem('role')

  const getPreviousMessages = (): void => {
    let previousMessage = selectedToView.previousMessage
    let messagesToSave: Array<any> = []
    for (let index = 0; index < 10; index++) {
      if (previousMessage != null) {
        messagesToSave.push(previousMessage)
        previousMessage = previousMessage.previousMessage
      }
      else {
        break
      }
    }
    setPreviousMessages(messagesToSave.reverse())
  }

  useEffect(() => {
    getPreviousMessages()
  }, [])

  const send = (): void => {
    sendMessage({
      variables: {
        data: {
          from: user.user.id,
          to: actionSelected === 'reply' ? [selectedToView.from.id] : selectedToSend,
          body: body,
          subject: selectedToView.subject,
          previousMessageId: selectedToView.id
        }
      }
    })
      .then(result => {
        if (result.data.saveMessage) {
          dispatch(setResponse({
            message: 'El mensaje se ha enviado con éxito.',
            error: false,
            description: ''
          }));
          dispatch(setShowModal(true));
          setAction('')
          setBody('')
        }
      }).catch(() => {
        dispatch(setResponse({
          message: 'Ups! No se pudo enviar el mensaje',
          error: true,
          description: 'Por favor reintenta nuevamente'
        }));
        dispatch(setShowModal(true));
      })
  }

  const handleBackToInbox = (): void => {
    dispatch(setComponentToShow(''))
    dispatch(selectMessage(''))
  }

  const handleBodyChange = (e): void => {
    setBody(e.target.value)
  }

  const handleCancelAction = (): void => {
    setAction('')
  }

  const sendTextAreaAndButton = (): ReactElement => {
    return (
      <div className="w-full">
        <textarea
          style={{ height: 175, margin: 0 }}
          value={body}
          placeholder="Escribir mensaje"
          onChange={handleBodyChange}
          className="w-full p-3 resize-none border rounded-lg border-gray-secundary focus:outline-none"
        />
        <div className="flex mt-8 pb-4 justify-between wrap">
          <Button width={'w-auto'} styles={'w-full justify-between '} text={actionSelected === 'reply' ? 'Responder mensaje' : 'Reenviar mensaje'} action={send} />
          <ButtonSecundary styles={'flex justify-end'} text={'Cancelar'} action={handleCancelAction}/>
        </div>
      </div>)
  }

  const switchSelected = (): ReactElement => {
    switch (actionSelected) {
      case 'resend':
        return <div className="w-full mt-4">
          <div className="w-full flex mb-4 items-center">
            <Icon path={resend} width={20} height={16} color={'#2E384D'} />
            <p className="ml-2 text-base text-gray-principal">Para: </p>
            <ToSelection selected={selectedToSend} setSeleted={setSelectedToSend} />
          </div>
          {sendTextAreaAndButton()}
        </div>
      case 'reply':
        return <div className="w-full mt-4">
          <div className="w-full flex mb-4 items-center">
            <p className="text-base text-gray-principal mr-1">Para:</p>
            <p className="text-base text-black-primary">{selectedToView.from.name}</p>
          </div>
          {sendTextAreaAndButton()}
        </div>
      default:
        return <div className="w-2/5 flex justify-between pb-4 mt-8">
          {!selectedToView.viewAsSender && <Button text={'Responder mensaje'} action={(): void => { setAction('reply') }} />}
          {role !== Roles.ORIENTED && <ButtonSecundary text={'Reenviar mensaje'} action={(): void => { setAction('resend') }} />}
        </div>
    }
  }

  return (
    <div className="w-full h-auto">
      <div className="flex border-b border-gray-secundary items-start w-full mt-8 pb-3">
        <span className='flex'>
          <button className="focus:outline-none mr-1 mt-1" onClick={handleBackToInbox}>
            <Icon path={reply} width={20} height={16} color={'#2E384D'} />
          </button>
          Atrás
        </span>
      </div>
      <div className="w-full flex flex-col pt-4 overflow-y-scroll">
        <div className="flex justify-between mb-4">
          <p className="text-lg text-black-primary">{selectedToView.subject}</p>
          <StringTodayDate predefinedDate={selectedToView.sentAt} />
        </div>
        {previousMessages.map((i, index) =>
          <div key={index} className="flex flex-col items-start border-b border-gray-secundary py-6">
            <p className="font-medium text-base">{i.from.name}</p>
            <p className="mt-2 text-xs text-black-primary w-4/5 text-left whitespace-pre">
              {i.body}
            </p>
          </div>)
        }
        <div className="flex flex-col items-start border-b border-gray-secundary py-6">
          <p className="font-medium text-base">{selectedToView.from.name}</p>
          <p className="mt-2 text-xs text-black-primary w-4/5 text-left whitespace-pre">
            {selectedToView.body}
          </p>
        </div>
      </div>
      {switchSelected()}
    </div>
  )
}

export default ViewMessage