import { ReactElement, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { downArrow, downloadIcon, upArrow, view } from '../../../assets/svg/dashboardIcons'
import Icon from '../../UI/Icon'


const Downloadable = ({data}): ReactElement => {
  const [expanded, setExpanded] = useState(false)
  const handleExpand = ():void => {
    setExpanded(!expanded)
  }

  return (
    <Accordion onChange={handleExpand} key={data.id} className="md:w-8/12 w-full" allowZeroExpanded>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton className="flex items-center text-base text-left justify-between border-b font-semibold py-4">
            <p className="pr-6">{data.Title}</p>
            <Icon path={expanded ? upArrow : downArrow} color={'#2E384D'} width={14} height={14} />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className="text-left text-xs py-4 text-black-primary">
          {data.Files && data.Files.map((file)=> 
            <div key={file.id} className="border-b py-4 border-gray-secundary flex justify-between items-center">
              <p className="text-base text-black-primary ">{file.Title}</p>
              <div className={'flex flex-wrap justify-between'}>
                <a href={'https://drive.google.com/uc?export=download&id=' + file.URL} download className="focus:outline-none cursor-pointer w-auto mr-2 px-5 transform hover:scale-110" >
                  <Icon path={downloadIcon} width={20} height={18} color={'#2E384D'} />
                </a>
                <a href={'https://drive.google.com/file/d/' + file.URL + '/view?usp=sharing'} target="_blank" rel="noopener noreferrer" className="focus:outline-none cursor-pointer w-auto mr-2 px-5 transform hover:scale-110" >
                  <Icon path={view} width={25} height={18} color={'#2E384D'} />
                </a>
              </div>
            </div>
          )}
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  )
}
export default Downloadable
