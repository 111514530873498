import { Roles } from './Roles';
import { ReactElement } from 'react';
import Icon from '../components/UI/Icon';
import { agenda, asignaciones, biblioteca, help, ingresos, inicio, mensajes, orientados, pagos } from '../assets/svg/navIcons';

interface NavOption {
  label: string,
  route: string,
  sectionName: string,
  icon: (string) => ReactElement
}


export const navOptions = (role: string): NavOption[] => {
  let options: NavOption[] = []
  let optionsOriented: NavOption[] = []
  let optionsOrienteer: NavOption[] = []
  let optionsAdmin: NavOption[] = []

  options.push({
    label: 'Inicio',
    route: 'home',
    sectionName: 'Inicio',
    icon: (color): ReactElement => (
      <Icon width={'23'} height={'20'} color={color} path={inicio}/>
    )
  })
  switch (role) {
    case Roles.ORIENTED:
      optionsOriented = [
        {
          label: 'Agenda',
          route: 'agenda',
          sectionName: 'Agenda',
          icon: (color): ReactElement => (
            <Icon width={'23'} height={'20'} color={color} path={agenda}/>
          )
        },
        {
          label: 'Actividades',
          route: 'activity',
          sectionName: 'Actividades',
          icon: (color): ReactElement => (
            <svg color={color} width={22} height={20} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M20,16v4a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V6A2,2,0,0,1,4,4H8" fill={'none'} stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
              <polygon fill={'none'} points="12.5 15.8 22 6.2 17.8 2 8.3 11.5 8 16 12.5 15.8" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            </svg>
          )
        },
        {
          label: 'Mensajes',
          route: 'messages',
          sectionName: 'Mensajes',
          icon: (color): ReactElement => (
            <Icon width={'22'} height={'20'} color={color} path={mensajes}/>
          )
        },
        // {
        //   label: 'Pagos',
        //   route: 'payments',
        //   sectionName: 'Pagos',
        //   icon: (color): ReactElement => (
        //     <Icon width={'22'} height={'20'} color={color} path={pagos}/>
        //   )
        // },
        {
          label: 'Ayuda',
          route: 'help',
          sectionName: 'Ayuda',
          icon: (color): ReactElement => (
            <Icon width={'20'} height={'20'} color={color} path={help}/>
          )
        }
      ]
      options.push(...optionsOriented)
      break;
    case Roles.ADMIN:
      optionsAdmin = [
        {
          label: 'Ingresos',
          route: 'admissions',
          sectionName: 'Ingresos',
          icon: (color): ReactElement => (
            <Icon width={'22'} height={'20'} color={color} path={ingresos}/>
          )
        },
        {
          label: 'Asignados',
          route: 'assigned',
          sectionName: 'Asignados',
          icon: (color): ReactElement => (
            <Icon width={'18'} height={'20'} color={color} path={asignaciones}/>
          )
        },
        {
          label: 'Agenda',
          route: 'agenda',
          sectionName: 'Agenda',
          icon: (color): ReactElement => (
            <Icon width={'22'} height={'20'} color={color} path={agenda}/>
          )
        },
        {
          label: 'Mensajes',
          route: 'messages',
          sectionName: 'Mensajes',
          icon: (color): ReactElement => (
            <Icon width={'22'} height={'20'} color={color} path={mensajes}/>
          )
        },
        {
          label: 'Pagos',
          route: 'payments',
          sectionName: 'Pagos',
          icon: (color): ReactElement => (
            <Icon width={'22'} height={'20'} color={color} path={pagos}/>
          )
        },
        {
          label: 'Biblioteca',
          route: 'library',
          sectionName: 'Biblioteca',
          icon: (color): ReactElement => (
            <Icon width={'22'} height={'20'} color={color} path={biblioteca}/>
          )
        }
      ]
      options.push(...optionsAdmin)

      break;
    case Roles.ORIENTEER:
      optionsOrienteer = [
        {
          label: 'Orientados',
          route: 'oriented',
          sectionName: 'Orientados',
          icon: (color): ReactElement => (
            <Icon width={'22'} height={'20'} color={color} path={orientados}/>
          )
        },
        {
          label: 'Agenda',
          route: 'agenda',
          sectionName: 'Agenda',
          icon: (color): ReactElement => (
            <Icon width={'23'} height={'20'} color={color} path={agenda}/>
          )
        },
        {
          label: 'Actividades',
          route: 'activity',
          sectionName: 'Actividades',
          icon: (color): ReactElement => (
            <svg color={color} width={22} height={20} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M20,16v4a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V6A2,2,0,0,1,4,4H8" fill={'none'} stroke={color} strokeLinecap="round" strokeLinejoin={'round'} strokeWidth="2"/>
              <polygon fill={'none'} points="12.5 15.8 22 6.2 17.8 2 8.3 11.5 8 16 12.5 15.8" stroke={color} strokeLinecap="round" strokeLinejoin={'round'} strokeWidth="2"/>
            </svg>
          )
        },
        {
          label: 'Mensajes',
          route:'messages',
          sectionName: 'Mensajes',
          icon: (color): ReactElement => (
            <Icon width={'22'} height={'20'} color={color} path={mensajes}/>
          )
        }
      ]
      options.push(...optionsOrienteer)

      break;
  }
  return options
}