import { checkStatus, parseJSON } from './checkStatus';

export const uploadFiles = async (data): Promise<Boolean | undefined> => {
  try {
    await fetch(process.env.REACT_APP_API + 'api/upload/feedback', {
      method: 'POST',
      body: data
    })
      .then(checkStatus)
      .then(parseJSON);
    return true
  } catch (error) {
    return false
  }

}


export const uploadImage = async (formData): Promise<Boolean | undefined> => {
  try {
    await fetch(process.env.REACT_APP_API + 'api/upload/profile_picture', {
      method: 'POST',
      body: formData
    })
      .then(checkStatus)
      .then(parseJSON);
    return true
  } catch (error) {
    return false
  }

}


export const uploadTicket = async (data): Promise<Boolean | undefined> => {
  try {
    await fetch(process.env.REACT_APP_API + 'api/upload/ticket', {
      method: 'POST',
      body: data
    })
      .then(checkStatus)
      .then(parseJSON);
    return true
  } catch (error) {
    return false
  }

}