import { ReactElement } from 'react';
import { Link } from 'react-router-dom';


const OrangeBorderCard = ({ title, description, width, height, link, margin= '', newtab }): ReactElement => {
  return (
    <Link style={{ whiteSpace: 'pre-line' }} to={location => ({ ...location, pathname: link })} replace target={newtab ? '_blank' : '_self'}  rel="noopener noreferrer" className={`border border-l-0 ${height} cursor-pointer ${margin} border-gray-secundary rounded-md ${width}`}>
      <div className="orange-card">
        <p className="title-smallCard">{title}</p>
        <p className="description-smallCard mt-2">{description}</p>
      </div>
    </Link>
  )
}

export default OrangeBorderCard