import { gql } from '@apollo/client';

export const GET_ALL_PROGRAMS = gql`
  query{
    getAllPrograms{
      id
      name
      description
      status
      activities{
        id
        accessLink
        name
        isRequired
      }
    }
  }
  `