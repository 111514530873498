import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteInterestLink, saveCommentMutation } from '../../mutations/Appointment';
import { setResponse, setShowModal } from '../../slices/modalSlice';
import Button from '../UI/Button';
import * as yup from 'yup';
import { useMutation, useQuery } from '@apollo/client';
import { GET_APPOINTMENT } from '../../queries/Appointment';
import { useParams } from 'react-router-dom';
import Linkify from 'linkify-react';
import {Link} from 'react-router-dom'
import LoadingSpinner from '../../utils/spinner';
import { add } from '../../assets/svg/dashboardIcons';
import Icon from '../UI/Icon';
interface LinkInterface {
  name: string,
  link: string
}

const Feedback = (): ReactElement => {
  const dispatch = useDispatch()
  const { appointmentId } = useParams();
  const { data, loading } = useQuery(GET_APPOINTMENT, {
    variables: { id: appointmentId }
  });
  const selectedAppointment = data ? data.appointmentById : null
  const [saveComment] = useMutation(saveCommentMutation);
  const [links, setLinks] = useState<Array<LinkInterface>>([])
  const [name, setName] = useState('')
  const [link, setLink] = useState('')
  const [comment, setComment] = useState('')
  const [detail, setDetail] = useState('')
  const [showComment, setShow] = useState(true)

  const renderLink = ({ attributes, content }) => {
    const { href } = attributes;
    return <Link to={href} className={'cursor-pointer  underline hover:text-green-principal text-green-principal'} >{content}</Link>;
  };

  useEffect(() => {
    if(selectedAppointment !== null){
      setLinks(selectedAppointment.interestLinks ? selectedAppointment.interestLinks : [])
      setComment(selectedAppointment.comments ? selectedAppointment.comments : '')
      setDetail(selectedAppointment.detail ? selectedAppointment.detail : '')
    }
  }, [selectedAppointment])

  const schema = yup.object().shape({
    name: yup.string().required(),
    link: yup.string().url().required()
  })

  const addLink = (): void => {
    schema
      .isValid({
        name,
        link
      }, { abortEarly: false })
      .then((res) => {

        if (!links.some(i => i.name == name && i.link == link) && res) {
          setLinks([...links, {
            name,
            link
          }])
        }
      })

    setLink('');
    setName('');
  }

  let interestLinksObj = () =>{
    if (links.length != 0) {
      let mapLinks = links.map((i)=>{return {name: i.name, link: i.link}})
      return mapLinks
    } else {
      return []
    }
  }

  const deleteLink = (link): void => {
    const linksModify = links.filter(i => i.name != link.name || i.link !== link.link)
    setLinks(linksModify)
  }

  const deleteLinkFromDatabase = (link): void => {
    deleteInterestLink(selectedAppointment.id, link.id).then(() => {
      dispatch(setResponse({
        message: 'El link de interés seleccionado se ha borrado correctamente',
        error: false,
        description: 'El orientado ya no podrá verlo'
      }))
      dispatch(setShowModal(true))
    })
    deleteLink(link)
  }

  const save = () =>{
    saveComment({
      variables: {
        data: {
          appointmentID: selectedAppointment.id,
          comments: comment,
          interestLinks: interestLinksObj()
        }
      }
    }).then (res => {
      if (res.data) {
        dispatch(setResponse({
          message: 'Los comentarios se enviaron con éxito.',
          error: false,
          description: 'El orientado podrá verlo'
        }))
        dispatch(setShowModal(true))
      }
    })
    setShow(true)
  }

  return loading ?
    <div className='w-full flex items-center justify-center'>
      <LoadingSpinner />
    </div> : 
    (
      <div className="w-full h-auto flex flex-col items-start">
        <label className="text-black-primary mt-2 text-base font-medium mb-2">Detalles del encuentro</label>
        <div className="text-black-primary text-xs w-88% text-left whitespace-pre-wrap ">
          <Linkify options={{render: renderLink}}>
            {detail.length >0 ? detail: 'Aún no hay detalles. Puede agregar/editar los mismos desde la agenda...'}
          </Linkify>
        </div>
        <label className="text-black-primary mt-10 text-base font-medium mb-2">Comentarios del encuentro</label>
        {showComment ?
          <div className="w-full flex flex-col items-start">
            <div className="text-black-primary text-xs w-88% text-left whitespace-pre-wrap">
              <Linkify options={{render: renderLink}}>
                {comment && comment.length >0 ? comment: 'Aún no hay comentarios...'}
              </Linkify>
            </div>
            <p className="text-black-primary mt-10 text-base font-medium">Links de interés</p>
            <div className="mt-2 w-full mb-12">
              {links.length == 0 ? <div className="w-full flex flex-col items-start">Aún no hay links de interés...</div> 
                : links.map((i, index) => (
                  <div
                    key={index}
                    className={`px-2 border-b ${index == 0 && 'border-t'} py-5 border-gray-secundary flex justify-between w-3/4`}
                  >
                    <p className="text-black-primary text-xs">{i.name}</p>
                    <p className="text-black-primary text-xs cursor-pointer  underline hover:text-green-principal text-green-principal" onClick={():Window|null => window.open(i.link,'_blank')}>{i.link}</p>
                    <p className="cursor-pointer" onClick={(): void => deleteLinkFromDatabase(i)}>x</p>
                  </div>
                ))
              }
            </div>
            <Button text={'Editar'} action={(): void => setShow(false)} styles='mb-12' />
          </div> :
          <div className="w-full flex flex-col items-start">
            <textarea
              style={{ height: 104, margin: 0 }}
              value={comment.length >0 ? comment : ''}
              placeholder="Escribir comentario"
              onChange={(e): void => setComment(e.target.value)}
              className="w-3/5 p-3 resize-none border rounded-lg border-gray-secundary focus:outline-none"
            />
            <div className="w-1/4 mt-6 flex justify-between items-center">
              <Button disabled={comment.length === 0} text="Guardar comentario" action={save} />
              <a 
                className="cancel-link py-2" 
                onClick={(): void => {
                  setShow(true)}
                }
              >
                Cancelar
              </a>
            </div>
            <p className="text-black-primary mt-8 text-base font-medium">Links de interés</p>
            <div className="flex w-4/5 items-end mt-2">
              <div className="w-1/2 flex flex-col mr-4">
                <label className="label-update">Título</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e): void => setName(e.target.value)}
                  placeholder="Ingresar el título del link"
                  className="w-full p-3 mt-2 text-black-primary bg-white border rounded-lg border-gray-secundary focus:outline-none" />
              </div>
              <div className="w-1/2 flex flex-col mr-4">
                <label className="label-update">Link</label>
                <input
                  value={link}
                  placeholder="Ingresar la dirección URL"
                  onChange={(e): void => setLink(e.target.value)}
                  type="text"
                  className="w-full p-3 mt-2 text-black-primary bg-white border rounded-lg border-gray-secundary focus:outline-none"
                />
              </div>
              <button
                type="button"
                className="focus:outline-none py-4"
                onClick={addLink}
              >
                <Icon width={20} height={18} path={add} color={'#0BA4A0'} />
              </button>
            </div>
            <div className="mt-2 w-full">
              {links.map((i, index) => (
                <div
                  key={index}
                  className={`px-2 border-b ${index == 0 && 'border-t'} mt-6 mb-2 py-5 border-gray-secundary flex justify-between w-3/4`}
                >
                  <p className="text-black-primary text-xs">{i.name}</p>
                  <p className="text-black-primary text-xs cursor-pointer  underline hover:text-green-principal text-green-principal" onClick={():Window|null => window.open(i.link,'_blank')}>{i.link}</p>
                  <p className="cursor-pointer" onClick={(): void => deleteLink(i)}>x</p>
                </div>
              ))}
            </div>
            <div className="w-1/4 mt-6 pb-12 flex justify-between items-center">
              <Button disabled={links && links.length ==0} text="Guardar Links" action={save} />
              <a 
                className="cancel-link " 
                onClick={(): void => {
                  setShow(true)}
                }
              >
                Cancelar
              </a>
            </div>
          </div>
        }
      </div>
    )
}

export default Feedback
