import { ReactElement, useState } from 'react';
import Button from '../UI/Button';
import StringTodayDate from './UI-mensajes/StringTodayDate';
import { useDispatch, useSelector } from 'react-redux';
import ToSelection from './UI-mensajes/ToSelection';
import { sendMessageMutation } from '../../mutations/Message';
import { session } from '../../slices/sessionSlice';
import { setResponse, setShowModal } from '../../slices/modalSlice';
import { useHistory } from 'react-router-dom';
import ButtonSecundary from '../UI/ButtonSecundary';
import { useMutation } from '@apollo/client';


const SendMessage = (): ReactElement => {
  const dispatch = useDispatch()
  const [sendMessage] = useMutation(sendMessageMutation)
  const [body, setBody] = useState('')
  const [subject, setSubject] = useState('')
  const { user } = useSelector(session)
  const [selected, setSelected] = useState<Array<String>>([])
  const history = useHistory();
  const handleCancelAction = (): void => {
    history.push('/messages')
  }


  const handleChangeBody = (e):void => {
    setBody(e.target.value)
  }

  const handleChangeSubject = (e):void => {
    setSubject(e.target.value)
  }

  const send = (e): void => {
    e.preventDefault();
    sendMessage({
      variables: {
        data: {
          from: user.user.id,
          to: selected,
          body: body,
          subject: subject
        }
      }
    })
      .then(result => {
        if (result.data.saveMessage) {
          dispatch(setResponse({
            message: 'Tu mensaje fue enviado con éxito',
            error: false,
            description: 'La/s persona/s seleccionadas lo estarán recibiendo'
          }));
          dispatch(setShowModal(true))
          history.push('/messages');
        }
      }).catch(() => {
        dispatch(setResponse({
          message: 'Ups! No se pudo enviar el mensaje',
          error: true,
          description: 'Por favor reintenta nuevamente'
        }));
        dispatch(setShowModal(true));
      })
  }

  return (
    <div className="w-5/6 ml-11">
      <div className="w-full pb-8">
        <div className="w-full mt-16 border-t border-gray-secundary flex flex-col items-start">
          <div className="w-full mt-4 flex justify-between items-center">
            <p className="text-lg text-black-primary">Nuevo mensaje</p>
            <StringTodayDate />
          </div>
          <form onSubmit={send} className="w-full">
            <div className="w-full border-b border-gray-secundary mt-7 pb-5 flex items-center">
              <p className="text-base text-gray-principal text-left">Para:</p>
              <ToSelection selected={selected} setSeleted={setSelected} />
            </div>
            <div className="w-full mt-4 flex mb-4">
              <p className="text-base text-gray-principal text-left">Asunto:</p>
              <input required value={subject} onChange={handleChangeSubject} type="text" className="focus:outline-none ml-2 w-full" />
            </div>
            <textarea
              style={{ height: 200, margin: 0 }}
              value={body}
              required
              placeholder="Escribir mensaje"
              onChange={handleChangeBody}
              className="w-full p-3 resize-none border rounded-lg border-gray-secundary focus:outline-none"
            />
            <div className="flex mt-8 pb-4 justify-between wrap">
              <Button width={'w-auto'} styles={'w-full justify-between'}  text={'Enviar mensaje'} action={() => { }} />
              <div>
                <ButtonSecundary styles={'flex justify-end'} text={'Cancelar'} action={handleCancelAction}/>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SendMessage