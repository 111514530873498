
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { markEventAsFinished } from '../../../../mutations/Appointment';
import { setResponse, setShowModal } from '../../../../slices/modalSlice';
import {isOlder, isFinished, isInProgress } from '../../../../utils/appointmentsUtils';
import { Roles } from '../../../../utils/Roles';
import { format } from 'date-fns';
import { getTime } from '../../../../utils/utilsDate';
import LoadingSpinner from '../../../../utils/spinner';
import Table from '../../../UI/Table';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

type AppointmentsData = {
  querydata: any;
  edit: any
}

const AppointmentsTabs = ( props: AppointmentsData ): ReactElement => {
  const edit = props.edit
  const [appointmentsToShow, setAppointmentsToShow] = useState([]);
  const role = localStorage.getItem('role');
  const isOrienteer = role === Roles.ORIENTEER;
  const dispatch = useDispatch();
  const timeZone = useSelector((state: any) => state.session.timezone);

  const orderColummns: Array<{
    id: string;
    desc: boolean;
  }> = [
    {
      id: 'date',
      desc: true,
    },
  ];

  const finishAppointment = (i): void => {
    markEventAsFinished(i.id)
      .then((): void => {
        props.querydata.refetch();
        dispatch(
          setResponse({
            message: 'El evento fue marcado como finalizado',
            error: false,
            description: 'El orientado lo verá en sus encuentros finalizados',
          })
        );
        dispatch(setShowModal(true));
      })
      .catch((): void => {
        dispatch(
          setResponse({
            message: 'El evento no pudo ser marcado como finalizado',
            error: true,
            description: 'Intentar nuevamente',
          })
        );
        dispatch(setShowModal(true));
      });
  };

  //modified logic for showing data in appointments table
  useEffect(() => {
    if (props.querydata.data) {
      const appointmentData = role == Roles.ORIENTED ?
        props.querydata.data.getAppointmentsBySlug : props.querydata.data.getAppointmentsByOriented

      const formatterOrienteers = appointmentData.map(
        (appointment) => {
          let userArr: any = [];
          if (appointment.confirmedOrienteers) {
            for (var i = 0; i < appointment.confirmedOrienteers.length; i++) {
              userArr.push(appointment.confirmedOrienteers[i].user);
            }
          }
          return {
            date: appointment.date,
            time: getTime( appointment.date, timeZone),
            id: appointment.id,
            detail: appointment.detail,
            name: appointment.name,
            status: appointment.status,
            orienteers: userArr.map((item) => item.name).join(', '),
            program:appointment.program.name,
            timeSpan: appointment.timeSpan
          };
        }
      )
      props.querydata.refetch();
      setAppointmentsToShow(formatterOrienteers);

    }
  }, [props.querydata]);

  const mapState = (appointment): ReactElement => {
    let stateToShow: ReactElement = <div />;

    if(appointment.statusVisual) {
      switch (appointment.statusVisual) {
        case 'INPROGRESS':
          stateToShow = (<div className='flex items-center'>
            <div className="w-auto rounded-sm flex items-center h-auto text-black-primary text-xs p-1 bg-green-success">
              En progreso
            </div>
          </div>)
          break;
        case 'TIMEUP':
          stateToShow = (<div className='flex items-center'>
            <div className="w-auto rounded-sm flex items-center h-auto text-black-primary text-xs p-1 bg-gray-secundary">
              Finalizado
            </div>
          </div>)
          break;
      }
      return stateToShow
    }

    switch (appointment.status) {
      case 'OPEN' || 'ACTIVE':
        stateToShow = (
          <div className="flex items-center">
            <div className="w-auto rounded-sm flex items-center h-auto text-black-primary text-xs p-1 bg-green-success">
              Agendado
            </div>
          </div>
        );
        break;
      case 'PAUSED' || 'SUSPENDED':
        stateToShow = (
          <div className="w-auto rounded-sm h-auto text-black-primary text-xs p-1 bg-yellow-info">
            A definir
          </div>
        );
        break;
      case 'FINISHED':
        stateToShow = (
          <div className="flex items-center">
            <div className="w-auto rounded-sm flex items-center h-auto text-black-primary text-xs p-1 bg-gray-secundary">
              Terminado
            </div>
          </div>
        );
        break;
    }
    return stateToShow;
  };

  const columns = [
    {
      Header: 'Fecha',
      accessor: 'date',
      Cell: ({ value }): string => {
        return format(new Date(value), 'dd/MM/yyyy');
      },
    },
    {
      Header: 'Horario',
      accessor: 'time',
    },
    {
      Header: 'Nombre del encuentro',
      accessor: 'name',
    },
    {
      Header: 'Orientador',
      accessor: 'orienteers',
    },
    {
      Header: 'Estado',
      accessor: 'status',
    },
    {
      Header: 'Programa',
      accessor: 'program',
    }
  ];

  const temp = appointmentsToShow.filter(i =>  (!(isFinished(i)) && !(isOlder(i)) && !(isInProgress(i))))
  const inProgress = appointmentsToShow.filter(i => isInProgress(i))
  const currents = temp.concat(inProgress)
  const olders = appointmentsToShow.filter(i => isOlder(i) || isFinished(i))
  const tableForCurrentsAppointments = (data): ReactElement => {
    if (isOrienteer) {
      return (
        <div className={'w-full'}>
          <Table
            data={data}
            initialRows={5}
            edit={(i): void => {
              edit(i);
            }}
            columns={columns}
            state={mapState}
            markAsFinished={finishAppointment}
            orderColumnArray={orderColummns}
          />
        </div>
      );
    } else {
      return (
        <div
          className={'w-full'}
        >
          <Table
            data={data}
            initialRows={5}
            edit={(i): void => {
              edit(i);
            }}
            columns={columns}
            state={mapState}
            orderColumnArray={orderColummns}
          />
        </div>
      );
    }
  };

  const tableForOldersAppointments = (data): ReactElement => {
    return (
      <div
        className={'w-full'}
      >
        <Table
          data={data}
          initialRows={5}
          edit={(i): void => {
            edit(i);
          }}
          columns={columns}
          state={mapState}
          orderColumnArray={orderColummns}
        />
      </div>
    )
  } 

  return (
    <div className={'w-full mb-10'}>
      {props.querydata.loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <Tabs
            className={'w-full flex flex-wrap items-center justify-between'}
            selectedTabClassName={'text-gray-800 border-b-4 border-green-secundary'}
          >
            <TabList className={'flex mb-0 list-none ml-1.5 '}>
              <Tab
                className={'text-gray-400 border-b-4 border-gray-200 px-14 pt-1 cursor-pointer text-base hover:text-black-primary  capitalize py-4 block leading-normal '}
              >
                Próximos encuentros
              </Tab>
              <Tab
                className={'text-gray-400 border-b-4 border-gray-200 px-14 pt-1 cursor-pointer text-base hover:text-black-primary  capitalize py-4 block leading-normal '}
              >
                Encuentros anteriores
              </Tab>
            </TabList>
            <TabPanel className={'w-full flex items-center justify-between'}>
              {props.querydata.loading ? (
                <LoadingSpinner />
              ) : (
                tableForCurrentsAppointments(currents)
              )}
            </TabPanel>
            <TabPanel className={'w-full flex items-center justify-between'}>
              {props.querydata.loading ? (
                <LoadingSpinner />
              ) : (
                tableForOldersAppointments(olders)
              )}
            </TabPanel>
          </Tabs>
        </div>
      )
      }
    </div>
  );
};

export default AppointmentsTabs;
