import { ReactElement } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

interface InputTagProps {
    options: any,
    defaultOptions: any,
    placeholder: string,
    state: any
  }

const InputTag = (props: InputTagProps): ReactElement => {
  const [ optionFilterSelected, setOptionFilterSelected] = props.state

  const animatedComponents = makeAnimated();

  const customStyles = {
    control: () => ({
      margin: 'auto',
      padding: 3,
      height: 'auto',
      display: 'flex',
      borderRadius: '20px',
      border:'1px solid #e5e7eb',
      '&:hover': {
        border: '1px solid #0BA4A0'
      }
    }),
    option: (state) => ({
      color: state.isFocused ? 'white' : 'black',
      padding: 6,
      backgroundColor: state.isFocused ? '#0BA4A0' : 'white',
      '&:hover': {
        backgroundColor: '#D5F4DD',
        cursor: 'pointer'
      }
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor:  '#D5F4DD'
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: 'black',
      '&:hover': {
        backgroundColor: '#0BA4A0',
        color: 'white'
      }
    })
  }

  const handleChange = (newValue, actionMeta) => {
    if (actionMeta.action == 'select-option') {
      setOptionFilterSelected((filterOptions) => [
        ...filterOptions,
        actionMeta.option,
      ]);
    }
    if (actionMeta.action == 'remove-value') {
      setOptionFilterSelected(
        optionFilterSelected.filter((e) => {
          return e.value != actionMeta.removedValue.value;
        })
      );
    }
  };

  return (
    <div className='w-3/6 text-xs text-black-primary rounded-full'>
      <Select
        options={props.options}
        components={animatedComponents}
        isClearable={false}
        defaultValue={props.defaultOptions}
        className='basic-multi-select'
        placeholder={props.placeholder}
        isMulti
        styles={customStyles}
        noOptionsMessage={()=> 'No hay más opciones'}
        onChange={handleChange}
      />
    </div>
  );
};

export default InputTag;