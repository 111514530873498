import { ReactElement } from 'react';
import Table from '../UI/Table';

const TableStrapiData = (dataARG: any): ReactElement => {
  const items = Array.isArray(dataARG) ? dataARG : Object.values(dataARG);

  const columns = [
    {
      Header: 'Slug',
      accessor: 'slug',
    },

    {
      Header: 'Nombre',
      accessor: 'name',
    },
  ];

  const EmptyArrayMessage = ({ message }): ReactElement => (
    <div className="w-5/6 flex justify-center items-center">
      <p className="text-base py-4 text-green-principal">{message}</p>
    </div>
  );

  return dataARG.length == 0 ? (
    <EmptyArrayMessage message={'No hay datos'} />
  ) : (
    items && <Table columns={columns} data={items[0]} initialRows={10} />
  );
};

export default TableStrapiData;
