import { ReactElement } from 'react-markdown/lib/react-markdown';

interface RouterPromptProps {
  when: any;
  onOK: any;
  onCancel: any;
  description: string;
  btn?: ReactElement 
}

const AlertPrompt = (props: RouterPromptProps): ReactElement => {
  return props.when ? (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0  outline-none focus:outline-none">
        <div className="bg-gray-modal p-4 rounded flex flex-col justify-around items-center my-4 shadow-lg max-w-2xl w-full">
          <div className="relative p-6 flex-auto">
            <p
              className="text-black text-base font-medium leading-tight tracking-tighter"
              style={{ whiteSpace: 'pre-line' }}
            >
              {props.description}
            </p>
          </div>
          <div>
            {props.btn}
          </div>
        </div>
      </div>
    </>
  ) : (
    <div />
  );
};

export default AlertPrompt;
