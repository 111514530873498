import { ReactElement } from 'react-markdown/lib/react-markdown';
import NewLayout from '../../components/Layout/NewLayout';
import { setCurrentSection } from '../../slices/navSlice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { Roles } from '../../utils/Roles';
import OrienteerAssignedActivities from '../../components/Actividades/OrienteerAssignedActivities';
import OrientedAssignedActivities from '../../components/Actividades/OrientedAssignedActivities';

const ActivityPage = (): ReactElement => {
  const dispatch = useDispatch();
  const role = localStorage.getItem('role');

  useEffect(() => {
    dispatch(setCurrentSection('Actividades'))
  })
  

  return (
    <NewLayout>
      <div>
        <div className={'w-full ml-11'}>
          {
            role == Roles.ORIENTEER ? 
              <OrienteerAssignedActivities/> 
              : 
              role == Roles.ORIENTED ?
                <OrientedAssignedActivities/> 
                :
                <div/>
          }
        </div>
      </div>
    </NewLayout>
  );
};

export default ActivityPage;
