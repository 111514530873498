import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ActivityCard from './ActivityCard';
import Carousel from 'react-multi-carousel';
import { responsive } from '../../../../constants/sizes';
import { setIsFinished } from '../../../../slices/actSlice';

const Activities = ({ required, notRequired }): ReactElement => {
  const dispatch = useDispatch()
  const [requiredActivities, setRequiredActivities] = useState<any>([])
  const [notRequiredActivities, setNotRequiredActivities] = useState<any>([])

  useEffect(()=> {
    if (required != undefined && required.length>0) {
      let activities: Array<ReactElement> = []
      required.map((i, index) => (
        activities.push(<ActivityCard info={i} key={index} />)
      ))
      setRequiredActivities(activities);
    }
  }, [required])

  useEffect(()=> {
    if (notRequired != undefined && notRequired.length>0) {
      let activitiesNotRequired: Array<ReactElement> = []
      notRequired.map((i, index) => (
        activitiesNotRequired.push(<ActivityCard info={i} key={index} />)
      ))
      setNotRequiredActivities(activitiesNotRequired);
    }
  }, [required])

  useEffect(()=>{
    dispatch(setIsFinished(false))
  }, [required, notRequired])
  
  const CustomLeftArrow = ({ onClick }): ReactElement => {
    return <button className="focus:outline-none text-opacity-50 hover:text-opacity-100 hover:bg-opacity-100 font-bold text-base text-center left-0 top-28 -mt-4 flex justify-center items-center absolute rounded-full bg-white bg-opacity-50 border hover:border-orange-secundary w-12 h-12 text-orange-secundary border-solid" onClick={(): void => onClick()}>{'<'}</button>
  };

  const CustomRightArrow = ({ onClick }): ReactElement => {
    return <button className="focus:outline-none font-bold text-base text-center right-0 top-28 -mt-4 flex justify-center items-center absolute rounded-full bg-white border border-orange-secundary w-12 h-12 text-orange-secundary border-solid" onClick={(): void => onClick()}>
      {'>'}
    </button>
  };

  return (
    <div className="w-full flex flex-col items-start mt-5">
      <p className="title-bold ml-1.5">Actividades</p>
      {required != undefined && required.length>0 ? 
        <div className="mt-6 xl:max-w-screen-2xl w-screen -ml-0.5">
          <Carousel
            swipeable={false}
            draggable={false}
            responsive={responsive}
            sliderClass={'react-multi-carousel-track h-full'}
            containerClass="h-72 w-3/4"
            itemClass=" w-full h-full px-2"
            arrows={true}
            partialVisbile={false}
            slidesToSlide = {4}
            customRightArrow={<CustomRightArrow onClick />}
            customLeftArrow={<CustomLeftArrow onClick />}
          >
            {requiredActivities.map(activity => (activity))}
            {/* {required && required.map( activity => {
              return <ActivityCard info={activity.i} key={activity.index} />
            })} */}
          </Carousel>
        </div>
        : <div className="ml-1.5 mb-20 mt-10">No hay actividades asignadas aún...</div>
      }
      <p className="title-bold -mt-8 ml-1.5">Actividades opcionales</p>
      {notRequired != undefined && notRequired.length>0 ? 
        <div className=" mb-8 mt-6 xl:max-w-screen-2xl w-screen -ml-0.5">
          <Carousel
            swipeable={false}
            draggable={false}
            responsive={responsive}
            sliderClass={'react-multi-carousel-track h-full'}
            containerClass="h-72 w-3/4"
            itemClass=" w-full h-full px-2"
            arrows={true}
            partialVisbile={false}
            slidesToSlide = {4}
            customRightArrow={<CustomRightArrow onClick />}
            customLeftArrow={<CustomLeftArrow onClick />}
          >
            {notRequiredActivities.map(activity => (activity))}
          </Carousel>
        </div>
        : <div className="ml-1.5 mb-20 mt-10">No hay actividades asignadas aún...</div>
      }
    </div>
  )
}

export default Activities