import { Field, FieldArray, Formik } from 'formik'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { createPayment } from '../../mutations/Payment'
import { setResponse, setShowModal } from '../../slices/modalSlice'
import Button from '../UI/Button'
import * as yup from 'yup';
import Icon from '../UI/Icon'
import { add, remove } from '../../assets/svg/dashboardIcons'
import CustomDatePicker from '../UI/CustomDatePicker'
import { getScriptDate } from '../../utils/utilsDate'
import { GET_ALL_PROGRAMS } from '../../queries/Program';
import { useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom';

const PaymentForm = ({ userId }): ReactElement => {
  const history = useHistory()
  const [programSelected, setProgramSelected] = useState<string>('')
  const [programs, setPrograms] = useState<any>([])
  const [dates, setDates] = useState<Array<Date>>([])
  const dispatch = useDispatch()
  const { data: allProgramsData, loading: loadingPrograms } = useQuery(GET_ALL_PROGRAMS)

  useEffect(() => {
    if (!loadingPrograms && allProgramsData != undefined  && allProgramsData?.getAllPrograms) {
      setPrograms(allProgramsData.getAllPrograms)
    }
  })


  const handleSelectChange = (event): void => {
    if (event.target.value != '') {
      setProgramSelected(event.target.value)
    }
  }
  const selectDateHandler = (d, currentIndex): void => {
    if (currentIndex === dates.length - 1) {
      var newToSave = dates.map(function (i, index) {
        return index === currentIndex ? d : i
      })
      setDates(newToSave)
    }
    else {
      setDates([...dates, d])
    }
  };

  const getCuotas = (values): string => {
    let cuotasModified: string = '[';

    values.map((i, index) => {
      cuotasModified = cuotasModified + `
      {value: ${i}, dueDate: "${getScriptDate(dates[index])}"
    }
  `
    })
    cuotasModified = cuotasModified + ']'
    return cuotasModified
  }

  const goBack = () => {
    history.push(`/admin-oriented-info/${userId}`)
  }

  return (
    <div>
      <Formik
        initialValues={{
          discountMotive: '',
          discountAmount: 0,
          price: 0,
          value: [],
          programSlug: programSelected
        }}
        onSubmit={async (values):Promise<void> => {
          const {
            discountMotive,
            discountAmount,
            price,
            value
          } = values;
          createPayment(`{
            orientedId:"${userId}",
            payments:[{
            discountMotive:"${discountMotive}" ,
            discountAmount:${discountAmount},
            price:  ${price},
            quotas:${getCuotas(value)},
            programSlug:"${programSelected}"}]}`).then((result) => {
            if (result.data.createPayment) {
              dispatch(setResponse({
                message: 'El pago ya fue creado.',
                error: false,
                description: 'Recibira una notificacion'
              }));
              dispatch(setShowModal(true));
            }
          }).catch(() => {
            dispatch(setResponse({
              message: 'No se pudo crear el pago',
              error: true,
              description: 'Vuelva a intentarlo'
            }));
            dispatch(setShowModal(true));
          })
        }} validationSchema={yup.object().shape({
          price: yup.string().required('Requerido'),

        })}>
        {({ values, handleChange, handleSubmit }):ReactElement => {
          return (
            <form onSubmit={handleSubmit}>
              <div className='w-full md:w-1/2  mb-6'>
                <label className='label-form'>Programa</label>
                <div className='flex-shrink w-full inline-block relative'>
                  <select
                    value={programSelected}
                    onChange={handleSelectChange}
                    id='programSlug'
                    className='block appearance-none input-form input-back w-full'>
                    <option selected value={''}>Seleccione programa</option>
                    {programs.length !==0 &&
                      programs.map((i, index) => (
                        <option key={index} value={i.id}>{i.name}</option>
                      ))}
                  </select>
                  <div
                    className='pointer-events-none absolute top-0 mt-3  right-0 flex items-center px-2 text-gray-600'>
                    <svg className='fill-current h-4 w-4' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
                      <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                    </svg>
                  </div>
                </div>
              </div>
              <div className='flex items-center justify-between mt-4'>
                <div className='w-full md:w-1/2 mb-6'>
                  <label className='label-form'>Motivo de descuento</label>
                  <input
                    className='input-form input-back block w-full'
                    type='text'
                    value={values.discountMotive}
                    name='discountMotive'
                    onChange={handleChange}
                  />
                </div>
                <div className='w-full md:w-1/2 ml-3 mb-6'>
                  <label className='label-form'>Monto del descuento</label>
                  <input
                    className='w-full input-form input-back'
                    type='number'
                    value={values.discountAmount}
                    id='discountAmount'
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='flex items-center justify-between mt-4'>
                <div className='w-full md:w-1/2 mb-6'>
                  <label className='label-form'>Precio</label>
                  <input
                    className='input-form input-back block w-full'
                    type='number'
                    value={values.price}
                    name='price'
                    onChange={handleChange}
                  />
                </div>
              </div>
              <FieldArray
                name='value'
                render={(arrayHelpers):ReactElement => (
                  <div>
                    {values.value && values.value.length > 0 ? (
                      values.value.map((val, index) => (
                        <div key={index} className='flex items-center justify-between mt-4'>
                          <div className='w-full md:w-1/2 mb-6'>
                            <label className='label-form'>Valor de cuota</label>
                            <Field
                              step='0.1'
                              className='input-form input-back block w-full'
                              name={`value.${index}`}
                              type="number"
                              onChange={handleChange}
                            />
                          </div>
                          <div key={index} className='w-full md:w-1/2 ml-3 mb-6'>
                            <label className='label-form'>Fecha de vencimiento </label>
                            <CustomDatePicker
                              setValue={(d):void => selectDateHandler(d, index)}
                              placeholder='Ingresar fecha'
                              value={dates[index]}
                              minDateToday
                              background={'input-back'}
                            />
                          </div>
                          <button
                            type="button"
                            className="focus:outline-none"
                            onClick={():void => arrayHelpers.remove(index)}
                          >
                            <Icon width={20} height={18} path={remove} color={'#0BA4A0'} />

                          </button>
                          <button
                            type="button"
                            className="focus:outline-none"
                            onClick={():void => arrayHelpers.insert(index + 1, '')}
                          >
                            <Icon width={20} height={18} path={add} color={'#0BA4A0'} />

                          </button>
                        </div>
                      ))
                    ) : (
                      <div className="w-full flex">
                        <a className="mb-4 underline text-left text-xs text-green-principal hover:text-green-principal cursor-pointer" onClick={():void => arrayHelpers.push('')}>Agregar cuotas</a>
                        <Icon width={18} height={16} path={add} color={'#0BA4A0'} />
                      </div>
                    )}
                  </div>
                )} />
              <div className='flex justify-start items-center'>
                <Button disabled={!userId || userId === '' || values.value.length === 0 || values.value[0] === '' || dates.length === 0} text="Guardar plan" action={():void => { }} />
                <a href='#' onClick={goBack} className='cancel-link ml-4 flex-shrink-0'>Cancelar</a>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  )
}

export default PaymentForm