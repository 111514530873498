import { gql } from '@apollo/client';

export const ALL_ADMIN = gql`
  query{
    allAdmin{
      user{
        name
        id
      }
    }
  }
  `