import { createSlice } from '@reduxjs/toolkit';

interface modalStateI {
  data : {
    message: string,
    error: boolean,
    description: string
  },
  show: boolean
}

export const slice = createSlice({
  name: 'modal',
  initialState: {
    data: {
      message: '',
      error: false,
      description: ''
    },
    show: false
  },
  reducers: {
    setResponse: (state, action): void => {
      state.data = action.payload
    },
    setShowModal: (state, action): void => {
      state.show = action.payload
    }
  }
});

export const { setResponse, setShowModal } = slice.actions;
export const modalState = (state): modalStateI => state.modal;
export default slice.reducer;