import React, { ReactElement } from 'react';
import Modal from '../UI/Modal';
import GlobalHeader from './GlobalHeader';
import Sidebar from './Sidebar';
import '../../App.css';


const NewLayout = ({ children }: { children: React.ReactNode }): ReactElement => {

  const session = localStorage.getItem('session')

  // Si es la primer vez que ingresa o la sesion expiró, redirige al login
  if (localStorage.length == 0 || session === 'expired' || isTokenExpired()) {
    window.location.replace(process.env.REACT_APP_WEB + 'login');
    return (<div></div>)
  }   

  return (
    <div className="App">
      <div>
        <div>
          <div className="flex h-screen font-roboto">        
            <Sidebar />
            <div className="flex-1 flex flex-col overflow-hidden">
              <GlobalHeader/>
              <main className="flex-1 overflow-x-hidden overflow-y-auto">
                {children}
              </main>
              <Modal width={'w-2/5'} />    
            </div>          
          </div>
        </div>    
      </div>
    </div>
  );
};

function isTokenExpired(): boolean {
  const exp = localStorage.getItem('exp')
  
  if(!exp) return true;
  const expTime: number = parseInt(exp);

  if (Date.now() >= expTime * 1000) {
    return true;
  }
  
  return false;  
}



export default NewLayout;
