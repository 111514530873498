import { ReactElement } from 'react'
import { downloadIcon } from '../../assets/svg/dashboardIcons';
import { download } from '../../utils/exportExcelUtil';
import { exportExcel } from '../../utils/formsFunctions';
import Icon from './Icon';

const ExportToExcel = ({ slug, orientedDni, disabled = false }): ReactElement => {

  const exportToExcel = () => {
    exportExcel(slug, orientedDni).then(res => { res?.blob().then(blob => download(blob, slug + '_' + orientedDni )) })
  }

  return (
    <button className="focus:outline-none" onClick={():void => exportToExcel()} disabled={disabled}>
      <Icon path={downloadIcon} width={18} height={19} color={disabled ? '#DADEEB' : '#2E384D'} />
    </button>
  );
}

export default ExportToExcel
