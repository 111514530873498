import { checkStatus, parseJSON } from './checkStatus';
import { Form } from './FormInterface';

const headers = {
  'Content-Type': 'application/json',
};

export const getToken = async (): Promise<String> => {
  const data = await fetch('https://cms.dalfonso.org/auth/local', {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      identifier: 'dalfonso-reader@strapi.com',
      password: 'Pass.dalfon'
    })
  }).then(checkStatus)
    .then(parseJSON);
  return data.jwt
}


export const getForms = async (): Promise<Array<Form> | undefined> => {

  try {
    const forms = await fetch(process.env.REACT_APP_STRAPI + 'forms', {
      method: 'GET',
      headers: headers,
    })
      .then(checkStatus)
      .then(parseJSON);
    return forms
  } catch (error) {
    return undefined
  }

}

export const getFormBySlug = async (slug): Promise<Form | undefined> => {
  try {
    // const token = await getToken().then(res => res)
    const form = await fetch(`${process.env.REACT_APP_STRAPI}forms?Slug=${slug}`, {
      method: 'GET',
      // headers: new Headers({
      //   'Authorization': 'Bearer ' + token 
      // })
    })
      .then(checkStatus)
      .then(parseJSON);
    return form[0]
  } catch (error) {
    return undefined
  }
}

export const saveFormData = async (data): Promise<Boolean | undefined> => {

  try {
    const result = await fetch(process.env.REACT_APP_API + 'api/activity/data', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data)
    })
      .then(checkStatus)
      .then(parseJSON);
    // Si existe el tag error, devuelve false  
    if (result.error !== undefined ) return false;
    return true
  } catch (error) {
    return false
  }

}

export const exportExcel = async (form, orientedDni): Promise<any> => {
  try {
    const data = await fetch(process.env.REACT_APP_API + `api/form/export/${form}/${orientedDni}`)
      .then(function (response) {
        return response
      })
    return data
  } catch (error) {
    return undefined
  }
}