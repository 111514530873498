import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateOriented } from '../../mutations/Oriented';
import { updateOrienteer, updateAdmin } from '../../queries';
import { saveUserInfo } from '../../slices/sessionSlice';
import { Roles } from '../../utils/Roles';
import Button from '../UI/Button';

const EditProfile = ({ role, userInfo, closeEdit }): ReactElement => {
  const isOriented = role == Roles.ORIENTED
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [forename, setForename] = useState('')
  const [surname, setSurname] = useState('')
  const [address, setAddress] = useState('')
  const [school, setSchool] = useState('')
  const [expectations, setExpectations] = useState('')
  const [motive, setMotive] = useState('')
  const dispatch = useDispatch();
  const [linkedIn, setLinkedIn] = useState('')
  useEffect(() => {
    setEmail(userInfo.user.email)
    setPhone(userInfo.user.phone)
    setForename(userInfo.user.forename)
    setSurname(userInfo.user.surname)
    setAddress(isOriented ? userInfo.address : '')
    setSchool(isOriented ? userInfo.school : '')
    setExpectations(isOriented ? userInfo.expectations : '')
    setLinkedIn(isOriented ? '' : userInfo.socialNetwork)
    setMotive(isOriented? userInfo.motive : '')
  }, [])

  const updateUser = (): void => {
    switch (role) {
      case Roles.ORIENTED:
        updateOriented(userInfo.id,
          `{
            school: "${school}",
            address: "${address}",
            expectations: "${expectations}",
            motive:"${motive}"
          }`,
          `{
            email: "${email}",
            forename: "${forename}",
            surname: "${surname}",
            name: "${forename} ${surname}",
            phone: "${phone}"
          }`
        ).then(res => {
          if (res.data.updateOriented) {
            dispatch(saveUserInfo(res.data.updateOriented))
            closeEdit()
          }
        })
        break;
      case Roles.ORIENTEER:
        updateOrienteer(userInfo.id,
          `{
            socialNetwork: "${linkedIn}"
          }`,
          `{
            email: "${email}",
            phone: "${phone}"
          }`
        ).then(res => {
          if (res.data.updateOrienteer) {
            dispatch(saveUserInfo(res.data.updateOrienteer))
            closeEdit()
          }
        })
        break;
      case Roles.ADMIN:
        updateAdmin(
          `{
            adminId: "${userInfo.id}",
            phone: "${phone}",
            email: "${email}",
            socialNetwork: "${linkedIn}"
          }`
        ).then(res => {
          if (res.data.updateAdmin) {
            dispatch(saveUserInfo(res.data.updateAdmin))
            closeEdit()
          }
        })
        break;
    }

  }

  const setValue = (name, value): void => {
    switch (name) {
      case 'email':
        setEmail(value)
        break;
      case 'phone':
        setPhone(value)
        break;
      case 'forename':
        setForename(value)
        break;
      case 'surname':
        setSurname(value)
        break;
      case 'address':
        setAddress(value)
        break;
      case 'school':
        setSchool(value)
        break;
      case 'expectations':
        setExpectations(value)
        break;
      case 'linkedin':
        setLinkedIn(value)
        break;
      case 'motive':
        setMotive(value)
        break;
    }
  }

  const handleInputChange = (event): void => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setValue(name, value)
  }

  return (
    <div className="block md:w-full md:h-full md:mt-4">
      {isOriented ?
        <div>
          <div>
            <label className="label-update">Nombre</label>
            <input onChange={handleInputChange} value={forename} name={'forename'} type="text" className="block w-full p-3 mt-2 text-gray-700 bg-white appearance-none border rounded-lg border-gray-secundary focus:outline-none focus:bg-gray-300 focus:shadow-inner" required readOnly />
          </div>
          <div>
            <label className="label-update">Apellido</label>
            <input onChange={handleInputChange} value={surname} name={'surname'} type="text" className="block w-full p-3 mt-2 text-gray-700 bg-white appearance-none border rounded-lg border-gray-secundary focus:outline-none focus:bg-gray-300 focus:shadow-inner" required readOnly />
          </div>
          <div>
            <label className="label-update">Domicilio</label>
            <input onChange={handleInputChange} value={address} name={'address'} type="text" className="block w-full p-3 mt-2 text-gray-700 bg-white appearance-none border rounded-lg border-gray-secundary focus:outline-none focus:bg-gray-300 focus:shadow-inner" required />
          </div>
          <div>
            <label className="label-update">Teléfono</label>
            <input onChange={handleInputChange} value={phone} name={'phone'} type="text" className="block w-full p-3 mt-2 text-gray-700 bg-white appearance-none border rounded-lg border-gray-secundary focus:outline-none focus:bg-gray-300 focus:shadow-inner" required />
          </div>
          <div>
            <label className="label-update">Email</label>
            <input onChange={handleInputChange} value={email} name={'email'} type="text" className="block w-full p-3 mt-2 text-gray-700 bg-white appearance-none border rounded-lg border-gray-secundary focus:outline-none focus:bg-gray-300 focus:shadow-inner" required />
          </div>
          <div>
            <label className="label-update">Colegio</label>
            <input onChange={handleInputChange} value={school} name={'school'} type="text" className="block w-full p-3 mt-2 text-gray-700 bg-white appearance-none border rounded-lg border-gray-secundary focus:outline-none focus:bg-gray-300 focus:shadow-inner" required />
          </div>
          <div>
            <label className="label-update">Expectativas</label>
            <input onChange={handleInputChange} value={expectations} name={'expectations'} type="text" className="block w-full p-3 mt-2 text-gray-700 bg-white appearance-none border rounded-lg border-gray-secundary focus:outline-none focus:bg-gray-300 focus:shadow-inner" required />
          </div>
          <div>
            <label className="label-update">Porque se acercó a D'Alfonso</label>
            <input onChange={handleInputChange} value={motive} name={'motive'} type="text" className="block w-full p-3 mt-2 text-gray-700 bg-white appearance-none border rounded-lg border-gray-secundary focus:outline-none focus:bg-gray-300 focus:shadow-inner" required/>
          </div>
        </div>
        :
        <div>
          <div>
            <label className="label-update">Email</label>
            <input onChange={handleInputChange} value={email} name={'email'} type="text" className="block w-full p-3 mt-2 text-gray-700 bg-white appearance-none border rounded-lg border-gray-secundary focus:outline-none focus:bg-gray-300 focus:shadow-inner" required />
          </div>
          <div>
            <label className="label-update">Teléfono</label>
            <input onChange={handleInputChange} value={phone} name={'phone'} type="text" className="block w-full p-3 mt-2 text-gray-700 bg-white appearance-none border rounded-lg border-gray-secundary focus:outline-none focus:bg-gray-300 focus:shadow-inner" required />
          </div>
          <div>
            <label className="label-update">LinkedIn</label>
            <input onChange={handleInputChange} value={linkedIn} name={'linkedin'} type="text" className="block w-full p-3 mt-2 text-gray-700 bg-white appearance-none border rounded-lg border-gray-secundary focus:outline-none focus:bg-gray-300 focus:shadow-inner" required />
          </div>
        </div>
      }

      <div className={`${role == Roles.ORIENTED ? 'w-4/5' : 'w-3/5'} md:mt-6 items-center flex justify-between`}>
        <Button text={role == Roles.ORIENTED ? 'Confirmar datos' : 'Guardar'} action={updateUser} />
        <a onClick={(): void => closeEdit()} className="cancel-link">Cancelar</a>
      </div>
    </div>
  )
}

export default EditProfile