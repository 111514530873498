import { ReactElement, useState } from 'react';
import { search } from '../../assets/svg/dashboardIcons';
import { Oriented } from '../../utils/OrientedInterface';
import Icon from './Icon';


const OrientedsSearch = ({ componentToShow, oriented }:
  {
    componentToShow: (data: any) => ReactElement,
    oriented: Array<Oriented>
  })
  : ReactElement => {

  const [toSearch, setSearch] = useState('')

  const filteredOrientedList = toSearch != '' ?
    oriented.filter(i => i.dni.toString().toLocaleLowerCase().includes(toSearch.toLocaleLowerCase()) || i.user.name.toLocaleLowerCase().includes(toSearch.toLocaleLowerCase()))
    : oriented

  const handleSearchChange = (event): void => {
    setSearch(event.target.value)
  }

  return (
    <div className=" w-full md:mt-8 flex flex-col">
      <div className='pr-4'>
        <div className="w-4/6 flex justify-center items-center shadow-sm border rounded-lg border-gray-secundary focus:shadow-inner">
          <input
            type="search"
            onChange={handleSearchChange}
            placeholder="Buscar orientado por nombre, apellido o DNI"
            className="flex-1 p-3 ml-1 text-xs text-gray-principal focus:outline-none"
          />
          <span className="pr-2">
            <Icon path={search} color={'#2E384D'} width={22} height={18} />
          </span>
        </div>
      </div>
      {componentToShow(filteredOrientedList)}
    </div>
  )
}

export default OrientedsSearch