import { ApolloQueryResult, FetchResult, } from '@apollo/client';
import { gql } from '@apollo/client';
import { client } from '../client-apollo';

export const assignAct = (data) => client
  .mutate({
    mutation: gql`
    mutation{
      assignActivity(data: ${data}){
        id
        isComplete
        deliveryDate
        appointmentId
        activity{
          id
          name
          accessLink
          isRequired
        }
      }
    }
  `
  })


export const allActivities = () => client
  .query({
    query: gql`
    query{
      activities{
       id
       name
       isRequired
       accessLink
     }
    }`
  })


export const allOrienteers = () => client
  .query({
    query: gql`
    query{
      allOrienteer{
        idKeycloak
        id
        user{
          name
          phone
          email
          profilePicture
        }
        socialNetwork
        oriented{
          id
        }
      }
    }
`
  })

export const GET_ORIENTEERS_FOR_OPTIONS = gql `
query {
  allOrienteerForOptions{
    id
    user{
      name
    }
  }
}
`;

export const login = (username, password): Promise<ApolloQueryResult<any>> => {
  return client
    .query({
      query: gql`
      query{
        login(username: "${username}", password: "${password}"){
          accessToken
          refreshToken
          role
          idKc
          username
        }
      }
    `
    })
}

export const changePassword = (username, oldPass, newPass, id): Promise<ApolloQueryResult<any>> => client.query({
  query: gql`
  query{
    changePassword(
      username: "${username}",
      oldPassword: "${oldPass}"
      newPassword: "${newPass}",
      kcUserId: "${id}"
    ){
      message
    }
  }
`
})

export const orienteerInfo = (id): Promise<ApolloQueryResult<any>> => client
  .query({
    query: gql`
    query{
      orienteer(id: "${id}"){
        id
        idKeycloak
        user{
          id
          name
          email
          phone
          id
          profilePicture
        }
        socialNetwork
        oriented{
          id
          user{
            name
          }
        }
        calendarId
      }
    }
`
  })


export const adminInfo = (id): Promise<ApolloQueryResult<any>> => client
  .query({
    query: gql`
  query{
    admin(id: "${id}"){
      id
      idKeycloak
      user{
        id
        name
        email
        phone
        id
        profilePicture
      }
      socialNetwork
    }
  }
`
  })

export const updateOrienteer = (id, orientedInputsToUpdate, basicInputsToUpdate) => client
  .mutate({
    mutation: gql`
     mutation{
      updateOrienteer(id: "${id}", 
      fields:${orientedInputsToUpdate}, 
      basicData:${basicInputsToUpdate}){
        id
        idKeycloak
        user{
          id
          name
          email
          phone
          profilePicture
        }
        socialNetwork
        oriented{
          id
          user{
            name
          }
        }
      }
    }
`
  })

export const updateAdmin = (data) => client
  .mutate({
    mutation: gql`
     mutation{
      updateAdmin(data: ${data}){
        id
        idKeycloak
        user{
          name
          email
          phone
          profilePicture
        }
        socialNetwork
      }
    }
`
  })

export const unassignAct = (data): Promise<FetchResult<any>> => {
  return client
    .mutate({
      mutation: gql`
      mutation{
        unassignActivity(data: ${data})
      }
        `
    })
}

export const saveOrientedKc = (values, id): Promise<FetchResult<any>> => {
  return client
    .query({
      query: gql`
       query {
       saveOrientedKc(
        password: "${values.password}",
        username: "${values.username}",
        idUser: "${id}",
        )
        {
        message
        }
        }
        `
    })
}

export const getKcUsername = (idKc): Promise<ApolloQueryResult<any>> => {
  return client
    .query({
      query: gql`
      query{
           getKcUsername(idKc: "${idKc}")
           }
`
    });
};

export const updateUserPassword = (newPassword, idKc): Promise<ApolloQueryResult<any>> => {
  return client
    .query({
      query: gql`
      query{
           updateUserPassword(idKc: "${idKc}",newPassword: "${newPassword}")
           }
`
    });
};

export const createEvent = (values): Promise<FetchResult<any>> => {
  return client
    .mutate({
      mutation: gql`
      mutation{
      createEvent(data:${values}){
      id
      name
      date
      }
      }
        `
    });
}

//TODO: añadir return de creación exitosa del evento en Google Calendar
export const createEventMutation = gql`
  mutation createEvent ($data:CreateAppointmentInput! ){
    createEvent(data:$data){
      id,
      name
    }
  }
  ` 


export const updateEvent = (values): Promise<FetchResult<any>> => {
  return client
    .mutate({
      mutation: gql`
      mutation{
      updateEvent(data:${values}){
      id
      }
      }
        `
    });
}

export const updateEventMutation = gql`
  mutation updateEvent($data:UpdateEventInput!){
    updateEvent(data:$data){
      id,
      name,
    }
  }
  `

export const events = () => client
  .query({
    query: gql`
    query{
      events{
        id
        name
        date
        detail
        orienteds{id,user{name}}
      }
    }`
  });


export const ORIENTEER_CONTACTS = gql`
  query{
    allOrienteer{
      user{
        name
        id
      }
    }
  }
  `;


export const GET_FEEDBACK_ORIENTEER = gql`
  query FindFeedbackFiles($id: String!, $appointmentId: String!){
    findFeedbackFiles(userId: $id, appointmentId: $appointmentId){
      id
      name
    }
  }
  `;

export const GET_FEEDBACK_ORIENTED = gql`
  query FindFeedbackFiles($id: String!, $available: Boolean!, $appointmentId: String!){
    findFeedbackFiles(userId: $id, available: $available, appointmentId: $appointmentId){
      id
      name
    }
  }
  `;

export const deleteFile = (id): Promise<FetchResult<any>> => {
  return client
    .mutate({
      mutation: gql`
        mutation{
          deleteDriveFile(data: {
            feedbackDriveFileId: "${id}"
          })
        }
          `
    });
}





