import { FieldArray, useFormikContext } from 'formik';
import { ReactElement, useEffect, useState } from 'react';

interface ActivityCheckboxListProps {
  arrayList: Array<any>;
}

const ActivityCheckboxList = (
  props: ActivityCheckboxListProps
): ReactElement => {

  const { values }: { values: any } = useFormikContext();
  const[sortedArray, setSortedArray] = useState<any>([])

  useEffect(()=>{
    if(props.arrayList?.length >0) {
      const sortedActivities = [...props.arrayList].sort(function (a, b) {return (a.name.localeCompare(b.name))})
      setSortedArray(sortedActivities)
    }
  }, [props.arrayList])

  return (
    <FieldArray name="activities">
      {(arrayHelpers) => {
        return (
          <div className="mt-4">
            {sortedArray.map((activity:any) => {
              return (
                <div className="flex items-center mb-4" key={activity.id}>
                  <input
                    id={activity.id}
                    className={'text-base py-4 text-green-principal w-5 h-5'}
                    name="activityInput"
                    type="checkbox"
                    value={activity.id}
                    checked={
                      values.activities?.findIndex(
                        (e) => e.id === activity.id
                      ) !== -1
                    }
                    onChange={(e) => {
                      if (e.target.checked) arrayHelpers.push(activity);
                      else {
                        const idx = values.activities?.findIndex(
                          (e) => e.id === activity.id
                        );
                        arrayHelpers.remove(idx);
                      }
                    }}
                  />
                  <label key={activity.id} htmlFor={activity.id} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    {activity.name}
                  </label>
                </div>
              );
            })}
          </div>
        );
      }}
    </FieldArray>
  );
};

export default ActivityCheckboxList;
