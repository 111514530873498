import { ReactElement, useEffect, useState } from 'react';
import TittleInProgress from '../UI/TittleInProgress';
import { Formik } from 'formik';
import * as yup from 'yup';
import { updateProgram } from '../../mutations/Program';
import NewPromptButtonGB from '../UI/NewPromptButtonGB';
import { setResponse, setShowModal } from '../../slices/modalSlice';
import { GET_ACTIVITIES } from '../../queries/Activity';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import ActivityCheckboxList from './ActivityCheckboxList';

interface ProgramEditProps {
  program: any;
  onConfirm: any;
  onCancel: any;
}

const ProgramEdit = (props: ProgramEditProps): ReactElement => {
  const { data, loading } = useQuery(GET_ACTIVITIES);
  const [activities, setActivities] = useState<any[]>([]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!loading && data) {
      const allActivities = data.activities;
      setActivities(allActivities);
    }
  }, [data]);

  const updateMyProgram = ({
    id,
    name,
    description,
    status,
    activities,
  }): void => {
    if (!id) {
      return;
    } else {
      const data = `{
        id: "${id}",
        name: "${name}",
        description: "${description}",
        status: "${status}"
        activities: ${JSON.stringify(activities.map(act => act.id))}
      }`;
      updateProgram(data)
        .then((res) => {
          if (res.data) {
            dispatch(
              setResponse({
                message: 'El programa fue editado con éxito!',
                error: false,
              })
            );
            dispatch(setShowModal(true));
            history.goBack();
            close();
          }
        })
        .catch(() => {
          dispatch(
            setResponse({
              message: 'Ups! No se pudo editar el programa',
              error: true,
              description: 'Por favor reintenta más tarde.',
            })
          );
          dispatch(setShowModal(true));
        });
    }
  };

  const schema = yup.object().shape({
    id: yup.string().required('El ID no puede ser modificado.'),
    name: yup
      .string()
      .required('El nombre es requerido.')
      .min(3, 'Debe contener 3 letras mínimo.'),
    description: yup
      .string()
      .required('La descripción es requerida.')
      .min(3, 'Debe contener 3 letras mínimo.'),
    status: yup
      .string()
      .required('El estado es requerido.')
      .min(3, 'Debe contener 3 letras mínimo.'),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        id: props.program.id,
        name: props.program.name,
        description: props.program.description,
        status: props.program.status,
        activities: props.program.activities,
      }}
      onSubmit={updateMyProgram}
      validationSchema={schema}
    >
      {(formik): ReactElement => {
        return (
          <form onSubmit={formik.handleSubmit}>
            <div className="w-full ml-11 flex flex-col items-start">
              <TittleInProgress text={'Editar Programa'} width={'w-3/3'} />
              <div className="flex w-1/2 mt-4 justify-between">
                <div className="flex flex-col w-1/2">
                  <label className="label-update">ID</label>
                  <input
                    id="id"
                    name="id"
                    className="w-full mt-2 px-3 py-2 rounded-lg border border-gray-secundary outline-none"
                    defaultValue={formik.values.id}
                    value={formik.values.id}
                    disabled={true}
                  />
                  {formik.errors.id &&
                    (formik.touched.id || formik.isSubmitting) && (
                    <p>{formik.errors.id}</p>
                  )}
                </div>
              </div>
              <div className="flex w-1/2 mt-4 justify-between">
                <div className="flex flex-col w-1/2">
                  <label className="label-update">Nombre</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="block w-full mt-2 p-3 text-gray-700 bg-white appearance-none border rounded-lg border-gray-secundary focus:outline-none focus:bg-gray-300 focus:shadow-inner"
                    placeholder="Nombre"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.name &&
                    (formik.touched.name || formik.isSubmitting) && (
                    <p>{formik.errors.name}</p>
                  )}
                </div>
              </div>
              <div className="flex w-1/2 mt-4 justify-between">
                <div className="flex flex-col w-1/2">
                  <label className="label-update">Descripción</label>
                  <input
                    type="text"
                    id="description"
                    name="description"
                    className="block w-full mt-2 p-3 text-gray-700 bg-white appearance-none border rounded-lg border-gray-secundary focus:outline-none focus:bg-gray-300 focus:shadow-inner"
                    placeholder="Descripción"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.description &&
                    (formik.touched.description || formik.isSubmitting) && (
                    <p>{formik.errors.description}</p>
                  )}
                </div>
              </div>
              <div className="flex w-1/2 mt-4 justify-between">
                <div className="flex flex-col w-1/2">
                  <label className="label-update">Estado</label>
                  <input
                    type="text"
                    id="status"
                    name="status"
                    className="block w-full mt-2 p-3 text-gray-700 bg-white appearance-none border rounded-lg border-gray-secundary focus:outline-none focus:bg-gray-300 focus:shadow-inner"
                    placeholder="Estado"
                    value={formik.values.status}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.status &&
                    (formik.touched.status || formik.isSubmitting) && (
                    <p>{formik.errors.status}</p>
                  )}
                </div>
              </div>
              <div
                id="checkbox-group"
                className="flex w-1/2 mt-4 justify-between"
              >
                <div
                  role="group"
                  aria-labelledby="checkbox-group"
                  className="flex flex-col w-1/2"
                >
                  <label className="label-update my-2">Actividades</label>
                  <ActivityCheckboxList arrayList={activities} />                  
                </div>
              </div>
              <div className="flex justify-around w-1/3 h-16 mt-10">
                <NewPromptButtonGB
                  text={'Guardar'}
                  action={(): void => {}}
                  okButton={true}
                  type={'submit'}
                />
                <NewPromptButtonGB
                  text={'Cancelar'}
                  action={props.onCancel}
                  okButton={false}
                  type={'button'}
                />
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default ProgramEdit;
