import { FetchResult, gql } from '@apollo/client';
import { client } from '../../client-apollo';

export const sendMessage = (data): Promise<FetchResult<any>> => {
  return client
    .mutate({
      mutation: gql`
      mutation {
        saveMessage(data: ${data}){
          id
        }
      }
        `
    });
}

export const sendMessageMutation = gql `
  mutation saveMessage ($data: SaveMessageInput!) {
    saveMessage(data:$data){
      id
    }
  }
  `

export const markAsRead = (userId, messageId): Promise<FetchResult<any>> => {
  return client
    .mutate({
      mutation: gql`
      mutation {
        markMessage(data:{
          userId: "${userId}",
          messageId: "${messageId}",
          read: true
        }){
          id
        }
      }
        `
    });
}

export const deleteForMe = (userId, messageId): Promise<FetchResult<any>> => {
  return client
    .mutate({
      mutation: gql`
      mutation {
        deleteMessage(data:{
          userId: "${userId}",
          messageId: "${messageId}"
        })
      }
        `
    });
}

