import { ReactElement, useEffect, useState } from 'react';
import { getDate } from '../../utils/utilsDate';
import ExportToExcel from './ExportToExcelButton';
import { confirm } from 'react-confirm-box';
import PromptButton from './PromptButton';

const ActivityTable = ({ data, deleteAct, downloadInfo }): ReactElement => {
  const [dataAct, setDataAct] = useState(data)

  useEffect(() => {
    if (data != dataAct) {
      setDataAct(data)
    }
  }, [data])

  const options : any = {
    render: (message, onConfirm, onCancel) => {
      return (
        <div className='bg-gray-modal border ml-6 mb-24 h-48 border-gray-secundary p-4 text-center rounded flex flex-col justify-around items-center my-4 shadow-lg max-w-2xl'>
          <div className='w-full'>
            <h3 className={' text-left text-md font-bold text-center px-8'}>{message}</h3>
            <p className='text-black-primary text-center text-xs'></p>
          </div>
          <div className='flex justify-around w-full h-16'>
            <PromptButton text={'Borrar'} action={onConfirm} redAlert={true} width={'w-5/12'}/>
            <PromptButton text={'Cancelar'} action={onCancel} redAlert={false} width={'w-5/12'}/>
          </div>
        </div>
      );
    }
  };

  const confirmateDelete = async (i) =>{
    const didConfirm = await confirm('¿Estás seguro que deseas eliminar la actividad? Tené en cuenta que no es posible recuperarla.', options);
    if (didConfirm) deleteAct(i)  
  }

  const state = (isComplete): ReactElement => {
    return (
      <div
        className={`w-auto rounded-sm h-auto text-black-primary text-xs p-1 ${isComplete ? 'bg-gray-secundary' : 'bg-yellow-info'}`}>
        {isComplete ? 'Finalizado' : 'Pendiente'}
      </div>
    )
  }

  return (
    <table className='min-w-max w-full border border-gray-secundary mt-4 rounded-lg'>
      <thead>
        <tr className='bg-white text-sm leading-normal border'>
          <th className='py-3 px-6 text-left font-bold text-green-principal'>Nombre</th>
          <th className='py-3 px-6 text-left font-bold text-xs text-green-principal'>Fecha de entrega</th>
          <th className='py-3 px-6 text-left font-bold text-xs text-green-principal'>Link de acceso</th>
          <th className='py-3 px-6 text-left font-bold text-xs text-green-principal'>Estado</th>
          <th className='py-3 px-6 text-left font-bold text-xs text-green-principal' />
        </tr>
      </thead>
      <tbody>
        {dataAct.map((i, index) => (
          <tr className='border-b border-gray-secundary hover:bg-gray-100' key={index}>
            <td className='py-3 px-6 text-left whitespace-nowrap'>
              <div className='flex items-center'>
                <span className='text-black-primary text-xs'>{i.activity.name}</span>
              </div>
            </td>
            <td className='py-3 px-6 text-left'>
              <div className='flex items-center'>
                <span className='text-black-primary text-xs'>{getDate(i.deliveryDate)}</span>
              </div>
            </td>
            <td className='py-3 px-6 text-center'>
              <div className='flex items-center'>
                <span className='text-black-primary text-xs'>{i.activity.accessLink}</span>
              </div>
            </td>
            <td className='py-3 px-6 text-center'>
              <div className='flex items-center'>
                <span className='text-black-primary text-xs'>{state(i.isComplete)}</span>
              </div>
            </td>
            <td className='py-3 px-6 text-center'>
              <div className='flex item-center justify-center'>
                <div onClick={(): Promise<void> => confirmateDelete(i)} className='w-4 mr-2 transform hover:text-purple-500 hover:scale-110'>
                  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16' />
                  </svg>
                </div>
                <ExportToExcel orientedDni={downloadInfo} slug={i.activity.accessLink} disabled={!i.isComplete}/>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default ActivityTable