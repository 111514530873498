import { ReactElement, useEffect, useState } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import { useMutation } from '@apollo/client';
import 'react-datepicker/dist/react-datepicker.css';
import { FastField, Form, Formik } from 'formik';
import * as yup from 'yup';
import { createEventMutation } from '../../queries';
import { setResponse, setShowModal } from '../../slices/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { convertToUTC, formaterToSubmit, getDate, noTime } from '../../utils/utilsDate';
import TittleInProgress from '../UI/TittleInProgress';
import { Roles } from '../../utils/Roles';
import Button from '../UI/Button';
import CustomDatePicker from '../UI/CustomDatePicker';
import { halfHourlyRange } from '../../utils/utilsDate';
import timeSpanOptions from '../../utils/timeSpanOptions';
import AlertPrompt from '../../utils/AlertPrompt';
import LoadingSpinner from '../../utils/spinner';
import PromptButton from '../UI/PromptButton';
import { useHistory } from 'react-router-dom';
import { session } from '../../slices/sessionSlice';
import OrientedSelect from './OrientedSelect';

const AgendaNewAppointment = ({ role, back, programs, optionsOriented, optionsOrienteer }): ReactElement => {
  const dispatch = useDispatch();
  const { user } = useSelector(session)
  const [createEvent] = useMutation(createEventMutation);
  const [startDate, setDate] = useState(new Date());
  const [time, setTime] = useState([] as string[]);
  const [timeSpan, setTimeSpan] = useState('0');
  const [chosenTime, setChosenTime] = useState('');
  const [commentsOnAppointment, setCommentsOnAppointment] = useState('');
  const [charCount, setCharCount] = useState(600);
  const [submitOriented, setsubmitOriented] = useState([]);
  const [submitOrienteer, setsubmitOrienteer] = useState([]);
  const [submitProgram, setSubmitProgram] = useState('');
  const [submitName, setsubmitName] = useState('');
  const [generalSubmit, setGeneralSubmit] = useState(false);
  const [assignGoogleMeet, setAssignGoogleMeet] = useState(false);
  const history = useHistory();
  const [isLoggedOnGoogle, setIsLoggedOnGoogle] = useState(false);
  const timeZone = useSelector((state: any) => state.session.timezone);

  //Send credentials
  const storageGoogleTokens = localStorage.getItem('g-tokens')
  let credentialsData;
  if(storageGoogleTokens){
    credentialsData = storageGoogleTokens
  }

  useEffect(()=>{
    timeHandler();
  },[startDate])
  
  useEffect(()=>{
    if(storageGoogleTokens && user.calendarId){
      setIsLoggedOnGoogle(true)
    }
  },[storageGoogleTokens])

  const timeHandler = (): void => {
    let finalTimeArr: string[] = [];
    const timeOpt: string[] = halfHourlyRange(8, 21);
    const today: Date = new Date();
    const validation: Date =  startDate;
    if (noTime(validation) <= noTime(today)) {
      let hoursArr: string[] = [];
      const hour: number = today.getHours();
      const index: number = timeOpt.indexOf(hour.toString() + ':00');
      const plus30: boolean = validation.getMinutes() >= 30;
      if (index >= 0) {
        hoursArr = plus30 ? timeOpt.slice(index + 2) : timeOpt.slice(index + 1);
      } else {
        hour < 9 ? (hoursArr = timeOpt) : (hoursArr= [])
      }
      finalTimeArr = hoursArr.map((i) => i);
    } else {
      finalTimeArr = timeOpt.map((i) => i);
    }
    setTime(finalTimeArr);
  };
  const selectDateHandler = (pickedDate): void => {
    setDate(pickedDate);
    timeHandler();
  };

  const validParams = (values): boolean => {
    return (
      !values.orienteds ||
      !values.name ||
      submitOriented.length === 0 ||
      submitOrienteer.length === 0 ||
      !values.time ||
      !values.timeSpan
    );
  };

  const goTo = ()=>{
    history.push('/profile/google-config')
  }


  return (
    <div className="bg-white min-h-screen my-8">
      {!isLoggedOnGoogle ? (
        <div>
          <LoadingSpinner />
          <AlertPrompt
            when={!isLoggedOnGoogle}
            onOK={() => true}
            onCancel={() => false}
            description={
              'El calendario no está configurado.\n Configure Google Calendar desde su perfil.'
            }
            btn={<PromptButton text={'Ir a mi perfil'} action={goTo} />}
          />
        </div>
      ) : ( optionsOriented != undefined && optionsOriented.length >0 && optionsOrienteer && programs.length>0 ?
        <div className="container mx-auto">
          <div className="inputs w-full max-w-6xl">
            {role == Roles.ADMIN ? (
              <>
                {
                  <>
                    <TittleInProgress text={'Crear un evento'} width={'w-52'} />
                    <h1 className="text-lgs text-gray-primary text-left mt-4">
                      Puedes crear un primer encuentro entre Orientadores y
                      Orientados.
                    </h1>
                  </>
                }
              </>
            ) : (
              <>
                {
                  <>
                    <TittleInProgress text={'Crear un evento'} width={'w-52'} />
                    <h1 className="text-lgs text-black-primary text-left mt-2">
                      Notifica a tus orientados la sugerencia de dias y horarios
                      disponibles para agendar encuentros.
                    </h1>{' '}
                  </>
                }
              </>
            )}
            <Formik
              enableReinitialize
              initialValues={{
                id: null,
                orienteds: submitOriented,
                name: submitName,
                orienteers: submitOrienteer,
                date: getDate(startDate),
                time: chosenTime,
                timeSpan: timeSpan,
                detail: commentsOnAppointment,
                isReprogramable: false,
                programSlug: submitProgram,
                credentials: credentialsData,
                hasGoogleMeet: assignGoogleMeet
              }}
              onSubmit={async (values): Promise<void> => {
                setGeneralSubmit(true);
                if (validParams(values)) {
                  return;
                }

                const {
                  orienteds,
                  name,
                  orienteers,
                  date,
                  time,
                  timeSpan,
                  detail,
                  isReprogramable,
                  programSlug,
                  credentials,
                  hasGoogleMeet
                } = values;

                console.log(timeZone)
                console.log(convertToUTC(time, timeZone))

                createEvent({
                  variables: {
                    data: {
                      programSlug: programSlug,
                      name: name,
                      orienteerIDs: orienteers.map(
                        (orienteer: any) => orienteer.value
                      ),
                      orientedIDs: orienteds.map(
                        (oriented: any) => oriented.value
                      ),
                      date: formaterToSubmit(date),
                      time: time,
                      timeSpan: Number(timeSpan),
                      details: detail,
                      isReprogramable: isReprogramable,
                      credentials: credentials,
                      calendarId: user.calendarId,
                      hasGoogleMeet: hasGoogleMeet,
                      eventOrganizer: user.user.email,
                      timezone: timeZone
                    },
                  },
                })
                  .then((result) => {
                    if (result.data.createEvent) {
                      dispatch(
                        setResponse({
                          message: 'El evento ya fue creado.',
                          error: false,
                          description:
                            'Recibira una notificacion para que se contacte con administrador',
                        })
                      );
                      back();
                      dispatch(setShowModal(true));
                    }
                  })
                  .catch((): void => {
                    dispatch(
                      setResponse({
                        message: 'El evento no pudo ser creado.',
                        error: true,
                        description: 'Intentar nuevamente',
                      })
                    );
                    dispatch(setShowModal(true));
                  });
              }}
              validationSchema={yup.object().shape({
                name: yup.string().required('El nombre es requerido'),
                date: yup.string().required('La fecha es requerida'),
                time: yup.string().required('El horario es requerido'),
                programSlug: yup.string().required('El programa es requerido'),
                detail: yup.string().max(600, 'No se deben superar los 600 caracteres'),
              })}
            >
              {({
                values,
                handleSubmit,
                handleBlur,
                errors,
                handleChange,
                touched,
              }): ReactElement => (
                <Form className="mt-2 pt-4" onSubmit={handleSubmit}>
                  <div className="flex flex-wrap">
                    <div className="personal w-11/12 mt-10">
                      <h2 className="text-base text-black-primary text-left font-bold">
                        01. Informacion sobre el tipo de programa
                      </h2>
                      <div className="flex items-center mt-4">
                        <div className="w-full md:w-1/3 flex justify-start flex-col">
                          <label htmlFor="programSlug" className="label-form">Programa</label>
                          <div className="flex-shrink w-full inline-block relative">
                            <FastField
                              name="programSlug"
                              type="select">
                              {({field})=>
                                (<select
                                  {...field}
                                  id="programSlug"
                                  disabled={false}
                                  onChange={(e): void => {
                                    handleChange(e);
                                    setSubmitProgram(e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  className="block appearance-none text-black-primary w-full bg-white border border-gray-secundary px-3 py-3 rounded-md focus:outline-none"
                                >
                                  <option value={''}>Seleccione programa</option>
                                  {programs &&
                                    programs.map((i, index) => (
                                      <option key={index} value={i.id}>
                                        {i.name}
                                      </option>
                                    ))}
                                </select>)}
                            </FastField>
                            {errors.programSlug && touched.programSlug && (
                              <p className="text-red-error">
                                {errors.programSlug}
                              </p>
                            )}
                            <div className="pointer-events-none absolute top-0 mt-3  right-0 flex items-center px-2 text-gray-600">
                              <svg
                                className="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="w-full border-t border-gray-secundary mt-10 mb-10"></div>
                      <h2 className="text-base text-black-primary text-left font-bold">
                        02. Informacion sobre el evento
                      </h2>
                      <div className="flex items-center mt-2">
                        <div className="w-11/12 md:w-3/4 flex justify-start flex-col">
                          <label htmlFor='name' className="block tracking-wide text-black-primary text-xs mb-2 text-left">
                            Nombre del evento
                          </label>
                          <input
                            onChange={(event): void => {
                              handleChange(event)
                              setsubmitName(event.target.value);
                            }}
                            id="name"
                            onBlur={handleBlur}
                            placeholder="Ingresar nombre"
                            className=" block w-full bg-white text-black-primary border border-gray-secundary hover:border-green-principal rounded-md px-2 pt-3 pb-3  leading-tight"
                          />

                          {errors.name && touched.name && (
                            <p className="text-red-error">{errors.name}</p>
                          )}
                        </div>
                        <div className="w-full md:w-3/4 px-3 flex justify-start flex-col">
                          <label className="block tracking-wide text-black-primary text-xs mb-2 text-left">
                            Orientador/es participante/s
                          </label>
                          <Multiselect
                            value={values.orienteers}
                            id="orienteers"
                            displayValue="label"
                            loading={optionsOrienteer[0].label != '' ? false : true}
                            loadingMessage={'cargando...'}
                            options={optionsOrienteer}
                            showCheckbox={true}
                            onBlur={handleBlur}
                            showArrow={true}
                            placeholder="Seleccionar orientador"
                            onSelect={(options): void => {
                              setGeneralSubmit(false);
                              setsubmitOrienteer(options);
                            }}
                            selectedValues={''}
                            style={{
                              searchBox: {
                                border: '1px solid #DADEEB',
                                padding: '8px 0px 8px 12px',
                              },
                              chips: {
                                background: '#F4F7FB',
                                color: '#0BA4A0',
                              },
                              option: {
                                background: '#FFFFFF',
                                color: '#0BA4A0',
                              },
                            }}
                          />
                          {submitOrienteer.length === 0 && generalSubmit && (
                            <p>El orientador es requerido</p>
                          )}
                        </div>
                        <div className="w-full md:w-3/4 px-3 flex justify-start flex-col">
                          <label className="block tracking-wide text-black-primary text-xs mb-2 text-left">
                            Orientado/s participante/s
                          </label>
                          { !submitProgram ? 'Primero seleccione un programa' : 
                            <FastField name="orientedSelect" >
                              {({ field }) => (                          
                                field && <OrientedSelect 
                                  submitProgram={submitProgram} 
                                  orienteds={values.orienteds} 
                                  optionsOriented={optionsOriented}
                                  setsubmitOriented={setsubmitOriented} 
                                  setGeneralSubmit={setGeneralSubmit} />
                              )}
                            </FastField>
                          }
                          {submitOriented.length === 0 && generalSubmit && (
                            <p>El orientado es requerido</p>
                          )}
                        </div>
                      </div>

                      <div className="w-full border-t border-gray-secundary mt-10 mb-10"></div>

                      <h2 className="text-base text-black-primary text-left font-bold">
                        03. Dias y horarios disponibles
                      </h2>
                      <div className="flex items-center  mt-4">
                        <div className="w-full md:w-1/3 flex justify-start flex-col">
                          <label className="block tracking-wide text-black-primary text-xs mb-2 text-left">
                            Fecha
                          </label>
                          <CustomDatePicker
                            value={startDate}
                            placeholder="Ingresar fecha"
                            setValue={selectDateHandler}
                            minDateToday
                          />
                        </div>
                        <div className="w-full md:w-1/3 px-3 flex justify-start flex-col">
                          <label className="block tracking-wide text-black-primary text-xs mb-2 text-left">
                            Horario
                          </label>
                          <div className="flex-shrink w-full inline-block relative">
                            <select
                              id="time"
                              value={values.time}
                              onChange={(e): void => {
                                handleChange(e);
                                setChosenTime(e.target.value);
                              }}
                              onBlur={handleBlur}
                              className="block appearance-none text-black-principal w-full bg-white border border-gray-secundary hover:border-green-principal px-3 py-3 rounded-md"
                            >
                              <option className="text-gray-principal">
                                Seleccionar horario
                              </option>
                              {time.map((appointmentTime, index) => {
                                return (
                                  <option key={index} value={appointmentTime}>
                                    {appointmentTime}
                                  </option>
                                );
                              })}
                            </select>
                            <div className="pointer-events-none absolute top-0 mt-3  right-0 flex items-center px-2 text-gray-600">
                              <svg
                                className="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </div>
                            {errors.time && touched.time && (
                              <p className="text-red-error">{errors.time}</p>
                            )}
                          </div>
                        </div>
                        <div className="w-full md:w-1/3 px-3 flex justify-start flex-col">
                          <label className="block tracking-wide text-black-primary text-xs mb-2 text-left">
                            Duración estimada
                          </label>
                          <div className="flex-shrink w-full inline-block relative has-tooltip">
                            <select
                              id="timeSpan"
                              value={values.timeSpan}
                              onChange={(e): void => {
                                handleChange(e);
                                setTimeSpan(e.target.value);
                              }}
                              onBlur={handleBlur}
                              className="has-tooltip block appearance-none text-black-principal w-full bg-white border border-gray-primary px-3 py-3 rounded-md"
                            >
                              <option className="text-gray-principal">
                                Seleccionar horario
                              </option>
                              {timeSpanOptions.map((i, index) => (
                                <option key={index} value={i.value}>
                                  {i.label}
                                </option>
                              ))}
                            </select>
                            <div className="pointer-events-none absolute top-0 mt-3  right-0 flex items-center px-2 text-gray-600">
                              <svg
                                className="fill-current h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </div>
                            <div className="italic tooltip bottom-20">
                              La duración o el tiempo estimado dependerá de la
                              dinámica y tipo de encuentro
                            </div>
                            {errors.timeSpan && touched.timeSpan && (
                              <p className="text-red-error">
                                {errors.timeSpan}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="personal w-full border-t border-gray-secundary mt-10 pt-10">
                        <h2 className="text-base text-black-primary text-left font-bold mb-4">
                          04. Detalle
                        </h2>
                        <div className="w-full md:w-2/3 mb-6 flex justify-start flex-col">
                          <label htmlFor="detail" className="block tracking-wide text-black-primary text-xs mb-2 text-left">
                            Comentarios del evento{' '}
                          </label>
                          <p className={`flex justify-start items-center ${charCount<=0 ? 'text-red-500': 'text-gray-500'}`}>{charCount} /600</p>
                          {errors.detail && touched.detail && (
                            <p className="pb-2 flex justify-start items-start text-red-error">{errors.detail}</p>
                          )}
                          <textarea
                            name="detail"
                            placeholder="Escribe un comentario..."
                            onChange={(e):void => {
                              setCharCount(600-e.target.value.length);
                              setCommentsOnAppointment(e.target.value);
                            }}

                            //value={values.detail}
                            maxLength={600}
                            className="rounded-md text-black-principal leading-normal resize-none w-full h-40 py-3 px-3 border border-gray-secundary hover:border-green-principal font-medium placeholder-gray-secundary focus:outline-none focus:bg-white"
                          > 
                          </textarea>
                        </div>
                      </div>
                      <div className=" w-1/3 mb-6 flex items-center pl-4 border border-gray-200 rounded dark:border-gray-700">
                        <input
                          className={
                            'w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                          }
                          name="gmeetcheckbox"
                          onChange={() => {setAssignGoogleMeet(!assignGoogleMeet)}}
                          type="checkbox"
                          id="gmeetcheck"
                          value={' '}
                        />
                        <label
                          htmlFor="gmeetcheck"
                          className={
                            'w-full py-4 ml-2 text-gray-900 dark:text-gray-300'
                          }
                        >
                          Agregar Google Meet al evento
                        </label>
                      </div>

                      <div className="flex justify-start items-center">
                        <Button text="Agendar evento" action={(): void => {}} />
                        <a
                          className="cancel-link ml-2"
                          onClick={(): void => back()}
                        >
                          Cancelar
                        </a>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div> : <LoadingSpinner/>)
      }
    </div>
  );
};

export default AgendaNewAppointment;
