import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NewLayout from '../../components/Layout/NewLayout';
import { setCurrentSection } from '../../slices/navSlice';
import MyProgram from '../../components/Inicio/Oriented/MyProgram';
import { useParams } from 'react-router-dom';
import { setPrograms, strapiData } from '../../slices/initialStrapiData';
import { getPrograms } from '../../utils/strapiFunctions';
import BreadCrumb from '../../components/UI/BreadCrumb';
import BreadCrumbItem from '../../components/UI/BreadCrumbItem';
import ProgramBreadCrumb from '../../components/UI/ProgramBreadCrumb';

const MyProgramsPage = (): ReactElement => {
  const { programSlug } = useParams();
  const dispatch = useDispatch();
  const { programs } = useSelector(strapiData);
  const [programName, setProgramName] = useState();

  useEffect(() => {
    if (programs.length == 0){
      getPrograms().then((res) => {
        dispatch(setPrograms(res));
      });
    }
    dispatch(setCurrentSection('Inicio'));
  }, []);

  useEffect(() => {
    if (programs != null && programs.length > 0) {
      const filterByProgram = programs.filter((val) =>
        val.slug.includes(programSlug)
      );
      setProgramName(filterByProgram[0].name);
    }
  }, [programs]);

  return (
    <NewLayout>
      <div className=" w-5/6 ml-11">
        <BreadCrumb>
          <BreadCrumbItem Name={'Inicio'} path={'/home'} isCurrentPage={false}/>
          <ProgramBreadCrumb isCurrentPage={true} />
        </BreadCrumb>
        <MyProgram programName={programName} />
      </div>
    </NewLayout>
  );
};

export default MyProgramsPage;