import { ReactElement, useState } from 'react'
import { downArrow, upArrow } from '../../../assets/svg/dashboardIcons'
import Icon from '../../UI/Icon'
import TittleInProgress from '../../UI/TittleInProgress'
import Downloadable from '../../Inicio/Oriented/ProfessionLabDownload'

const ProfessionLabDetail = ({ lab }): ReactElement => {
  const [descendente, setDescendente] = useState(true)

  const handleOrder = ():void => {
    setDescendente(!descendente)
  }

  const files = [...lab.Subcategory].sort(function (a, b) {
    if (descendente ? a.Title > b.Title : a.Title < b.Title) {
      return 1;
    }
    if (descendente ? a.Title < b.Title : a.Title > b.Title) {
      return -1;
    }
    return 0;
  });

  return (
    <div className="flex flex-col items-start">
      <TittleInProgress text={lab.Title} width={'w-auto'} />
      <div className="mt-8 w-full">
        <div className="focus:outline-none flex items-center mb-4">
          <p onClick={handleOrder} className="cursor-pointer text-left text-green-principal text-xs font-bold">Nombre</p>
          <div className="pt-2 ml-2">
            {descendente ?
              <Icon width={20} height={18} path={downArrow} color={'#0BA4A0'} /> :
              <Icon width={20} height={18} path={upArrow} color={'#0BA4A0'} />
            }
          </div>
        </div>
        {files.map(file => (
          <Downloadable data={file} key={file.id} />
        ))}
      </div>
    </div>
  )

}
export default ProfessionLabDetail
