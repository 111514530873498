import { ReactElement } from 'react';
import Question from './Question';

export default function Questions({ data }): ReactElement {

  return (
    <div className="w-full">
      {data && data.map(i =>
        <Question data={i} key={i.id} />)}
    </div>
  );
}