import User from './UserInterface';
import {PaymentPlan} from './PaymentPlan';
export interface Oriented {
  dni: number
  id: string
  idKeycloak: string
  firstPay: boolean
  address: string
  school: string
  user: User,
  age: string
  expectations: string
  profilePicture:null,
  appointmentActivities: {
    deliveryDate: Date
    isComplete: boolean
    appointmentId: string,
    activity: {
      name: string
      accessLink: string
      isRequired: boolean
    }
  },
  orienteer: {
    id: string
  },
  appointments: {
    name: string
    date: string
    status: string
  },
  paymentPlans: PaymentPlan[]
}

export const initialState: Oriented = {
  dni: 0,
  id: '',
  idKeycloak: '',
  firstPay: false,
  address: '',
  school: '',
  age: '',
  expectations: '',
  profilePicture:null,
  user: { name: '', email: '', phone: '', creationDate: 0 },
  orienteer: { id: '' },
  paymentPlans: [{
    id: '',
    programSlug: '',
    programStatus: '',
    paymentStatus: '',
    discountAmount: 0,
    discountMotive: '',
    price: 0
  }],
  appointments: {
    name: '',
    date: '',
    status: ''
  },
  appointmentActivities: {
    deliveryDate: new Date(),
    isComplete: false,
    appointmentId: '',
    activity: {
      name: '',
      accessLink: '',
      isRequired: false
    }
  }
}