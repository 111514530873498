import { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import NewLayout from '../../components/Layout/NewLayout';
import Button from '../../components/UI/Button';
import ButtonLink from '../../components/UI/ButtonLink';
import TittleInProgress from '../../components/UI/TittleInProgress';
import { changePassword } from '../../queries';
import { setResponse, setShowModal } from '../../slices/modalSlice';

const ChangePasswordPage = (): ReactElement => {
  const [error, setError] = useState({ insecure: false, notEquals: false });
  const [showResetPassword, setShowResetPass] = useState(false);
  const [actualPass, setActualPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [repeatPass, setRepeatPass] = useState('');
  const idkc = localStorage.getItem('idkc');
  const username = localStorage.getItem('username');
  const dispatch = useDispatch();

  const regularExpression =
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,}$/;

  const handleActualPassChange = (event): void => {
    setActualPass(event.target.value);
  };
  //const handleShowResetPass = (): void => {
  //  const actualState = showResetPassword;
  //  setShowResetPass(!actualState);
  //};
  const handleDisabledCondition = (): boolean => {
    const blanckInputs =
      actualPass.trim().length === 0 ||
      newPass.trim().length === 0 ||
      repeatPass.trim().length === 0;
    return blanckInputs || error.insecure || error.notEquals;
  };

  const handleNewPassChange = (event): void => {
    const value = event.target.value;
    setNewPass(value);
    setError({
      ...error,
      insecure: !regularExpression.test(value),
    });
  };
  const switchPass = (): void => {
    changePassword(username, actualPass, newPass, idkc)
      .then((res) => {
        if (res.data.changePassword) {
          showModalPassword('Contraseña cambiada con éxito', '', false);
          const actualState = showResetPassword
          setShowResetPass(actualState);
        }
      })
      .catch((): void => {
        showModalPassword(
          'Ha ocurrido un error',
          'Revise sus datos y vuelva a intentarlo.',
          true
        );
      });
  };

  const showModalPassword = (msg, description, error): void => {
    dispatch(
      setResponse({
        message: msg,
        error,
        description,
      })
    );
    dispatch(setShowModal(true));
  };

  const resetPasswords = (): void => {
    setActualPass(''), setNewPass(''), setRepeatPass('');
  };
  const updatePassword = (): void => {
    //change pass
    switchPass();
    //after changing pass, reset the states to void
    resetPasswords();
  };

  const handleRepeatPassChange = (event): void => {
    const value = event.target.value;
    setRepeatPass(value);
    setError({
      ...error,
      notEquals: value != newPass,
    });
  };

  return (
    <NewLayout>
      <div className="w-full md:ml-11">
        <div className=" block md:w-2/5 md:mt-10">
          <TittleInProgress text={'Cambiar contraseña'} width={'w-64'} />
          <label className="block md:mt-4 text-xs font-semibold text-black-primary text-left">
            Contraseña actual
          </label>
          <input
            onChange={handleActualPassChange}
            type="password"
            value={actualPass}
            placeholder="Ingrese contraseña actual"
            className="block w-full p-3 mt-2 text-gray-700 white border rounded-lg border-gray-secundary focus:outline-none focus:bg-gray-300 focus:shadow-inner"
            name="pass"
            required
          />
          <label className="block mt-2 text-xs font-semibold text-black-primary text-left">
            Contraseña nueva
          </label>
          <input
            onChange={handleNewPassChange}
            type="password"
            value={newPass}
            placeholder="Ingrese contraseña nueva"
            className="block w-full p-3 mt-2 text-gray-700 bg-white appearance-none border rounded-lg border-gray-secundary focus:outline-none focus:bg-gray-300 focus:shadow-inner"
            name="newpass"
            required
          />
          {error.insecure && (
            <p className="text-red-error text-left text-xss">
              La contraseña debe tener al menos 8 caracteres, un número, una
              minúscula y una mayúscula*
            </p>
          )}
          <label className="block mt-2 text-xs font-semibold text-black-primary text-left">
            Repetir contraseña
          </label>
          <input
            onChange={handleRepeatPassChange}
            type="password"
            value={repeatPass}
            placeholder="Repetir contraseña nueva"
            className="block w-full p-3 mt-2 text-gray-700 bg-white appearance-none border rounded-lg border-gray-secundary focus:outline-none focus:bg-gray-300 focus:shadow-inner"
            name="reppass"
            required
          />
          {error.notEquals && (
            <p className="text-red-error text-left text-xss">
              Las contraseñas no coinciden*
            </p>
          )}
          <div className="w-full flex justify-between h-20 items-center">
            <Button styles={'mr-10 w-fit mr-8'}
              text={'Cambiar contraseña'}
              action={updatePassword}
              disabled={handleDisabledCondition()}
            />
            <ButtonLink redAlert={true} text={'Cancelar'} to='/profile' styles={' mr-10 w-fit mr-8 '} />

          </div>
        </div>
      </div>
    </NewLayout>
  );
};

export default ChangePasswordPage;
