import User from './UserInterface'

export interface Orienteer {
  idKeycloak: string,
  id: string,
  user: User
  socialNetwork: string,
  oriented: {
    id: string
  }
}

export const initialOrienteerState = {
  idKeycloak: '',
  id: '',
  user: {
    name: '',
    phone: '',
    email: ''
  },
  socialNetwork: '',
  oriented: {
    id: ''
  }
}