import { ApolloQueryResult, gql } from '@apollo/client';
import { client } from '../../client-apollo';

export const orientedInfo = (id): Promise<ApolloQueryResult<any>> => client
  .query({
    query: gql`
  query{
    oriented(id: "${id}"){
      id
      dni
      idKeycloak
      paymentPlans{
        id
        programSlug
        programStatus
        paymentStatus
        discountAmount
        discountMotive
        price
        quotas{
          id
          status
          value
          number
          dueDate
          paymentPlan{programSlug}
        }
      }
      appointmentActivities{
        deliveryDate,
        isComplete,
        appointmentId
        activity{
          id
          name
          isRequired
          accessLink
        }
      }
      user{
        id
        name
        forename
        surname
        email
        phone
        id
        profilePicture
      }
      orienteer{
        id
      }
      socialNetwork
      school
      hasBrotherOriented
      numberOfBrothersOriented
      expectations
      address
      age
      educationLevel
      motive
    }
  }
`
  })

export const ADMIN_ORIENTED_BY_ID = gql`
  query OrientedById($id: String!){
    orientedById(id: $id){
      id
      dni
      idKeycloak
      address
      school
      dni
      expectations
      motive
      age
      user{
        name
        forename
        surname
        email
        phone
        id
        profilePicture
      }
      paymentPlans{
        id
        programSlug
      }
      orienteer{
        id
      }
    }
  }
`

export const ORIENTED_BY_ID = gql`
  query OrientedById($id: String!){
    orientedById(id: $id){
      id
      user{
        name
        id
        profilePicture
      }
     }
  }
`

export const orientedById = (id): Promise<ApolloQueryResult<any>> => {
  return client
    .query({
      query: gql`
    query{
      orientedById(id: "${id}"){
        id
        user{
          name
          forename
          surname
          email
          phone
          id
          profilePicture
        }
        appointmentActivities{
          id
          deliveryDate
          appointmentId
          orientedId
          activity{
            id
            name
            accessLink
            isRequired
          }
        }
        paymentPlans{
          id
          programSlug
          programStatus
          paymentStatus
          discountAmount
          discountMotive
          price
        }
       address
       school
       dni
       expectations
       age
       }
    }
  `
    });
};

export const GET_ORIENTED = gql`
query OrientedById($id: String!) {
  orientedById(id: $id) {
    id
    dni
  user{
    name
    forename
    surname
  }
  paymentPlans{
    id
    programSlug
    programStatus
    paymentStatus
    discountAmount
    discountMotive
    price
  }
    idKeycloak
  appointmentActivities{
    deliveryDate
    appointmentId
    isComplete
    activity{
      isRequired
      name
      id
      accessLink
    }
  }
  }
}
`;
export const ALL_ORIENTED = gql`
query{
    allOriented{
      idKeycloak
      school
      dni
      id
      orienteer{
          id
      }
      user{
        id
        name
        profilePicture
      }
    }
  }
`
export const ORIENTED_CONTACTS = gql`
  query{
    allOriented{
      user{
        name
        id
      }
    }
  }
  `;


export const ONLY_INTERESTED = gql`
  query{
    onlyInterested{
      id
      idKeycloak
      dni
      address
      motive
      age
      paymentPlans{
        id
      }
      school
       user{
            name
            email
            phone
            id
            profilePicture
          }
    }
  }
  `;

export const ONLY_ENROLLED_WITHOUT_PAY = gql`
  query{
    onlyEnrolledWithoutPaying{
      id
      idKeycloak
      dni
      address
      motive
      age
      school
       user{
            name
            email
            phone
            id
            profilePicture
          }
    }
  }
  `;

export const ONLY_ORIENTED = gql`
  query{
    onlyOriented{
      confirmedAppointments{
          name
          comments
          privateComment
          interestLinks{
            id
            name
            link	
          }
          programSlug
          date
          status
          id
        }
      idKeycloak
      school
      address
      id
      age
      dni
      firstPay
      expectations
      motive
      appointmentActivities{
        activity{
          id
          isRequired
          name
          accessLink
        }
        deliveryDate,
        isComplete,
        activityId,
        appointmentId,
        orientedId
      }
      orienteer{
          id
      }
      appointments{
          name,
          date,
          status
      }
      paymentPlans{
        id
        programSlug
        programStatus
        paymentStatus
        discountAmount
        discountMotive
        price
        quotas{
          status
        }
      }
      user{
        id
        name
        phone
        email
        id
        profilePicture
      }
    }
  }
  `;

export const ONLY_ENROLLED = gql`
query{
  onlyEnrolled{
    idKeycloak
    school
    id
    address
    motive
    age
    dni
    firstPay
    expectations
    motive
    orienteer{
        id
    }
    paymentPlans{
      id
      programSlug
      programStatus
      paymentStatus
      discountAmount
      discountMotive
      price
      quotas{
        status
      }
    }
    user{
      id
      name
      phone
      email
      id
      profilePicture
    }
  }
}
`

export const GET_ALL_ORIENTED_WITH_USER = gql`
  query{
    getAllOrientedsWithUser{
      id
      dni
      school      
      user{
        id
        name
        profilePicture
      }
      orienteer{
        id
      }
      paymentPlans{
        programSlug
      }      
    }
  }
  `;

export const GET_ORIENTEDS_FOR_OPTIONS = gql`
query{
  getAllOrientedsWithUser{
    id
    dni
    user{
      id
      name
    }
    paymentPlans{
      programSlug
    }      
  }
}
`;  

export const GET_MY_ORIENTEDS = gql`
  query GetMyOrienteds($idOrienteer: String!){
    getMyOrienteds(idOrienteer: $idOrienteer){
      id
      dni
      user{
        id
        name
        profilePicture
        creationDate
      }
    }
  }
  `;

export const GET_OTHERS_ORIENTEDS = gql`
  query GetOthersOrienteds($idOrienteer: String!){
    getOthersOrienteds(idOrienteer: $idOrienteer){
      id
      dni
      user{
        id
        name
        profilePicture
        creationDate
      }
    }
  }
  `;  