import { FetchResult, gql } from '@apollo/client'
import { client } from '../../client-apollo'
/* eslint-disable */

export const createPayment = (values): Promise<FetchResult<any>> => {
  return client
    .mutate({
      mutation: gql`
           mutation {
            createPayment(data : ${values})
            {
            id
            }
            }
            `
    })
}


export const payQuota = (values): Promise<FetchResult<any>> => {
  return client
    .mutate({
      mutation: gql`
           mutation {
            payQuota(data :{quotaId:"${values.id}",status:${values.status}})
            { 
            id
            }
             }
            `
    })
}


