import { ReactElement, useEffect, useState } from 'react'
import { Multiselect } from 'multiselect-react-dropdown';
import { useQuery } from '@apollo/client';
import { ORIENTEER_CONTACTS } from '../../../queries';
import { ORIENTED_CONTACTS } from '../../../queries/Oriented';
import { ALL_ADMIN } from '../../../queries/Admin';
import { Roles } from '../../../utils/Roles';

interface User {
  name: string
  id: number
}

const ToSelection = ({ selected, setSeleted }): ReactElement => {
  const [options, setOptions] = useState<Array<User>>([])
  const orienteer = useQuery(ORIENTEER_CONTACTS)
  const oriented = useQuery(ORIENTED_CONTACTS)
  const admin = useQuery(ALL_ADMIN)
  const role = localStorage.getItem('role')

  const mapInfoUser = (data): Array<User> => {
    return (data.map(i => {
      return { ...i.user }
    }))
  }

  const addAllKindToOptions = ():void => {
    setOptions(
      [
        ...mapInfoUser(orienteer.data.allOrienteer),
        ...mapInfoUser(admin.data.allAdmin),
        ...mapInfoUser(oriented.data.allOriented)
      ])
  }

  const addOnlyOrienteersAndAdmin = ():void => {
    setOptions(
      [
        ...mapInfoUser(orienteer.data.allOrienteer),
        ...mapInfoUser(admin.data.allAdmin)
      ])
  }

  useEffect(() => {
    if (!orienteer.loading && !oriented.loading && !admin.loading) {
      role !== Roles.ORIENTED ? addAllKindToOptions() : addOnlyOrienteersAndAdmin()
    }

  }, [orienteer, oriented, admin])

  const onSelect = (selectedList):void => {
    const toAdd = selectedList.filter(i => !selected.includes(i.id))
    setSeleted([...selected, ...toAdd.map(i => { return i.id })])
  }

  const onRemove = (selectedList):void => {
    setSeleted(selectedList.map(i => { return i.id }))
  }

  return (
    <Multiselect
      options={options}
      onSelect={onSelect}
      onRemove={onRemove}
      displayValue="name"
      placeholder=''
      closeIcon={'cancel'}
      avoidHighlightFirstOption={true}
      id={'css_custom'}
      style={{
        optionContainer: {
          width: '35%'
        },
        chips: {
          background: '#DADEEB',
          borderRadius: '30px',
          color: '#2E384D',
          fontSize: '14px'
        },
        searchBox: {
          border: 'none'
        },
        option: {
          color: '#2E384D',
          fontSize: '14px'
        }
      }}
    />
  )
}
export default ToSelection