import { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import NewLayout from '../../components/Layout/NewLayout';
import Orienteds from '../../components/Orientados/Orienteds';
import { setCurrentSection } from '../../slices/navSlice';

const OrientedPage =(): ReactElement => {

  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(setCurrentSection('Orientados'))
  })

  return (
    <NewLayout>
      <div>
        <Orienteds />
      </div>
    </NewLayout>            
  )

}

export default OrientedPage;