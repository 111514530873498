import { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import NewLayout from '../../components/Layout/NewLayout';
import AccountingReports from '../../components/Pagos/AccountingReport';
import { setCurrentSection } from '../../slices/navSlice';
import {useHistory} from 'react-router-dom'

const AccountingReportsPage =(): ReactElement => {
  const history = useHistory()

  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(setCurrentSection('Pagos'))
  })

  const goToPayments = () => {
    history.push('/payments')
  }

  return (
    <NewLayout>
      <div className="w-full md:ml-11">
        <AccountingReports goBack={goToPayments}/>
      </div>
    </NewLayout>            
  )

}

export default AccountingReportsPage;