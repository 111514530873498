import { createSlice } from '@reduxjs/toolkit';

interface HeaderState {
  text: string,
  button: boolean
}
const initialState = {
  header: {
    text: '',
    button: false
  }
}
export const slice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setHeader: (state, action): void => {
      state.header = action.payload
    }
  }
});
export const { setHeader } = slice.actions;
export default slice.reducer;
export const headerState = (state): HeaderState => state.header.header;
