import { createSlice } from '@reduxjs/toolkit';

interface SwitchPageStateI {
  data: any,
  component: string 
}

export const slice = createSlice({
  name: 'switchPage',
  initialState: {
    data: null,
    component: ''
  },
  reducers: {
    setData: (state, action): void => {
      state.data = action.payload
    },
    setComponent: (state, action): void => {
      state.component = action.payload
    }

  }
});

export const { setData, setComponent } = slice.actions;
export const switchPageState = (state): SwitchPageStateI => state.switchPage;
export default slice.reducer;