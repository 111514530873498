import { ReactElement, useEffect } from 'react';
import NewLayout from '../../components/Layout/NewLayout';
import { useDispatch } from 'react-redux';
import { setCurrentSection } from '../../slices/navSlice';
import { Roles } from '../../utils/Roles';
import AgendaTableOriented from '../../components/Agenda/AgendaTableOriented';
import AgendaTableOrienteer from '../../components/Agenda/AgendaTableOrienteer';
import { useHistory } from 'react-router-dom'

const AgendaPage =(): ReactElement => {
  const history = useHistory()
  const role = localStorage.getItem('role')
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(setCurrentSection('Agenda'))
  })

  useEffect(()=>{
    if(role == Roles.ADMIN) {
      history.push('/agenda/admin')
    }
  })

  return (
    <NewLayout>
      { role == Roles.ORIENTED ? <AgendaTableOriented /> : <AgendaTableOrienteer />}
    </NewLayout>            
  )

}

export default AgendaPage;