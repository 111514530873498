import { ReactElement } from 'react';
import Appointments from '../../components/Inicio/Oriented/Appointments/Appointments';
import NewLayout from '../../components/Layout/NewLayout';
import {useParams} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { session } from '../../slices/sessionSlice';
import { useQuery } from '@apollo/client';
import { APPOINTMENTS_BY_SLUG_BY_ORIENTED } from '../../queries/Appointment';
import LoadingSpinner from '../../utils/spinner';
import BreadCrumb from '../../components/UI/BreadCrumb';
import BreadCrumbItem from '../../components/UI/BreadCrumbItem';
import ProgramBreadCrumb from '../../components/UI/ProgramBreadCrumb';

const ProgramAppointmentsPage =(): ReactElement => {
  const { programSlug } = useParams()
  const { user } = useSelector(session)

  const appointmentsByProgram = useQuery(APPOINTMENTS_BY_SLUG_BY_ORIENTED, {
    variables: {id: user.id, programSlug: programSlug}, 
  })

  return (
    <NewLayout>
      <div className=' w-full ml-11'>
        <BreadCrumb>
          <BreadCrumbItem Name={'Inicio'} path={'/home'} isCurrentPage={false}/>
          <ProgramBreadCrumb isCurrentPage={false}/>
          <BreadCrumbItem Name={'Encuentros'} isCurrentPage={true}/>
        </BreadCrumb>
        {appointmentsByProgram.loading ? <LoadingSpinner/> : <Appointments querydata={appointmentsByProgram}/>}
      </div>
    </NewLayout>            
  )

}

export default ProgramAppointmentsPage;