import { ReactElement, } from 'react';
import TittleInProgress from '../../UI/TittleInProgress';
import OrangeBorderCard from '../../UI/BorderOrangeCard';
import { useHistory, useParams} from 'react-router-dom'
import ButtonSecundary from '../../UI/ButtonSecundary';


const MyProgram = ({programName}): ReactElement => {
  const history = useHistory();
  const { programSlug } = useParams()
  const goTo = () => {
    history.push('/messages/new')
  }
  

  return (
    <div className="w-full flex flex-col items-start ">
      <div className={'w-full flex flex-row justify-between items-end pr-4'}>
        <TittleInProgress text={programName} width={'w-100'} />
        <div className={'flex justify-end'}>
          <ButtonSecundary
            text={'Contactar orientador'}
            action={goTo}
          />
        </div>
      </div>
      <div className="flex w-11/12 mt-7">
        <OrangeBorderCard
          link={`/my-programs/${programSlug}/appointments`}
          height={'h-auto'}
          width={'w-2/5'}
          title={'Mis encuentros'}
          description={'Accedé a cada uno de los encuentros durante tu proceso.'}
          newtab={false}
        />
      </div>
      {programSlug === 'orientacion-vocacional' &&
        <>
          <TittleInProgress text={'LAB de profesiones'} width={'w-auto'} />
          <div className="flex flex-nowrap flex-row h-full w-full justify-center items-start mt-7">
            <OrangeBorderCard
              link={`/my-programs/${programSlug}/profession-labs`}
              height={'h-36'}
              width={'w-4/12'}
              margin={'mr-5'}
              title={'Fichas de Carreras'}
              description={'Explorá todas las áreas profesionales, carreras y testimonios profesionales en formato PDF.'}
              newtab={false}
            />
            <OrangeBorderCard
              link={'https://open.spotify.com/show/6i9efIdaOomNuIUAgNmpn1?si=35d52981d7394e56'}
              height={'h-36'}
              width={'w-4/12'}
              margin={'mr-5'}
              title={'Podcast D’Alfonso'}
              description={'Historias que Inspiran: testimonios profesionales, y Coffe Lab: conversaciones con universidades.'}
              newtab={true}
            />
            <OrangeBorderCard
              link={'https://www.buscarreras.com/universidades/'}
              height={'h-36'}
              width={'w-4/12'}
              margin={'mr-5'}
              title={'Buscarreras'}
              description={'Navegá por nuestro BUSCARRERAS para conocer toda la oferta académica: carreras y universidades.'}
              newtab={true}
            />
          </div>
        </>
      }
    </div>
  )
}

export default MyProgram