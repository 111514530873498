import { ReactElement, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { downArrow, upArrow } from '../../assets/svg/dashboardIcons';
import Icon from '../UI/Icon';

const Question = ({data}): ReactElement => {
  const [expanded, setExpanded] = useState(false)

  const handleExpand = ():void => {
    setExpanded(!expanded)
  }

  return (
    <Accordion onChange={handleExpand} className="md:w-3/5 w-full" allowZeroExpanded>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton className="flex items-center text-base text-left justify-between border-b font-semibold py-4">
            <p className="pr-6">{data.question}</p>
            <Icon path={expanded ? upArrow : downArrow} color={'#2E384D'} width={14} height={14} />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className="text-left text-xs border-b py-4 text-black-primary">
          {data.answer}
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  )
}

export default Question