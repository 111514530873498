import { ReactElement, useEffect } from 'react';
import TittleInProgress from '../../components/UI/TittleInProgress';
import { useHistory, useParams, Link } from 'react-router-dom';
import ButtonSecundary from '../../components/UI/ButtonSecundary';
import NewLayout from '../../components/Layout/NewLayout';
import { getProfessionLabs } from '../../utils/strapiFunctions';
import LoadingSpinner from '../../utils/spinner';
import BreadCrumb from '../../components/UI/BreadCrumb';
import BreadCrumbItem from '../../components/UI/BreadCrumbItem';
import ProgramBreadCrumb from '../../components/UI/ProgramBreadCrumb';
import { useDispatch, useSelector } from 'react-redux';
import { professionLabs, setLabs } from '../../slices/labsSlice';

const ProfessionLabListPage = (): ReactElement => {
  const dispatch = useDispatch()
  const history = useHistory();
  const { programSlug } = useParams();
  const {labsList} = useSelector(professionLabs)

  useEffect(()=> {
    if (labsList == undefined || labsList.length == 0) {
      getProfessionLabs()
        .then(res => {
          dispatch(setLabs(res))
        });
    }
  }, [])  

  const goTo = () => {
    history.push('/messages/new');
  };

  return (
    <NewLayout>
      <div className=" w-full ml-11">
        <div className="w-5/6 flex flex-col items-start -mt-7">
          <div
            className={'w-full flex flex-row justify-between items-end pr-4'}
          >
            <BreadCrumb>
              <BreadCrumbItem Name={'Inicio'} path={'/home'} isCurrentPage={false}/>
              <ProgramBreadCrumb isCurrentPage={false}/>
              <BreadCrumbItem Name={'LAB de profesiones'} isCurrentPage={true}/>    
            </BreadCrumb>
            <div className={'flex justify-end w-5/6'}>
              <ButtonSecundary text={'Contactar orientador'} action={goTo} />
            </div>
          </div>
          {programSlug === 'orientacion-vocacional' && (
            <>
              <TittleInProgress text={'LAB de profesiones'} width={'w-auto'} />
              <div className="flex flex-wrap w-full items-start mt-3">
                { labsList!== undefined && labsList.length !=0 ?
                  labsList.map((i) => (
                    <Link
                      to={`/my-programs/${programSlug}/profession-labs/${i.id}`}
                      key={i.id}
                      className="cursor-pointer flex flex-col justify-center items-center border w-1/4 h-20 text-center py-4 px-3 border-gray-secundary rounded-md mr-4 my-2"
                    >
                      <p className="text-black-primary text-lgs font-medium">
                        {i.Title}
                      </p>
                    </Link>
                  ))
                  :
                  <div className='flex flex-wrap w-full justify-center items-start mt-3'>
                    <LoadingSpinner/>
                  </div>
                }
              </div>
            </>
          )}
        </div>
      </div>
    </NewLayout>
  );
};

export default ProfessionLabListPage;
