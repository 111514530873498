import '../../src/App.css';
//import { useState } from 'react';

//uncomment in case component state is needed
//const [isLoading, setIsLoading] = useState(true);
//
export default function LoadingSpinner() {
    
  return (
    <div className="spinner-container">
      <div className="loading-spinner">
      </div>
    </div>
  );
}