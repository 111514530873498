import { FetchResult, gql } from '@apollo/client'
import { client } from '../../client-apollo'

export const assignOrienteer = (idOriented: string, idOrienteer: string): Promise<FetchResult<any>> => {
  return client
    .mutate({
      mutation: gql`
        mutation{
          addOrienteer(id:"${idOriented}", 
            idOrienteer: "${idOrienteer}")
        }
          `
    })
}

export const updateOriented = (id, orientedInputsToUpdate, basicInputsToUpdate): Promise<FetchResult<any, Record<string, any>, Record<string, any>>> => client
  .mutate({
    mutation: gql`
     mutation{
      updateOriented(id:"${id}", 
      fields:${orientedInputsToUpdate}, 
      basicData:${basicInputsToUpdate}
      ){
        id
        dni
        idKeycloak
        user{
          name
          forename
          surname
          email
          phone
          profilePicture
        }
        orienteer{
          id
        }
        socialNetwork
        school
        hasBrotherOriented
        numberOfBrothersOriented
        expectations
        address
        confirmedAppointments{
          id
          name,
          date
          comments
          privateComment
          programSlug
          interestLinks{
            id
            name
            link
          }
          status
        }
        age
        educationLevel
        motive
      }
     }
 `
  })

export const orientedPost = (values): Promise<FetchResult<any>> => {
  return client
    .mutate({
      mutation: gql`
         mutation {
         createOriented(variables : {
          dni:${values.dni},
          age:"${values.age}",
          school:"${values.school}",
          expectations:"${values.expectations}",
          address:"${values.address}"
          },
          basicInfo:{
          phone: "${values.phone}",
          email: "${values.email}",
          name: "${values.forename} ${values.surname}",
          forename: "${values.forename}",
          surname: "${values.surname}",
          })
          {dni 
          id
          age
          school
          user{id}
          }
           }
          `
    })
}