import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { session } from '../../slices/sessionSlice';
import { labelsByRole, role, valueByLabel } from '../../utils/profileUtils';
import TittleInProgress from '../UI/TittleInProgress';
import avatar from '../../assets/images/Vector .png';
import ImageAvatar from '../UI/ImageAvatar';
import { Link } from 'react-router-dom';

const Profile = (): ReactElement => {
  const { user } = useSelector(session);
  const roleValue = localStorage.getItem('role');

  return (
    <div className="h-full w-full mb-20">
      <TittleInProgress text={'Mi perfil'} width={'w-auto'} />
      <div className="border rounded-md block md:flex md:w-2/3 md:mt-3.5 border-gray-secundary flex">
        <div className="w-full md:w-1/2 h-auto pt-24">
          <div className="w-full h-1/2 flex justify-center items-end">
            <div className=" grid grid-columns-1 crusor-pointer">
              <label className="cursor-pointer mt-6">
                {
                  <>
                    {user.user.profilePicture ? (
                      <ImageAvatar
                        src={
                          process.env.REACT_APP_EXPORT_FILES +
                          user.user.profilePicture
                        }
                        text={'your avatar'}
                        size={false}
                      />
                    ) : (
                      <ImageAvatar
                        src={avatar}
                        text={'your avatar'}
                        size={false}
                      />
                    )}
                  </>
                }
              </label>
            </div>
          </div>
          {
            <div className="w-full h-1/3 flex items-center flex-col justify-center">
              <Link to="/profile/edit" className={'flex justify-center items-center py-2 px-6 focus:outline-none bg-green-principal text-white rounded-full h-10 font-semibold'}>Editar Perfil</Link>
              <Link to="/profile/change-password" className={'cancel-link mt-1'}>Cambiar contraseña</Link>
              {roleValue === 'orienteer' ?
                <a href='/profile/google-config' className={'cancel-link mt-1'} >Vinculación de mi calendario con Google</a> : ''
              }
            </div>
          }
        </div>
        <div className="w-full md:w-3/5 p-8 bg-white border-gray-secundary border-l mt-6 mb-6">
          <div>
            <div className="pb-6">
              <h2 className="text-black-common text-lg text-left">
                {user.user.name}
              </h2>
              {roleValue && (
                <p className="text-left text-gray-principal text-sm">
                  {role(roleValue)}
                </p>
              )}
            </div>
            {roleValue &&
              labelsByRole(roleValue).map((i, index) => (
                <div className="pb-4" key={index}>
                  <label
                    htmlFor="about"
                    className="text-gray-principal block pb-1 text-left uppercase text-xss"
                  >
                    {i}
                  </label>
                  <p className="text-black-primary text-base text-left">
                    {valueByLabel(i, user)}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Profile;
