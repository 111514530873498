import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'professionLabs',
  initialState: {
    labsList: []
  },
  reducers: {
    setLabs: (state, action): void => {
      state.labsList = action.payload
    }
  }
})


export const {setLabs} = slice.actions;
export const professionLabs = (state):any => state.professionLabs;
export default slice.reducer;