import { ReactElement, useEffect, useState } from 'react';
import Linkify from 'linkify-react';
import {Link} from 'react-router-dom'
interface CommentsProps{
  appointment: any,
}

const Comments = (props: CommentsProps): ReactElement => {
  const [comment, setComment] = useState('')
  const [detail, setDetail] = useState('')
  const [links, setLinks] = useState<any>([])
  //const links = props.appointment.interestLinks ? props.appointment.interestLinks : []
  //const comment = props.appointment.comments ? props.appointment.comments : props.appointment.detail

  const renderLink = ({ attributes, content }) => {
    const { href } = attributes;
    return <Link to={href} className={'cursor-pointer  underline hover:text-green-principal text-green-principal'} >{content}</Link>;
  };

  useEffect(()=>{
    if(props.appointment !== null) {
      setLinks(props.appointment.interestLinks ? props.appointment.interestLinks : [])
      setComment(props.appointment.comments ? props.appointment.comments : '')
      setDetail(props.appointment.detail ? props.appointment.detail : '')
    }
  }, props.appointment)

  return (
    <div className="w-full flex flex-col items-start">
      <label className="text-black-primary mt-6 text-base font-medium mb-2">Detalles del encuentro</label>
      <div className="text-black-primary text-xs w-88% text-left whitespace-pre-wrap">
        <Linkify options={{render: renderLink}}>
          {detail.length >0 ? detail : 'Aún no hay detalles...'}
        </Linkify>
      </div>
      <label className="text-black-primary mt-10 text-base font-medium mb-2">Comentarios del encuentro</label>
      <div className="text-black-primary text-xs w-88% text-left whitespace-pre-wrap">
        <Linkify options={{render: renderLink}}>
          {comment.length >0 ? comment : 'Aún no hay comentarios...'}
        </Linkify>
      </div>
      <p className="text-black-primary mt-10 text-base font-medium">Links de interés</p>
      <div className="mt-2 w-full mb-12">
        {links.length == 0 ? <div className="w-full flex flex-col items-start">Aún no hay links de interés...</div> : links.map((i, index) => (
          <div
            key={index}
            className={`px-2 border-b ${index == 0 && 'border-t'} py-5 border-gray-secundary flex justify-between w-3/4`}
          >
            <p className="text-black-primary text-xs">{i.name}</p>
            <p className="text-black-primary text-xs cursor-pointer  underline hover:text-green-principal text-green-principal" onClick={():Window|null => window.open(i.link,'_blank')}>{i.link}</p>
            <p className="cursor-pointer"/>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Comments