import Markdown from 'react-markdown';
import { Formik } from 'formik';
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setResponse, setShowModal } from '../../slices/modalSlice';
import { session } from '../../slices/sessionSlice';
import { initialForm } from '../../utils/FormInterface';
import { getFormBySlug, saveFormData } from '../../utils/formsFunctions';
import Button from './Button';
import { setIsCompleted, setIsFinished } from '../../slices/actSlice';

const FormBuilder = ({ slug, close, appointmentId, actId }): ReactElement => {
  const { user } = useSelector(session)
  const [currentForm, setCurrent] = useState(initialForm)
  const dispatch = useDispatch()

  useEffect(() => {
    getFormBySlug(slug).then(res => {
      res != undefined && setCurrent(res)
    }).catch((err) => console.error(err))

    window.addEventListener('beforeunload', confirmationMessage);
    window.history.pushState(null, '', window.location.href);
    return ():void => {
      window.removeEventListener('beforeunload', confirmationMessage);
      window.onpopstate = ():void => { }
    };
  }, [])

  useEffect(()=>{
    dispatch(setIsCompleted(false))
  },[])

  const confirmationMessage = async (e):Promise<string> => {
    var confirmationMessage = '';
    e.returnValue = confirmationMessage;
    return confirmationMessage;
  }
  
  const getOptions = (fieldOptions: string, placeholder:string): Array<string> => {
    let fieldOptArr=fieldOptions.split('\n')
    fieldOptArr.unshift(placeholder||'Seleccione una opción')
    return fieldOptArr
  }
  const getInitialValues = (): any => {
    const obj = {}
    currentForm.Fields.map(i => {
      obj[i.field_name] = i.field_type == 'number' ? 0 : ''
    })
    return obj
  }

  const checkedValues = (values): any => {
    const obj = {}
    for (var i in values) {
      if (currentForm.Fields.some(e => e.field_name == i)) {
        obj[i] = values[i]
      }
    }
    return obj
  }

  const fillInputs = (fieldName: string, values):any=>{
    switch(fieldName){
      case 'nombre':
        values['nombre']=user.user.forename
        return user.user.forename;
      case 'apellido':
        values['apellido']=user.user.surname
        return user.user.surname;
      case 'dni':
        values['dni']=user.dni;
        return user.dni;
      case 'edad':
        values['edad']=user.age;
        return user.age;
      default:
        return false
    }
  }
  /*Sum: Change values based on checked boxes in fields with multiples options*/ 
  const addToValues=(option: string, index, values):any =>{
    if (values[index.field_name] === '' ) values[index.field_name] = []
    values[index.field_name].includes(option) ? values[index.field_name].splice(values[index.field_name].indexOf(option),1) : values[index.field_name].push(option)
  } 
  /*sum: Makes sure that at least one value is inside each values array to be able to submit*/
  const isRequired=(elem, values):boolean=>values[elem.field_name] !== '' && values[elem.field_name].length !==0 ? false:true;

  /*Sum: Makes input based on if the field has multiple options to check*/
  const inputMaker= (index ,options:String, values,handleChange):any =>{
    const optArr=options.split('\n')
    if(options.includes('\n')){
      return (
        optArr.map((elem, i):any=>{
          return(
            <div className={`flex w-full justify-between items-center text-left min-h-20 px-4 py-4 border ${i%2 == 0 ?'bg-green-secundary bg-opacity-10':'bg-opacity-5'}`}>
              <div>{elem}</div>
              <input
                className="border border-gray-secundary focus:outline-none px-2 py-1"
                type={index.field_type}
                name={index.field_name}
                required={index.required && isRequired(index, values)}
                value={values[elem]}
                onChange={handleChange}
                onClick={():void=>addToValues(elem, index, values)}
              />
            </div>
          )
        })
      ) 
    }
    return(
      <input
        className="border border-gray-secundary focus:outline-none px-2 py-1"
        type={index.field_type}
        name={index.field_name}
        required={index.required}
        value={values[index.field_name]}
        onChange={handleChange}
      />
    )
  }
  const Form = ({ handleChange, handleSubmit, values }): ReactElement => {

    return (
      <form className="w-full" onSubmit={handleSubmit}>
        {
          currentForm.Fields.map(i => (
            <div className="flex w-5/6 justify-between items-center mt-4 text-left" key={i.id}>
              {i.field_type==='checkbox'?
                <div className="flex flex-col w-full items-center mx-auto text-left">
                  {i.field_label && <label className="font-medium mb-4">{i.field_label}</label>}
                  {i.field_details && <Markdown children={i.field_details} className="text-black-primary mt-4 text-xs text-left w-11/12 bg-gray-secundary mb-2"/>}
                  <div className='grid grid-cols-3 w-11/12'>
                    {inputMaker(i, i.field_options, values, handleChange)}
                  </div>
                </div>
                :
                <>  
                  <label className="font-medium">{i.field_label}</label>
                  <div className="flex flex-col w-full items-center">
                    {i.field_details && <Markdown children={i.field_details} className="text-black-primary mt-4 text-xs w-11/12 bg-gray-secundary mb-2"/>}
                    {i.field_type !== 'select' ?
                      <input
                        className="border w-11/12 border-gray-secundary focus:outline-none px-2 py-1"
                        type={i.field_type}
                        placeholder={i.field_placeholder}
                        name={i.field_name}
                        required={i.required}
                        value={fillInputs(i.field_name, values)?fillInputs(i.field_name, values):values[i.field_name]}
                        onChange={handleChange}
                      /> :
                      <select
                        name={i.field_name}
                        className="border w-11/12 border-gray-secundary focus:outline-none px-2 py-1 "
                        onChange={handleChange}
                        required={i.required}
                        value={values[i.field_name]}
                      > 
                        {getOptions(i.field_options, i.field_placeholder).map((option, index) => (
                          index==0? <option hidden selected value={''} >{option}</option> :
                            <option value={option} >{option}</option>
                        ))}
                      </select>
                    }
                  </div>
                </>
              }
            </div>
          ))
        }
        <div className="w-full flex items-center justify-center mt-4">
          <Button
            action={(): void => { }}
            text={currentForm.submit_text}
          />
        </div>
      </form>
    )
  }
 
  return currentForm.Slug == '' ?
    <div />
    : (
      <div className="w-5/6 border-2 border-gray-secundary pb-4 pl-4">
        <p className="title-bold">{currentForm.Title}</p>
        <Formik
          initialValues={getInitialValues()}
          onSubmit={(values): void => {
            const valuesToSend = checkedValues(values)
            saveFormData({
              slug,
              orientedDNI: user.dni,
              orientedID: user.id,
              appointmentID: appointmentId,
              activityID: actId,
              format: 'form',
              ...valuesToSend
            }).then(res => {
              if (res) {
                dispatch(setIsCompleted(true))
                dispatch(setIsFinished(true))
                dispatch(setResponse({
                  message: 'Actividad completada correctamente',
                  error: false,
                  description: ''
                }));
                dispatch(setShowModal(true));
              }
              else {
                dispatch(setResponse({
                  message: 'Error al guardar la actividad',
                  error: true,
                  description: ''
                }));
                dispatch(setShowModal(true));
              }
            })
            close()
          }}
        >
          {(props): ReactElement => <Form {...props} />}
        </Formik>
      </div>
    )
}

export default FormBuilder