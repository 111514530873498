import { ReactElement } from 'react';
import { getDate } from '../../../../utils/utilsDate';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom'

const ActivityCard = ({ info}): ReactElement => {
  const { programSlug } = useParams();
  return (
    <div className=" h-4/5 border border-gray-secundary rounded-lg p-6 flex flex-col items-start">
      <div className={`${info.isComplete ? 'bg-gray-secundary' : 'bg-yellow-info'} px-2`}>
        <p className="text-black-primary text-xs">{info.isComplete ? 'Finalizada' : 'Pendiente'}</p>
      </div>
      <p className="title-smallCard mt-5">{info.activity.name}</p>
      <p className="description-smallCard mt-32 absolute">Vence el {getDate(info.deliveryDate)}</p>
      <Link className={`link absolute mt-40 ${info.isComplete && 'text-gray-secundary hover:text-gray-secundary cursor-default'}`} 
        disabled={info.isComplete} 
        to={info.isComplete ? '#' : `/my-programs/${programSlug}/appointments/${info.appointmentId}/activity/${info.activity.id}`}
      >
        Ir a la actividad {'>'}
      </Link>
     
    </div>
  )
}

export default ActivityCard