import { ReactElement } from 'react';
import CalendarInput from '../../components/Perfil/CalendarInput';
import NewLayout from '../../components/Layout/NewLayout';

const GoogleConfigPage = (): ReactElement => {

  return (
    <NewLayout>
      <div className="h-full w-full mx-12">
        <CalendarInput />
      </div>
    </NewLayout>
  );
};

export default GoogleConfigPage;
