import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

const ProgramCard = ({ data }): ReactElement => {
  const { name, description, slug } = data


  return (
    <div className="border-t rounded-md border-gray-secundary mt-4 mb-px h-52 w-80">
      <div className="p-6 h-3/4 flex justify-between items-start flex-col w-full border-l border-r rounded-md rounded-b-none border-gray-secundary">
        <p className="title-smallCard">{name}</p>
        <p className="description-smallCard">{description}</p>
      </div>
      <div className="bg-green-principal text-base text-white w-full text-left h-1/4 flex items-center border border-green-principal rounded-b-md">
        <Link to={`/my-programs/${slug}`}
          className="ml-6 cursor-pointer hover:underline hover:text-white">
          Ir al programa
        </Link>
      </div>
    </div>
  )
}

export default ProgramCard