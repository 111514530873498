import { gql } from '@apollo/client';

export const GET_ACTIVITIES = gql`
  query{
    activities{
      id
      accessLink
      duration
      name
      format
      isRequired
    }
  }
  `

export const GET_ACTIVITIES_BY_ORIENTEER = gql `
  query GetActivitiesInAppointmentsByOrienteer($id: String!){
    getActivitiesInAppointmentsByOrienteer(id: $id){
      id
      deliveryDate
      isComplete
      activity{
        name
      }
      oriented{
        id
        dni
        user{
          name
          forename
          surname
        }
      }
      appointment{
        id
        name
      }
    }
  }`

export const GET_ACTIVITIES_BY_ORIENTED = gql `
  query GetAppointmentsAndActivitiesByOriented($id: String!){
    getAppointmentsAndActivitiesByOriented(id: $id){
      deliveryDate
      isComplete
      activity{
        name
      }
      appointment{
        id
        name
        confirmedOrienteers{
          user{
            name
            forename
            surname
          }
        }
        program{
          id
          name
        }
      }
    }
  }
`
