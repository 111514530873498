import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Table from '../UI/Table';
import TittleInProgress from '../UI/TittleInProgress';
import { mapState } from './MapStateUtil';
import { payQuota } from '../../mutations/Payment';
import { setResponse, setShowModal } from '../../slices/modalSlice';
import UploadTicket from './UploadTicket';
import ImageAvatar from '../UI/ImageAvatar';
import avatar from '../../assets/images/Vector .png';
import { useHistory } from 'react-router-dom'
import { getDate } from '../../utils/utilsDate';

const PaymentDetails = ({ paymentPlan, goBack, refetch}): ReactElement => {
  const history = useHistory()
  const [showUpload, setShowUpload] = useState(false)
  const [ticket, setTicket] = useState(false)  
  const [quotas, setQuotas] = useState([]);
  const dispatch = useDispatch()

  useEffect(()=>{
    const cuotasArray = paymentPlan.quotas
    var length = cuotasArray.length;
    const formatter = cuotasArray.map(
      quotas => {
        return {
          cuota: quotas.number + ' de ' + length,
          date: getDate(quotas.dueDate),
          name: quotas.paymentPlan.programSlug,
          ...quotas
        };
      }
    );
    setQuotas(formatter)
  }, [paymentPlan])

  useEffect(()=> {
    if(showUpload) {
      history.replace(`/payments/${paymentPlan.id}/details/upload`)
    } else {
      history.replace(`/payments/${paymentPlan.id}/details`)
    }
  }, [showUpload])

  const goToUploadTicket = (original): void => {
    setTicket(original);
    setShowUpload(!showUpload);
  };


  const payQuotas = (values): void => {
    const { id, status } = values
    payQuota(
      {
        id,
        status: status === 'PENDING' ? true : false
      }
    ).then((): void => {
      refetch()
    }).catch((): void => {
      dispatch(setResponse({
        message: 'El pago de la cuota no fue actualizado.',
        error: true,
        description: 'Intentelo nuevamente'
      }));
      dispatch(setShowModal(true));
    })
  }

  const columns = [{

    Header: 'Programa',
    accessor: 'name'
  },
  {
    Header: 'Fecha',
    accessor: 'date'
  },
  {
    Header: 'Cuota',
    accessor: 'cuota'
  },
  {
    Header: 'Monto pagado',
    accessor: 'value'
  },
  {
    Header: 'Estado',
    accessor: 'status'

  }]   

  return (
    <div className="w-full flex flex-col items-start">

      {!showUpload && paymentPlan ? (
        <>
          <a onClick={():void => goBack(goBack)} className="underline text-black-primary text-xs text-left mt-8 hover:text-black-primary cursor-pointer">{'<'}ir a pagos</a>

          <div className="w-2/5 flex justify-left items-center mt-10">
            {!paymentPlan.oriented.user.profilePicture ? (
              <ImageAvatar src={avatar} text={'your avatar'} size={true} />) : (
              <ImageAvatar src={process.env.REACT_APP_EXPORT_FILES  + paymentPlan.oriented.user.profilePicture} text={'your avatar'} size={true}/>
            )}
            <p className="title-smallCard mb-1 ml-6">{paymentPlan.oriented.user.name}</p>
          </div>
          <TittleInProgress text={'Detalle de pagos'} width={'w-auto'} />
          <div className="w-5/6 mt-8">
            {quotas && <Table 
              columns={columns}
              data={quotas}
              state={mapState}
              upload={goToUploadTicket}
              approved={payQuotas}
              initialRows={10} /> }
          </div></>) : <UploadTicket ticket={ticket} goBack={goToUploadTicket}/>}
    </div>
  )
}

export default PaymentDetails