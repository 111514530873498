import { configureStore } from '@reduxjs/toolkit';
import sessionReducer from '../slices/sessionSlice';
import orienteersReducer from '../slices/orienteersSlice';
import modalReducer from '../slices/modalSlice';
import switchPageReducer from '../slices/switchPageSlice';
import headerReducer from '../slices/headerSlice';
import navReducer from '../slices/navSlice';
import messageReducer from '../slices/messageSlice';
import strapiDataReducer from '../slices/initialStrapiData';
import activityReducer from '../slices/actSlice'
import labsReducer from '../slices/labsSlice'
import timezoneReducer from '../slices/sessionSlice';

export default configureStore({
  reducer: {
    session: sessionReducer,
    orienteers: orienteersReducer,
    modal: modalReducer,
    switchPage: switchPageReducer,
    header: headerReducer,
    nav: navReducer,
    inbox: messageReducer,
    strapiData: strapiDataReducer,
    activity: activityReducer,
    professionLabs: labsReducer,
    timezone: timezoneReducer,
  },
});
