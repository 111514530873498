/*eslint-disable */
import { ReactElement } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import PaginationButtons from './PaginationButtons';

interface TableWithPaginationBackendProps {
  data: Array<any>;
  columns: Array<{
    Header: string;
    accessor: string;
  }>;
  paginationState: any;
  showColumnNames?: Boolean;
  orderColumnArray?:  Array<{
    id: string;
    desc: boolean;
  }>;
  
}

const TableWithPaginationBackend = (props: TableWithPaginationBackendProps): ReactElement => {

  const [ paginationState, setPaginationState ] = props.paginationState

  const sortColumnHandler =(column)=>{
    const {orderMode} = paginationState
    const newOrderMode = orderMode === 'ASC' ? 'DESC' : 'ASC'
    setPaginationState({...paginationState, orderMode: newOrderMode, orderBy: column.orderName})
  }

  const {
    columns,
    data,
    showColumnNames,
    orderColumnArray,
  } = props;

  const { 
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { 
        pageIndex: 0, 
        pageSize: '10',
        sortBy: orderColumnArray === undefined ? [] : orderColumnArray
      },
      
    },
    useSortBy,
    usePagination
  );
  
  const previousPageHandler = () => {
    const currentPage = paginationState.currentPage;
    const newCurrentPage = currentPage == 1 ? 1 : currentPage - 1    
    setPaginationState({...paginationState, currentPage: newCurrentPage})
  }

  const nextPageHandler = () => {
    const currentPage = paginationState.currentPage;    
    const newCurrentPage = currentPage == paginationState.totalPages ? paginationState.totalPages : currentPage + 1    
    setPaginationState({...paginationState, currentPage: newCurrentPage})
  }

  return (
    <>
      <div className="h-auto w-full mb-2 pagination">
        <PaginationButtons
          className = {'w-full flex justify-end mt-7 mb-2 mr-1'}
          prevClick = {previousPageHandler}
          nextClick = {nextPageHandler}
          currentPage = {paginationState.currentPage}
          totalPages = {paginationState.totalPages}
          isPreviousDisabled  = {!(paginationState.currentPage > 1)}
          isNextDisabled = {!(paginationState.currentPage < paginationState.totalPages)}
        />
      </div>
      <table
        cellSpacing={0}
        className="w-full border border-gray-secundary border-separate rounded-lg"
        {...getTableProps()}
      >
        {showColumnNames && (
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                className="text-black-primary text-sm"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    className="py-3 px-6 text-left font-bold text-green-principal "
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    onClick={()=>sortColumnHandler(column)}
                  >
                    {column.render('Header')}
                    <span>
                      {paginationState.orderMode === 'ASC' ? ' ▼' : ' ▲'}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        )}
        <tbody className="text-black-primary text-xs" {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr className="hover:bg-gray-100 w-full" {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      className="py-3 px-6 text-left border-t border-gray-secundary"
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}                      
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

TableWithPaginationBackend.defaultProps = {
  showColumnNames: true,
};

export default TableWithPaginationBackend;