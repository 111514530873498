import { FetchResult, gql } from '@apollo/client';
import { client } from '../../client-apollo';


export const saveProgram = (data): Promise<FetchResult<any>> => {
  return client
    .mutate({
      mutation: gql`
      mutation {
        saveProgram(data: ${data}){
          id
        }
      }
        `
    });
}

export const deleteProgram = (programId): Promise<FetchResult<any>> => {
  return client
    .mutate({
      mutation: gql`
      mutation {
        deleteProgram(data:{
          programId: "${programId}"
        })
      }
        `
    });
}

export const updateProgram = (data): Promise<FetchResult<any, Record<string, any>, Record<string, any>>> => client
  .mutate({
    mutation: gql`
     mutation{
      updateProgram(data: ${data}){
      	id
        name
        description
        status
  }
    }
 `
  })