
const timeSpanOptions:{label: string , value: number}[] = [
  {
    label: '15 minutos',
    value: 15
  },{
    label: '30 minutos',
    value: 30
  },{
    label: '45 minutos',
    value: 45
  },{
    label: '1hr',
    value: 60
  },{
    label: '1hr 15 min',
    value: 75
  },{
    label: '1hr 30 min',
    value: 90
  },{
    label: '1hr 45 min',
    value: 105
  },{
    label: '2hr',
    value: 120
  }
]

export default timeSpanOptions