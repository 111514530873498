import React, { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import logo from '../../assets/images/logo.png';
import { session } from '../../slices/sessionSlice';
import { navOptions } from '../../utils/navOptions';
import { navState } from '../../slices/navSlice';
import { useQuery } from '@apollo/client';
import { isReaded } from '../../queries/Message';



const Sidebar = (): ReactElement => {
  const {section} = useSelector(navState)
  const { user } = useSelector(session)
  const [messages, setMessages] = useState(0)
  const idkc = localStorage.getItem('idkc')
  const role = localStorage.getItem('role');
  const { data: messagesData, loading: messagesLoading, refetch: messagesRefetch } = useQuery(isReaded, {
    variables: {id:  user===null?idkc:user.user.id}
  })

  useEffect(()=>{
    if(!messagesLoading){
      showMessages()
    }
    
  }, [messagesData])

  useEffect(()=>{
    messagesRefetch({id:  user===null?idkc:user.user.id})
  }, [user])

  const showMessages = ():void =>{
    if(messagesData!==undefined){
      let amountMessages = 0
      messagesData.messages.map((message)=>{
        if(message.receivers[0].read==false){
          amountMessages++
        }
      })
      setMessages(amountMessages)
    }
  }

  return (
    <div className="md:w-1/5 w-3/4 h-full fixed z-30 inset-y-0 left-0 w-60 transition duration-300 transform bg-white dark:bg-gray-900 overflow-y-auto lg:translate-x-0 lg:static lg:inset-0 border-gray-secundary border-r">
      <div className="flex items-center justify-center mt-8">
        <div className="flex items-center justify-center  ">
          <span className="text-gray-800 w-1/2 dark:text-white text-2xl font-semibold">
            <img src={logo} />
          </span>
        </div>
      </div>
      <nav className="flex flex-col mt-10  text-center">
        {user && role && navOptions(role)?.map((i, index) => (
          // TODO: remove v2
          <Link to={'/' + i.route} key={index} id={i.label} className={`flex h-14 items-center hover:text-black-primary justify-between text-base text-black-primary ${i.label === 'Mensajes' && 'text-bold'} ${section == i.sectionName && 'bg-green-secundary bg-opacity-10 text-green-principal'} hover:bg-opacity-10 hover:bg-green-secundary fas fa-file-import`}>
            <div className={`w-1 ${section == i.sectionName && 'bg-green-principal'}  h-14`}></div>
            <div className='flex justify-start items-center w-5/6'>
              {i.icon( section == i.label ? '#0BA4A0' : '#2E384D')}
              <span className="ml-2.5">{i.label} {i.label === 'Mensajes' && messages !== 0 &&<div className="bg-green-principal bg-opacity-10 rounded-lg h-5 w-5 text-xs leading-4 font-bold inline-block rounded-full "><small >{messages}</small></div>}</span>
            </div>
          </Link>
        ))}
      </nav>
    </div>
  );
};

export default Sidebar;
