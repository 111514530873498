import { ReactElement, useState } from 'react';
import TittleInProgress from '../UI/TittleInProgress';
import * as yup from 'yup';
import { Formik } from 'formik';
import { setResponse, setShowModal } from '../../slices/modalSlice';
import { saveCalendarConfig } from '../../mutations/Orienteer'
import { useDispatch, useSelector } from 'react-redux';
import Button from '../UI/Button';
import { saveUserInfo, session } from '../../slices/sessionSlice';


const CalendarInput = (): ReactElement => {

  const { user } = useSelector(session);
  const dispatch = useDispatch();
  const [getCalendarID, setCalendarID] = useState(user.calendarId)

  const saveData = (): void => {
    if(!user.id && !getCalendarID){
      return;
    } else{
      saveCalendarConfig(user.id, `{
        calendarId: "${getCalendarID}"
      }`)
        .then(result => {
          if (result) {
            dispatch(setResponse({
              message: 'El calendario fue configurado con éxito!',
              error: false
            }));
            dispatch(saveUserInfo({...user, calendarId: getCalendarID}));
            dispatch(setShowModal(true));
          }
        }).catch((error) => {
          console.error(error);
          dispatch(setResponse({
            message: 'Ups! Ocurrió un error al cargar el calendario.',
            error: true
          }));
          dispatch(setShowModal(true));
        })
    }
  }

  const handleInputChange = (event): void => {
    const target = event.target;
    const value = target.value;
    setCalendarID(value)
  }

  const schema = yup.object().shape({
    calendarId: yup.string().email().required('El email es requerido.')
  })

  return (
    <Formik
      enableReinitialize
      initialValues={{
        userId: user.id,
        calendarId: ''
      }}
      onSubmit={saveData}
      validationSchema={schema}
    >
      {(formik):ReactElement => {
        return(
          <form onSubmit={formik.handleSubmit}>
            <div className="h-full w-full my-4">
              <TittleInProgress text={'Vinculación del calendario de Google'} width={'w-full'} />
              <div className="w-3/6 h-auto border rounded-md block mt-4 pb-3 border-gray-secundary flex">
                <div className="w-full m-2">
                  <div className="w-full h-1/3 flex flex-col justify-start">
                    {getCalendarID != null ? '' :
                      <label className="text-gray-principal flex justify-start">
                        Ingrese su email, dónde visualizará los eventos de D'Alfonso:
                      </label> 
                    }
                    <input
                      type="text"
                      id='calendarId'
                      name='calendarId'
                      className="block w-2/3 p-2 mt-2 text-gray-700 appearance-none border rounded-lg border-gray-secundary focus:shadow-inner"
                      value={getCalendarID}
                      onChange={handleInputChange}
                      required
                    />
                    <div className="w-full h-full flex flex-start mt-2">
                      <Button text="Guardar" action={(): void => saveData()} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )
      }}
    </Formik>
  );
};

export default CalendarInput;
