import { ReactElement, useEffect } from 'react';
import NewLayout from '../../components/Layout/NewLayout';
import { useDispatch } from 'react-redux';
import { setCurrentSection } from '../../slices/navSlice';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_APPOINTMENT } from '../../queries/Appointment';
import LoadingSpinner from '../../utils/spinner';
import BegginingAppointmentDetail from '../../components/Inicio/Oriented/AppoinmentDetail/BegginingAppointmentDetail';
import BreadCrumbItem from '../../components/UI/BreadCrumbItem';
import BreadCrumb from '../../components/UI/BreadCrumb';

const AgendaOrientedDetailsPage = (): ReactElement => {
  const dispatch = useDispatch();
  const { appointmentId } = useParams();

  useEffect(() => {
    dispatch(setCurrentSection('Agenda'));
  });

  const { data, loading } = useQuery(GET_APPOINTMENT, {
    variables: { id: appointmentId },
  });


  return (
    <NewLayout>
      <div className="w-full md:ml-11">
        <BreadCrumb>
          <BreadCrumbItem Name={'Encuentros'} path={'/agenda'} isCurrentPage={false}/>
          <BreadCrumbItem Name={'Detalle del encuentro'} isCurrentPage={true}/>
        </BreadCrumb>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <BegginingAppointmentDetail appointment={data.appointmentById} />
        )}
      </div>
    </NewLayout>
  );
};

export default AgendaOrientedDetailsPage;
