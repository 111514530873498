import  { useEffect, ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import Orienteds from '../../components/Asignaciones/Orienteds';
import NewLayout from '../../components/Layout/NewLayout';
import { setCurrentSection } from '../../slices/navSlice';

const AssignedPage =(): ReactElement => {

  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(setCurrentSection('Asignados'))
  })

  return (
    <NewLayout>
      <div className="w-full h-full">
        <Orienteds />
      </div>
    </NewLayout>
  )

}

export default AssignedPage;