import { ReactElement } from 'react';

const NewPromptButtonGB = ({ text, action,  disabled = false, okButton = true, type }): ReactElement => {
  return (
    <div className={'w-full'}>
      <button
        disabled={disabled}
        type={type}
        onClick={action}
        className={`${okButton ? 'bg-green-principal text-white my-1 mx-6 w-3/6 focus:outline-none rounded-full h-10 font-semibold' : 'border text-black-primary'} my-1 mx-6 w-3/6 focus:outline-none border-black-primary rounded-full h-10 font-semibold`}>
        {text}
      </button>
    </div>
  )
}

export default NewPromptButtonGB