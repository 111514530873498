import { ReactElement, useEffect, useState } from 'react';
import OrientedProfileInfo from '../../components/Ingresos/OrientedProfileInfo';
import NewLayout from '../../components/Layout/NewLayout';
import { useParams } from 'react-router-dom';
import { ADMIN_ORIENTED_BY_ID } from '../../queries/Oriented';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../../utils/spinner';
import BreadCrumb from '../../components/UI/BreadCrumb';
import BreadCrumbItem from '../../components/UI/BreadCrumbItem';

const AdminOrientedInfoPage =(): ReactElement => {

  const { orientedId } = useParams()
  const [orientedName, setOrientedName] = useState('...')
  const {data, loading} = useQuery(ADMIN_ORIENTED_BY_ID, {
    variables: { id: orientedId }
  })

  useEffect(()=>{
    if(!loading && data){
      setOrientedName(data.orientedById.user.name)
    }
  })
  
  return (
    <NewLayout>
      <div className=' w-5/6 ml-11'>
        <BreadCrumb>
          <BreadCrumbItem Name={'Inicio'} path={'/'} isCurrentPage={false}/>
          <BreadCrumbItem Name={`${orientedName}`} path={''} isCurrentPage={true}/>
        </BreadCrumb>
        {loading ? <LoadingSpinner /> : <OrientedProfileInfo oriented={data.orientedById}/>}
      </div>
    </NewLayout>            
  )

}

export default AdminOrientedInfoPage;