import { ReactElement, useEffect, useState } from 'react';
import FinalMaterial from '../../components/Orientados/Finalmaterial';
import { ORIENTED_BY_ID } from '../../queries/Oriented';
import { APPOINTMENTS_BY_ORIENTED } from '../../queries/Appointment';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../../utils/spinner';

type OrientedAppointment = {
  orientedId: string
  appointmentId: string
}

const FinalMaterialPage = (props:OrientedAppointment): ReactElement => {
  const [selectedAppointment, setAppointment] = useState([]);
  const [currentOriented, setOriented] = useState({});

  const { data: oriented,
    loading: loadingOriented } = useQuery(ORIENTED_BY_ID, {
    variables: { id: props.orientedId },
  });

  const { data, loading } = useQuery(APPOINTMENTS_BY_ORIENTED, {
    variables: { id: props.orientedId },
  });

  let filterSelectedAppointment = () => {
    const appointmentSelectedId = !loading
      ? data.getAppointmentsByOriented.find(
        (AppointmentSelected) => AppointmentSelected.id == props.appointmentId
      )
      : {};
    setAppointment(appointmentSelectedId);
  };

  let setCurrentOriented = () => {
    const orientedSelectedId = !loadingOriented
      ? oriented.orientedById
      : {};
    setOriented(orientedSelectedId);
  };

  useEffect(() => {
    if (!loading && !loadingOriented){
      filterSelectedAppointment();
      setCurrentOriented();
    }
  }, [loading, loadingOriented]);


  return (
    <div>
      {loading && loadingOriented ? (
        <LoadingSpinner />
      ) : (
        <FinalMaterial
          oriented={currentOriented}
          appointment={selectedAppointment}
        />
      )}
    </div>
  );
};

export default FinalMaterialPage;
