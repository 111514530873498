import { ReactElement, useEffect, useState } from 'react';
import { GET_CALENDAR_TOKENS } from '../../queries/Appointment/index';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../../utils/spinner';
import { Redirect } from 'react-router-dom';
import { getUrlRedirectApiAccess } from '../../utils/authGoogle';

const GoogleApiAccessRedirectPage = (): ReactElement => {
  const params = new URL(document.location.href).searchParams;
  let code = params.get('code');
  const [hasGToken, setHasGToken] = useState(false);

  const url_redirect = getUrlRedirectApiAccess() 
  const { loading, data } = useQuery(GET_CALENDAR_TOKENS, {
    variables: {
      data: {
        code: code,
        dev: true,
        url:  url_redirect
      } 
    },
  });

  useEffect(() => {
    console.log('loading: ', loading);
    console.log('data out: ', data);
    if (!loading && data) {
      console.log('data: ', data);
      const gTokens = data.getToken;
      localStorage.setItem('g-tokens', gTokens);
      setHasGToken(true);
    }
  }, [loading]);

  return (
    <div className="h-full w-full mx-12">
      { hasGToken ? <Redirect to="/" /> : <LoadingSpinner /> }
    </div>
  );
};

export default GoogleApiAccessRedirectPage;
