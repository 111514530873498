import { ReactElement, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Button from '../UI/Button'
import Icon from '../UI/Icon'
import { closeIcon, file } from '../../assets/svg/dashboardIcons'
import ButtonSecundary from '../UI/ButtonSecundary'
import { uploadFiles } from '../../utils/uploadFiles'
import { useDispatch } from 'react-redux'
import { sendFeedback } from '../../mutations/Appointment'
import { useQuery } from '@apollo/client'
import { deleteFile, GET_FEEDBACK_ORIENTEER } from '../../queries'
import Table from '../UI/Table'
import { setResponse, setShowModal } from '../../slices/modalSlice'
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../utils/spinner'

type OrientedAppointment = {
  oriented: any
  appointment: any
}

const FinalMaterial = (props: OrientedAppointment): ReactElement => {
  const { appointmentId } = useParams();
  const orientedUserId = Object.keys(props.oriented).length === 0 ? '' : props.oriented.user.id
  const [files, setFiles] = useState<Array<any>>([])
  const dispatch = useDispatch()
  const [filesToRender, setFilesRender] = useState<Array<any>>([])
  const feedbackFiles = useQuery(GET_FEEDBACK_ORIENTEER, {
    variables: { id: orientedUserId, appointmentId: appointmentId }
  })

  const columns = [
    {
      Header: 'Nombre',
      accessor: 'name'
    },
    {
      Header: 'Formato',
      accessor: 'type',
      disableSortBy: true
    }
  ]

  const deleteSelectedFile = (feedbackFileId):void => {
    deleteFile(feedbackFileId).then((): void => {
      dispatch(setResponse({
        message: 'Archivo Borrado',
        error: false,
        description: 'Ya no podrás verlo'
      }));
      dispatch(setShowModal(true))
      feedbackFiles.refetch()
    }).catch((): void => {
      dispatch(setResponse({
        message: 'El archivo no pudo ser borrado',
        error: true,
        description: 'Intentar nuevamente más tarde.'
      }));
      dispatch(setShowModal(true));
    })
  }

  useEffect(() => {
    if (!feedbackFiles.loading) {
      feedbackFiles.data && setFilesRender(feedbackFiles.data.findFeedbackFiles.map(i => {
        return ({
          ...i,
          type: 'PDF'
        })
      }))
    }
  }, [feedbackFiles])


  const { getRootProps, getInputProps } = useDropzone({
    accept: '.pdf',
    onDrop: (acceptedFiles) => {
      setFiles([
        ...files,
        ...acceptedFiles
      ]
      );
    }
  })

  const upload = (showToOriented: Boolean):void => {
    const formData = new FormData()
    const file = files[0]

    formData.append('feedback', file)
    formData.append('userId', props.oriented.user.id)
    formData.append('appointmentId', props.appointment.id)

    uploadFiles(formData).then((): void => {
      sendFeedback(`{
        orientedId: "${props.oriented.id}",
        appointmentId: "${props.appointment.id}",
        sendFeedback: ${showToOriented}
      }`)
        .then((): void => {
          setFiles([])
          feedbackFiles.refetch();
          dispatch(setResponse({
            message: 'Archivo guardado',
            error: false,
            description: showToOriented ? 'El orientado podrá verlo en material final' : 'Guardado como borrador'
          }));
          dispatch(setShowModal(true));
        })
    }).catch((): void => {
      dispatch(setResponse({
        message: 'El archivo no pudo ser guardado',
        error: true,
        description: 'Intentar nuevamente más tarde.'
      }));
      dispatch(setShowModal(true));
    })
  }

  const uploadFile = ():void => {
    upload(true)
  }

  const saveAsBorrador = ():void => {
    upload(false)
  }

  const notUploadFile = (file):void => {
    const updatedList = files.filter(i => i.name != file.name)
    setFiles(updatedList)
  }

  const filesRender = files.map((i) => (
    <div key={i.name} className="mt-2 flex w-full">
      <div className="flex flex-row w-full">
        <Icon path={file} width={14} height={24} color={''} />
        <div className="ml-2 w-full">
          <p className="text-xss text-left text-black-primary">{i.name}</p>
          <div className="flex w-full flex-row">
            <div className="rounded-md bg-green-principal w-1/5" style={{ height: 8 }} />
            <button className="focus:outline-none" onClick={():void => notUploadFile(i)}>
              <Icon path={closeIcon} color={''} width={20} height={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  ))

  return (
    <div className="w-full flex flex-col items-start">
      <p className="mt-8 text-base text-black-primary font-medium">Cargar archivos</p>
      <div {...getRootProps()} className="back-drop mt-4 border py-10 border-dashed border-gray-principal w-1/2 flex flex-col justify-center">
        <input {...getInputProps()} id="file-upload" />
        <label className="mb-2" htmlFor="file-upload">
          <Button text={'Cargar archivo'} action={(): void => { }} />
        </label>
        <p className="tex-black-primary text-xss">Arrastrar o cargar tus archivos aquí</p>
      </div>
      <div className="mt-4 w-full">{filesRender}</div>
      <p className="mt-10 text-base text-black-primary font-medium">Documentos cargados</p>
      {feedbackFiles.loading ? <LoadingSpinner/> :
        <div className="w-1/2 mt-4">
          <Table columns={columns} data={filesToRender} deleteRow={deleteSelectedFile} />
        </div>}
      <div className="flex mt-10 pb-4">
        <ButtonSecundary text="Guardar borrador" disabled={files.length === 0} action={saveAsBorrador} />
        <div className="ml-2">
          <Button text="Enviar material final" disabled={files.length === 0} action={uploadFile} />
        </div>
      </div>
    </div>
  )
}

export default FinalMaterial