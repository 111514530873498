import { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { navState } from '../../slices/navSlice';
import { session } from '../../slices/sessionSlice';
import ImageAvatar from '../UI/ImageAvatar';
import avatar from '../../assets/images/Vector .png';
import {Link} from 'react-router-dom';
import { logoutURLOriented, logoutURLOrienteer } from '../../utils/authGoogle';
import { Roles } from '../../utils/Roles';

const GlobalHeader = (): ReactElement => {

  const { user } = useSelector(session)
  const { toShow } = useSelector(navState)
  const welcomeMessage = user != null ? '¡Hola, ' + user.user.name + '!' : 'Hola team dalfonso!'
  const rol = localStorage.getItem('role')

  const [showProfileOptions, setShowOptions] = useState(false)
  const handleShowProfileOptions = (): void => {
    const actualState = showProfileOptions
    setShowOptions(!actualState)
  }

  const handleLogout = () => {
    localStorage.clear()
  }

  return ( user === null ? <div /> :
    <header className="flex justify-between items-center p-6 bg-black-primary">
      <h1 className="text-3xl font-large text-white md:ml-8">
        {toShow == 'Inicio' || toShow == 'Profile' ? welcomeMessage : toShow}
      </h1>
      <div className="flex items-center space-x-4">
        <div className="relative">
          <button className="flex items-center space-x-2 relative focus:outline-none" onClick={handleShowProfileOptions}>
            {!user.user.profilePicture ? (
              <ImageAvatar src={avatar} text={'your avatar'} size={true} />) : (
              <ImageAvatar src={process.env.REACT_APP_EXPORT_FILES + user.user.profilePicture} text={'your avatar'} size={true} />
            )}
          </button>
          {showProfileOptions && <div onMouseLeave={handleShowProfileOptions} className="absolute right-0 mt-2 w-56 bg-white rounded-md border-2 border-gray-secundary z-10">
            <Link to={'/profile'}
              className="block cursor-pointer hover:text-black-primary px-4 py-4 text-sm text-black-primary far fa-user">
              Mi perfil
            </Link>
            <a
              href={rol == Roles.ORIENTEER ? logoutURLOrienteer : logoutURLOriented }
              className="block text-black-primary hover:text-black-primary px-4 py-4 text-sm cursor-pointer"
              onClick={handleLogout}
            >
              Cerrar sesion
            </a>
          </div>}
        </div>
      </div>
    </header>
  );
};

export default GlobalHeader;
