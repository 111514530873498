import { useEffect, useState } from 'react'
import { ReactElement } from 'react-markdown/lib/react-markdown'

const ReloguinModal = ({ show }):ReactElement => {
  const [showThis, setShowThis] = useState(false)

  useEffect(() => {
    setShowThis(show)
  }, [show])

  const handleClose = ():void => {
    setShowThis(false)
  }

  return (
    <div className={`${showThis ? 'block' : 'hidden'} back-modal fixed w-full h-full flex items-center justify-center`}>
      <div className="bg-white rounded-md p-4 w-1/4 flex flex-col items-center">
        <p className="text-black-dalfonso font-semibold">Tu sesión ha expirado! Volve a ingresar</p>
        <button onClick={handleClose} className="focus:outline-none px-4 py-2 rounded-lg bg-green-principal text-white font-bold mt-4">
          OK
        </button>
      </div>
    </div>
  )
}

export default ReloguinModal