import { DateTime } from 'luxon';

export const daysFromToday = (date): number => {
  const today = new Date()
  const appointmentDate = new Date(date).getTime()
  const result = appointmentDate - today.getTime()
  return Math.round(result / (1000 * 60 * 60 * 24))
}

export const getDate = (date): string => {
  const dateAppointment = new Date(date)
  const month = dateAppointment.getMonth()+1
  return dateAppointment.getDate()+ '/'+month+'/'+dateAppointment.getFullYear()
}

export const noTime = (date):Date =>{
  let noTimeDate = new Date(date)
  noTimeDate.setHours(0,0,0,0)
  return noTimeDate
}

export const getScriptDate = (date): string => {
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  return `${month}-${day}-${year}`
}

export const formatterDate = (date): string => {
  const dateAppointment = date.split('/')
  return dateAppointment[1] + '/' + dateAppointment[0] + '/' + dateAppointment[2]
}

export const formaterToSubmit = (date) :string =>{
  const dateAppointment = date.split('/')
  return dateAppointment[2] + '-' + dateAppointment[1] + '-' + dateAppointment[0]
}

export const lastMinute = (date):Date => new Date(date.getFullYear(),date.getMonth(),date.getDate(),23,59,59)

export const getTime = (date: Date | string, timeZone: string | null): string => {
  const zonedDate = DateTime.fromISO(date.toString(), { zone: 'utc' }).setZone(timeZone);
  // Convert to specific timezone
  // const zonedDate = DateTime.fromISO(date, { zone: 'utc' })
  console.log(zonedDate.toFormat('HH:mm'), timeZone)
  return zonedDate.toFormat('HH:mm');
};

export const formatTime = (date): string => {
  const dateAppointment = new Date(date)
  const addCeroIfIHave = dateAppointment.getMinutes() < 10 ? '0' : ''
  return dateAppointment.getHours() + ':' + addCeroIfIHave + dateAppointment.getMinutes()
}

export const halfHourlyRange = (start,end) : string[] =>{
  let times : string[] = []
  for (let i = start; i <= end ; i++) i!==end? times.push( i+':00' ,i+':30' ):times.push( i+':00')
  return times
}

// prueba con ipify
export async function getUserIP(): Promise<string | null> {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
}

// Obtengo la zona horaria con mi token
export async function getTimezoneByIp(ipAddress: string | null): Promise<string | null> {
  try {
    const response = await fetch(`https://ipinfo.io/${ipAddress}/json?token=851f3e870f62bf`);

    if (!response.ok) {
      console.log('Error');
    }
    
    const data = await response.json();
    return data.timezone || null;
  } catch (error) {
    console.error('Error:', error);
    return null; 
  }
}

export function convertToUTC(date: string | Date, timezone: string | null): string | null {
  const dateString = date instanceof Date ? date.toISOString() : date;
  
  // Crea el objeto DateTime en la zona horaria especificada o en la local si `timezone` es null
  const localDateTime = timezone
    ? DateTime.fromISO(dateString, { zone: timezone })
    : DateTime.fromISO(dateString);

  // Verifica si la fecha es válida
  if (!localDateTime.isValid) {
    console.error('Error', localDateTime.invalidReason);
    return null;
  }

  // Convierte a UTC y retorna en formato HH:mm
  const utcDateTime = localDateTime.toUTC();
  return utcDateTime.toFormat('HH:mm');
}