import { ReactElement, useEffect } from 'react';
import FormOrienteerLogin from './Auth/FormOrienteerLogin';

const OrienteerLogin = (): ReactElement => {
  
  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <FormOrienteerLogin /> 
  );
};

export default OrienteerLogin;
