import { ReactElement, useEffect, useState } from 'react';
import NewLayout from '../../components/Layout/NewLayout';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentSection } from '../../slices/navSlice';
import { useQuery } from '@apollo/client';
import { fetchTimezone, session } from '../../slices/sessionSlice';
import { getTime } from '../../utils/utilsDate';
import { useParams, useHistory } from 'react-router-dom'
import LoadingSpinner from '../../utils/spinner';
import { ALL_APPOINTMENTS } from '../../queries/Appointment';
import AdminAppointmentDetail from '../../components/Agenda/AdminAppointmentDetail';


const AdminAgendaViewPage =(): ReactElement => {
  const { appointmentId } = useParams()
  const { user } = useSelector(session);
  const dispatch = useDispatch();
  const [confirmedAppoitment, setConfirmedAppoitment] = useState<null | Array<any>>(null);
  const history = useHistory()
  const timeZone = useSelector((state: any) => state.session.timezone);

  useEffect(() => {
    dispatch(fetchTimezone());
  }, [])

  useEffect(()=>{
    dispatch(setCurrentSection('Agenda'))
  })

  const allEventsForAdmin = useQuery(ALL_APPOINTMENTS);
  const { data, loading } = allEventsForAdmin;
  useEffect(() => {
    if (!loading) {
      const appointments = data.events;
      const formatterConfirmed = appointments.map((appointment) => {
        let userArr: any = [];
        if (appointment.confirmedOrienteers) {
          for (var i = 0; i < appointment.confirmedOrienteers.length; i++) {
            userArr.push(appointment.confirmedOrienteers[i].user);
          }
        }
        let OrienteerIdArray: any = [];
        for (var j = 0; j< appointment.confirmedOrienteers.length; j++) {
          OrienteerIdArray.push(appointment.confirmedOrienteers[j].id);
        }
        return {
          date: appointment.date,
          time: getTime(appointment.date, timeZone),
          name: appointment.name,
          id: appointment.id,
          detail: appointment.detail,
          orienteer: user.user.name,
          timeSpan: appointment.timeSpan,
          orienteerId: OrienteerIdArray.map((item) => item).join(', '),
          orienteers: userArr.map((item) => item.name).join(', '),
          orientedsDropdown: appointment.orienteds,
          orienteersDropdown: userArr,
          program: appointment.program,
        };
      });
      setCurrentAppointment(formatterConfirmed);
    }
  }, [data]);

  const setCurrentAppointment =(AppointmentList) => {
    let AppointmentFound = AppointmentList.find((AppointmentSelected) => AppointmentSelected.id == appointmentId )
    if (AppointmentFound != undefined) {
      setConfirmedAppoitment(AppointmentFound)
    }

  }

  const moveBack = () => {
    history.goBack()
  }
  
  return (
    <NewLayout>
      {!confirmedAppoitment ? 
        <LoadingSpinner/> 
        :
        <div className="w-full md:ml-11">
          <AdminAppointmentDetail appointment={confirmedAppoitment} back={moveBack}/>
        </div>}
    </NewLayout>            
  )

}

export default AdminAgendaViewPage;