import { useQuery } from '@apollo/client';
import { ReactElement, useEffect, useState } from 'react';
import { GET_ACCOUNTING_REPORT } from '../../queries/Payment';
import { exportExcelAccountingReport } from '../../utils/ExportAccountingReport';
import { download } from '../../utils/exportExcelUtil';
import { getScriptDate } from '../../utils/utilsDate';
import Button from '../UI/Button';
import CustomDatePicker from '../UI/CustomDatePicker';
import Table from '../UI/Table';
import TittleInProgress from '../UI/TittleInProgress';
import { mapState } from './MapStateUtil';


const AccountingReports = ({ goBack }): ReactElement => {
  const [reports, setData] = useState<Array<any>>([])
  const [reportsToShow, setDataToShow] = useState<Array<any>>([])
  const [from, setFrom] = useState<Date | null>()
  const [to, setTo] = useState<Date | null>();
  const [variables, setVariables] = useState({})
  const [status, setStatus] = useState('')
  const { data, loading, refetch } = useQuery(GET_ACCOUNTING_REPORT, {
    variables: variables
  })

  useEffect(() => {
    from && to && setVariables({ to: to.toLocaleDateString(), from: from.toLocaleDateString() })
    refetch()
  }, [to, from])

  useEffect(() => {
    if (!loading) {
      setData(data.getAccountingReport)
      setDataToShow(data.getAccountingReport)
    }
  }, [data]);

  const handleFilterPaid = (): void => {
    setStatus('PAID')
    setDataToShow(reports.filter(i => i.status === 'PAID'))
  }

  const handleFilterNotPaid = (): void => {
    setStatus('PENDING')
    setDataToShow(reports.filter(i => i.status === 'PENDING'))
  }

  const handleShowAll = (): void => {
    setStatus('')
    setDataToShow(reports)
  }


  const columns = [
    {
      Header: 'Nombre y apellido',
      accessor: 'name'
    },
    {
      Header: 'DNI',
      accessor: 'dni'
    },
    {
      Header: 'Orientador',
      accessor: 'orienteerName'
    },
    {
      Header: 'Servicio',
      accessor: 'service'
    },
    {
      Header: 'Monto Informado',
      accessor: 'amountInformed'
    },
    {
      Header: 'Monto total pagado',
      accessor: 'totalAmountPaid'
    },
    {
      Header: 'Monto a pagar',
      accessor: 'amountToPay'
    },
    {
      Header: 'Estado',
      accessor: 'status'
    }
  ]

  const DownloadReport = ():void => {
    let fromDate = ''
    let toDate = ''
    if (from && to) {
      fromDate = getScriptDate(from)
      toDate = getScriptDate(to)
    }
    exportExcelAccountingReport(status, fromDate, toDate).then(res => {res?.blob().then(blob =>download(blob, 'Informe-contable-inscriptos'))})
  }

  return (
    <div className="w-full flex flex-col">
      <a onClick={():void => goBack()} className="underline text-black-primary text-xs text-left mt-8 hover:text-black-primary cursor-pointer">{'<'}ir a pagos</a>
      <div className="w-5/6 flex items-start">
        <TittleInProgress text={'Informe Contable Inscriptos'} width={'w-auto'} />
      </div>
      <label className="text-xs mt-8 text-black-primary text-left">Periodo</label>
      <div className="flex justify-between w-5/6 items-center">
        <div className="flex w-4/5">
          <div className="w-2/5 mr-4">
            <CustomDatePicker value={from} setValue={setFrom} placeholder={'Desde'} />
          </div>
          <div className="w-2/5">
            <CustomDatePicker value={to} setValue={setTo} placeholder={'Hasta'} />
          </div>
        </div>
        <Button text={'Descargar informe'} action={DownloadReport} styles={' 2xl:mr-0 md:-mr-3.5'}/>
      </div>
      <div className="flex mt-4">
        <div className='flex items-center mr-2 cursor-pointer' onClick={handleFilterPaid}>
          <div className="w-auto rounded-sm flex items-center h-auto text-black-primary text-xs p-1 bg-green-success">
            Pago
          </div>
        </div>
        <div className='flex items-center cursor-pointer mr-2' onClick={handleFilterNotPaid}>
          <div className="w-auto rounded-sm flex items-center h-auto text-black-primary text-xs p-1 bg-yellow-info">
            Pendiente de Pago
          </div>
        </div>
        <div className='flex items-center cursor-pointer' onClick={handleShowAll}>
          <div className="w-auto rounded-sm flex items-center h-auto text-black-primary text-xs p-1 bg-gray-secundary">
            Todos
          </div>
        </div>
      </div>
      <div className="w-11/12 mt-8">
        <Table columns={columns} data={reportsToShow} state={mapState} initialRows={10}/>
      </div>
    </div>
  )
}

export default AccountingReports