import { ReactElement, useState, useEffect } from 'react';
import FormBuilder from '../../../UI/FormBuilder';
import { useHistory } from 'react-router-dom';
import { RouterPrompt } from '../../../../utils/RouterPrompt';

type appointmentData = {
  appointment: string,
  accessLink: string,
  activity: string
}

const Activity = (props: appointmentData): ReactElement => {
 
  const history = useHistory();
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(()=>{
    setShowPrompt(true)
  })  

  const setClosed = () => {
    setShowPrompt(false)
    setImmediate(() => history.push(`/agenda/${props.appointment}/oriented/details`))
  }

  return (
    <div className="w-full mt-8 pb-4">
      <RouterPrompt
        when={showPrompt}
        onOK={() => true}
        onCancel={() => false}
        description={'Si abandonas la actividad los datos no serán guardados.\n¿Estás seguro que deseas salir?\nPresioná Si para continuar o No para seguir en la página actual.'}
      />
      <FormBuilder slug={props.accessLink} close={():void => setClosed()} appointmentId={props.appointment} actId={props.activity}/>
    </div>
  )
}

export default Activity