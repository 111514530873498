import { useQuery } from '@apollo/client';
import { ReactElement, useEffect, useState } from 'react';
import TittleInProgress from '../UI/TittleInProgress';
import ButtonLink from '../UI/ButtonLink';
import LoadingSpinner from '../../utils/spinner';
import TablePrograms from './TablePrograms';
import { GET_ALL_PROGRAMS } from '../../queries/Program';

const ProgramsContainer = (): ReactElement => {
  const { data, loading, refetch } = useQuery(GET_ALL_PROGRAMS);
  const [programs, setPrograms] = useState<any>([]);
 

  useEffect(() => {
    if (!loading && data) {
      const allPrograms = data.getAllPrograms;
      setPrograms(allPrograms);
    }
  }, [data]);

  useEffect(() => {
    if (data || programs.length == 0) {
      refetch();
    }
  }, [programs]);

  return (
    <div className="container w-full">
      <div className="grid-cols-1">
        <div className="">
          <TittleInProgress text={'Nuestros Programas'} width={'w-auto'} />
        </div>
        <div className="flex flex-row-reverse mt-8">
          <ButtonLink
            text={'Cargar programa'}
            to="/library/programs/new"
          />
        </div>
      </div>
      <div className="mt-8">
        {loading ? <LoadingSpinner /> : <TablePrograms dataARG={programs} />}
      </div>
    </div>
  );
};

export default ProgramsContainer;
