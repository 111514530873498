import { ReactElement, useEffect, useRef, useState } from 'react';
import NewLayout from '../../components/Layout/NewLayout';
import { useDispatch } from 'react-redux';
import { setCurrentSection } from '../../slices/navSlice';
import AgendaNewAppointment from '../../components/Agenda/AgendaNewAppointment';
import {useHistory} from 'react-router-dom'
import { useQuery } from '@apollo/client';
import { GET_ORIENTEERS_FOR_OPTIONS } from '../../queries';
import { GET_ORIENTEDS_FOR_OPTIONS } from '../../queries/Oriented';
import { GET_ALL_PROGRAMS } from '../../queries/Program';
import LoadingSpinner from '../../utils/spinner';

const NewAppointmentPage =(): ReactElement => {
  const role = localStorage.getItem('role')
  const history = useHistory()
  const dispatch = useDispatch();
  const componentMounted = useRef(true);
  const [programs, setPrograms] = useState<any>([]);
  const { data: dataOrienteer, loading: loadingOrienteer } = useQuery(GET_ORIENTEERS_FOR_OPTIONS)
  const { data: dataOriented, loading: loadingOriented } = useQuery(GET_ORIENTEDS_FOR_OPTIONS);
  const { data: allProgramsData, loading: loadingPrograms } = useQuery(GET_ALL_PROGRAMS);

  const [optionsOriented, setOptionsOriented] = useState<Array<any>>([]);
  const [optionsOrienteer, setOptionsOrienteer] = useState<Array<any>>([]);

  useEffect(()=>{
    dispatch(setCurrentSection('Agenda'))
  }, [])

  useEffect(()=>{
    if (!loadingPrograms && allProgramsData) {
      setPrograms(allProgramsData.getAllPrograms);
    }
  })

  useEffect(() => {
    if (!loadingOrienteer && dataOrienteer) {
      const formatter = dataOrienteer.allOrienteerForOptions.map(
        (orienteer) => {
          return {
            label: orienteer.user.name,
            value: orienteer.id,
          }
        }
      )
      const alphabeticalSort = [...formatter].sort(function (a, b) {return (a.label.localeCompare(b.label))})
      setOptionsOrienteer(alphabeticalSort)
    }
  }, [dataOrienteer])

  useEffect(() => {
    if (componentMounted.current) {
      if (!loadingOriented && !!dataOriented) {
        const formatter = dataOriented.getAllOrientedsWithUser.map(
          (oriented) => {
            return {
              label: `${oriented.user.name} (${oriented.dni})` ,
              value: oriented.id,
              programs: oriented.paymentPlans.map((program)=>{return program.programSlug}),
              dni: 'DNI: ' + oriented.dni
            };
          }
        );
        setOptionsOriented(formatter);
      }
    }
  }, [dataOriented]);

  const back = () => {
    history.goBack()
  }

  return (
    <NewLayout>
      <div className="w-full md:ml-11">
        {
          loadingOriented && loadingOrienteer && loadingPrograms && optionsOriented.length == 0 && optionsOrienteer.length == 0 ?
            <LoadingSpinner/> : 
            <AgendaNewAppointment 
              role={role} 
              back={back} 
              programs={programs} 
              optionsOriented={optionsOriented} 
              optionsOrienteer={optionsOrienteer} 
            /> 
        } 
      </div>            
    </NewLayout>
  )

}

export default NewAppointmentPage;