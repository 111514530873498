import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { strapiData } from '../../../slices/initialStrapiData';
import { session } from '../../../slices/sessionSlice';
import Slider from '../../UI/Slider';
import TittleInProgress from '../../UI/TittleInProgress';
import News from '../News';
import InscriptionCard from './InscriptionCard';
import ProgramCard from './ProgramCard';
import LoadingSpinner from '../../../utils/spinner';


const BeginningOriented = (): ReactElement => {
  const { inscriptionCards, programs } = useSelector(strapiData)
  const [programsRender, setProgramsRender] = useState([])
  const { user } = useSelector(session)

  const programSlugs: Array<string> = user.paymentPlans ? user.paymentPlans.map(i => i.programSlug) : []


  useEffect(() => {
    if(programs){
      const orientedPrograms = programs.filter(i => programSlugs.includes(i.slug))
      setProgramsRender(orientedPrograms)
    }
  }, [programs])
  
  
  let cards: Array<ReactElement> = []
  programsRender && programsRender.map((i, index) => {
    cards.push(<ProgramCard key={index} data={i} />)
  })
  let items: Array<ReactElement> = []
  inscriptionCards && inscriptionCards.map((i, index) => {
    items.push(<InscriptionCard key={index} title={i.Title} description={i.Subtitle} message={i.Message} />)
  })

  return (
    <div className=' w-full ml-11'>
      <div className="w-10/12">
        <div className='mb-2'>
          <TittleInProgress text={'Mis programas'} width={'w-52'} />
        </div>
        {programs && programs.length >0 && programsRender.length >0 ?
          <Slider items={cards} />
          : programSlugs.length >0 ?
            <LoadingSpinner/>
            : <div className="flex mt-6 justify-start items-center text-base">
                No hay programas disponibles para mostrar.
            </div>}
        
        <div className="w-36 mb-2">
          <TittleInProgress text={'Inscribite'} width={'w-36'} />
        </div>
        <div>
          <Slider items={items} />
        </div>
        <News />
      </div>
    </div>
  )
}

export default BeginningOriented