import { ReactElement } from 'react';

const InscriptionCard = ({ title, description, message }): ReactElement => {

  const handleOpenWspLink = ():void=> {
    const text = message ? encodeURIComponent(message) : ''
    window.open('https://api.whatsapp.com/send?phone=5491165821424&text=' + text, '_blank')
  }

  return (
    <div onClick={handleOpenWspLink} className="cursor-pointer border border-l-0 h-36 w-80 border-gray-secundary rounded-md">
      <div className="orange-card">
        <p className="title-smallCard">{title}</p>
        <p className="description-smallCard mt-2">{description}</p>
      </div>
    </div>
  )
}

export default InscriptionCard