import { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AgendaAdmin from '../../components/Agenda/AgendaAdmin';
import NewLayout from '../../components/Layout/NewLayout';
import { setCurrentSection } from '../../slices/navSlice';

const AgendaAdminPage =(): ReactElement => {
    
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(setCurrentSection('Agenda'))
  })

  return (
    <NewLayout>    
      <AgendaAdmin />
    </NewLayout>            
  )
  
}
  
export default AgendaAdminPage;