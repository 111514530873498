import { FC, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSvg, view } from '../../../assets/svg/dashboardIcons';
import { markAsRead } from '../../../mutations/Message';
import { selectMessage, setComponentToShow } from '../../../slices/messageSlice';
import { session } from '../../../slices/sessionSlice';
import { getDate } from '../../../utils/utilsDate';
import Icon from '../../UI/Icon';

interface MessageTableProps {
  data: Array<any>
  deleteMessage?: (messageId) => void,
  viewAsSender?: boolean
}

const MessagesTable: FC<MessageTableProps> = (props): ReactElement => {
  const { data, deleteMessage, viewAsSender } = props
  const { user } = useSelector(session)
  const dispatch = useDispatch()

  const itHasBeenRead = (message): boolean => {
    return message.receivers.some(r => r.to.id === user.user.id && r.read)
  }

  const handleViewMessage = (message): void => {
    !itHasBeenRead(message) && !viewAsSender && markAsRead(user.user.id, message.id)
    dispatch(setComponentToShow('view'))
    dispatch(selectMessage({ ...message, viewAsSender }))
  }

  const handleDelete = (message): void => {
    deleteMessage && deleteMessage(message.id)
  }

  return (
    <table cellSpacing={0} className='w-full border border-gray-secundary border-separate rounded-lg'>
      <tbody className='text-black-primary text-xs'>
        {data.map((i, index) =>
          <tr key={i.id} className={`hover:bg-gray-100 w-full ${itHasBeenRead(i) || viewAsSender ? 'font-normal' : 'font-bold'}`}>
            <td className={`py-3 px-6 text-left ${index !== 0 && 'border-t border-gray-secundary'}`}>{i.from.name}</td>
            <td className={`py-3 px-6 text-left ${index !== 0 && 'border-t border-gray-secundary'}`}>{i.subject}</td>
            <td className={`py-3 px-6 text-left ${index !== 0 && 'border-t border-gray-secundary'}`}>{getDate(i.sentAt)}</td>
            <td className={`text-left ${index !== 0 && 'border-t border-gray-secundary'}`}>
              <button className="focus:outline-none cursor-pointer w-4 mr-2 transform hover:scale-110" onClick={():void => handleViewMessage(i)} >
                <Icon path={view} width={20} height={15} color={'#2E384D'} />
              </button>
            </td>
            {!viewAsSender &&
              <td className={`text-left ${index !== 0 && 'border-t border-gray-secundary'}`}>
                <button
                  className="focus:outline-none cursor-pointer w-4 mr-2 transform hover:scale-110"
                  onClick={():void => handleDelete(i)}
                >
                  <Icon
                    path={deleteSvg}
                    width={20}
                    height={18}
                    color={'#2E384D'}
                  />
                </button>
              </td>
            }
          </tr>)}
      </tbody>
    </table>
  )
}

MessagesTable.defaultProps = {
  viewAsSender: false
}

export default MessagesTable