import { useQuery } from '@apollo/client';
import React, { ReactElement, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { assignAct, unassignAct } from '../../queries';
import { GET_ORIENTED } from '../../queries/Oriented';
import { setResponse, setShowModal } from '../../slices/modalSlice';
import ActivityTable from '../UI/ActivityTable';
import Button from '../UI/Button';
import CustomInputPicker from '../UI/CustomInputPicker';
import { lastMinute } from '../../utils/utilsDate';
import { useParams } from 'react-router-dom';
import { GET_ALL_PROGRAMS } from '../../queries/Program';
import LoadingSpinner from '../../utils/spinner';

type ActivitiesProgram = {
  program: {
    id: string,
    name: string,
  }
}

const AssignActs = (props: ActivitiesProgram): ReactElement => {
  const programId = props.program == undefined ? '': props.program.id 
  const { orientedId, appointmentId } = useParams();
  const dispatch = useDispatch()
  const { data, refetch } = useQuery(GET_ORIENTED, {
    variables: { id: orientedId }
  })
  const selectedOriented = data ? data.orientedById : null
  const [requiredActs, setRequiredActs] = useState([])
  const [notRequiredActs, setNotRequired] = useState([])
  const [overdueDate, setOverdueDate] = useState<Date | null>();
  const [overdueDateOp, setOverdueDateOp] = useState<Date | null>();
  const [activities, setActivities] = useState([])
  const [optionalActs, setOptionalActs] = useState([])
  const [selectedAct, setSelectedAct] = useState('')
  const [selectedOpAct, setSelectedOpAct] = useState('')
  const { data: allProgramsData, loading: loadingPrograms } = useQuery(GET_ALL_PROGRAMS)
  const InputRef = React.forwardRef((props, ref) => <CustomInputPicker innerRef={ref} props={props} />);


  useEffect(()=> {
    if(!loadingPrograms) {
      const programList = allProgramsData.getAllPrograms
      //first, filter by the appointment program
      const filterProgramActs = programList.filter((e) => {return e.id == programId })
      //map the activities of such program
      const activityList = filterProgramActs.map((element) => {return element.activities}).flat()
      //filter activities by required/not required
      const reqActivities = activityList.filter(i => i.isRequired)
      const optActivities = activityList.filter(i => !(i.isRequired))
      //sort activities alphabetically
      const reqActivitiesSorted = reqActivities.sort(function (a, b) {return (a.name.localeCompare(b.name))})
      const optActivitiesSorted = optActivities.sort(function (a, b) {return (a.name.localeCompare(b.name))})
      //set both required and not required activities
      setActivities(reqActivitiesSorted)
      setOptionalActs(optActivitiesSorted)
    }
  },[requiredActs, selectedOriented, loadingPrograms])

  useEffect(() => {
    if (selectedOriented != null) {
      getRequiredAndNotRequiredActs()
    }

  }, [selectedOriented])

  const deleteActivity = (selected): void => {
    unassignAct(`{
    activityID: "${selected.activity.id}",
    appointmentID: "${selected.appointmentId}",
    orientedID: "${orientedId}"
    }
    `).then(res => {
      if (res.data.unassignActivity) {
        dispatch(setResponse({
          message: 'La actividad ha sido eliminada.',
          error: false,
          description: 'El orientado ya no podrá realizarla. '
        }))
        dispatch(setShowModal(true))
        refetch()
      }
    })
  }

  const getRequiredAndNotRequiredActs = (): void => {
    const required = selectedOriented.appointmentActivities.filter(i => i.appointmentId == appointmentId && i.activity.isRequired)
    const notRequired = selectedOriented.appointmentActivities.filter(i => i.appointmentId == appointmentId && !i.activity.isRequired)
    setRequiredActs(required)
    setNotRequired(notRequired)
  }

  const assign = (overdueDate, idAct): void => {
    if (requiredActs.some(i => i['activity']['id'] == idAct) || notRequiredActs.some(i => i['activity']['id'] == idAct)) {
      dispatch(setResponse({
        message: 'Actividad ya asignada',
        error: true,
        description: 'El orientado ya cuenta con esta actividad asignada'
      }))
      dispatch(setShowModal(true))
    }
    else {
      assignAct(`
    {
      activityID: "${idAct}",
      orientedID: "${orientedId}",
      appointmentID: "${appointmentId}",
      deliveryDate: "${lastMinute(overdueDate)}"
    }
    `).then((result) => {
        if (result.data) {
          dispatch(setResponse({
            message: 'Guardamos la actividad exitosamente.',
            error: false,
            description: 'El orientado podrá realizarla en el plazo que estableciste. Recuerda que sólo podrás editarla mientras esté en estado “Pendiente de realizar”'
          }))
          dispatch(setShowModal(true))
          refetch()
        }
      }).catch(() => {
        dispatch(setResponse({
          message: 'Ups! No se pudo guardar la actividad correctamente.',
          error: true,
          description: 'Por favor reintenta nuevamente'
        }))
        dispatch(setShowModal(true))
      })
    }
  }

  const ActForm = ({ value, setValue, acts, selected, setSelected }): ReactElement => {
    return (
      <div className="flex w-full mt-6 mb-6">
        <div className="w-35% h-auto flex flex-col mr-4">
          <label className="label-update">Nombre</label>
          <select defaultValue={''} value={selected} onChange={(e): void => setSelected(e.target.value)} className="focus:outline-none border border-gray-secundary text-xs p-3 mt-2 rounded-md text-gray-principal">
            <option value={''}>Seleccionar actividad</option>
            {acts.map((i, index) => (
              <option key={index} value={i.id}>{i.name}</option>
            ))}
            
          </select>
        </div>
        <div className="w-35% h-auto flex flex-col mr-4">
          <label className="label-update mb-2">Fecha de entrega</label>
          <DatePicker selected={value} onChange={(date): void => setValue(date)} dateFormat="dd-MM-yyyy" customInput={React.createElement(InputRef)} />
        </div>
        <div className="flex items-end">
          <Button text={'Asignar actividad'} action={(): void => assign(value, selected)} disabled={!value || selected == ''} />
        </div>
      </div>
    )
  }

  return (
    <div className="w-full flex flex-col">
      <div className="border-b border-gray-secundary w-5/6 flex flex-col items-start pb-6">
        <p className="title-bold">Actividades</p>
        <ActForm
          value={overdueDate}
          setValue={setOverdueDate}
          acts={activities}
          selected={selectedAct}
          setSelected={setSelectedAct}
        />
        { requiredActs == undefined ? <LoadingSpinner/>  
          : requiredActs.length == 0 ? <div>No hay actividades asignadas aún...</div>
            : <ActivityTable
              data={requiredActs} 
              downloadInfo={selectedOriented ? selectedOriented.dni : 0} 
              deleteAct={deleteActivity} /> 
        }
      </div>
      <div className="flex flex-col w-5/6 flex flex-col items-start mt-4">
        <p className="title-bold">Actividades opcionales</p>
        <ActForm
          value={overdueDateOp}
          setValue={setOverdueDateOp}
          acts={optionalActs}
          selected={selectedOpAct}
          setSelected={setSelectedOpAct}
        />
        { notRequiredActs == undefined ? <LoadingSpinner/>
          : notRequiredActs.length == 0 ? <div>No hay actividades asignadas aún...</div>
            : <ActivityTable
              data={notRequiredActs} 
              downloadInfo={selectedOriented ? selectedOriented.dni : 0} 
              deleteAct={deleteActivity} />
        }
      </div>
    </div>
  )
}

export default AssignActs