import { ReactElement, useEffect, useState } from 'react';
import TittleInProgress from '../UI/TittleInProgress';
import { GET_PAYMENT_PLAN_PAGINATED } from '../../queries/Payment';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../../utils/spinner';
import { mapState } from './MapStateUtil';
import TableWithPaginationBackend from '../UI/TableWithPaginationBackend';
import Icon from '../UI/Icon';
import { view } from '../../assets/svg/dashboardIcons';
import BackendSearch from '../UI/BackendSearch';

const AdminPaymentsView = (): ReactElement => {
  const [payments, setpayments] = useState<Array<any>>([]);
  const [search, setSearch] = useState('');
  const [paginationState, setPaginationState] = useState({
    currentPage: 1,
    totalPages: 1,
    rowsPerPage: 10,
    orderBy: 'name',
    orderMode: 'ASC',
  });
  const { data, loading } = useQuery(GET_PAYMENT_PLAN_PAGINATED, {
    variables: {
      data: {
        take: paginationState.rowsPerPage as number,
        skip: (paginationState.rowsPerPage *
          (paginationState.currentPage - 1)) as number,
        search: search,
        orderBy: paginationState.orderBy,
        orderMode: paginationState.orderMode,
      },
    },
  });

  useEffect(() => {
    if (!loading) {
      const totalRows = data.getPaymentPlansPaginated.total;
      const totalPages =
        totalRows % paginationState.rowsPerPage == 0
          ? totalRows / paginationState.rowsPerPage
          : Math.floor(totalRows / paginationState.rowsPerPage) + 1;
      setPaginationState({
        ...paginationState,
        totalPages: totalPages,
      });

      setpayments(data.getPaymentPlansPaginated.data);
    }
  }, [data]);

  useEffect(()=>{
  }, [paginationState])

  const columns = [
    {
      Header: 'Nombre y apellido',
      accessor: 'oriented.user.name',
      orderName: 'name'
    },
    {
      Header: 'DNI',
      accessor: 'oriented.dni',
      orderName: 'dni'
    },
    {
      Header: 'Programa',
      accessor: 'programSlug',
      orderName: 'program'
    },
    {
      Header: 'Estado',
      accessor: 'status',
      orderName: 'status',
      Cell: ({ value }) => {
        return mapState({ status: value });
      },
    },
    {
      Header: 'Acciones',
      accessor: 'action',
      Cell: ({ row }) => {
        return (
          <Link
            to={`/payments/${row.original.id}/details`}
            className="focus:outline-none cursor-pointer w-4 mr-2 transform hover:scale-110"
          >
            <Icon path={view} width={28} height={18} color={'#2E384D'} />
          </Link>
        );
      },
    },
  ];

  return loading ? (
    <LoadingSpinner />
  ) : (
    <div className="w-auto mr-20 flex flex-col">
      <div className="flex items-end grid grid-cols-2 gap-2">
        <div>
          <TittleInProgress text={'Pagos'} width={'w-1/8'} />
        </div>
        <div className="rounded-lg">
          <Link
            to={'/payments/registered-accounting-reports'}
            className={
              'justify-items-end justify-self-end py-2 px-6 bg-green-principal text-white rounded-full h-10 font-semibold'
            }
          >
            Generar informe
          </Link>
        </div>
      </div>
      <div className="w-full mt-8">
        <BackendSearch
          options={[search, setSearch]}
        />
        <div className={'w-5/6'}>
          <TableWithPaginationBackend
            columns={columns}
            data={payments}
            paginationState={[paginationState, setPaginationState]}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminPaymentsView;
