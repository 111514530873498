
const PaginationButtons = ({className, prevClick, nextClick, currentPage, totalPages, isNextDisabled, isPreviousDisabled}) => {
  return (
    <div className={className}>
      <p className="text-xss text-black-primary font-medium">{currentPage} de {totalPages}</p>
      <button onClick={prevClick} disabled = { isPreviousDisabled } className="focus:outline-none text-black-primary text-xss ml-2 mr-4 font-medium">{'<'}</button>
      <button onClick={nextClick} disabled = { isNextDisabled  } className="focus:outline-none text-black-primary text-xss font-medium">{'>'}</button>
    </div>
  )
}

export default PaginationButtons