import { gql } from '@apollo/client';
import { client } from '../../client-apollo';

export const saveCalendarConfig = (id, calendarId) => client
  .mutate({
    mutation: gql`
    mutation{
      saveCalendarConfig(id: "${id}",
      fields:${calendarId}){
        calendarId
      }
    }
    `
  });