import { createSlice } from '@reduxjs/toolkit';

interface NavState {
  toShow: string
  section: string
}

export const slice = createSlice({
  name: 'nav',
  initialState: {
    toShow: 'Inicio',
    section: 'Inicio'
  },
  reducers: {
    setCurrentSection: (state, action): void => {
      state.toShow = action.payload
      state.section = action.payload
    }
  }
});

export const { setCurrentSection } = slice.actions;
export const navState = (state): NavState => state.nav;
export default slice.reducer;