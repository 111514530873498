import { ReactElement } from 'react';
import { agenda } from '../../assets/svg/navIcons';
import Icon from './Icon';

const CustomInputPicker = ({ props, innerRef, placeholder = 'Seleccionar fecha', background = '' }): ReactElement => (
  <div ref={innerRef} onClick={props.onClick} onChange={props.onChange} className={'w-full md:w-full flex justify-between items-center border text-gray-principal text-xs border-gray-secundary p-3.5 rounded-md '+ background}>
    <label className="text-xs text-gray-principal">{!props.value ? placeholder : props.value}</label>
    <Icon path={agenda} color={'#8798AD'} width={14} height={12} />
  </div>
)

export default CustomInputPicker