import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'strapiData',
  initialState: {
    news: [],
    programs: [],
    inscriptionCards: [],
    activities: []
  },
  reducers: {
    setNews: (state, action): void => {
      state.news = action.payload
    },
    setPrograms: (state, action): void => {
      state.programs = action.payload
    },
    setInscriptionCards: (state, action): void => {
      state.inscriptionCards = action.payload
    },
    setActivities: (state, action): void => {
      state.activities = action.payload
    }
  }
});

export const { setNews, setPrograms, setInscriptionCards, setActivities } = slice.actions;
export const strapiData = (state): any => state.strapiData;
export default slice.reducer;