import { ReactElement, useState } from 'react';

type Props = {
  error: any
  width?: string
}

const FetchErrorMessage = ({error, width}: Props): ReactElement => {
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  return (
    <div className={`my-20 ${width ? width : 'width-auto'} flex flex-col justify-center items-center`}>
      <p className={'text-base'}>Ocurrió un problema al conectar con el servidor.</p>
      <button className={'outline-none focus:outline-none'} onClick={()=>{setShowErrorMessage(!showErrorMessage)}}>
        <p>Para ver el error haz click aquí.  {showErrorMessage ? ' ▲ ' : ' ▼ '}</p>
      </button>
      {
        showErrorMessage && (
          <div className={'mt-4 border border-gray-secundary rounded-lg'}>
            <p className={'my-2'}>
              Error: {error?.message}
            </p> 
            <hr/>
            <p className={'my-2'}>
              networkError Stack: {error?.networkError?.stack}
            </p>
          </div>
        )
      }
      
    </div>
  )

}

export default FetchErrorMessage