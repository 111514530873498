import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NewLayout from '../../components/Layout/NewLayout';
import BeginningOrienteer from '../../components/Inicio/Orienteer/BeginningOrienteer';
import { setCurrentSection } from '../../slices/navSlice';
import { Roles } from '../../utils/Roles';
import BeginningOriented from '../../components/Inicio/Oriented/BeginningOriented';
import BeginningAdmin from '../../components/Inicio/Admin/BeginningAdmin';
import {
  getIncriptionAvailable,
  getNews,
  getPrograms,
} from '../../utils/strapiFunctions';
import {
  setInscriptionCards,
  setNews,
  setPrograms,
} from '../../slices/initialStrapiData';
import { orientedInfo } from '../../queries/Oriented';
import { saveUserInfo, session } from '../../slices/sessionSlice';
import { adminInfo, orienteerInfo } from '../../queries';
import { strapiData } from '../../slices/initialStrapiData';
import LoadingSpinner from '../../utils/spinner';

const HomePage = (): ReactElement => {

  console.log('Prueba para desplegar forsozamente')

  let content = {};
  const dispatch = useDispatch();
  const role = localStorage.getItem('role');
  const idkc = localStorage.getItem('idkc');
  const {user} = useSelector(session)
  const { news, programs, inscriptionCards } = useSelector(strapiData);

  useEffect(() => {
    dispatch(setCurrentSection('Inicio'));
  });

  useEffect(() => {
    if (news && news.length == 0) {
      getNews().then((res) => dispatch(setNews(res)));
    }
    if (role === Roles.ORIENTED) {
      if (programs && programs.length == 0) {
        getPrograms().then((res) => {
          dispatch(setPrograms(res));
        });
      }
      if (inscriptionCards && inscriptionCards.length == 0) {
        getIncriptionAvailable().then((res) =>
          dispatch(setInscriptionCards(res))
        );
      }
    }
  }, []);

  const getInfoFrom = (role: string, id: string): void => {
    switch (role) {
      case Roles.ORIENTED:
        orientedInfo(id).then((result) => {
          dispatch(saveUserInfo(result.data.oriented));
        });
        break;
      case Roles.ORIENTEER:
        orienteerInfo(id).then((result) => {
          dispatch(saveUserInfo(result.data.orienteer));
        });
        break;
      case Roles.ADMIN:
        adminInfo(id).then((result) => {
          dispatch(saveUserInfo(result.data.admin));
        });
        break;
      default:
        break;
    }
  };

  let HomePageContent = () => {
    const role = localStorage.getItem('role');
    role && idkc && getInfoFrom(role, idkc);
    if(!user) {return <LoadingSpinner/>}
    switch (role) {
      case Roles.ORIENTED:
        content = <BeginningOriented />;
        return content;
      case Roles.ADMIN:
        content = <BeginningAdmin />;
        return content;
      case Roles.ORIENTEER:
        content = <BeginningOrienteer />;
        return content;
      default:
        content = <div />;
    }
  };

  return (
    <NewLayout>
      <div className="w-full h-full">{HomePageContent()}</div>
    </NewLayout>
  );
};

export default HomePage;
