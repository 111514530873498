import { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NewLayout from '../../components/Layout/NewLayout';
import EditProfile from '../../components/Perfil/EditProfile';
import { role } from '../../utils/profileUtils';
import { setResponse, setShowModal } from '../../slices/modalSlice';
import avatar from '../../assets/images/Vector .png';
import { session } from '../../slices/sessionSlice';
import { useHistory } from 'react-router-dom';
import { uploadImage } from '../../utils/uploadFiles';
import TittleInProgress from '../../components/UI/TittleInProgress';
import ImageAvatar from '../../components/UI/ImageAvatar';
import Button from '../../components/UI/Button';

const ProfileEditPage = (): ReactElement => {
  const dispatch = useDispatch();
  const roleValue = localStorage.getItem('role');
  const history = useHistory();
  const handleShowEdit = () => {
    history.push('/profile');
  };
  const { user } = useSelector(session);

  const [picture, setPicture] = useState<any>({});
  const [currentPicture, setCurrentPicture] = useState<any>(true);
  const [showButton, setShowButton] = useState(true);

  const handleShowButton = (isShowing): void => {
    setShowButton(isShowing);
  };
  const uploadPicture = (event): void => {
    setPicture({
      pictureAsFile: event.target.files[0],
    });

    setCurrentPicture({
      picturePreview: URL.createObjectURL(event.target.files[0]),
    });

    handleShowButton(false);
  };
  const setImageAction = (): void => {
    const formData = new FormData();
    formData.append('userId', user.user.id);
    formData.append('profilePicture', picture.pictureAsFile);

    uploadImage(formData).then((result) => {
      if (result) {
        dispatch(
          setResponse({
            message: 'La imagen fue subida',
            error: false,
            description: '',
          })
        );
        dispatch(setShowModal(true));
      }
      location.reload()
    });
  };

  return (
    <NewLayout>
      <div className="w-full md:ml-11">
        <div className="h-full w-full mb-20 md:ml-11">
          <TittleInProgress text={'Mi perfil'} width={'w-32'} />
          <div className="border rounded-md block md:flex md:w-2/3 md:mt-3.5 border-gray-secundary flex">
            <div className="w-full md:w-1/2 h-auto pt-24">
              <div className="w-full h-1/2 flex justify-center items-end">
                <div className=" grid grid-columns-1 crusor-pointer">
                  <label className="cursor-pointer mt-6">
                    {
                      <>
                        {!currentPicture ? (
                          <ImageAvatar
                            src={avatar}
                            text={'your avatar'}
                            size={false}
                          />
                        ) : (
                          <ImageAvatar
                            src={currentPicture.picturePreview}
                            text={'your avatar'}
                            size={false}
                          />
                        )}
                        <input
                          type="file"
                          name="image"
                          className="hidden"
                          onChange={uploadPicture}
                        />
                        <Button styles={'md:mt-6 items-center flex'}
                          text={'Subi tu foto'}
                          action={setImageAction}
                          disabled={showButton}
                        />
                      </>
                    }
                  </label>
                </div>
              </div>
            </div>
            <div className="w-full md:w-3/5 p-8 bg-white border-gray-secundary border-l mt-6 mb-6">
              <div>
                <div className="pb-6">
                  <h2 className="text-black-common text-lg text-left">
                    {user.user.name}
                  </h2>
                  {roleValue && (
                    <p className="text-left text-gray-principal text-sm">
                      {role(roleValue)}
                    </p>
                  )}
                </div>
                {
                  <EditProfile
                    role={roleValue}
                    userInfo={user}
                    closeEdit={handleShowEdit}
                  />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </NewLayout>
  );
};

export default ProfileEditPage;
