import { ReactElement, ReactNode } from 'react';

type Props = {
  userInfo: any,
  children?: ReactNode
};

const CardMeeting = ({ userInfo, children }: Props): ReactElement => {
  return (
    <div className="rounded-lg w-80 py-4 pl-6 mr-1 mb-4 border border-gray-secundary">
      <div className="flex">
        <div className="mr-2 flex items-center">
          <span className="float-left bg-green-500 rounded-full px-1 py-1 text-white"></span>
        </div>
        <div className="w-2/3">
          <h1 className="text-left font-semibold text-black-primary text-lgs">
            {userInfo.user.name}
          </h1>
        </div>
      </div>
      <div className="w-2/3 mt-2">
        {children}
      </div>
    </div>
  );
};

export default CardMeeting;