import { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import NewLayout from '../../components/Layout/NewLayout';
import OrangeBorderCard from '../../components/UI/BorderOrangeCard';
import TittleInProgress from '../../components/UI/TittleInProgress';
import { setCurrentSection } from '../../slices/navSlice';

const LibraryPage = (): ReactElement => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentSection('Biblioteca'));
  });

  return (
    <NewLayout>
      <div className="w-full flex flex-col items-start ml-11">
        <div className={'w-full flex flex-row justify-between items-end pr-4'}>
          <TittleInProgress text={'Encuentra todo el material disponible de D’Alfonso'} width={'w-100'} />
        </div>
        <div className="flex flex-nowrap flex-row h-full w-full items-start mt-8">
          <OrangeBorderCard
            link={'/library/activity'}
            height={'h-36'}
            width={'w-4/12'}
            margin={'mr-5'}
            title={'Actividades'}
            description={'Aquí podrás consultar y cargar nuevas actividades.'}
            newtab={false}
          />
          <OrangeBorderCard
            link={'/library/programs'}
            height={'h-36'}
            width={'w-4/12'}
            margin={'mr-5'}
            title={'Programas'}
            description={'Accedé al detalle de cada uno de nuestros programas.'}
            newtab={false}
          />
        </div>
      </div>
    </NewLayout>
  );
};

export default LibraryPage;
