import { FetchResult, gql } from '@apollo/client';
import { client } from '../../client-apollo';

export const deleteActivity = (activityID): Promise<FetchResult<any>> => {
  return client
    .mutate({
      mutation: gql`
      mutation{
        deleteActivity(data: {
          activityID: "${activityID}"
        })
      }
    `
    })
}

export const saveActivity = (data):Promise<any> => client
  .mutate({
    mutation: gql`
  mutation{
    createActivity(data: ${data}){
      id
    }
  }
  `
  })

export const updateActivity = (data): Promise<FetchResult<any, Record<string, any>, Record<string, any>>> => client
  .mutate({
    mutation: gql `
     mutation{
      updateActivity(data: ${data}){
        id
        name
        accessLink
        isRequired
        duration
        format
      }
     }
    `
  })
