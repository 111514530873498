import { useQuery } from '@apollo/client'
import { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentSection } from '../../slices/navSlice';
import NewLayout from '../../components/Layout/NewLayout';
import { useParams } from 'react-router-dom'
import { GET_APPOINTMENT } from '../../queries/Appointment/index'
import BegginingAppointmentDetail from '../../components/Inicio/Oriented/AppoinmentDetail/BegginingAppointmentDetail';
import LoadingSpinner from '../../utils/spinner';
import BreadCrumb from '../../components/UI/BreadCrumb';
import BreadCrumbItem from '../../components/UI/BreadCrumbItem';
import ProgramBreadCrumb from '../../components/UI/ProgramBreadCrumb';

const AppointmentsDetailsPage =(): ReactElement => {
  const dispatch = useDispatch()
  const { programSlug, id } = useParams()
  const { data, loading} = useQuery(GET_APPOINTMENT, {
    variables: { id: id }
  })
  
  useEffect(() => {
    dispatch(setCurrentSection('Agenda'));
  });


  return (
    <NewLayout>
      <div className="w-full md:ml-11">
        <BreadCrumb>
          <BreadCrumbItem Name={'Inicio'} path={'/home'} isCurrentPage={false}/>
          <ProgramBreadCrumb isCurrentPage={false}/>
          <BreadCrumbItem Name={'Encuentros'} path={`/my-programs/${programSlug}/appointments`} isCurrentPage={false}/>
          <BreadCrumbItem Name={'Detalle del encuentro'} isCurrentPage={true}/>
        </BreadCrumb>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <BegginingAppointmentDetail appointment={data.appointmentById} />
        )}
      </div>
    </NewLayout>            
  )

}

export default AppointmentsDetailsPage;