import { ReactElement, useEffect, useState } from 'react';
import Table from '../UI/Table';
import TittleInProgress from '../UI/TittleInProgress';
import { useQuery } from '@apollo/client';
import { GET_ACTIVITIES_BY_ORIENTED } from '../../queries/Activity';
import { session } from '../../slices/sessionSlice';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import LoadingSpinner from '../../utils/spinner';
import { Link } from 'react-router-dom';
import Icon from '../UI/Icon';
import SearchBox from '../UI/SearchBox';
import { search as searchIcon } from '../../assets/svg/dashboardIcons';


const OrientedAssignedActivities = (): ReactElement => {
  const {user} = useSelector(session)
  const getActivitiesByOriented = useQuery(GET_ACTIVITIES_BY_ORIENTED, {
    variables: {id:user.id}
  });
  const [activitiesInAppointments, setActivitiesInAppointments] = useState<any>([])
  const [search, setSearch] = useState('')
  const [activitiesFiltered, setactivitiesFiltered] = useState([])

  let normalizeSearchInput = (searchInput) => {
    const normalizedInput = searchInput
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
    return normalizedInput;
  }

  const orderColummns: Array<{
    id: string;
    desc: boolean;
  }> = [
    {
      id: 'deliveryDate',
      desc: true,
    },
  ];

  useEffect(()=>{
    if (!getActivitiesByOriented.loading) {
      const data = getActivitiesByOriented.data.getAppointmentsAndActivitiesByOriented
      setActivitiesInAppointments(data)    
    }
  }, [getActivitiesByOriented.loading])

  useEffect(()=> {
    const filteredValues = search != '' && search != undefined ?
      activitiesInAppointments.filter(i => i.activity.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
      normalizeSearchInput(i.appointment.program.name).includes(normalizeSearchInput(search)) ||
      i.appointment.program.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
      i.appointment.confirmedOrienteers.map(orienteer => orienteer.user.name).includes(search))
      : activitiesInAppointments

    setactivitiesFiltered(filteredValues)
  }, [search, activitiesInAppointments])

  const columns = [
    {
      Header: 'Vencimiento',
      accessor: 'deliveryDate',
      Cell: ({ value }):string => {return format(new Date(value), 'dd/MM/yyyy')}
    },
    {
      Header: 'Actividad',
      accessor: 'activity.name',
    },
    {
      Header: 'Estado',
      accessor: 'isComplete',
    },
    {
      Header: 'Evento asignado',
      accessor: 'appointment.name',
    },
    {
      Header: 'Orientador',
      accessor: 'appointment.confirmedOrienteers[0].user.name',
    },
    {
      Header: 'Programa',
      accessor: 'appointment.program.name',
    },
    {
      Header: 'Acciones',
      accessor:'',
      Cell: ({ row }) => {
        return (
          <Link
            to={`/my-programs/${row.original.appointment.program.id}/appointments/${row.original.appointment.id}`}
            className="focus:outline-none cursor-pointer w-4 transform hover:scale-110"
          >
            <Icon path={searchIcon} width={28} height={18} color={'#2E384D'} />
          </Link>
        );
      },
    }
  ]

  const mapState = (activity): ReactElement => {
    let stateToShow: ReactElement = <div />;
    if(activity.isComplete) {          
      stateToShow = (<div className='flex items-center'>
        <div className="w-auto rounded-sm flex items-center h-auto text-black-primary text-xs p-1 bg-gray-secundary">
          Finalizada
        </div>
      </div>)
    } else {
      stateToShow = (<div className='flex items-center'>
        <div className="w-auto rounded-sm h-auto text-black-primary text-xs p-1 bg-yellow-info">
          Pendiente
        </div>
      </div>)
    }
    return stateToShow
  }

  return(
    <div className={'w-full'}>
      <TittleInProgress text={'Actividades'} width={'w-full'} />
      <div className={'w-11/12'}>
        {getActivitiesByOriented.loading ? 
          <LoadingSpinner/> 
          : 
          <div>
            <SearchBox options={[search, setSearch]} placeholder='Buscar por nombre de la actividad...'/>
            {activitiesFiltered.length == 0 ?
              <div className='mt-20 w-4/6 flex justify-center items-center'>
                <p>No se encontraron resultados.</p>
              </div> :
              <Table 
                data={activitiesFiltered} 
                columns={columns} 
                state={mapState}
                orderColumnArray={orderColummns}
              />
            }
          </div>
        }
      </div>

    </div>) 
}

export default OrientedAssignedActivities