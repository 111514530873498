import { ReactElement, useEffect, useState } from 'react';
import imageLogin from '../assets/images/login.png';
import { login, allOrienteers } from '../queries';
import { useDispatch } from 'react-redux';
import {loginState } from '../slices/sessionSlice';
import { useHistory } from 'react-router-dom';
import { Roles } from '../utils/Roles';
import { saveOrienteers } from '../slices/orienteersSlice'
import { setResponse, setShowModal } from '../slices/modalSlice';
import Modal from './UI/Modal';
//import Button from './UI/Button';
import ReloguinModal from './UI/ReloguinModal';
import { getIncriptionAvailable, getNews, getProfessionLabs, getPrograms } from '../utils/strapiFunctions';
import { setInscriptionCards, setNews, setPrograms } from '../slices/initialStrapiData';
import { setLabs } from '../slices/labsSlice';

var jwt = require('jsonwebtoken');


const Login = (): ReactElement => {
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [showReloguin, setShowReloguin] = useState(false)
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const session = localStorage.getItem('session')
    session === 'expired' && setShowReloguin(true)
    localStorage.clear()
  }, [])

  const handlePasswordChange = (event): void => {
    setPassword(event.target.value)
  }

  const handleEmailChange = (event): void => {
    setEmail(event.target.value)
  }

  const getAllOrienteersIfAdmin = (role): void => {
    if (role == Roles.ADMIN) {
      allOrienteers().then(res => {
        //sort data by orienteers name
        const orienteers = res.data.allOrienteer
        const newOrienteers = [...orienteers]
        const orienteersSorted = newOrienteers.sort(function (a, b) { return (a.user.name.localeCompare(b.user.name)) })
        dispatch(saveOrienteers(orienteersSorted))
      })
    }
  }

  const getProgramasAndInscriptions = (role): void => {
    if (role === Roles.ORIENTED) {
      getPrograms().then(res => { dispatch(setPrograms(res)) })
      getIncriptionAvailable().then(res => dispatch(setInscriptionCards(res)))
      getProfessionLabs().then(res => { dispatch(setLabs(res)) })
    }
  }

  const enter = (e): void => {
    e.preventDefault();
    login(email, password)?.then((result) => {
      const role = result.data.login.role
      //save info session in localStorage
      localStorage.setItem('token', result.data.login.accessToken)
      localStorage.setItem('username', result.data.login.username)
      localStorage.setItem('role', role)
      localStorage.setItem('idkc', result.data.login.idKc)

      var { exp } = jwt.decode(result.data.login.accessToken);
      localStorage.setItem('exp', exp)

      // save info LoginState session in redux
      dispatch(loginState(true))

      //get all orienteers if admin
      getAllOrienteersIfAdmin(role)
      //set modal off if login failed before
      dispatch(setShowModal(false))
      //programs and inscriptions if oriented
      getProgramasAndInscriptions(role)
      //save news
      getNews().then(res => dispatch(setNews(res)))
      //redirect
      history.push('/')
    }).catch(() => {
      dispatch(setResponse({
        message: 'Usuario o contraseña incorrectos',
        error: true,
        description: 'Revise sus datos y vuelva a intentarlo'
      }))
      setEmail('')
      setPassword('')
      dispatch(setShowModal(true))
      window.scrollTo(0, document.body.scrollHeight)
    })
  }

  return (
    <div className="min-w-screen h-screen flex items-center justify-center overflow-hidden">
      <div className="md:flex w-full h-full">
        <div className="md:w-1/2 flex flex-col items-center justify-center bg-gray-secundary">
          <img
            src={imageLogin}
            alt="ChitChat Logo"
          />
          <Modal width={'absolute w-2/6 mt-96'} />
        </div>
        <div className="text-center w-3/4 flex items-center">
          <div className="w-full md:w-2/5 ml-32">
            <div className="text-center mb-6">
              <h1 className="text-lg font-normal text-black-primary text-left">Ingresá a tu portal</h1>
            </div>
            <form onSubmit={enter}>
              <div>
                <div className="w-full mb-4">
                  <label className="label-update font-normal mb-2 text-sm">DNI</label>
                  <input
                    type="username"
                    className="w-full px-3 py-2 rounded-lg border border-gray-secundary outline-none"
                    placeholder="Ingresa tu DNI"
                    value={email}
                    onChange={handleEmailChange} />
                </div>
                <div className="w-full mb-10">
                  <label className="label-update font-normal mb-2 text-sm">Contraseña</label>
                  <input
                    type="password"
                    className="w-full px-3 py-2 rounded-lg border border-gray-secundary outline-none"
                    placeholder="Ingresa tu contraseña"
                    value={password}
                    onChange={handlePasswordChange} />
                  <p className="mt-2 text-left font-Rubik text-gray-400">Si no sabes tu contraseña, escribe un mail a <a href="#" className="text-green-principal">recepcion@dalfonso.org</a>
                  </p>
                </div>
                <div className="w-full flex items-start">
                  <button className="py-2 px-6 focus:outline-2 outline outline-gray-300 bg-green-principal text-white rounded-full h-10 font-semibold">
                    Ingresar
                  </button>
                </div>
                <p className="mt-2 text-left font-Rubik text-gray-400">Al iniciar sesión estás aceptando nuestra política sobre  <a href="#" onClick={() => window.open(window.location.origin + '/politicas-de-proteccion-de-datos-personales', '_blank')} className="text-green-principal">Protección de Datos</a></p>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ReloguinModal show={showReloguin} />
    </div>
  );
};

export default Login;