import { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import NewLayout from '../../components/Layout/NewLayout';
import Inbox from '../../components/Mensajes/Messages';
import { setCurrentSection } from '../../slices/navSlice';

const MessagesPage =(): ReactElement => {

  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(setCurrentSection('Mensajes'))
  })

  return (
    <NewLayout>
      <Inbox />
    </NewLayout>            
  )

}

export default MessagesPage;