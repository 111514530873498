import { ReactElement, useEffect, useState } from 'react';
import avatar from '../../assets/images/Vector .png';
import ImageAvatar from '../UI/ImageAvatar';
import { orientedById } from '../../queries/Oriented';
import { initialState } from '../../utils/OrientedInterface';
import { useHistory } from 'react-router-dom'

type OrientedInfo = {
  oriented: any
}

const OrientedProfileInfo = (props: OrientedInfo): ReactElement => {
  const selected = props.oriented
  const history = useHistory()
  const { user: { name, profilePicture },id,  school} = selected;
  const [userFromQuery, setUserFromQuery] = useState(initialState)
  useEffect(() => {
    if(id){
      orientedById(id)
        .then(response => {
          let data = response.data.orientedById
          setUserFromQuery(data)
        });
    }
  }, [selected])
  const handleShowEdit = (): void => {
    history.push(`/admissions/form/${id}`)
  };

  const handleGoToPayments = (): void => {
    history.push(`/admin-oriented/payment-plans/${id}`)
  }
  const formatPrograms = ( paymentPlans ):ReactElement => {
    let programArr : String[] = []
    paymentPlans && paymentPlans.map(( plan ) => programArr.includes( plan.programSlug ) ? programArr : programArr.push( plan.programSlug ))
    return ( 
      <>
        {programArr.map( (program) => {
          return (
            <>
              {program && program.charAt(0).toUpperCase() + program.slice(1).replaceAll('-', ' ')}
              <br/>
            </>
          )
        })
        }
      </> 
    )
  }
  return (
    <div>
      <div className='bg-white min-h-screen mt-2 -ml-6'>
        <div className='container'>
          <div className='inputs w-full max-w-4xl p-6'>
            <div className='grid grid-cols-2'>
              <h2 className='title-bold text-left'>01. Información básica</h2>
              <a href='#' onClick={handleShowEdit} className='underline hover:text-green-principal text-green-principal text-xs pt-2'>
                Editar perfil
              </a>
              <a href='#' onClick={handleGoToPayments} className='col-end-3 underline hover:text-green-principal text-green-principal text-xs pt-2 align-self-center justify-self-center'>
                Asignar Plan de pagos
              </a>
            </div>
            <div>
              <div className='mt-6 pt-4'>
                <div className='flex items-center justify-between mt-4'>
                  <div className='px-4 py-5 rounded-full text-center w-48 h-40'>
                    <div className='text-center' />
                    <h2 className='cursor-pointer mb-10 ml-10'>
                      {!profilePicture ? (
                        <ImageAvatar src={avatar} width={'w-28 h-28'} text={'your avatar'} size={false} />) : (
                        <ImageAvatar src={process.env.REACT_APP_EXPORT_FILES + profilePicture} width={'w-28 h-28'} text={'your avatar'} size={false} />
                      )}
                    </h2>
                  </div>
                  <div className=' grid grid-columns-1 mr-64'>
                    <div className='w-full '>
                      <div
                        className='text-lg capitalize text-left block w-full'
                      >{name}</div>
                    </div>
                    <div className='w-full mt-4'>
                      <h2 className='label'>Email</h2>
                      <div
                        className='mt-1.5 fieldData'
                      >{userFromQuery.user.email}</div>
                    </div>
                    <div className='w-full mt-3'>
                      <h2 className='label'>Teléfono</h2>
                      <div
                        className='mt-1.5 fieldData'
                      >{userFromQuery.user.phone}</div>
                    </div>
                    <div className='w-full mt-3'>
                      <h2 className='label'>Programa para
                        iniciar</h2>
                      <div
                        className='block w-full bg-white text-black-common text-left'
                      >{formatPrograms(userFromQuery.paymentPlans)}</div>
                    </div>
                  </div>
                  <div className='flex items-center justify-between mt-4' />
                </div>
                <div className='w-full mt-11'>
                  <h2 className='title-bold text-left'>02. Datos personales</h2>
                  <div className='flex items-center justify-between mt-4'>
                    <div className='w-full md:w-1/2'>
                      <h2 className='label mb-1.5'>Numero de Dni</h2>
                      <div
                        className='fieldData'
                      >{userFromQuery.dni}</div>
                    </div>
                    <div className='w-full md:w-1/2'>
                      <h2 className='label mb-1.5'>Edad</h2>
                      <div
                        className='fieldData'
                      >{userFromQuery.age}</div>
                    </div>
                  </div>
                  <div className='flex items-center justify-between mt-3'>
                    <div className='w-full md:w-1/2'>
                      <h2 className='label mb-1.5'>Colegio</h2>
                      <div
                        className='fieldData'
                      >{school}</div>
                    </div>
                    <div className='w-full md:w-1/2'>
                      <h2 className='label mb-1.5'>Domicilio</h2>
                      <div
                        className='fieldData'
                      >{userFromQuery.address}</div>
                    </div>
                  </div>
                  <div className='w-full md:w-full mt-3'>
                    <h2 className='label mb-1.5'>Porque se acerco a
                      dalfonso</h2>
                    <div
                      className='fieldData'
                    > {userFromQuery.expectations!=='null'? userFromQuery.expectations:''}</div>
                  </div>
                  <div className='w-full mt-11'>
                    <h2 className='title-bold text-left'>03. Crear usuario y
                      contraseña</h2>
                    <div className='w-full md:w-1/2 mt-4'>
                      <h2 className='label mb-1.5'>Usuario</h2>
                      <div className='fieldData'>
                        {userFromQuery.dni}
                      </div>
                    </div>
                    <div className='w-full md:w-1/2 mt-3'>
                      <h2 className='label mb-1.5'>Contraseña</h2>
                      <div
                        className='fieldData'
                      >{'*******'}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrientedProfileInfo;