import { ReactElement, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { savePrivateCommentMutation } from '../../mutations/Appointment';
import { setResponse, setShowModal } from '../../slices/modalSlice';
import Button from '../UI/Button';
import { GET_APPOINTMENT } from '../../queries/Appointment';
import { useParams } from 'react-router-dom';


const PrivateComment = (): ReactElement => {
  const dispatch = useDispatch()
  const { appointmentId } = useParams();
  const [savePrivateComment] = useMutation(savePrivateCommentMutation);
  const { data, loading} = useQuery(GET_APPOINTMENT, {
    variables: { id: appointmentId }
  });
  const appointment = data ? data.appointmentById : null
  const [comment, setComment] = useState('')
  const [showTextarea, setShowTextarea] = useState(false)

  useEffect(() => {
    if(appointment != null){
      setComment(appointment.privateComment ? appointment.privateComment : '')
      setShowTextarea(appointment.privateComment == null)
    }
  }, [appointment])

  const savePrivate = (): void => {
    savePrivateComment({
      variables: { 
        data:{
          appointmentID: appointment.id, 
          privateComments: comment 
        }
      }
    })
      .then(res=>{
        if(res.data) {
          dispatch(setResponse({
            message: 'Los comentarios se guardaron con éxito.',
            error: false,
            description: 'El orientado no podrá verlo'
          }))
          dispatch(setShowModal(true))
        }
      })
    setShowTextarea(false)
  }


  return loading ? <div/> : (
    <div className="w-full flex flex-col items-start">
      <label className="text-black-primary mt-2 text-base font-medium mb-2">Comentarios privados</label>
      {!showTextarea ?
        <div className="w-full flex flex-col items-start">
          <p className="text-black-primary text-xs mb-8 whitespace-pre-wrap text-left">{comment}</p>
          <Button text={'Editar'} action={(): void => setShowTextarea(true)} />
        </div>
        :
        <div className="w-full flex flex-col items-start">
          <textarea
            style={{ height: 154, margin: 0 }}
            value={comment}
            placeholder="Escribir comentario"
            onChange={(e): void => setComment(e.target.value)}
            className="w-3/5 p-3 resize-none border rounded-lg border-gray-secundary focus:outline-none"
          />
          <div className="w-1/4 flex justify-between mt-8">
            <Button disabled={comment.length === 0} text="Guardar comentario" action={(): void => savePrivate()} />
            {appointment.privateComment !== null && <a className="cancel-link" onClick={(): void => setShowTextarea(false)}>Cancelar</a>}
          </div>
        </div>
      }

    </div>
  )
}

export default PrivateComment