import { ReactElement, useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { updateActivity } from '../../mutations/Activity';
import { Form } from '../../utils/FormInterface';
import { getForms } from '../../utils/formsFunctions';
import TittleInProgress from '../UI/TittleInProgress';
import NewPromptButtonGB from '../UI/NewPromptButtonGB';
import { setResponse, setShowModal } from '../../slices/modalSlice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

interface EditFormProps {
  Selectedactivity: any,
  onCancel: any
}

const EditFormAct = (props: EditFormProps ): ReactElement => {
  const [forms, setForms] = useState<Array<Form> | undefined>([]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    getForms().then((res) => {
      setForms(res);
    });
  }, []);
  const findName = (accessLink:String) : String | undefined => {
    let name:String=''
    if (forms==undefined){
      return
    }
    forms.map((form)=>{
      if (form.Slug === accessLink){
        name = form.Title
      }
    })
    return name 
  }

  const updateAct = ({
    id,
    required, 
    accessLink, 
    duration, 
    format 
  }) : void => {
    const name = findName(accessLink);
    if (!name || !accessLink || !duration || !format) {
      return;
    } else {
      const data = `{
        id: "${id}",
        name: "${name}",
        accessLink: "${accessLink}",
        isRequired: ${required},
        duration: "${duration}",
        format: "${format}"
      }`;
      updateActivity(data).then((res) => {
        if (res.data) {
          dispatch(
            setResponse({
              message: 'La actividad fue editada con éxito!',
              error: false,
            })
          );
          dispatch(setShowModal(true));
          history.goBack();
        }
      })
        .catch(() => {
          dispatch(
            setResponse({
              message: 'Ups! No se pudo editar la actividad',
              error: true,
              description: 'Por favor reintenta más tarde.',
            })
          );
          dispatch(setShowModal(true));
        });
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        id: props.Selectedactivity.id,
        required: props.Selectedactivity.isRequired,
        accessLink: props.Selectedactivity.accessLink,
        duration: props.Selectedactivity.duration,
        format: props.Selectedactivity.format,
      }}
      onSubmit={updateAct}
      validationSchema={yup.object().shape({
        required: yup.boolean(),
        accessLink: yup.string().required('El formulario es requerido.'),
        duration: yup.string().required('La duración es requerido.'),
        format: yup.string().required('El formato es requerido.'),
      })}
    >
      {(formik):ReactElement => {
        useEffect(() => {}, []);
        return (
          <form onSubmit={formik.handleSubmit}>
            <div className="w-full ml-11 flex flex-col items-start">
              <TittleInProgress text={'Editar actividad'} width={'w-1/5'} />
              <div className="flex w-1/2 mt-8 justify-between">
                <div className="flex flex-col w-2/5">
                  <label className="label-update">Formulario</label>
                  <select
                    id="accessLink"
                    name="accessLink"
                    defaultValue={formik.values.accessLink}
                    disabled
                    className="focus:outline-none border border-gray-secundary text-xs px-3 py-2 mt-2 rounded-md text-black-principal"
                  >
                    {forms &&
                      forms.map((i, index) => (
                        <option key={index} value={i.Slug}>
                          {i.Title}
                        </option>
                      ))}
                  </select>
                  {formik.errors.accessLink &&
                  (formik.touched.accessLink || formik.isSubmitting) && (
                    <p>{formik.errors.accessLink}</p>
                  )}
                </div>
                <div className="flex flex-col w-1/2">
                  <label className="label-update">Duración</label>
                  <input
                    type="text"
                    id="duration"
                    name="duration"
                    className="w-full mt-2 px-3 py-2 rounded-lg border border-gray-secundary outline-none"
                    placeholder="Duración"
                    value={formik.values.duration}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.duration &&
                  (formik.touched.duration || formik.isSubmitting) && (
                    <p>{formik.errors.duration}</p>
                  )}
                </div>
              </div>
              <div className="flex w-1/2 mt-4 items-center mb-8 justify-between">
               
                <div className="flex flex-col w-2/5 justify-center">
                  <label className="label-update">Formato</label>
                  <select
                    id="format"
                    name="format"
                    defaultValue={formik.values.format}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="focus:outline-none border border-gray-secundary text-xs px-3 py-2 mt-2 rounded-md text-gray-principal"
                  >
                    <option value={''}>Seleccionar formato</option>
                    <option value={'form'}>Form</option>
                  </select>
                  {formik.errors.format &&
                  (formik.touched.format || formik.isSubmitting) && (
                    <p>{formik.errors.format}</p>
                  )}
                </div>
              </div>
              <div className="flex items-center mb-8">
                <input
                  id="required"
                  name="required"
                  checked={formik.values.required}
                  className="cursor-pointer"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="checkbox"
                />
                <label className="label-update mt-0 ml-2">Requerido</label>
              </div>
              <div className="flex justify-around w-1/3 h-16 mt-10">
                <NewPromptButtonGB
                  text={'Guardar'}
                  action={(): void => {}}
                  okButton={true}
                  type={'submit'}
                />
                <NewPromptButtonGB
                  text={'Cancelar'}
                  action={props.onCancel}
                  okButton={false}
                  type={'button'}
                />             
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default EditFormAct;
