import { ReactElement } from 'react';

const NewsCard = ({title, description, Link}): ReactElement => {
  const handleOpenLink = ():void => {window.open(Link, '_blank')}
  return Link!== null ?(
    <div onClick={handleOpenLink} className="cursor-pointer hover:bg-green-secundary hover:bg-opacity-10 border rounded-md border-gray-secundary w-80 h-36 px-6 mt-4 mb-4 flex flex-col justify-around py-2">
      <p className="title-smallCard text-base max-h-2/3 overflow-hidden">{title}</p>
      <p className="description-smallCard text-xs max-h-1/3 overflow-hidden">{description}</p>
    </div>
  ):(
    <div className="border rounded-md border-gray-secundary w-80 h-36 px-6 mt-4 mb-4 flex flex-col justify-around py-2">
      <p className="title-smallCard text-base max-h-2/3 overflow-hidden">{title}</p>
      <p className="description-smallCard text-xs max-h-1/3 overflow-hidden">{description}</p>
    </div>
  )
}

export default NewsCard