import { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import IngresoForm from '../../components/Ingresos/IngresoForm';
import NewLayout from '../../components/Layout/NewLayout';
import { setCurrentSection } from '../../slices/navSlice';

const AdmissionsFormPage =(): ReactElement => {
    
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(setCurrentSection('Ingresos'))
  })

  const data = ''
  return (
    <NewLayout>
      <div className="w-full md:ml-11">
        <IngresoForm data={data}/>
      </div>
    </NewLayout>            
  )
  
}
  
export default AdmissionsFormPage;