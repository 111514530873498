import { ReactElement, useEffect } from 'react';
import ViewMessage from '../Mensajes/ViewMessage';
import { useDispatch, useSelector } from 'react-redux';
import { messageState } from '../../slices/messageSlice';
import { MESSAGES_BY_USER, MESSAGES_SENT_BY_USER } from '../../queries/Message';
import { session } from '../../slices/sessionSlice';
import Pagination from '../UI/Pagination';
import MessagesTable from './UI-mensajes/MessagesTable';
import { useQuery } from '@apollo/client';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ButtonLink from '../UI/ButtonLink';
import { deleteForMe } from '../../mutations/Message';
import { setResponse, setShowModal } from '../../slices/modalSlice';
import LoadingSpinner from '../../utils/spinner';

const Inbox = (): ReactElement => {
  const { componentToShow } = useSelector(messageState)
  const dispatch = useDispatch()
  const { user } = useSelector(session)
  const { data, loading, refetch } = useQuery(MESSAGES_BY_USER, {
    variables: { id: user.user.id }
  })
  const messagesSentByUser = useQuery(MESSAGES_SENT_BY_USER, {
    variables: { id: user.user.id }
  })


  // TODO: revisar condición en useEffect
  useEffect(() => {
    componentToShow === '' && data && messagesSentByUser.refetch();
    refetch()
  }, [componentToShow])

  const handleOrderMessage = (asSender):Array<object> => {
    const messages = asSender ? messagesSentByUser.data.messagesSentByUser : data.messages
    return messages.slice().sort((a, b) => new Date(b.sentAt).getTime() - new Date(a.sentAt).getTime())
  }

  const deleteMessage = (messageId):void => {
    deleteForMe(user.user.id, messageId).then((): void => {
      refetch()
      dispatch(setResponse({
        message: 'Mensaje borrado correctamente.',
        error: false,
        description: 'Ya no podrás verlo en tu bandeja de entrada.'
      }))
      dispatch(setShowModal(true))
    })
      .catch((): void => {
        dispatch(setResponse({
          message: 'El mensaje no pudo ser borrado.',
          error: true,
          description: 'Vuelva a intentar más tarde.'
        }))
        dispatch(setShowModal(true))
      })
  }

  const switchCurrent = (): ReactElement => {
    switch (componentToShow) {
      case 'view':
        return <ViewMessage />
      default:
        return <div />
    }
  }

  const contentTab = (data, asSender): ReactElement => <Pagination
    data={data !== undefined ? handleOrderMessage(asSender) : []}
    itemData={null}
    perPage={9}
    table={(data):ReactElement => <MessagesTable data={data} viewAsSender={asSender} deleteMessage={deleteMessage} />} />

  return (
    <div className="w-5/6 ml-11">
      {componentToShow == '' ? (
        <div>
          {loading && messagesSentByUser.loading ? 
            <LoadingSpinner/> 
            : 
            <Tabs className={'w-full flex flex-wrap items-center justify-between'} selectedTabClassName={'text-gray-800 border-b-4 border-green-secundary'}>
              <TabList className={'flex mb-0 list-none ml-1.5 '}>
                <Tab className={'text-gray-400 border-b-4 border-gray-200 px-14 pt-1 cursor-pointer text-base hover:text-black-primary  capitalize py-4 block leading-normal '}>
                  Bandeja de entrada
                </Tab>
                <Tab className={'text-gray-400 border-b-4 border-gray-200 px-14 pt-1 cursor-pointer text-base hover:text-black-primary  capitalize py-4 block leading-normal '}>
                  Enviados
                </Tab>
              </TabList>
              <ButtonLink text={'Nuevo mensaje'} to='/messages/new' styles={'mb-10 mt-7'}/>
              <TabPanel className={'w-full flex items-center justify-between'}>
                {contentTab(data, false)}
              </TabPanel>
              <TabPanel className={'w-full flex items-center justify-between'}>
                {contentTab(messagesSentByUser.data, true)}
              </TabPanel>
            </Tabs>
          }
        </div>
      ) :
        switchCurrent() 
      }
    </div>
  )
}

export default Inbox