import { ReactElement } from 'react-markdown/lib/react-markdown'
import PaymentForm from '../../components/Ingresos/PaymentForm';
import { useParams } from 'react-router-dom';
import NewLayout from '../../components/Layout/NewLayout';
import { useQuery } from '@apollo/client';
import { ORIENTED_BY_ID } from '../../queries/Oriented';
import { useEffect, useState } from 'react';
import BreadCrumb from '../../components/UI/BreadCrumb';
import BreadCrumbItem from '../../components/UI/BreadCrumbItem';


const PaymentPlanPage = ():ReactElement => {
  const { orientedId } = useParams()
  const [orientedName, setOrientedName] = useState('...')
  const {data:dataOriented, loading:loadingOriented} = useQuery(ORIENTED_BY_ID, {
    variables: {id:orientedId}
  })

  useEffect(()=>{
    if(!loadingOriented && dataOriented?.orientedById){
      setOrientedName(dataOriented.orientedById.user.name)
    }
  })

  return(
    <NewLayout>
      <div className=' w-5/6 ml-11 mt-10'>
        <BreadCrumb>
          <BreadCrumbItem Name={'Inicio'} path={'/'} isCurrentPage={false}/>
          <BreadCrumbItem Name={`${orientedName}`} path={`/admin-oriented-info/${orientedId}`} isCurrentPage={false}/>
          <BreadCrumbItem Name={'Plan de pagos'} path={''} isCurrentPage={true}/>
        </BreadCrumb>
        <div className='min-h-screen my-8'>
          <h2 className='title-bold text-left mb-8 '>Asignar Plan de pagos</h2>
          <PaymentForm userId={orientedId} />
        </div>
      </div>
    </NewLayout>
  )
}

export default PaymentPlanPage;