import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import NewLayout from '../../components/Layout/NewLayout';
import { setCurrentSection } from '../../slices/navSlice';
import { ADMIN_ORIENTED_BY_ID } from '../../queries/Oriented/index';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../utils/spinner';
import IngresoEditForm from '../../components/Ingresos/IngresoEditForm';
import BreadCrumb from '../../components/UI/BreadCrumb';
import BreadCrumbItem from '../../components/UI/BreadCrumbItem';

const AdmissionsEditPage =(): ReactElement => {
    
  const dispatch = useDispatch();
  let [orientedName, setOrientedName] = useState('...')

  const { id } = useParams()

  const {data, loading} = useQuery(ADMIN_ORIENTED_BY_ID, {
    variables: { id: id }
  })



  useEffect(()=>{
    dispatch(setCurrentSection('Ingresos'))
    if (!loading && data.orientedById) {
      setOrientedName(data.orientedById.user.name)
    }
  })

  return (
    <NewLayout>
      <div className="w-full md:ml-11">
        <BreadCrumb>
          <BreadCrumbItem Name={'Inicio'} path={'/'} isCurrentPage={false}/>
          <BreadCrumbItem Name={`${orientedName}`} path={`/admin-oriented-info/${id}`} isCurrentPage={false}/>
          <BreadCrumbItem Name={'Editar Perfil'} path={''} isCurrentPage={true}/>
        </BreadCrumb>
        {loading ? <LoadingSpinner /> : <IngresoEditForm data={data.orientedById}/> }
      </div>
    </NewLayout>            
  )
  
}
  
export default AdmissionsEditPage;