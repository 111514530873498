import { ReactElement } from 'react';
import { loginUrl } from '../../utils/authGoogle';
import imageLogin from '../../assets/images/login.png'
import logo from '../../assets/images/logo.png';
import Modal from '../UI/Modal';

const FormOrienteerLogin = (): ReactElement => {
  return (
    <div className="min-w-screen h-screen flex items-center justify-center overflow-hidden">
      <div className="md:flex w-full h-full">
        <div className="w-5/12 flex flex-col items-center justify-center bg-gray-secundary">
          <img className="transform transition duration-500 hover:scale-95"
            src={imageLogin}
            alt="ChitChat Logo"
          />
          <Modal width={'absolute w-2/6 mt-96'} />
        </div>
        <div className="text-center w-7/12 flex items-center">
          <div className="w-3/4 ml-32 flex flex-col justify-start">
            <div className="w-8/12 text-center mb-6 flex flex-col ">
              <img className="w-10/12 mb-16 transform transition duration-500 hover:scale-105" src={logo} />
              <h1 className="text-lg font-normal text-black-primary text-left">Ingresá a tu portal</h1>
              <h2 className="font-normal text-gray-600 text-left"> Ingreso para <i>Orientadores</i></h2>
            </div>
            <div className="w-3/4 my-5">
              <a href={loginUrl()}>
                <button className="w-3/4 text-center py-3 my-3 border flex space-x-2 items-center justify-center border-slate-200 rounded-lg text-slate-700 hover:border-slate-400 hover:text-slate-900 hover:shadow-md transition duration-150">
                  <img
                    src="https://www.svgrepo.com/show/355037/google.svg"
                    className="w-6 h-6"
                    alt=""
                  />{' '}
                  <span>Ingresar con Google</span>
                </button>
              </a>
            </div>
            <div className="flex justify-start">
              <span className="mt-2 text-left text-gray-400">Si no podes ingresar, contactanos a: <a href="mailto:recepcion@dalfonso.org" className="text-green-principal"> recepcion@dalfonso.org </a> </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormOrienteerLogin;
