/* eslint-disable */
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { saveProgram } from '../../mutations/Program';
import { setResponse, setShowModal } from '../../slices/modalSlice';
import { useHistory } from 'react-router-dom';
import TittleInProgress from '../UI/TittleInProgress';
import * as yup from 'yup';
import { Formik } from 'formik';
import NewPromptButtonGB from '../UI/NewPromptButtonGB';
import { GET_ACTIVITIES } from '../../queries/Activity';
import { useQuery } from '@apollo/client';
import ActivityCheckboxList from './ActivityCheckboxList';

interface Program{
  id: string
  name: string
  description: string
  status: string
  activities: any
}


const NewProgram = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data, loading } = useQuery(GET_ACTIVITIES);
  const [activities, setActivities] = useState<any[]>([]);

  useEffect(() => {
    if (!loading && data) {
      const allActivities = data.activities;
      setActivities(allActivities)
    }
  }, [data]);

  const onCancel = () => {
    history.push('/library/programs')
  }

  const schema = yup.object().shape({
    id: yup.string().required('El ID es requerido.').min(3, 'Debe contener 3 letras mínimo.'),
    name: yup.string().required('El nombre es requerido.').min(3, 'Debe contener 3 letras mínimo.'),
    description: yup.string().required('La descripción es requerida.').min(3, 'Debe contener 3 letras mínimo.'),
    status: yup.string().required('El estado es requerido.').min(3, 'Debe contener 3 letras mínimo.'),
  })

  const sendNewProgram = (data: Program): void => {
    if(!data.id || !data.description || !data.name || !data.status){
      return;
    } else{
      
      const test = `{
        id: "${data.id}",
        name: "${data.name}",
        description: "${data.description}",
        status: "${data.status}"
        activities: ${JSON.stringify(data.activities.map(act => act.id))}
        
      }`;      
      saveProgram(test)
        .then(result => {
          if (result.data.saveProgram) {
            dispatch(setResponse({
              message: 'El programa fue enviado con éxito!',
              error: false,
              description: 'Podrás consultarlo en nuestra lista de programas.'
            }));
            dispatch(setShowModal(true));
            history.push('/library/programs');
          }
        }).catch(() => {
          dispatch(setResponse({
            message: 'Ups! No se pudo cargar el programa',
            error: true,
            description: 'Por favor reintenta más tarde.'
          }));
          dispatch(setShowModal(true));
        })
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        id: '',
        name: '',
        description: '',
        status: '',
        activities: []
      }}
      onSubmit={sendNewProgram}
      validationSchema={schema}
    >
      {(formik):ReactElement => {
        return (
          <form onSubmit={formik.handleSubmit}>
            <div className="w-full ml-11 flex flex-col items-start">
              <TittleInProgress text={'Nuevo Programa'} width={'w-3/3'} />
              <div className="flex w-1/2 mt-4 justify-between">
                <div className="flex flex-col w-1/2">
                  <label className="label-update">ID</label>
                  <input
                    type="text"
                    id="id"
                    name="id"
                    className="block w-full mt-2 p-3 text-gray-700 bg-white appearance-none border rounded-lg border-gray-secundary focus:outline-none focus:bg-gray-300 focus:shadow-inner"
                    value={formik.values.id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.id &&
                  (formik.touched.id || formik.isSubmitting) && (
                    <p>{formik.errors.id}</p>
                  )}
                </div>
              </div>
              <div className="flex w-1/2 mt-4 justify-between">
                 <div className="flex flex-col w-1/2">
                  <label className="label-update">Nombre</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="block w-full mt-2 p-3 text-gray-700 bg-white appearance-none border rounded-lg border-gray-secundary focus:outline-none focus:bg-gray-300 focus:shadow-inner"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.name &&
                  (formik.touched.name || formik.isSubmitting) && (
                    <p>{formik.errors.name}</p>
                  )}
                </div>
              </div>
              <div className="flex w-1/2 mt-4 justify-between">
                <div className="flex flex-col w-1/2">
                  <label className="label-update">Descripción</label>
                  <input
                    type="text"
                    id="description"
                    name="description"
                    className="block w-full mt-2 p-3 text-gray-700 bg-white appearance-none border rounded-lg border-gray-secundary focus:outline-none focus:bg-gray-300 focus:shadow-inner"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.description &&
                  (formik.touched.description || formik.isSubmitting) && (
                    <p>{formik.errors.description}</p>
                  )}
                </div>
              </div>
              <div className="flex w-1/2 mt-4 justify-between">
                <div className="flex flex-col w-1/2">
                  <label className="label-update">Estado</label>
                  <input
                    type="text"
                    id="status"
                    name="status"
                    className="block w-full mt-2 p-3 text-gray-700 bg-white appearance-none border rounded-lg border-gray-secundary focus:outline-none focus:bg-gray-300 focus:shadow-inner"
                    value={formik.values.status}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.status &&
                  (formik.touched.status || formik.isSubmitting) && (
                    <p>{formik.errors.status}</p>
                  )}
                </div>
              </div>
              <div id="checkbox-group" className="flex w-1/2 mt-4 justify-between">
                <div role="group" aria-labelledby="checkbox-group" className="flex flex-col w-1/2">
                  <label className="label-update">Actividades</label>
                  <ActivityCheckboxList arrayList={activities} />                  
                </div>
              </div>
              <div className="flex justify-around w-1/3 h-16 mt-10">
                <NewPromptButtonGB
                  text={'Guardar'}
                  action={(): void => {}}
                  okButton={true}
                  type={'submit'}
                />
                <NewPromptButtonGB
                  text={'Cancelar'}
                  action={onCancel}
                  okButton={false}
                  type={'button'}
                />
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  )
}

/* eslint-enable */
export default NewProgram