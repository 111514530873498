import Carousel from 'react-multi-carousel';
import UAParser from 'ua-parser-js';
import 'semantic-ui-css/semantic.min.css';
import 'react-multi-carousel/lib/styles.css';
import { ReactElement } from 'react';
import { navState } from '../../slices/navSlice';
import { useSelector } from 'react-redux';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const CustomLeftArrow = ({ onClick }): ReactElement => {
  return <button className="focus:outline-none text-opacity-50 hover:text-opacity-100 hover:bg-opacity-100 font-bold text-base text-center left-5 flex justify-center items-center absolute rounded-full bg-white bg-opacity-50 border hover:border-orange-secundary w-12 h-12 text-orange-secundary border-solid" onClick={(): void => onClick()}>{'<'}</button>
};

const CustomRightArrow = ({ onClick }): ReactElement => {
  return <button className="focus:outline-none font-bold text-base text-center right-0 flex justify-center items-center absolute rounded-full bg-white border border-orange-secundary w-12 h-12 text-orange-secundary border-solid" onClick={(): void => onClick()}>
    {'>'}
  </button>
}


const Slider = ({ items }): ReactElement => {
  const { toShow } = useSelector(navState)

  return (
    <Carousel
      swipeable={false}
      draggable={false}
      responsive={responsive}
      containerClass={'container-slider min-h-44' + ( toShow == 'Inicio' ? ' w-full mr-40' : ' p-4' )}
      itemClass="item-slider"
      arrows={true}
      partialVisbile={false}
      customRightArrow={<CustomRightArrow onClick />}
      customLeftArrow={<CustomLeftArrow onClick />}
    >
      {items.map(i => (
        i
      ))}
    </Carousel>
  );
};

Slider.getInitialProps = ({ req }): any => {
  let userAgent;
  if (req) {
    userAgent = req.headers['user-agent'];
  } else {
    userAgent = navigator.userAgent;
  }
  const parser = new UAParser();
  parser.setUA(userAgent);
  const result = parser.getResult();
  const deviceType = (result.device && result.device.type) || 'desktop';
  return { deviceType };
};

export default Slider;