import { ReactElement } from 'react';

const StringTodayDate = ({predefinedDate = new Date()}): ReactElement => {

  const mapDay = (day): string => {
    let res = ''
    switch (day) {
      case 0:
        res = 'dom'
        break;
      case 1:
        res = 'lun'
        break;
      case 2:
        res = 'mar'
        break;
      case 3:
        res = 'mie'
        break;
      case 4:
        res = 'jue'
        break;
      case 5:
        res = 'vie'
        break;
      case 6:
        res = 'sab'
        break;
    }
    return res
  }

  const mapMonth = (month): string => {
    let res = ''
    switch (month) {
      case 0:
        res = 'ene'
        break;
      case 1:
        res = 'feb'
        break;
      case 2:
        res = 'mar'
        break;
      case 3:
        res = 'abr'
        break;
      case 4:
        res = 'may'
        break;
      case 5:
        res = 'jun'
        break;
      case 6:
        res = 'jul'
        break;
      case 7:
        res = 'ago'
        break;
      case 8:
        res = 'sep'
        break;
      case 9:
        res = 'oct'
        break;
      case 10:
        res = 'nov'
        break;
      case 11:
        res = 'dic'
        break;
    }
    return res
  }

  const formatDateToSpanish = ():string => {
    const date = new Date(predefinedDate)
    const dateString = `${mapDay(date.getDay())}, ${date.getDate()} de ${mapMonth(date.getMonth())} del ${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`
    return dateString;
  }

  return (
    <p className="text-xs text-gray-principal">{formatDateToSpanish()}</p>
  )
}

export default StringTodayDate