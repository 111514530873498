import { useQuery } from '@apollo/client';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { GET_MY_ORIENTEDS, GET_OTHERS_ORIENTEDS } from '../../queries/Oriented';
import { session } from '../../slices/sessionSlice';
import Card from '../UI/CardMeeting';
import Pagination from '../UI/Pagination';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Oriented } from '../../utils/OrientedInterface';
import { Link } from 'react-router-dom';
import OrientedsSearch from '../UI/OrientedsSearch';
import LoadingSpinner from '../../utils/spinner';
import FetchErrorMessage from '../UI/FetchErrorMessage';

const Orienteds = (): ReactElement => {
  const { user } = useSelector(session);
  // const { data, loading } = useQuery(ONLY_ORIENTED)
  // const orienteds: Array<Oriented> = !loading ? data.onlyOriented : []
  // const myOrienteds: Array<Oriented> = orienteds.filter(i => i.orienteer !==null && i.orienteer.id == user.id)
  // const otherOrienteds: Array<Oriented> = orienteds.filter(i => i.orienteer==null || i.orienteer.id != user.id)
  // refactor for only_oriented
  const { data: dataMyOrienteds, loading: loadingMyOrienteds, error: errorMyOrienteds } = useQuery(
    GET_MY_ORIENTEDS,
    {
      variables: { idOrienteer: user.id },
    }
  );
  const { data: dataOthersOrienteds, loading: loadingOthersOrienteds, error: errorOthersOrienteds } =
    useQuery(GET_OTHERS_ORIENTEDS, {
      variables: { idOrienteer: user.id },
    });

  const myOrienteds: Array<Oriented> = !loadingMyOrienteds && dataMyOrienteds
    ? dataMyOrienteds.getMyOrienteds
    : [];
  const otherOrienteds: Array<Oriented> = !loadingOthersOrienteds && dataMyOrienteds
    ? dataOthersOrienteds.getOthersOrienteds
    : [];

  //sort orienteds to show them by creation date
  const myOrientedsSorted: Array<Oriented> = [...myOrienteds].sort(function (
    a,
    b
  ) {
    return b.user.creationDate - a.user.creationDate;
  });
  const othersOrientedsSorted: Array<Oriented> = [...otherOrienteds].sort(
    function (a, b) {
      return b.user.creationDate - a.user.creationDate;
    }
  );

  const item = (i, index): ReactElement => (
    <div className= {'mr-4'}>
      <Card key={index} userInfo={i}>
        <Link
          to={`/oriented/${i.id}/appointments`}
          className="flex cursor-pointer justify-start underline hover:text-green-principal text-green-principal text-base"
        >
          Ver Encuentros
        </Link>
      </Card>
    </div>
  );

  return (
    <div className="ml-11 mt-4 h-full lg:max-w-5xl md:ml-11 md:mt-8">
      {loadingMyOrienteds && loadingOthersOrienteds ? (
        <LoadingSpinner />
      ) : ( 
        (!dataMyOrienteds || !dataOthersOrienteds) && (errorMyOrienteds || errorOthersOrienteds) ? (
          <div>
            <FetchErrorMessage error={errorMyOrienteds}></FetchErrorMessage>
          </div>
        ) : (
          <Tabs
            className={'w-full flex flex-wrap items-center justify-between'}
            selectedTabClassName={
              'text-gray-800 border-b-4 border-green-secundary'
            }
          >
            <TabList className={'flex mb-0 list-none ml-1.5 '}>
              <Tab
                className={
                  'text-gray-400 border-b-4 border-gray-200 px-14 pt-1 cursor-pointer text-base hover:text-black-primary  capitalize py-4 block leading-normal '
                }
              >
                Mis orientados
              </Tab>
              <Tab
                className={
                  'text-gray-400 border-b-4 border-gray-200 px-14 pt-1 cursor-pointer text-base hover:text-black-primary  capitalize py-4 block leading-normal '
                }
              >
                Otros orientados
              </Tab>
            </TabList>
            <TabPanel className={'w-full flex items-center justify-between'}>
              <OrientedsSearch
                componentToShow={(data): ReactElement => (
                  <Pagination
                    data={data}
                    itemData={item}
                    table={null}
                    perPage={9}
                  />
                )}
                oriented={myOrientedsSorted}
              />
            </TabPanel>
            <TabPanel className={'w-full flex items-center justify-between'}>
              <OrientedsSearch
                componentToShow={(data): ReactElement => (
                  <Pagination
                    data={data}
                    itemData={item}
                    table={null}
                    perPage={9}
                  />
                )}
                oriented={othersOrientedsSorted}
              />
            </TabPanel>
          </Tabs>)
      )}
    </div>
  );
};

export default Orienteds;
