/* eslint-disable */

import { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  getLoginRedirectUri,
  getToken,
  googleBrokerEndpoint,
  googleBrokerEndpointGET,
  googleBrokerEndpointProps,
  TokenRequest,
} from '../../utils/authGoogle';
import LoadingSpinner from '../../utils/spinner';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loginState } from '../../slices/sessionSlice';
import { setShowModal } from '../../slices/modalSlice';
import { getNews } from '../../utils/strapiFunctions';
import { setNews } from '../../slices/initialStrapiData';

var jwt = require('jsonwebtoken');

const LoginRedirect = (): ReactElement => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const [tokenRequest, setTokenRequest] = useState<TokenRequest>();
  // const { state, code, scope, prompt } = searchParams
  const [tokenLoading, setTokenLoading] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory()    

  const setCredentials = (credential) => {
    const role = 'orienteer'
    localStorage.setItem('token', credential.access_token)
    localStorage.setItem('username', "username test")
    localStorage.setItem('role', role)
    
    
    const decodeToken =  jwt.decode(credential.access_token);
    console.log("decodeToken: ", decodeToken)
    var { exp, sub, resource_access, email } = decodeToken

    const adminCli = resource_access['admin-cli'];
    if(adminCli && adminCli?.roles){
      console.log("adminCli var: ", adminCli)
      console.log("roles var: ", adminCli.roles)
      if(adminCli.roles.includes('admin')) console.log('roles admin ok')
      if(adminCli.roles.includes('orienteer')) console.log('roles orienteer ok')
    }

    localStorage.setItem('exp', exp)
    localStorage.setItem('idkc', sub)
    localStorage.setItem('email', email)

    // save info LoginState session in redux
    dispatch(loginState(true))
  
    //set modal off if login failed before
    dispatch(setShowModal(false))
    
    //save news
    getNews().then(res => dispatch(setNews(res)))
    //redirect
    history.push('/google/api-access')  
  }


  useEffect(() => {
    const code = searchParams.get('code');
    // const state = searchParams.get('state');
    // const scope = searchParams.get('scope');
    // const prompt = searchParams.get('prompt');
    // const hd = searchParams.get('hd');
    // const authuser = searchParams.get('authuser');


    console.log('searchParams', searchParams);
   
    
    const redirect_uri = getLoginRedirectUri()

    const tokenRequest: TokenRequest = {
      code: code ? code : '',
      redirect_uri: redirect_uri,
      client_id: `${process.env.REACT_APP_KEYCLOAK_GOOGLE_TOKEN_CLIENT_ID}`,
      client_secret: `${process.env.REACT_APP_KEYCLOAK_GOOGLE_TOKEN_CLIENT_SECRET}`,
      grant_type: 'authorization_code',
    };
    setTokenRequest(tokenRequest);
    getToken(tokenRequest as TokenRequest).then((result) => {
      console.log("getToken Result", result)
      if(result.error){
        throw new Error('Get Token error'+ ' - ' + result.error + ' - ' + result.error_description)
      }
      setCredentials(result)
      setTokenLoading(false)

    }).catch((error) => {
      console.error("getToken error")    
      console.error(error)
    })

  }, []);

  useEffect(() => {
    console.log("tokenLoading: ", tokenLoading)
  }, [tokenLoading])

  return ( <div className="h-full w-full mx-12">
    { tokenLoading ? <LoadingSpinner /> : "Ingreso correcto" }    
  </div>
  )
 
};

/* eslint-enable */
export default LoginRedirect;
