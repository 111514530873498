import { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import NewLayout from '../../components/Layout/NewLayout';
import AppointmentsOriented from '../../components/Orientados/AppointmentsOriented';
import { setCurrentSection } from '../../slices/navSlice';
import { useParams } from 'react-router-dom';
import { ORIENTED_BY_ID } from '../../queries/Oriented/index';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../../utils/spinner';
import { APPOINTMENTS_BY_ORIENTED } from '../../queries/Appointment';
import BreadCrumb from '../../components/UI/BreadCrumb';
import BreadCrumbItem from '../../components/UI/BreadCrumbItem';

const OrientedAppointmentPage =(): ReactElement => {
  const dispatch = useDispatch();
  const { orientedId } = useParams();
  
  useEffect(()=>{
    dispatch(setCurrentSection('Orientados'))
  })

  const appointments = useQuery(APPOINTMENTS_BY_ORIENTED, {
    variables: { id: orientedId },
  })
  
  const {data, loading} = useQuery(ORIENTED_BY_ID, {
    variables: { id: orientedId }
  })

  const nameOriented = loading ? '...' : data.orientedById.user.name


  return (
    <NewLayout>
      <div>
        <div className="w-full ml-11">
          <BreadCrumb>
            <BreadCrumbItem Name={'Orientados'} path={'/oriented'} isCurrentPage={false}/>
            <BreadCrumbItem Name={nameOriented} isCurrentPage={true}/>
          </BreadCrumb> 
        </div>
        {loading && appointments ? <LoadingSpinner /> : <AppointmentsOriented oriented={data.orientedById} querydata={appointments} />}
      </div>
    </NewLayout>            
  )

}

export default OrientedAppointmentPage;