export interface Form {
  id: number,
  Slug: string,
  submit_text: string,
  Title: string,
  published_at: string,
  created_at: string,
  updated_at: string,
  Fields: [
    {
      id: number,
      field_name: string,
      field_type: string,
      field_label: string,
      field_placeholder: string,
      required: boolean,
      field_options: string,
      field_details: string
    }
  ]
}

export const initialForm = {
  id: 0,
  Slug: '',
  submit_text: '',
  Title: '',
  published_at: '',
  created_at: '',
  updated_at: '',
  Fields: [
    {
      id: 0,
      field_name: '',
      field_type: '',
      field_label: '',
      field_placeholder: '',
      required: false,
      field_options: '',
      field_details: ''
    }
  ]
}