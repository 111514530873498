import { ReactElement, useEffect } from 'react';
import OrientedsSearch from '../UI/OrientedsSearch';
import OrientedCard from '../Asignaciones/OrientedCard';
import Pagination from '../UI/Pagination';
import TittleInProgress from '../UI/TittleInProgress';
import Button from '../UI/Button';
import { useQuery } from '@apollo/client';
import { ONLY_ENROLLED, ONLY_ENROLLED_WITHOUT_PAY, ONLY_INTERESTED } from '../../queries/Oriented';
import { useHistory } from 'react-router-dom';

const Ingresos = (): ReactElement => {
  const enrolled = useQuery(ONLY_ENROLLED)
  const inactive = useQuery(ONLY_INTERESTED)
  const inactiveWithPlan = useQuery(ONLY_ENROLLED_WITHOUT_PAY)
  const incomings = !enrolled.loading && !inactive.loading && !inactiveWithPlan.loading ? [...enrolled.data.onlyEnrolled, ...inactive.data.onlyInterested, ...inactiveWithPlan.data.onlyEnrolledWithoutPaying] : []
  const history = useHistory();

  useEffect(() => {
    inactive.refetch()
    inactiveWithPlan.refetch()
    enrolled.refetch()
  }, [])

  const handleShowForm = () => {
    history.push('/admissions/form')
  }

  const goToAssignedOriented = (oriented) => {
    if(!oriented.id){
      console.error('Oriented not found.')
      return
    }
    history.push(`/admissions/${oriented.id}`);
  }

  const item = (i, index): ReactElement => {
    return (
      <OrientedCard userInfo={i} key={index} select={goToAssignedOriented} />
    )
  }

  return (
    <div className='h-auto lg:max-w-5xl md:ml-11 mb-20'>
      <div className='grid grid-cols-2'>
        <div className="pb-1">
          <TittleInProgress text={'Nuevos usuarios a orientar'} width="w-21" />
        </div>
        <div className='flex flex-wrap self-end flex-row-reverse rounded-lg -mr-4'>
          <Button text="Ingresar orientado" action={handleShowForm} styles={'w-fit mr-8'}/>
        </div>
      </div>
      <div className='lg:max-w-5xl'>
        <OrientedsSearch
          oriented={incomings}
          componentToShow={(data): ReactElement => <Pagination data={data} itemData={item} table={null} perPage={9} />}
        />
      </div>
    </div>
  )
}

export default Ingresos;