import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { session } from '../../slices/sessionSlice';
import Table from '../UI/Table';
import { getTime } from '../../utils/utilsDate';
import TittleInProgress from '../UI/TittleInProgress';
import { useQuery } from '@apollo/client';
import { ALL_APPOINTMENTS } from '../../queries/Appointment';
import { format } from 'date-fns';
import LoadingSpinner from '../../utils/spinner';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const AgendaAdmin = (): ReactElement => {
  const [confirmedAppoitment, setConfirmedAppoitment] =
    useState<null | Array<any>>(null);
  const { user } = useSelector(session);
  const history = useHistory();
  const timeZone = useSelector((state: any) => state.session.timezone);

  const orderColummns: Array<{
    id: string;
    desc: boolean;
  }> = [
    {
      id: 'date',
      desc: true,
    },
  ];

  let refetchData;

  const allEventsForAdmin = useQuery(ALL_APPOINTMENTS);
  const { data, loading, refetch } = allEventsForAdmin;
  refetchData = refetch;
  useEffect(() => {
    if (!loading) {
      const appointments = data.events;
      const formatterConfirmed = appointments.map((appointment) => {
        let userArr: any = [];
        if (appointment.confirmedOrienteers) {
          for (var i = 0; i < appointment.confirmedOrienteers.length; i++) {
            userArr.push(appointment.confirmedOrienteers[i].user);
          }
        }
        return {
          date: appointment.date,
          time: getTime(appointment.date, timeZone),
          name: appointment.name,
          id: appointment.id,
          detail: appointment.detail,
          status: appointment.status,
          orienteer: user.user.name,
          timeSpan: appointment.timeSpan,
          orienteers: userArr.map((item) => item.name).join(', '),
          orientedsDropdown: appointment.orienteds,
          orienteersDropdown: userArr,
          program:appointment.program.name
        };
      });
      setConfirmedAppoitment(formatterConfirmed);
    }
  }, [data]);

  useEffect(() => {
    refetchData();
  }, []);

  const columnsAdmin = React.useMemo(
    () => [
      {
        Header: 'Fecha',
        accessor: 'date',
        Cell: ({ value }): string => {
          return format(new Date(value), 'dd/MM/yyyy');
        },
      },
      {
        Header: 'Horario',
        accessor: 'time',
      },
      {
        Header: 'Evento',
        accessor: 'name',
        disableSortBy: true,
      },
      {
        Header: 'Orientadores',
        accessor: 'orienteers',
        disableSortBy: true,
      },
      {
        Header: 'Programa',
        accessor: 'program'
      },
      {
        Header: 'Estado',
        accessor: 'status'
      }
    ],
    []
  );

  const mapState = (appointment): ReactElement => {
    let stateToShow: ReactElement = <div />;

    switch (appointment.status) {
      case 'OPEN' || 'ACTIVE':
        stateToShow = (
          <div className="flex items-center">
            <div className="w-auto rounded-sm flex items-center h-auto text-black-primary text-xs p-1 bg-green-success">
              Agendado
            </div>
          </div>
        );
        break;
      case 'PAUSED' || 'SUSPENDED':
        stateToShow = (
          <div className="w-auto rounded-sm h-auto text-black-primary text-xs p-1 bg-yellow-info">
            A definir
          </div>
        );
        break;
      case 'FINISHED':
        stateToShow = (
          <div className="flex items-center">
            <div className="w-auto rounded-sm flex items-center h-auto text-black-primary text-xs p-1 bg-gray-secundary">
              Terminado
            </div>
          </div>
        );
        break;
    }
    return stateToShow;
  };

  const handleView = (row) =>{
    history.push(`/agenda/admin/appointment/view/${row.id}`)
  }

  // replaced use of <Pagination/> component with <Table/> to avoid pagination bug in tables
  return (
    <div className="h-full w-full md:ml-11 mb-20">
      {
        <div>
          {
            <div className="w-11/12 grid grid-cols-1">
              <div className="flex justify-between w-full">
                <TittleInProgress text={'Últimos eventos'} width={'w-52'} />
                <div className="w-74 mt-11">
                  <Link
                    to={'/agenda/appointment/new'}
                    className=" py-2 px-6 focus:outline-none bg-green-principal text-white rounded-full h-10 font-semibold"
                  >
                    Agendar evento
                  </Link>
                </div>
              </div>
              {confirmedAppoitment != null ? (
                <Table
                  columns={columnsAdmin}
                  data={confirmedAppoitment}
                  initialRows={10}
                  state={mapState}
                  orderColumnArray={orderColummns}
                  viewRow={handleView}
                />
              ) : (
                <LoadingSpinner />
              )}
            </div>
          }
        </div>
      }
    </div>
  );
};

export default AgendaAdmin;
