import { ApolloQueryResult, gql } from '@apollo/client';
import { client } from '../../client-apollo';

export const isReaded = gql`
query Messages($id: String!){
  messages(userId: $id){
    id
    receivers{
      read
    }
  }
}
`


export const MESSAGES_BY_USER = gql`
query Messages($id: String!){
  messages(userId: $id){
    id
    subject
    sentAt
    receivers{
      read
      to{
        id
      }
    }
    previousMessage{
      from{
        name
      }
      body
      previousMessage{
        from{
          name
        }
        body
        previousMessage{
          from{
            name
          }
          body
          previousMessage{
            from{
              name
            }
            body
            previousMessage{
              from{
                name
              }
              body
              previousMessage{
                from{
                  name
                }
                body
                previousMessage{
                  from{
                    name
                  }
                  body
                  previousMessage{
                    from{
                      name
                    }
                    body
                    previousMessage{
                      from{
                        name
                      }
                      body
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    body
    from{
      id
      name
      email
    }

  }
}
`

export const MESSAGES_SENT_BY_USER = gql`
query messagesSentByUser($id: String!){
  messagesSentByUser(id: $id){
    id
    subject
    sentAt
    receivers{
      read
      to{
        id
      }
    }
    previousMessage{
      from{
        name
      }
      body
      previousMessage{
        from{
          name
        }
        body
        previousMessage{
          from{
            name
          }
          body
          previousMessage{
            from{
              name
            }
            body
            previousMessage{
              from{
                name
              }
              body
            }
          }
        }
      }
    }
    body
    from{
      id
      name
      email
    }
  }
}
`

export const messagesByuser = (id): Promise<ApolloQueryResult<any>> => {
  return client
    .query({
      query: gql`
      query {
        messages(userId: "${id}"){
          id
          subject
          sentAt
          receivers{
            read
            to{
              id
            }
          }
          previousMessage{
            from{
              name
            }
            body
            previousMessage{
              from{
                name
              }
              body
              previousMessage{
                from{
                  name
                }
                body
                previousMessage{
                  from{
                    name
                  }
                  body
                  previousMessage{
                    from{
                      name
                    }
                    body
                  }
                }
              }
            }
          }
          body
          from{
            id
            name
            email
          }
      
        }
      }
    `
    });
};


