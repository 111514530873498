export const parseJSON = (resp): any => (resp.json ? resp.json() : resp);

export const checkStatus = (resp): any => {
  if (resp.status >= 200 && resp.status < 300) {
    return resp;
  }
  return parseJSON(resp).then(resp => {
    throw resp;
  });
};
