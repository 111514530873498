import { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import NewLayout from '../../components/Layout/NewLayout';
import SendMessage from '../../components/Mensajes/NewMessage';
import { setCurrentSection } from '../../slices/navSlice';

const NewMessagePage =(): ReactElement => {

  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(setCurrentSection('Mensajes'))
  })

  return (
    <NewLayout>
      <SendMessage />
    </NewLayout>            
  )

}

export default NewMessagePage;