import { ReactElement, useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import Button from '../UI/Button';
import { useDispatch } from 'react-redux';
import {
  saveOrientedKc,
  updateUserPassword
} from '../../queries';
import avatar from '../../assets/images/Vector .png';
import { setResponse, setShowModal } from '../../slices/modalSlice';
import { updateOriented } from '../../mutations/Oriented';
import { orientedPost } from '../../mutations/Oriented'
import { orientedById } from '../../queries/Oriented';
import { uploadImage } from '../../utils/uploadFiles';
import ImageAvatar from '../UI/ImageAvatar';
import PaymentForm from './PaymentForm';
import { useHistory } from 'react-router-dom';

type UserData = {
  data: any
}

const IngresoForm = (props: UserData): ReactElement => {
  const dispatch = useDispatch();
  const userInfo = props.data;
  const newAdmission = userInfo ? false : true;
  const isNewOriented = userInfo == null || !userInfo.idKeycloak;
  const [newUser, setNewUser] = useState({ dni: '', id: '' });
  const [picture, setPicture] = useState<any>({});
  const [currentPicture, setCurrentPicture] = useState<any>();
  const regularExpression = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,}$/
  const [orientedDni, setOrientedDni] = useState<any>('');
  const history = useHistory();


  const goBackPrincipal = ():void => {
    history.push('/admissions')
  }

  const uploadPicture = ( event ):void => {

    setPicture({
      pictureAsFile: event.target.files[0]
    });

    setCurrentPicture({
      picturePreview: URL.createObjectURL(event.target.files[0]),
    });

  };

  return (
    <div className='min-h-screen my-8'>
      <div className='container'>
        <div className='inputs w-full max-w-3xl'>
          <div className='grid grid-cols-2'>
            <h2 className='title-bold text-left'>01. Información básica</h2>
          </div>
          <div>
            <Formik
              initialValues={{
                dni: '',
                school: '',
                expectations: '',
                address: '',
                age: '',
                profilePicture: picture.pictureAsFile,
                email: '',
                forename: '',
                surname: '',
                phone: '',
              }}
              onSubmit={async (values):Promise<void> => {
                if (newAdmission) {
                  orientedPost(values).then((result) => {
                    if (result.data.createOriented) {
                      const dataNewUser = result.data.createOriented
                      setNewUser({ dni: dataNewUser.dni, id: dataNewUser.id })
                      const formData = new FormData();
                      formData.append('userId', dataNewUser.user.id);
                      formData.append('profilePicture', picture.pictureAsFile);

                      uploadImage(formData)

                      dispatch(setResponse({
                        message: 'El aplicante ya fue creado.',
                        error: false,
                        description: 'Recibira una notificacion para que se contacte con administrador'
                      }));
                      dispatch(setShowModal(true));
                    }
                  }).catch(() => {
                    dispatch(setResponse({
                      message: 'El aplicante ya fue creado.',
                      error: false,
                      description: 'Recibira una notificacion para que se contacte con administrador'
                    }));
                    dispatch(setShowModal(true));
                  });
                } else {
                  const { forename, surname, email, phone, school, address, expectations, age } = values;
                  updateOriented(userInfo.id, `{
                school:"${school}",
                age:"${age}",
                address:"${address}",
                expectations:"${expectations}"}`, `{
                email: "${email}",
                name: "${forename} ${surname}",
                forename: "${forename}",
                surname: "${surname}",
                phone: "${phone}"}`).then((result) => {
                    if (result.data.updateOriented) {
                      dispatch(setResponse({
                        message: 'El aplicante ya fue actualizado.',
                        error: false,
                        description: 'Recibira una notificacion para que se contacte con administrador'
                      }));
                      dispatch(setShowModal(true));
                    }
                  });
                }
              }} validationSchema={yup.object().shape({
                email: yup.string().email('Email invalido').required('Requerido'),
                forename: yup.string().min(3, 'Muy corto!').required('Requerido'),
                surname: yup.string().min(3, 'Muy corto!').required('Requerido'),
                dni: yup.number().min(7, 'Debe ser 7 digitos').required('Requerido'),
                phone: yup.number().required('Requerido')
              })}>
              {({ values, handleChange, handleSubmit, errors, touched, setFieldValue }):ReactElement => {
                useEffect(():void => {
                  {
                    !newAdmission &&
                      orientedById(userInfo.id).then(user => {
                        const {
                          user: { forename, surname, email, phone },
                          school,
                          age,
                          dni,
                          expectations,
                          address
                        } = user.data.orientedById;
                        setFieldValue('forename', forename);
                        setFieldValue('surname', surname);
                        setFieldValue('email', email);
                        setFieldValue('phone', phone);
                        setFieldValue('dni', dni);
                        setFieldValue('school', school);
                        setFieldValue('age', age);
                        setFieldValue('expectations', expectations);
                        setFieldValue('address', address);
                        setOrientedDni( dni);
                      });
                  }
                }, []);
                return (


                  <form onSubmit={handleSubmit}>
                    <div className='flex items-center justify-between mt-4'>
                      <div className='pt-12 rounded-full text-center w-46 h-40'>
                        <div className='text-center' />
                        Foto
                        <label className='cursor-pointer mt-6'>
                          {!currentPicture ? (
                            <ImageAvatar width={'w-32 h-32'} src={avatar} text={'your avatar'} size={false} />) : (
                            <ImageAvatar width={'w-32 h-32'} src={currentPicture.picturePreview} text={'your avatar'} size={false} />)}
                          <input type='file' name='image' className='hidden' value={values.profilePicture} onChange={uploadPicture} />
                        </label>
                      </div>
                      <div className='w-full md:w-1/2 ml-4 mb-6'>
                        <label className='label-form'>Nombre</label>
                        <input
                          className='input-form input-back block w-full'
                          type='text'
                          value={values.forename}
                          name='forename'
                          onChange={handleChange}
                          placeholder="Ingresar nombre"
                        />
                        {errors.forename && touched.forename && (
                          <p className='text-red-800 text-left'>{errors.forename}</p>
                        )}
                      </div>
                      <div className='w-full md:w-1/2 ml-3 mb-6'>
                        <label className='label-form'>Apellido</label>
                        <input
                          className='w-full input-form input-back'
                          type='text'
                          value={values.surname}
                          id='surname'
                          onChange={handleChange}
                          placeholder="Ingresar apellido"
                        />
                        {errors.surname && touched.surname && (
                          <p className='text-red-800 text-left'>{errors.surname}</p>
                        )}
                      </div>
                    </div>
                    <div className='flex items-center justify-between mb-4'>
                      <div className='w-full md:w-1/2 ml-input mb-6'>
                        <label className='label-form'>Mail</label>
                        <input
                          className='input-form input-back w-full'
                          type='email'
                          id='email'
                          value={values.email}
                          onChange={handleChange}
                          placeholder="Ingresar mail"
                        />
                        {errors.email && touched.email && (
                          <p className='text-red-800 text-left'>{errors.email}</p>
                        )}
                      </div>
                      <div className='w-full md:w-1/2 ml-3 mb-6'>
                        <label className='label-form'>Teléfono</label>
                        <input
                          className='w-full input-form input-back'
                          type='text'
                          value={values.phone}
                          id='phone'
                          onChange={handleChange}
                          placeholder="Ingresar teléfono"
                        />
                        {errors.phone && touched.phone && (
                          <p className='text-red-800 text-left'>{errors.phone}</p>
                        )}
                      </div>
                    </div>
                    <div className='flex flex-wrap mb-6'>
                      <div className='personal w-full pt-4'>
                        <h2 className='title-bold text-left'>02. Datos personales</h2>
                        <div className='flex items-center justify-between mt-4'>
                          <div className='w-full md:w-1/2 mb-6'>
                            <label className='label-form'>Numero de
                              DNI</label>
                            <input
                              className='input-form input-back w-full'
                              value={values.dni}
                              id='dni'
                              onChange={handleChange}
                              placeholder="Ingresar DNI"
                            />
                            {errors.dni && touched.dni && (
                              <p className='text-red-800 text-left'>{errors.dni}</p>
                            )}
                          </div>
                          <div className='w-full md:w-1/2 ml-3 mb-6'>
                            <label className='label-form'>Edad</label>
                            <input
                              className='input-form input-back w-full'
                              type='text'
                              value={values.age}
                              id='age'
                              onChange={handleChange}
                              placeholder="Ingresar edad"
                            />
                          </div>
                        </div>
                        <div className='flex items-center justify-between mt-4'>
                          <div className='w-full md:w-1/2 mb-6'>
                            <label
                              className='label-form'>Colegio</label>
                            <input
                              className='input-form input-back w-full'
                              type='text'
                              value={values.school}
                              id='school'
                              onChange={handleChange}
                              placeholder="Ingresar colegio"
                            />
                          </div>
                          <div className='w-full md:w-1/2 ml-3 mb-6'>
                            <label
                              className='label-form'>Domicilio</label>
                            <input
                              className='input-form input-back w-full'
                              type='text'
                              value={values.address}
                              id='address'
                              onChange={handleChange}
                              placeholder="Ingresar domicilio"
                            />
                            {errors.address && touched.address && (
                              <p>{errors.address}</p>
                            )}
                          </div>
                        </div>
                        <div className='w-full md:w-full mb-6'>
                          <label className='label-form'>¿Por qué se acercó a D’Alfonso?</label>
                          <textarea
                            value={values.expectations}
                            onChange={handleChange}
                            id='expectations'
                            className='rounded-lg text-black-primary input-back border leading-normal resize-none w-full h-40 py-2 px-3 border border-gray-secundary focus:outline-none'
                            placeholder="Escribe un comentario"
                          />
                        </div>
                        <div className='flex justify-start'>
                          <Button text="Guardar Datos" action={():void => { }} />
                        </div>
                      </div>
                    </div>
                  </form>);
              }}
            </Formik>

            <h2 className='title-bold text-left md:mt-10'>03.Crear usuario y contraseña</h2>
            <Formik
              enableReinitialize
              initialValues={{
                password: '',
                username: newAdmission ? newUser.dni : orientedDni,
                passwordConfirmation: ''
              }}
              onSubmit={async (values):Promise<void> => {
                if (isNewOriented) {
                  saveOrientedKc(values, newAdmission ? newUser.id : userInfo.id).then((result) => {
                    if (result.data.saveOrientedKc) {
                      dispatch(setResponse({
                        message: 'El orientado ya fue creado.',
                        error: false,
                        description: 'Recibira una notificacion para que se contacte con administrador'
                      }));
                      dispatch(setShowModal(true));
                    }
                  }).catch(() => {
                    dispatch(setResponse({
                      message: 'Usuario ya existente',
                      error: true,
                      description: 'Vuelva a intentarlo con otro usuario'
                    }));
                    dispatch(setShowModal(true));
                  })
                } else {
                  const { password } = values;
                  updateUserPassword(password, userInfo.idKeycloak).then((result) => {
                    if (result.data.updateUserPassword) {
                      dispatch(setResponse({
                        message: 'La contraseña ya fue actualizada.',
                        error: false,
                        description: 'Recibira una notificacion para que se contacte con administrador'
                      }));
                      dispatch(setShowModal(true));
                    }
                  });
                }

              }} validationSchema={yup.object().shape({
                username: yup.string().required('Requerido'),
                password: yup.string().required('Contraseña requerida').test({
                  message: 'La contraseña debe tener al menos 8 caracteres, un número, una minúscula y una mayúscula*',
                  test: (value) => value ? regularExpression.test(value) : true
                }),
                passwordConfirmation: yup.string()
                  .oneOf([yup.ref('password'), null], 'Contraseñas no coinciden')
              })}>
              {({ values, handleChange, handleSubmit, errors, touched }):ReactElement => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className='flex items-center justify-between mt-4'>
                      <div className='w-full md:w-1/2'>
                        <label
                          className='label-form'>Usuario</label>
                        <input
                          className='input-form input-back w-full'
                          type='text'
                          value={values.username}
                          id='username'
                          onChange={handleChange}
                          placeholder="Ingresar usuario"
                        />
                        {errors.username && touched.username && (
                          <p className="text-xss text-error">{errors.username}</p>
                        )}
                      </div>
                    </div>
                    <div className='flex items-center justify-between mt-4'>
                      <div className='w-full md:w-1/2'>
                        <label className='label-form'>Nueva contraseña</label>
                        <input
                          className='input-form input-back w-full'
                          type='password'
                          value={values.password}
                          id='password'
                          onChange={handleChange}
                          placeholder="Ingresar nueva contraseña"
                        />
                        {errors.password && touched.password && (
                          <p className="text-xss text-error">{errors.password}</p>
                        )}
                      </div>
                    </div>
                    <div className='flex items-center justify-between mt-4 mb-10'>
                      <div className='w-full md:w-1/2'>
                        <label className='label-form'>Repetir contraseña</label>
                        <input
                          className='input-form input-back w-full'
                          type='password'
                          value={values.passwordConfirmation}
                          id='passwordConfirmation'
                          onChange={handleChange}
                          placeholder="Repetir contraseña"
                        />
                        {errors.passwordConfirmation && touched.passwordConfirmation && (
                          <p className="text-xss text-error">{errors.passwordConfirmation}</p>
                        )}
                      </div>
                    </div>
                    <div className='flex justify-start items-center'>
                      <Button text={isNewOriented ? 'Ingresar Orientado' : 'Modificar Orientado'} action={():void => { }} disabled={newAdmission && newUser.id == ''} />
                      <a href='#' onClick={goBackPrincipal} className='cancel-link ml-4 flex-shrink-0'>Cancelar Ingreso</a>
                    </div>
                  </form>
                );
              }}
            </Formik>
            <h2 className='title-bold text-left md:mt-10'>04.Plan de pagos</h2>
            <PaymentForm userId={newAdmission ? newUser.id : userInfo.id}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IngresoForm;