import { ReactElement, useEffect } from 'react';
import NewLayout from '../../components/Layout/NewLayout';
import Help from '../../components/Ayuda/Help';
import { setCurrentSection } from '../../slices/navSlice';
import { useDispatch } from 'react-redux';

const HelpPage =(): ReactElement => {

  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(setCurrentSection('Ayuda'))
  })

  return (
    <NewLayout>
      <Help />
    </NewLayout>            
  )

}

export default HelpPage;