import { ReactElement } from 'react';

const ImageAvatar = ({ size, src, text, width = 'w-56 h-56' }): ReactElement => {
  return (
    <>
      {size ? (
        <img
          src={src}
          alt={text}
          className="md:h-16 md:w-16 h-8 w-8 rounded-full border-2  object-cover"
        />) : (
        <img
          src={src}
          alt={text}
          className={`imageCard ${width}`}
        />
      )}
    </>

  )
}

export default ImageAvatar