import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { session } from '../../slices/sessionSlice';
import LoadingSpinner from '../../utils/spinner';
import { getDate } from '../../utils/utilsDate';
import Table from '../UI/Table';
import TittleInProgress from '../UI/TittleInProgress';
import { mapState } from './MapStateUtil';

const OrientedPaymentsView = (): ReactElement => {
  const { user } = useSelector(session);
  const [payment, setpayment] = useState<any[]>([]);

  useEffect(() => {
    const cuotasArray = user.paymentPlans[0].quotas;
    const length = cuotasArray.length;
    const formatter = cuotasArray.map((quotas) => {
      return {
        cuota: quotas.number + ' de ' + length,
        date: getDate(quotas.dueDate),
        name: quotas.paymentPlan.programSlug,
        ...quotas,
      };
    });
    setpayment(formatter);
  }, []);

  const columns = [
    {
      Header: 'Programa',
      accessor: 'name',
    },
    {
      Header: 'Fecha',
      accessor: 'date',
    },
    {
      Header: 'Cuota',
      accessor: 'cuota',
    },
    {
      Header: 'Monto pagado',
      accessor: 'value',
    },
    {
      Header: 'Estado',
      accessor: 'status',
    },
  ];

  return (
    <div className="w-full flex flex-col">
      <div className="flex justify-between w-5/6">
        <TittleInProgress text={'Detalle de pagos'} width={'w-auto'} />
      </div>
      {payment.length == 0 ? (
        <LoadingSpinner />
      ) : (
        <div className='w-5/6'>
          <Table columns={columns} data={payment} state={mapState} initialRows={10} />
        </div>
      )}
    </div>
  );
};

export default OrientedPaymentsView;
