import { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import NewLayout from '../../components/Layout/NewLayout';
import Profile from '../../components/Perfil/Profile';
import { setCurrentSection } from '../../slices/navSlice';

const ProfilePage = (): ReactElement => {
  const dispatch= useDispatch()
  
  useEffect(() => {
    dispatch(setCurrentSection('Inicio'));
  });
    
  return(
    <NewLayout>
      <div className="w-full md:ml-11">
        <Profile/>
      </div>
    </NewLayout>
  )
}

export default ProfilePage;