import { ReactElement } from 'react';

const TittleInProgress = ({ width, text }): ReactElement => {
  return (
    <div className={`h-8 ${width} md:mt-12 flex items-center pl-1 justify-start `}>
      <h1 className="md:text-lg text-black-primary background-progress pr-1">{text}</h1>
    </div>
  )
}

export default TittleInProgress