import { Multiselect } from 'multiselect-react-dropdown';
import React, { useState } from 'react';
import { ReactElement, useMemo } from 'react';

interface OrientedSelectProps {
  submitProgram: any;
  orienteds: any;
  optionsOriented: any;
  setsubmitOriented: any;
  setGeneralSubmit: any;
  selectedValues?: any;
}

const OrientedSelect = (props: OrientedSelectProps): ReactElement => {

  const [optionOrientedsFiltered, setOptionOrientedsFiltered] = useState<any>([]);
  const multiRef = React.createRef();

  const orientedsFilterByProgram = (optionsOriented, submitProgram) => {
    return optionsOriented.filter((option)=>{return option.programs.includes(submitProgram)})   
  }

  const allOrienteds = useMemo(
    () => orientedsFilterByProgram(props.optionsOriented, props.submitProgram),
    [props.orienteds, props.submitProgram]
  );  

  const search = (text) => {        
    if(text.length > 1){
      const aux = allOrienteds.filter((option)=>{return option.label.toLowerCase().includes(text.toLowerCase())})  
      setOptionOrientedsFiltered(aux)
    } else{
      setOptionOrientedsFiltered([])
    }
  }

  return (
    <Multiselect
      displayValue={'label'}
      showCheckbox={true}
      showArrow={true}
      loading={props.optionsOriented.length !== 0 ? false : true}
      loadingMessage={'cargando...'}
      emptyRecordMsg={'No tenemos resultados para esta búsqueda'}
      options={optionOrientedsFiltered}
      placeholder={'Seleccionar orientado'}
      onSearch={search}
      onSelect={(options): void => {
        props.setsubmitOriented(options);
        props.setGeneralSubmit(false);
      }}
      ref={multiRef}
      id="orienteds"
      selectedValues={props.selectedValues}
      style={{
        searchBox: {
          border: '1px solid #DADEEB',
          padding: '8px 0px 8px 12px',
        },
        chips: {
          background: '#F4F7FB',
          color: '#0BA4A0',
        },
        option: {
          background: '#FFFFFF',
          color: '#0BA4A0',
        },
      }}
    />
  );
};

export default OrientedSelect;
