import { useQuery } from '@apollo/client';
import { ReactElement, useEffect, useState } from 'react';
import { GET_FEEDBACK_ORIENTED } from '../../../../queries';
import Table from '../../../UI/Table';

const FinalMaterialView = ({ userId, appointmentId }): ReactElement => {
  const [filesToRender, setFilesRender] = useState<Array<any>>([])
  const { data, loading } = useQuery(GET_FEEDBACK_ORIENTED, {
    variables: { id: userId, available: true, appointmentId: appointmentId }
  })

  useEffect(() => {
    if (!loading && data) {
      setFilesRender(data.findFeedbackFiles.map(i => {
        return ({
          ...i,
          type: 'PDF'
        })
      }))
    }
  }, [data])

  const columns = [
    {
      Header: 'Nombre',
      accessor: 'name'
    },
    {
      Header: 'Formato',
      accessor: 'type',
      disableSortBy: true
    }
  ]

  return (
    <div className="w-full mt-2">
      {!loading && 
        <div className="w-1/2 flex flex-col items-start">
          <p className="text-black-primary mt-2 text-base font-medium mb-4">Documentos de devolución</p>
          <Table columns={columns} data={filesToRender} download={true}/>
        </div>
      }
    </div>
  )
}

export default FinalMaterialView