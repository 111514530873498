import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ProgramsContainer from '../../components/Biblioteca/ProgramsContainer';
import TableStrapiData from '../../components/Biblioteca/TableStrapiData';
import NewLayout from '../../components/Layout/NewLayout';
import TittleInProgress from '../../components/UI/TittleInProgress';
import { setCurrentSection } from '../../slices/navSlice';
import { getPrograms } from '../../utils/strapiFunctions';
import BreadCrumb from '../../components/UI/BreadCrumb';
import BreadCrumbItem from '../../components/UI/BreadCrumbItem';

const ProgramsPage = (): ReactElement => {
  const [open, setOpen] = useState(false);
  const [strapiData, setStrapiData] = useState<any>([]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentSection('Biblioteca'));
  });

  useEffect(() => {
    if (strapiData.length == 0) {
      getPrograms().then((res) => {
        setStrapiData(res);
      });
    }
  }, []);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <NewLayout>
      <div className="">
        <div className="px-12">
          <BreadCrumb>
            <BreadCrumbItem Name={'Biblioteca'} path={'/library'} isCurrentPage={false} />
            <BreadCrumbItem Name={'Programas'} path={'/library/programs'} isCurrentPage={true} />
          </BreadCrumb>
          <div>
            <ProgramsContainer />
          </div>
          <div>
            {open && (
              <div>
                <div className="w-5/6 flex justify-start items-center">
                  <TittleInProgress
                    text={'Programas cargados en Strapi'}
                    width={'w-auto'}
                  />
                </div>
                <TableStrapiData data={strapiData} />
              </div>
            )}

            {!open && (
              <button
                type="button"
                className="px-3 bg-green-principal text-white rounded-full h-10 font-thin mt-12 mx-4 font-semibold"
                onClick={toggle}
              >
                Ver Programas en Strapi
              </button>
            )}
          </div>
        </div>
      </div>
    </NewLayout>
  );
};

export default ProgramsPage;
