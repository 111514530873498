import { createSlice } from '@reduxjs/toolkit';

interface MessageState {
  componentToShow: String,
  selectedToView: any
}

export const slice = createSlice({
  name: 'inbox',
  initialState: {
    componentToShow: '',
    selectedToView: null
  },
  reducers: {
    setComponentToShow: (state, action): void => {
      state.componentToShow = action.payload
    },
    selectMessage: (state, action): void => {
      state.selectedToView = action.payload
    }
  }
});

export const { setComponentToShow, selectMessage } = slice.actions;
export const messageState = (state): MessageState => state.inbox;
export default slice.reducer;