import { ReactElement } from 'react';

const Icon = ({width, height, color, path}): ReactElement => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 20" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d={path} />
    </svg>
  )
}

export default Icon