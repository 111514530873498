import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ButtonSecundary from '../components/UI/ButtonSecundary';
import PromptButton from '../components/UI/PromptButton';

interface RouterPromptProps {
  when: any,
  onOK: any,
  onCancel: any,
  description: string
}

export function RouterPrompt(props: RouterPromptProps) {

  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    if (props.when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return 'true';
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, props.when]);

  const handleOK = useCallback(async () => {
    if (props.onOK) {
      const canRoute = await Promise.resolve(props.onOK());
      if (canRoute) {
        history.block(() => {});
        if(currentPath == history.location.pathname){
          history.goBack();
        }
        history.push(currentPath);
      }
    }
  }, [currentPath, history, props.onOK]);

  const handleCancel = useCallback(async () => {
    if (props.onCancel) {
      const canRoute = await Promise.resolve(props.onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, props.onCancel]);

  return showPrompt ? (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="bg-gray-modal p-4 rounded flex flex-col justify-around items-center my-4 shadow-lg max-w-2xl w-full">
          <div className="relative p-6 flex-auto">
            <p className="text-black text-base font-medium leading-tight tracking-tighter" style={{ whiteSpace: 'pre-line' }}>
              {props.description}
            </p>
          </div>
          <div className="flex justify-center items-center w-full h-16">
            <PromptButton text={'Si'} action={handleOK} redAlert={false} width={'w-3/12'}/>
            <ButtonSecundary text={'No'} action={handleCancel}/>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  ) : null;
}
