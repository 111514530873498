import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { assignOrienteer } from '../../mutations/Oriented';
import { setResponse, setShowModal } from '../../slices/modalSlice';
import { orienteers } from '../../slices/orienteersSlice';
import { initialOrienteerState } from '../../utils/OrienteerObj';
import Button from '../UI/Button';
import avatar from '../../assets/images/Vector .png';
import ImageAvatar from '../UI/ImageAvatar';
import { PaymentPlan } from '../../utils/PaymentPlan';




const AssignmentOriented = ({ userInfo, back }): ReactElement => {
  const orienteersList = useSelector(orienteers)
  const [orienteerSelected, setSelected] = useState(initialOrienteerState)
  const dispatch = useDispatch()

  useEffect(() => {
    if (userInfo.orienteer) {
      const orienteer = orienteersList.find(i => i.id == userInfo.orienteer.id)
      if(orienteer != undefined){
        setSelected(orienteer)
      }
    }
  }, [])

  const handleSelectChange = (event): void => {
    if (event.target.value != '') {
      const orienteer = orienteersList.find(i => i.id == event.target.value)
      if(orienteer != undefined && orienteer != orienteerSelected){
        setSelected(orienteer)
      }
    }
    else {
      setSelected(initialOrienteerState)
    }
  }

  const validator = (toValidate): any => toValidate==='' || toValidate===undefined || toValidate===null ? 'A completar' : toValidate

  const assign = (): void => {
    assignOrienteer(userInfo.id, orienteerSelected.id).then(res => {
      if (res.data.addOrienteer) {
        dispatch(setResponse({
          message: 'El orientado ya fue asignado a su referente.',
          error: false,
          description: 'Recibira una notificacion para que se contacte el Orientado'
        }))
        dispatch(setShowModal(true))
      }
    }).catch(() => {
      dispatch(setResponse({
        message: 'Ups! No se pudo asignar el orientado a su referente.',
        error: true,
        description: 'Por favor reintenta nuevamente'
      }))
      dispatch(setShowModal(true))
    })
  }

  return (
    <div className="h-full w-full md:ml-11 py-8">
      <h1 className="subtitleAsignation">Asignación de Orientado a un Orientador Referente </h1>
      <div className="md:mt-4 card">
        <div className="cardImage">
          <div className="w-full p-8 mx-2 flex justify-center">

            {!userInfo.user.profilePicture ? (

              <ImageAvatar src={avatar} text={'your avatar'} size={false} />) : (
              <ImageAvatar src={process.env.REACT_APP_EXPORT_FILES + userInfo.user.profilePicture} text={'your avatar'} size={false} />
            )}
          </div>
        </div>
        <div className="cardInformation">
          <div className="pb-6">
            <p className="text-lg text-left">{userInfo.user.name}</p>
            <p className="role">Orientado</p>
          </div>
          <div className="grid grid-cols-2">
            <div className="pb-4">
              <p className="label">DNI</p>
              <p className="fieldData">{userInfo.dni}</p>
            </div>
            <div className="pb-4">
              <p className="label">Mail</p>
              <p className="fieldData">{validator(userInfo.user.email)}</p>
            </div>
            <div className="pb-4">
              <p className="label">Teléfono</p>
              <p className="fieldData">{validator(userInfo.user.phone)}</p>
            </div>
            <div className="pb-4">
              <p className="label">Programa</p>
              {userInfo.paymentPlans && userInfo.paymentPlans.map((paymentPlan: PaymentPlan) => (
                <p className="fieldData">{paymentPlan.programSlug}</p>
              ))}
            </div>
            <div className="pb-4">
              <p className="label">Colegio</p>
              <p className="fieldData">{validator(userInfo.school)}</p>
            </div>
            <div className="pb-4">
              <p className="label">Expectativas</p>
              <p className="fieldData">{validator(userInfo.expectations)}</p>
            </div>
            <div className="pb-4">
              <p className="label">Porque se acercó a D'Alfonso</p>
              <p className="fieldData">{validator(userInfo.motive)}</p>
            </div>
          </div>
        </div>
      </div>
      <h1 className="subtitleAsignation">Selección de un Orientador Referente </h1>
      <h1 className="md:text-xs md:mt-4 md:mb-2 text-black-primary text-left">Referente</h1>
      <div className="w-1/4">
        <select
          onChange={handleSelectChange}
          className="focus:outline-none block appearance-none text-gray-600 w-full bg-white border border-gray-400 shadow-inner px-4 py-2 pr-8 rounded">
          <option selected value={''}>Seleccione orientador</option>
          {orienteersList.map(i => (
            <option key={i.id} value={i.id}>{i.user.name}</option>
          ))}
        </select>
      </div>
      {orienteerSelected.id != '' &&
        <div className="md:mt-6 card">
          <div className="cardImage">
            <div className="w-full p-8 mx-2 flex justify-center">

              {!orienteerSelected.user['profilePicture'] ? (

                <ImageAvatar src={avatar} text={'your avatar'} size={false} />) : (
                <ImageAvatar src={process.env.REACT_APP_EXPORT_FILES + orienteerSelected.user['profilePicture']} text={'your avatar'} size={false} />
              )}
            </div>
          </div>
          <div className="cardInformation">
            <div className="pb-6">
              <p className="text-lg text-left">{orienteerSelected.user.name}</p>
              <p className="role">Orientador</p>
            </div>
            <div className="grid grid-cols-2">
              <div className="pb-4">
                <p className="label">Teléfono</p>
                <p className="fieldData">{orienteerSelected.user.email}</p>
              </div>
              <div className="pb-4">
                <p className="label">Mail</p>
                <p className="fieldData">{orienteerSelected.user.email}</p>
              </div>
              <div className="pb-4">
                <p className="label">LInkedin</p>
                <p className="fieldData">{orienteerSelected.socialNetwork}</p>
              </div>
              <div className="pb-4">
                <p className="label">N° de orientados asignados</p>
                <p className="fieldData">{orienteerSelected['oriented'] ? orienteerSelected['oriented']['length'] : 0}</p>
              </div>
            </div>
          </div>
        </div>}
      <div className="w-1/3 flex h-16 md:mt-8 items-center justify-between">
        <Button disabled={orienteerSelected.id == ''} text={userInfo.orienteer ? 'Modificar orientador/a' : 'Asignar orientador/a'} action={assign} />
        <a onClick={back} className="hover:text-black-primary text-black-primary cursor-pointer text-base underline block flex-shrink-0">Cancelar asignación</a>
      </div>
    </div>
  );
};
export default AssignmentOriented;