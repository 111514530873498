import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

const ButtonLink = ({ text, to, disabled = false, redAlert = false, width = 'w-auto', styles = '' }): ReactElement => {
  return (
    <div className={`flex justify-center items-center' ${styles}`}>
      <Link 
        className={`py-2 px-6 ${width} focus:outline-none ${redAlert ? 'bg-red-error' : disabled ?  'bg-gray-disable' : 'bg-green-principal'} text-white rounded-full h-10 font-semibold`}
        disabled={disabled}
        to={to}>
        
        {text}
      </Link>
    </div>
  )
}

export default ButtonLink