import { createSlice } from '@reduxjs/toolkit';

interface ActivityState {
  isCompleted: boolean
  isFinished: boolean  
}

export const slice = createSlice({
  name: 'activity',
  initialState: {
    isCompleted: true,
    isFinished: false,
  },
  reducers: {
    setIsCompleted: (state, action): void => {
      state.isCompleted = action.payload
    },
    setIsFinished: (state, action): void => {
      state.isFinished = action.payload
    }    
  }
});

export const { setIsCompleted, setIsFinished } = slice.actions;
export const activity = (state): ActivityState => state.activity;
export default slice.reducer;