import  { useEffect, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AssignmentOriented from '../../components/Asignaciones/AssignmentOriented';
import NewLayout from '../../components/Layout/NewLayout';
import { setCurrentSection } from '../../slices/navSlice';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { ADMIN_ORIENTED_BY_ID } from '../../queries/Oriented/index';
import LoadingSpinner from '../../utils/spinner';
import { useHistory } from 'react-router-dom';
import { orienteers, saveOrienteers } from '../../slices/orienteersSlice';
import { allOrienteers } from '../../queries';

const AssignedDetailsPage =(): ReactElement => {
  const { orientedId } = useParams()
  const history = useHistory();
  const value = useSelector(orienteers)
  
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(setCurrentSection('Asignados'))

    if(value.length == 0){
      allOrienteers().then(res => {
        const orienteers = res.data.allOrienteer
        const newOrienteers = [...orienteers]
        const orienteersSorted = newOrienteers.sort(function (a, b) {return (a.user.name.localeCompare(b.user.name))})
        dispatch(saveOrienteers(orienteersSorted))
      })
    }
  })

  const {data, loading} = useQuery(ADMIN_ORIENTED_BY_ID, {
    variables: { id: orientedId }
  })
  
  const returnTo = () => {history.push('/assigned')}

  return (
    <NewLayout>
      {loading ? <LoadingSpinner /> : <AssignmentOriented userInfo={data.orientedById} back={returnTo} />}
    </NewLayout>
  )

}

export default AssignedDetailsPage;