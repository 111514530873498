import { ApolloQueryResult, gql } from '@apollo/client'
import { client } from '../../client-apollo'
/* eslint-disable */

// Deprecated: use GET_PAYMENT_PLAN_PAGINATED
export const USER_PAYMENT_LIST = gql`
          query{
            usersPaymentList{
            id
            dni
            user{name,id,profilePicture}
            paymentPlans{
            paymentStatus
            id
            quotas{
              id
              status
              value
              number
              dueDate
              paymentPlan{programSlug}
            }
            programSlug
            }
          }
        }
      `

// Deprecated: use GET_PAYMENT_PLAN_PAGINATED
export const usersPaymentList = (): Promise<ApolloQueryResult<any>> => {
  return client
    .query({
      query: gql`
        query{
            usersPaymentList{
            id
            dni
            user{name,id,profilePicture}
            paymentPlans{
              paymentStatus
              id
              quotas{
                id
                status
                value
                number
                dueDate
                paymentPlan{programSlug}
              }
            programSlug
            }
          }
        }
      `
    })
}

export const GET_PAYMENT_PLAN_PAGINATED = gql`
    query getPaymentPlansPaginated($data: PaymentPlanPaginatedInput!){
      getPaymentPlansPaginated(data: $data){
        total,
        data{
          id,
          programSlug,
          paymentStatus,
          oriented{
            id,
            dni,
            user{
              id,
              name,
              profilePicture        
            }      	
          }
        }
      }  
    }
  `;

export const GET_ACCOUNTING_REPORT = gql`
  query GetAccountingReport($from: String, $to: String, $status: String){
    getAccountingReport(fromDate: $from, toDate: $to, paymentStatus: $status){
    name
    service
    amountInformed
    totalAmountPaid
    amountToPay
    status
    orienteerName
    dni
    }
  }
    `

export const GET_PAYMENT_BY_ID = gql`
query GetPaymentPlan($id: String!){
  getPaymentPlan(id: $id){
    id
    oriented{
      id,
      dni,
      user{
        id,
        name,
        profilePicture        
      }      	
    }
    quotas{
      status
      paymentPlan{
        programSlug
      }
      value
      id
      number
      dueDate
    }
  }
}
  `