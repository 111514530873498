import { ReactElement } from 'react';
import AppointmentsTabs from '../Inicio/Oriented/Appointments/AppointmentsTabs';
import TittleInProgress from '../UI/TittleInProgress';
import avatar from '../../assets/images/Vector .png';
import { useHistory, useParams, Link } from 'react-router-dom';

type AppointmentOrientedProps = {
  oriented: any;
  querydata: any;
};
const AppointmentsOriented = (
  props: AppointmentOrientedProps
): ReactElement => {
  const oriented = props.oriented;
  const history = useHistory();
  const { orientedId } = useParams();

  const view = (appointmentSelected): void => {
    history.push(
      `/oriented/${orientedId}/details/${appointmentSelected.id}`
    );
  };

  return (
    <div className="w-full md:ml-11">
      <div className="w-5/6">
        <div className="flex w-full justify-between mt-10 items-center">
          <div className="flex w-auto items-center">
            {!oriented.user.profilePicture ? (
              <img
                className="md:h-16 md:w-16 h-8 w-8 rounded-full border-2  object-cover"
                src={avatar}
                alt="Your avatar"
              />
            ) : (
              <img
                className="md:h-16 md:w-16 h-8 w-8 rounded-full border-2  object-cover"
                src={
                  process.env.REACT_APP_EXPORT_FILES +
                  oriented.user.profilePicture
                }
                alt="Your avatar"
              />
            )}
            <p className="title-bold ml-4">{oriented.user.name}</p>
          </div>
          <Link
            to={'/agenda/appointment/new'}
            className=" flex justify-center items-center  py-2 px-6 focus:outline-none bg-green-principal text-white rounded-full h-10 font-semibold hover:text-white"
          >
            Crear encuentro
          </Link>
        </div>
        <TittleInProgress text={'Encuentros'} width={'w-1/6'} />
        <div className="w-full mt-8">
          <AppointmentsTabs querydata={props.querydata} edit={view} />
        </div>
      </div>
    </div>
  );
};

export default AppointmentsOriented;
