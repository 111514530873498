import { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import NewLayout from '../../components/Layout/NewLayout';
import { setCurrentSection } from '../../slices/navSlice';
import Ingresos from '../../components/Ingresos/Ingresos'

const AdmissionsPage =(): ReactElement => {
    
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(setCurrentSection('Ingresos'))
  })

  return (
    <NewLayout>
      <div className="w-full h-full">
        <Ingresos />
      </div>
    </NewLayout>            
  )
  
}
  
export default AdmissionsPage;