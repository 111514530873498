// eslint-disable-file
import styles from './styles.module.css';
import logo from '../../assets/images/logo.png';
import { useHistory } from 'react-router-dom';

export default function PoliticasDeProteccion() {
  const router = useHistory();

  const handleGoHome = () => {
    router.push('/');
  }

  return (
    <>
      <header className={styles.header}>
        <img src={logo} alt="Logo" className={styles.logo} onClick={handleGoHome} />

        <nav className={styles.nav}>
          <div className={styles.primaryBtn}>
            <a href="/">Ingresar</a>
          </div>
        </nav>
      </header>
      <div className={styles.screenContainer}>
        <main className={styles.container}>
          <h1 className={styles.title}>Política de Protección de Datos Personales</h1>

          <h2 className={styles.subtitle}>Política de Protección de Datos Personales - ARGENTINA</h2>
          <p className={styles.text}>
            Autorizo y consiento por medio del presente a Orientar con Sentido S.R.L., sus afiliados, oficiales, empleados, agentes, franquiciados, sucesores – incluidos aquellos que actúen en virtud de su autoridad- (en adelante, “Orientar”), en los términos de los artículos 5 y 11 de la Ley de Protección de Datos Personales N°25.326, su Decreto Reglamentario 1558/2001 y normas de la Agencia de Acceso a la Información Pública (“AAIP”) (en adelante, la “LPDP”) a recolectar, utilizar, tratar, ceder, difundir y/o transmitir, mi nombre, número de usuario y demás datos personales vertidos en el presente portal del usuario (en adelante, los “Datos Personales”), de forma gratuita con el fin de que Orientar pueda ofrecer, prestar y/o desarrollar de forma adecuada el servicio de consultorías de orientación vocacional por sí o por terceros autorizados al efecto, como también con cualquier otro fin lícito que Orientar considere razonablemente apropiado en relación con el servicio de orientación vocacional antes referido.
            Declaro haber sido informado en los términos del artículo 6 de la LPDP acerca del carácter obligatorio de la presente; que mis Datos Personales serán almacenados en la base de datos propiedad de Orientar con domicilio en Av. Leandro N. Alem 1050, piso 13, Ciudad Autónoma de Buenos Aires, Argentina y de la posibilidad de ejercer el derecho de acceso, rectificación y supresión de los Datos Personales antes referidos enviando un correo a recepcion@dalfonso.org. Orientar contestará las solicitudes dentro de los plazos establecidos en los artículos 14 y 16 LPDP. La falta de provisión de sus Datos Personales imposibilitará a Orientar prestar adecuadamente el servicio de consultorías de orientación vocacional. De proveerlos, sus Datos Personales podrán ser transferidos a Orientar o a los terceros autorizados al efecto para las finalidades antes indicadas. Declaro que los presentes Datos Personales son verídicos, y se encuentran actualizados. Por último, declaro, manifiesto y garantizo que he sido informado que “El titular de los Datos Personales tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo al efecto conforme lo establecido en el artículo 14, inciso 3 LPDP. La AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, Órgano de Control de la LPDP tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de Datos Personales.
          </p>

          <h2 className={styles.subtitle}>Política de Protección de Datos Personales - ESPAÑA</h2>
          <p className={styles.text}>
            En cumplimiento de lo dispuesto en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y Garantía de los Derechos Digitales y el Reglamento Europeo de Protección de Datos, se le informa sobre la incorporación de sus datos a un fichero titularidad de ORIENTAR CON SENTIDO, S.L., con domicilio en CL. GARCÍA BRUSTENGA, 1 46020 VALENCIA. La finalidad del tratamiento es la gestión de datos de clientes y proveedores y su legitimación es que Orientar pueda ofrecer, prestar y/o desarrollar de forma adecuada el servicio de consultorías de orientación vocacional por sí o por terceros autorizados al efecto, como también con cualquier otro fin lícito que Orientar considere razonablemente apropiado en relación con el servicio de orientación vocacional antes referido. Los datos proporcionados se conservarán mientras no solicite el cese de la actividad. Los datos no se cederán a terceros salvo en los casos en que exista una obligación legal. Las cesiones que podrán llevarse a cabo, serán aquellas necesarias para el desarrollo de la relación jurídica existente entre las partes, así como aquellas que se encuentren previstas en la Ley, tales como aquellas cuyos destinatarios son entidades financieras, la Agencia Tributaria o Juzgados y Tribunales.
            Tiene derecho a acceder, rectificar, suprimir y oponerse al tratamiento de sus datos mediante solicitud remitida a ORIENTAR CON SENTIDO, S.L., con domicilio en CL. GARCÍA BRUSTENGA, 1 46020 VALENCIA. Además, puede consultar información adicional en nuestro Registro de Actividades de Tratamiento.
          </p>
        </main>
      </div>
      <footer className={styles.footer}>
        <div className={styles.footerContent}>
          <img src={logo} alt="Logo" className={styles.logo} style={{ width: '100px' }} onClick={handleGoHome} />
        </div>
      </footer>
    </>
  )
}
