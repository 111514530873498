import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ProgramEdit from '../../components/Biblioteca/ProgramEdit';
import NewLayout from '../../components/Layout/NewLayout';
import { setCurrentSection } from '../../slices/navSlice';
import { useHistory } from 'react-router-dom';
import { GET_ALL_PROGRAMS } from '../../queries/Program';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../utils/spinner';
import BreadCrumb from '../../components/UI/BreadCrumb';
import BreadCrumbItem from '../../components/UI/BreadCrumbItem';

const ProgramsEditPage =(): ReactElement => {
  const history = useHistory();
  const { id } = useParams()
  const [program, setProgram] = useState<any>([]);
  const { data, loading } = useQuery(GET_ALL_PROGRAMS);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading && data) {
      const allPrograms = data.getAllPrograms;
      const programFiltered = allPrograms.find((e) => e.id == id)
      setProgram(programFiltered);
    }
  }, [data]);

  useEffect(()=>{
    dispatch(setCurrentSection('Biblioteca'))
  })

  const onConfirm = () =>{
    history.push('/library/programs')
  }

  const onCancel = () =>{
    history.push('/library/programs')
  }

  return (
    <NewLayout>
      <div className='px-12'>
        <BreadCrumb>
          <BreadCrumbItem Name={'Biblioteca'} path={'/library'} isCurrentPage={false} />
          <BreadCrumbItem Name={'Programas'} path={'/library/programs'} isCurrentPage={false} />
          <BreadCrumbItem Name={'Editar Programa'} path={`/library/programs/edit/${program.id}`} isCurrentPage={true} />
        </BreadCrumb>
      </div>
      <div>
        {loading ? <LoadingSpinner /> : <ProgramEdit program={program} onCancel={onCancel} onConfirm={onConfirm}/>}
      </div>
    </NewLayout>
  )

}

export default ProgramsEditPage;