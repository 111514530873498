import { ReactElement, useEffect } from 'react';
import { GET_AUTH } from '../../queries/Appointment/index';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../../utils/spinner';
import { getUrlRedirectApiAccess } from '../../utils/authGoogle';

const GoogleApiAccessPage = (): ReactElement => {
  const email = localStorage.getItem('email');
  console.log('email google: ', email)


  const url_redirect = getUrlRedirectApiAccess() 

  const { loading, data } = useQuery(GET_AUTH, {
    variables: {
      data: {
        email: email,
        dev: true,
        url: url_redirect
      },
    },
  });

  useEffect(() => {
    if (!loading && data) {
      console.log('data: ', data);
      // setAuthUrl(data.getAuth);
      // Redirige automatico
      window.location.replace(data.getAuth);
    }
  }, [loading]);

  return (
    <div className="h-full w-full mx-12">
      {/* { loading ? <LoadingSpinner /> : <a href={authUrl}>Login app</a>} */}
      <LoadingSpinner />
    </div>
  );
};

export default GoogleApiAccessPage;
