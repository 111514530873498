import { ReactElement } from 'react';
import avatar from '../../assets/images/Vector .png';
import ImageAvatar from '../UI/ImageAvatar';

const OrientedCard = ({ userInfo, select, clickeable = true }): ReactElement => {
  const hasOrienteer = userInfo.orienteer && userInfo.orienteer.id.length !== 0

  return (
    <div onClick={(): void => select(userInfo)} className={`border ${clickeable && 'cursor-pointer'} w-80 rounded-lg md:flex border-gray-secundary h-22 justify-between mb-4 items-center mr-5`}>
      <div className="w-2/5 flex justify-center items-center">
        {userInfo.user && !userInfo.user.profilePicture ? (
          <ImageAvatar src={avatar} text={'your avatar'} size={true} />) : (
          <ImageAvatar src={process.env.REACT_APP_EXPORT_FILES + userInfo.user.profilePicture} text={'your avatar'} size={true} />
        )}
      </div>
      <div className="justify-center items-center w-3/5 py-2">
        <p className="title-smallCard truncate mb-1">{userInfo.user.name}</p>
        <p className="description-smallCard truncate mb-1">{userInfo.school}</p>
        <div className="flex w-full">
          <p className={'label text-left ' + (hasOrienteer ? 'text-green-principal' : 'text-red-error')}>{hasOrienteer ? 'Asignados' : 'Sin Asignar'}</p>
        </div>
      </div>
    </div>
  )
}

export default OrientedCard