export const search = 'M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617ZM14.025 13.875C15.2941 12.5699 16.0029 10.8204 16 9C16 5.132 12.867 2 9 2C5.132 2 2 5.132 2 9C2 12.867 5.132 16 9 16C10.8204 16.0029 12.5699 15.2941 13.875 14.025L14.025 13.875Z'
export const editSvg = 'M11.3322 5.92676L10.0732 4.66783L1.78067 12.9604V14.2193H3.0396L11.3322 5.92676ZM12.5911 4.66783L13.85 3.4089L12.5911 2.14997L11.3322 3.4089L12.5911 4.66783ZM3.7768 16H0V12.2223L11.9616 0.260678C12.1286 0.093766 12.355 0 12.5911 0C12.8272 0 13.0536 0.093766 13.2206 0.260678L15.7393 2.77943C15.9062 2.94639 16 3.17281 16 3.4089C16 3.64498 15.9062 3.8714 15.7393 4.03836L3.77769 16H3.7768Z'
export const deleteSvg = 'M15 4H20V6H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V6H0V4H5V1C5 0.734784 5.10536 0.48043 5.29289 0.292893C5.48043 0.105357 5.73478 0 6 0H14C14.2652 0 14.5196 0.105357 14.7071 0.292893C14.8946 0.48043 15 0.734784 15 1V4ZM16 6H4V18H16V6ZM7 9H9V15H7V9ZM11 9H13V15H11V9ZM7 2V4H13V2H7Z'
export const downloadIcon = 'M0 17H18V19H0V17ZM10 11.172L16.071 5.1L17.485 6.514L9 15L0.515 6.515L1.929 5.1L8 11.17V0H10V11.172Z'
export const closeIcon = 'M4.99999 4.05684L8.29999 0.756836L9.24266 1.6995L5.94266 4.9995L9.24266 8.2995L8.29999 9.24217L4.99999 5.94217L1.69999 9.24217L0.757324 8.2995L4.05732 4.9995L0.757324 1.6995L1.69999 0.756836L4.99999 4.05684Z'
export const reply = 'M10 16L0 8L10 0V5C15.523 5 20 9.477 20 15C20 15.273 19.99 15.543 19.968 15.81C18.505 13.036 15.638 11.119 12.313 11.005L12 11H10V16ZM8 9H12.034L12.381 9.007C13.666 9.05 14.905 9.317 16.057 9.773C14.59 8.075 12.42 7 10 7H8V4.161L3.202 8L8 11.839V9Z'
export const resend = 'M12.172 6.99968L6.808 1.63568L8.222 0.22168L16 7.99968L8.222 15.7777L6.808 14.3637L12.172 8.99968H0V6.99968H12.172Z'
export const file = 'M24 8.66675V25.9907C24.0012 26.1658 23.968 26.3395 23.9021 26.5017C23.8362 26.6639 23.739 26.8116 23.6161 26.9363C23.4931 27.061 23.3468 27.1602 23.1855 27.2284C23.0242 27.2965 22.8511 27.3322 22.676 27.3334H1.324C0.973084 27.3334 0.636522 27.1941 0.388262 26.9461C0.140002 26.6981 0.000353389 26.3617 0 26.0107V1.98941C0 1.27341 0.598666 0.666748 1.336 0.666748H15.996L24 8.66675ZM21.3333 10.0001H14.6667V3.33341H2.66667V24.6667H21.3333V10.0001ZM6.66667 7.33342H10.6667V10.0001H6.66667V7.33342ZM6.66667 12.6667H17.3333V15.3334H6.66667V12.6667ZM6.66667 18.0001H17.3333V20.6667H6.66667V18.0001Z'
export const downArrow = 'M6.99974 5.17168L11.9497 0.22168L13.3637 1.63568L6.99974 7.99968L0.635742 1.63568L2.04974 0.22168L6.99974 5.17168Z'
export const upArrow = 'M6.99974 2.828L2.04974 7.778L0.635742 6.364L6.99974 0L13.3637 6.364L11.9497 7.778L6.99974 2.828Z'
export const view = 'M10.9999 0C16.3919 0 20.8779 3.88 21.8189 9C20.8789 14.12 16.3919 18 10.9999 18C5.60791 18 1.12191 14.12 0.180908 9C1.12091 3.88 5.60791 0 10.9999 0ZM10.9999 16C13.0394 15.9996 15.0183 15.3068 16.6128 14.0352C18.2072 12.7635 19.3228 10.9883 19.7769 9C19.3211 7.0133 18.2048 5.24 16.6105 3.97003C15.0162 2.70005 13.0382 2.00853 10.9999 2.00853C8.96161 2.00853 6.9836 2.70005 5.38928 3.97003C3.79497 5.24 2.67868 7.0133 2.22291 9C2.677 10.9883 3.79258 12.7635 5.38705 14.0352C6.98152 15.3068 8.96044 15.9996 10.9999 16ZM10.9999 13.5C9.80643 13.5 8.66184 13.0259 7.81793 12.182C6.97401 11.3381 6.49991 10.1935 6.49991 9C6.49991 7.80653 6.97401 6.66193 7.81793 5.81802C8.66184 4.97411 9.80643 4.5 10.9999 4.5C12.1934 4.5 13.338 4.97411 14.1819 5.81802C15.0258 6.66193 15.4999 7.80653 15.4999 9C15.4999 10.1935 15.0258 11.3381 14.1819 12.182C13.338 13.0259 12.1934 13.5 10.9999 13.5ZM10.9999 11.5C11.6629 11.5 12.2988 11.2366 12.7677 10.7678C13.2365 10.2989 13.4999 9.66304 13.4999 9C13.4999 8.33696 13.2365 7.70107 12.7677 7.23223C12.2988 6.76339 11.6629 6.5 10.9999 6.5C10.3369 6.5 9.70098 6.76339 9.23214 7.23223C8.7633 7.70107 8.49991 8.33696 8.49991 9C8.49991 9.66304 8.7633 10.2989 9.23214 10.7678C9.70098 11.2366 10.3369 11.5 10.9999 11.5Z'
export const add='M9 9V5H11V9H15V11H11V15H9V11H5V9H9ZM10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18Z'
export const remove='M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM5 9H15V11H5V9Z'
export const uploadIcon='M12 2H2V18H16V6H12V2ZM0 0.992C0 0.444 0.447 0 0.999 0H13L18 5V18.993C18.0009 19.1243 17.976 19.2545 17.9266 19.3762C17.8772 19.4979 17.8043 19.6087 17.7121 19.7022C17.6199 19.7957 17.5101 19.8701 17.3892 19.9212C17.2682 19.9723 17.1383 19.9991 17.007 20H0.993C0.730378 19.9982 0.479017 19.8931 0.293218 19.7075C0.107418 19.5219 0.00209465 19.2706 0 19.008V0.992ZM10 10V14H8V10H5L9 6L13 10H10Z" fill="#09121F'
export const aprove='M10.602 8.76L12.014 10.172L20.48 1.706L21.894 3.12L12.014 13L5.65 6.636L7.064 5.222L9.189 7.347L10.602 8.759V8.76ZM10.604 5.932L15.556 0.979004L16.966 2.389L12.014 7.342L10.604 5.932ZM7.777 11.587L6.364 13L0 6.636L1.414 5.222L2.827 6.635L2.826 6.636L7.777 11.587Z'
