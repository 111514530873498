import { ReactElement, useEffect, useState } from 'react';
import LoadingSpinner from '../../utils/spinner';
import { getFrecuentQuestions } from '../../utils/strapiFunctions';
import TittleInProgress from '../UI/TittleInProgress';
import Questions from './QuestionsRender';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


const Help = (): ReactElement => {
  const [content, setContent] = useState<any>(null)

  useEffect(() => {
    getFrecuentQuestions().then(res => {
      setContent(res)
    })
  }, [])

  return content == null ? <LoadingSpinner /> : (
    <div className='w-full ml-11 mt-10'>
      <TittleInProgress text={content.title} width={'w-72 mb-10'} />
      <Tabs className={'w-full flex flex-wrap items-center justify-between'} selectedTabClassName={'text-gray-800 border-b-4 border-green-secundary'}>
        <TabList className={'flex mb-0 list-none ml-1.5 mb-10 '}>
          <Tab className={'text-gray-400 border-b-4 border-gray-200 px-14 pt-1 cursor-pointer text-base hover:text-black-primary  capitalize py-4 block leading-normal '}>
            Servicios
          </Tab>
          <Tab className={'text-gray-400 border-b-4 border-gray-200 px-14 pt-1 cursor-pointer text-base hover:text-black-primary  capitalize py-4 block leading-normal '}>
            Pagos/Devoluciones
          </Tab>
          <Tab className={'text-gray-400 border-b-4 border-gray-200 px-14 pt-1 cursor-pointer text-base hover:text-black-primary  capitalize py-4 block leading-normal '}>
            Comunicación
          </Tab>
        </TabList>
        <TabPanel className={'w-full flex items-center justify-between'}>
          <Questions data={content.questionsAnswersServices} />
        </TabPanel>
        <TabPanel className={'w-full flex items-center justify-between'}>
          <Questions data={content.questionsPay} />
        </TabPanel>
        <TabPanel className={'w-full flex items-center justify-between'}>
          <Questions data={content.questionsClient} />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Help;