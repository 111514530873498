import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchTimezone, session } from '../../slices/sessionSlice';
import Table from '../UI/Table';
import { getTime } from '../../utils/utilsDate';
import TittleInProgress from '../UI/TittleInProgress';
import { deleteEventMutation } from '../../mutations/Appointment';
import { setResponse, setShowModal } from '../../slices/modalSlice';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import { APPOINTMENTS_BY_ORIENTEER } from '../../queries/Appointment';
import { format } from 'date-fns';
import { confirm } from 'react-confirm-box';
import PromptButton from '../UI/PromptButton';
import LoadingSpinner from '../../utils/spinner';
import { Link, useHistory } from 'react-router-dom';
import InputTag from '../UI/InputTag';
import FetchErrorMessage from '../UI/FetchErrorMessage';
import SearchBox from '../UI/SearchBox';

const AgendaTableOrienteer = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [programOptions, setProgramOptions] = useState<any>();
  const [search, setSearch] = useState('')
  const [appointmentsFiltered, setAppointmentsFiltered] = useState<null | Array<any>>();
  const [confirmedAppointment, setconfirmedAppointment] = useState<null | Array<any>>(null);
  const [optionFilterSelected, setOptionFilterSelected] = useState<null | Array<any>>(null);
  const { user } = useSelector(session);
  const storageGoogleEmail = localStorage.getItem('email')
  const [deleteAppointment, { loading: loadingDeleteAppointment, error: errorDeleteAppointment }] = useMutation(deleteEventMutation);
  const timeZone = useSelector((state: any) => state.session.timezone);

  //Send credentials
  const storageCredentials = localStorage.getItem('g-tokens')

  useEffect(() => {
    dispatch(fetchTimezone());
  }, [])

  console.log(timeZone)

  const orderColummns: Array<{
    id: string;
    desc: boolean;
  }> = [
    {
      id: 'date',
      desc: true,
    },
  ];

  const eventsOfOrienteer = useQuery(APPOINTMENTS_BY_ORIENTEER, {
    variables: {
      id: user.id
    },
  });

  const { data, loading, refetch: refetchData, error } = eventsOfOrienteer;

  let normalizeSearchInput = (searchInput) => {
    const normalizedInput = searchInput
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
    return normalizedInput;
  }

  useEffect(() => {
    // check if appointments data exists
    if (confirmedAppointment != null && confirmedAppointment != undefined) {
      // first, filter by the program(s) selected in the input tag component 
      const filteredByProgramTag = confirmedAppointment.filter((appointment) => {
        return optionFilterSelected?.some((program) => { return program.value == appointment.program.id })
      })
      // then, filter those appointments by the search term from the search input
      // (if no search term is given, just return the appointments filtered by program)
      const filteredBySearch = search != '' && search != undefined ?
        filteredByProgramTag.filter(i => {
          const orientedFound = i.orientedsDropdown.some(oriented => {
            return normalizeSearchInput(oriented.user.name).includes(normalizeSearchInput(search)) || oriented.dni.toString().includes(search)
          })
          return orientedFound
        }) : filteredByProgramTag
      // set the appointments filtered to be displayed in the table component
      setAppointmentsFiltered(filteredBySearch)
    }
  }, [search, confirmedAppointment, programOptions, optionFilterSelected])

  useEffect(() => {
    if (errorDeleteAppointment) {
      console.error('Error al eliminar el evento: ', errorDeleteAppointment)
    }
  }, [errorDeleteAppointment])

  console.log('errorrrr',error)

  useEffect(() => {
    // formats query data and then set the following useState:
    // 1) list of programs options to filter
    // 2) all appointments and the information to display in the table
    // 3) set the filtered appointments first with all tags (unfiltered)
    if (!loading && !error) {
      const appointments = data.getAppointmentsByOrienteer;
      console.log('?xd', appointments)
      const formatterConfirmed = appointments.map((appointment) => {
        let userArr: any = [];
        if (appointment.confirmedOrienteers) {
          for (var i = 0; i < appointment.confirmedOrienteers.length; i++) {
            userArr.push(appointment.confirmedOrienteers[i].user);
          }
        }
       
        return {
          date: appointment.date,
          time: getTime(appointment.date, timeZone),
          name: appointment.name,
          id: appointment.id,
          detail: appointment.detail,
          orienteer: user.user.name,
          orienteerId: user ? user.id : undefined,
          timeSpan: appointment.timeSpan,
          status: appointment.status,
          orienteds:
            appointment.orienteds[0] && showParticipants(appointment.orienteds),
          orientedId: appointment.orienteds[0].user
            ? appointment.orienteds[0].user.id
            : undefined,
          orientedsDropdown: appointment.orienteds,
          orienteersDropdown: userArr,
          program: appointment.program
        };
      });
      const programList = formatterConfirmed.map((item) => {
        return {
          value: item.program.id,
          label: item.program.name
        }
      })
      const programListFiltered = programList.filter((item, index) => {
        const itemIndex = programList.findIndex((item) => { return item.value == programList[index].value })
        return itemIndex === index
      });
      setProgramOptions(programListFiltered)
      setconfirmedAppointment(formatterConfirmed);
    }
  }, [data]);
  console.log(data)

  useEffect(() => {
    refetchData();
  }, [data]);

  useEffect(() => {
    setOptionFilterSelected(programOptions)
  }, [programOptions])


  const showParticipants = (orientedArray: Array<any>) => {
    let participants: any = [];
    orientedArray.map((oriented) => participants.push(oriented.user.name));
    return participants.join(', ');
  };

  const handleShowEdit = (original): void => {
    history.push(`/agenda/appointment/edit/${original.id}`);
  };

  const options: any = {
    render: (message, onConfirm, onCancel) => {
      return (
        <div className="bg-gray-modal border ml-6 mb-24 h-48 border-gray-secundary p-4 text-center rounded flex flex-col justify-around items-center my-4 shadow-lg max-w-2xl">
          <div className="w-full">
            <h3 className={' text-left text-md font-bold text-center px-8'}>
              {message}
            </h3>
            <p className="text-black-primary text-center text-xs"></p>
          </div>
          <div className="flex justify-around w-full h-16">
            <PromptButton
              text={'Confirmar'}
              action={onConfirm}
              redAlert={true}
              width={'w-5/12'}
            />
            <PromptButton
              text={'Cancelar'}
              action={onCancel}
              redAlert={false}
              width={'w-5/12'}
            />
          </div>
        </div>
      );
    },
  };

  const handleDelete = async (original): Promise<void> => {
    const didConfirm = await confirm(
      '¿Estás seguro que deseas eliminar el evento? Tené en cuenta que no es posible recuperarlo.',
      options
    );
    try {
      if (didConfirm) {
        deleteAppointment({
          variables: {
            data: {
              appointmentID: original,
              credentials: storageCredentials,
              calendarId: storageGoogleEmail
            }
          }
        }).then((): void => {
          refetchData();
          dispatch(
            setResponse({
              message: 'El evento ya fue borrado.',
              error: false,
              description:
                'Recibira una notificacion para que se contacte con administrador',
            })
          );
          dispatch(setShowModal(true));
        })
      }
    } catch (error) {
      dispatch(
        setResponse({
          message: 'El evento no pudo ser borrado.',
          error: true,
          description: 'Volver a intentar más tarde',
        })
      );
      dispatch(setShowModal(true));
    }
  };

  const columnsOrienteer = React.useMemo(
    () => [
      {
        Header: 'Fecha',
        accessor: 'date',
        Cell: ({ value }): string => {
          return format(new Date(value), 'dd/MM/yyyy');
        },
      },
      {
        Header: 'Horario',
        accessor: 'time',
      },
      {
        Header: 'Evento',
        accessor: 'name',
        disableSortBy: true,
      },
      {
        Header: 'Participantes',
        accessor: 'orienteds',
        disableSortBy: true,
      },
      {
        Header: 'Programa',
        accessor: 'program.name',
      },
      {
        Header: 'Estado',
        accessor: 'status'
      }
    ],
    []
  );

  const mapState = (appointment): ReactElement => {
    let stateToShow: ReactElement = <div />;

    switch (appointment.status) {
      case 'OPEN' || 'ACTIVE':
        stateToShow = (
          <div className="flex items-center">
            <div className="w-auto rounded-sm flex items-center h-auto text-black-primary text-xs p-1 bg-green-success">
              Agendado
            </div>
          </div>
        );
        break;
      case 'PAUSED' || 'SUSPENDED':
        stateToShow = (
          <div className="w-auto rounded-sm h-auto text-black-primary text-xs p-1 bg-yellow-info">
            A definir
          </div>
        );
        break;
      case 'FINISHED':
        stateToShow = (
          <div className="flex items-center">
            <div className="w-auto rounded-sm flex items-center h-auto text-black-primary text-xs p-1 bg-gray-secundary">
              Terminado
            </div>
          </div>
        );
        break;
    }
    return stateToShow;
  };

  return (
    <div className="h-full w-full md:ml-11 mb-20">
      <div>
        <div className="w-11/12 grid-cols-1">
          <div className={'flex justify-between items-end w-full mb-4'}>
            <TittleInProgress text={'Filtrar por programa y orientados'} width={'w-auto'} />
          </div>
          <div>
            {
              optionFilterSelected &&
              <InputTag
                options={programOptions}
                state={[optionFilterSelected, setOptionFilterSelected]}
                defaultOptions={programOptions}
                placeholder={'Elegí tus programas...'}
              />
            }
          </div>

          <div className={'flex flex-row justify-between items-end w-full -mt-4'}>
            <TittleInProgress text={'Últimos eventos'} width={'w-52'} />
            <Link
              to={'/agenda/appointment/new'}
              className=" py-2 px-6 focus:outline-none bg-green-principal text-white rounded-full h-10 font-semibold"
            >
              Agendar evento
            </Link>
          </div>
          {(appointmentsFiltered != null && (confirmedAppointment && !loadingDeleteAppointment)) ? (
            <div>
              <div>
                <SearchBox options={[search, setSearch]} placeholder='Buscar por nombre o dni del orientado...' />
                {appointmentsFiltered.length == 0 ?
                  <div className='mt-20 w-4/6 flex justify-center items-center'>
                    <p>No se encontraron resultados.</p>
                  </div> :
                  <Table
                    columns={columnsOrienteer}
                    data={appointmentsFiltered}
                    initialRows={10}
                    state={mapState}
                    orderColumnArray={orderColummns}
                    edit={handleShowEdit}
                    deleteRow={handleDelete}
                  />
                }
              </div>
            </div>
          ) : (!loading && error ?
            <FetchErrorMessage error={error} />
            :
            <LoadingSpinner />
          )}
        </div>
      </div>
    </div>
  );
};

export default AgendaTableOrienteer;
