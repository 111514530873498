import { ReactElement } from 'react'

export const mapState = (payment): ReactElement => {
  const stateToShow = (text, backcolor): ReactElement => <div className='flex items-center'>
    <div className={'w-auto rounded-sm flex items-center h-auto text-black-primary text-xs p-1 ' + backcolor}>
      {text}
    </div>
  </div>
  switch (payment.status) {
    case 'PAID':
      return stateToShow('Pago', 'bg-green-success')
    default:
      return stateToShow('Pendiente de Pago', 'bg-yellow-info')
  }
}

