import { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { setResponse, setShowModal } from '../../slices/modalSlice';
import NewPromptButtonGB from '../UI/NewPromptButtonGB';
import Table from '../UI/Table';
import { useHistory } from 'react-router-dom';
import { deleteActivity as deleteActivityById } from '../../mutations/Activity';
import { confirm } from 'react-confirm-box';



const TableActivity = (dataARG: any): ReactElement => {
  const dispatch = useDispatch();
  const items = Array.isArray(dataARG) ? dataARG : Object.values(dataARG)
  const history = useHistory();

  const columns = [
    {
      Header: 'Nombre de la actividad',
      accessor: 'name'
    },
    {
      Header: 'Formato',
      accessor: 'format'
    },
    {
      Header: 'Duración',
      accessor: 'duration'
    },
    {
      Header: 'Slug',
      accessor: 'accessLink'
    }]

  const EmptyArrayMessage = ({ message }): ReactElement => (
    <div className="w-5/6 flex justify-center items-center">
      <p className="text-base py-4 text-green-principal">{message}</p>
    </div>
  )

  const options: any = {
    render: (message, onConfirm, onCancel) => {
      return (
        <div className="bg-gray-modal border ml-6 mb-24 h-48 border-gray-secundary p-4 text-center rounded flex flex-col justify-around items-center my-4 shadow-lg max-w-2xl">
          <div className="w-full">
            <h3 className={' text-left text-md font-bold text-center px-8'}>
              {message}
            </h3>
            <p className="text-black-primary text-center text-xs"></p>
          </div>
          <div className="flex justify-around w-full h-16">
            <NewPromptButtonGB
              text={'Si'}
              action={onConfirm}
              okButton={true}
              type={'button'}
            />
            <NewPromptButtonGB
              text={'No'}
              action={onCancel}
              okButton={false}
              type={'button'}
            />
          </div>
        </div>
      );
    },
  };

  const handleDelete = async (activityId): Promise<void> => {
    const didConfirm = await confirm(
      '¿Estás seguro que deseas eliminar la actividad?',
      options
    );
    try {
      if (didConfirm) {
        const {
          data: { deleteActivity },
        } = await deleteActivityById(activityId);
        if (deleteActivity) {
          dispatch(
            setResponse({
              message: 'La actividad fue borrada con éxito!',
              error: false
            })
          );
          dispatch(setShowModal(true));
          history.go(0)
        }
      }
    } catch (error) {
      dispatch(
        setResponse({
          message: 'La actividad no pudo ser borrada.',
          error: true,
          description: 'Intentá más tarde',
        })
      );
      dispatch(setShowModal(true));
    }
  };

  const handleEdit = (activity) => {
    history.push(`/library/activity/edit/${activity.id}`)
  }
            
  return(
    dataARG.length == 0 ?
      <EmptyArrayMessage message={'No hay actividades'} />      
      : 
      items && <Table columns={columns} data={items[0]} initialRows={10} deleteRow={handleDelete} edit={handleEdit} />    
  ) 
}

export default TableActivity;