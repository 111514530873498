import { gql } from '@apollo/client';


export const GET_APPOINTMENT = gql`
query AppointmentById($id: String!){
  appointmentById(id: $id){
    id
    name
    privateComment
    detail
    comments
    timeSpan
    interestLinks{
      id
      name
      link
    }
    program{
      id
      name
    }
    date
    googleMeetLink
  }
}
`;


export const APPOINTMENTS_BY_ORIENTED = gql` 
  query GetAppointmentsByOriented($id: String!){
    getAppointmentsByOriented(id: $id){
      name
      comments
      privateComment
      googleMeetLink
      detail
      interestLinks{
        id
        name
        link
      }
      orienteds{
        user{
          name
        }
      }
      confirmedOrienteers{
        user{
          name
        }
      }    	  
      date
      status
      id
      program{
        id
        name
      }
      timeSpan
    }
  }
`

export const APPOINTMENTS_BY_ORIENTED_SHORT = gql` 
  query GetAppointmentsByOriented($id: String!){
    getAppointmentsByOriented(id: $id){
      id
      name
      date
      timeSpan
      googleMeetLink
      confirmedOrienteers{
        user{
          name
        }
      }
    }
  }
`

export const APPOINTMENTS_BY_SLUG_BY_ORIENTED = gql` 
  query GetAppointmentsBySlug($id: String!, $programSlug: String!){
    getAppointmentsBySlug(id: $id, programSlug: $programSlug){
      name
      comments
      privateComment
      detail
      interestLinks{
        id
        name
        link
      }
      orienteds{
        user{
          name
        }
      }
      confirmedOrienteers{
        user{
          name
        }
      }    	  
      date
      status
      id
      program{
        id
        name
      }
      timeSpan
    }
  }
`

export const APPOINTMENTS_BY_ORIENTEER = gql` 
  query GetAppointmentsByOrienteer($id: String!){
    getAppointmentsByOrienteer(id: $id){
      name
      comments
      privateComment
      googleMeetLink
      detail
      interestLinks{
        id
        name
        link
      }
      orienteds{
        user{
          name
        }
        id
        dni
        paymentPlans{
          programSlug
        }
      }
      confirmedOrienteers{
        user{
          name
        }
        id
      }
      date
      status
      id
      program{
        name
        id
      }
      timeSpan
    }
  }
`

export const ALL_APPOINTMENTS = gql`
query Events{
  events{
    id
    name
    date
    detail
    status
    timeSpan
    orienteds{
      paymentPlans{
        programSlug
      }
      id
      user{
        name
        id
      }
    }
    confirmedOrienteers{
      user{
        name
      }
      id
    }
    program{
      id
      name
    }
  }
}
`
export const GET_AUTH = gql`
  query getAuth($data: GetAuthInput!){
    getAuth(data: $data)
  }
`

export const GET_CALENDAR_TOKENS = gql`
  query GetToken($data: GetTokenInput!){
    getToken(data: $data)
  }
`