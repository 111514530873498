
export const exportExcelAccountingReport = async (paymentStatus, fromDate, toDate): Promise<any> => {
  try {
    const data = await fetch(process.env.REACT_APP_API + `api/account-report/export?paymentStatus=${paymentStatus}&fromDate=${fromDate}&toDate=${toDate}`)
      .then(function (response) {
        return response
      })
    return data
  } catch (error) {
    return undefined
  }
}