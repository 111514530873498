import { ReactElement } from 'react';
import TittleInProgress from '../../../UI/TittleInProgress';
import AppointmentsTabs from './AppointmentsTabs';
import { useHistory, useParams } from 'react-router-dom'
import ButtonSecundary from '../../../UI/ButtonSecundary';

type appointmentsQuery = {
  querydata: any
}

const Appointments = (props: appointmentsQuery): ReactElement => {
  const history = useHistory()
  const { programSlug } = useParams()

  const selectAppointment = (selected): void => {
    const id = selected.id
    history.push(`/my-programs/${programSlug}/appointments/${id}`)
  }

  const goTo = () => {
    history.push('/messages/new')
  }

  return (
    <div className="-mt-5 w-5/6">
      <div className='w-full flex flex-row justify-between items-end mb-10'>
        <TittleInProgress text={'Encuentros'} width={'w-auto'} />
        <div className={''}>
          <ButtonSecundary
            text={'Contactar orientador'}
            action={goTo}
          />
        </div>
      </div>
      <AppointmentsTabs querydata={props.querydata} edit={selectAppointment}/>
    </div>
  )
}

export default Appointments