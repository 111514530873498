import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { strapiData } from '../../slices/initialStrapiData';
import Slider from '../UI/Slider';
import NewsCard from '../Inicio/Oriented/NewsCard';
import TittleInProgress from '../UI/TittleInProgress';

const News = (): ReactElement => {
  const { news } = useSelector(strapiData)
  let newsCards: Array<ReactElement> = []
  news && news.map((i, index) => {
    newsCards.push(<NewsCard key={index} title={i.Title} description={i.Subtitle} Link={i.Link}/>)
  })

  return (
    <div>
      <div className="w-1/6 mb-3">
        <TittleInProgress text={'Novedades'} width={'w-5/6'} />
      </div>
      <div>
        <Slider items={newsCards} />
      </div>
    </div>
  )
}

export default News