import { ReactElement } from 'react';
import { deleteProgram as deleteProgramById } from '../../mutations/Program';
import Table from '../UI/Table';
import { useDispatch } from 'react-redux';
import { setResponse, setShowModal } from '../../slices/modalSlice';
import { confirm } from 'react-confirm-box';
import NewPromptButtonGB from '../UI/NewPromptButtonGB';
import { useHistory } from 'react-router-dom';

const TablePrograms = (dataARG: any, ): ReactElement => {
  const items = Array.isArray(dataARG) ? dataARG : Object.values(dataARG);
  const dispatch = useDispatch();
  const history = useHistory();

  const options: any = {
    render: (message, onConfirm, onCancel) => {
      return (
        <div className="bg-gray-modal border ml-6 mb-24 h-48 border-gray-secundary p-4 text-center rounded flex flex-col justify-around items-center my-4 shadow-lg max-w-2xl">
          <div className="w-full">
            <h3 className={' text-left text-md font-bold text-center px-8'}>
              {message}
            </h3>
            <p className="text-black-primary text-center text-xs"></p>
          </div>
          <div className="flex justify-around w-full h-16">
            <NewPromptButtonGB
              text={'Si'}
              action={onConfirm}
              okButton={true}
              type={'button'}
            />
            <NewPromptButtonGB
              text={'No'}
              action={onCancel}
              okButton={false}
              type={'button'}
            />
          </div>
        </div>
      );
    },
  };

  const handleDelete = async (programId): Promise<void> => {
    const didConfirm = await confirm(
      '¿Estás seguro que deseas eliminar el programa?',
      options
    );
    try {
      if (didConfirm) {
        const {
          data: { deleteProgram },
        } = await deleteProgramById(programId);
        if (deleteProgram) {
          dispatch(
            setResponse({
              message: 'El programa fue borrado con éxito!',
              error: false
            })
          );
          dispatch(setShowModal(true));
          history.go(0)
        }
      }
    } catch (error) {
      dispatch(
        setResponse({
          message: 'El programa no pudo ser borrado.',
          error: true,
          description: 'Intentá más tarde',
        })
      );
      dispatch(setShowModal(true));
    }
  };

  const handleEdit = (program) => {
    history.push(`/library/programs/edit/${program.id}`)
  }

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
    },

    {
      Header: 'Nombre',
      accessor: 'name',
    },

    {
      Header: 'Descripción',
      accessor: 'description',
    },

    {
      Header: 'Estado actual',
      accessor: 'status',
    },
    {
      Header: 'Cant. Actividades',
      accessor: 'activities',
      Cell: ({ value }):string => {return value.length}
    },    
  ];

  const EmptyArrayMessage = ({ message }): ReactElement => (
    <div className="w-5/6 flex justify-center items-center">
      <p className="text-base py-4 text-green-principal">{message}</p>
    </div>
  );

  return dataARG.length == 0 ? (
    <EmptyArrayMessage message={'No hay programas'} />
  ) : (
    items && 
    <Table 
      columns={columns}
      data={items[0]}
      initialRows={10}
      deleteRow={handleDelete}
      edit={handleEdit}
    />
  );
};

export default TablePrograms;
