import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import NewLayout from '../../components/Layout/NewLayout';
import { setCurrentSection } from '../../slices/navSlice';
import { useParams, useHistory } from 'react-router-dom'
import { GET_PAYMENT_BY_ID } from '../../queries/Payment';
import { useQuery } from '@apollo/client';
import PaymentDetails from '../../components/Pagos/PaymentDetails';
import LoadingSpinner from '../../utils/spinner';

const PaymentsDetailPage =(): ReactElement => {
  const history = useHistory()
  const { paymentId } = useParams()
  const dispatch = useDispatch();
  const [paymentPlan, setPaymentPlan] = useState();

  useEffect(()=>{
    dispatch(setCurrentSection('Pagos'))
  }, [])

  const { data, loading, refetch } = useQuery(GET_PAYMENT_BY_ID, {
    variables: { id: paymentId }
  })

  useEffect(() => {
    if (!loading) {
      setPaymentPlan(data.getPaymentPlan);
    }
  }, [data])

  const goToPayments = () => {
    history.push('/payments')
  }

  return (
    <NewLayout>
      <div className="w-full md:ml-11">
        { !loading && paymentPlan  ? <PaymentDetails paymentPlan={paymentPlan} goBack={ goToPayments } refetch={refetch} /> : <LoadingSpinner/> }
      </div>
    </NewLayout>            
  )

}

export default PaymentsDetailPage;