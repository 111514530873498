import { createSlice } from '@reduxjs/toolkit';
import { Orienteer } from '../utils/OrienteerObj';

interface OrienteerState {
  value: Array<Orienteer>
}

export const slice = createSlice({
  name: 'orienteers',
  initialState: {
    value: []
  },
  reducers: {
    saveOrienteers: (state, action): void => {
      state.value = action.payload
    }
  }
});

export const { saveOrienteers } = slice.actions;
export const orienteers = (state): Array<Orienteer> => state.orienteers.value;
export const orienteerState = (state): OrienteerState => state.orienteers;
export default slice.reducer;