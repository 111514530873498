import { ReactElement } from 'react';

const ButtonSecundary = ({ text, action, disabled = false, styles='' }): ReactElement => {

  return (
    <div className={`w-full ${styles}`}>
      <button
        disabled={disabled}
        onClick={action}
        className={`${disabled ? 'bg-gray-disable text-white' : 'border text-black-primary'} py-1 px-6 w-auto focus:outline-none border-black-primary rounded-full h-10 font-semibold`}>
        {text}
      </button>
    </div>
  )
}

export default ButtonSecundary