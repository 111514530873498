import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import EditFormAct from '../../components/Biblioteca/EditFormAct';
import NewLayout from '../../components/Layout/NewLayout';
import { setCurrentSection } from '../../slices/navSlice';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../utils/spinner';
import { useHistory } from 'react-router-dom';
import { GET_ACTIVITIES } from '../../queries/Activity';
import BreadCrumb from '../../components/UI/BreadCrumb';
import BreadCrumbItem from '../../components/UI/BreadCrumbItem';

const EditActivityPage =(): ReactElement => {
  const history = useHistory();
  const { id } = useParams()
  const [activity, setActivity] = useState<any>([]);
  const { data, loading } = useQuery(GET_ACTIVITIES);
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setCurrentSection('Biblioteca'))
  })

  useEffect(() => {
    if (!loading && data) {
      const allActivities = data.activities;
      const ActivitiesFiltered = allActivities.find((e) => e.id == id)
      setActivity(ActivitiesFiltered);
    }
  }, [data]);


  const onCancel = () =>{
    history.push('/library/activity')
  }

  return (
    <NewLayout>
      <div className='px-12'>
        <BreadCrumb>
          <BreadCrumbItem Name={'Biblioteca'} path={'/library'} isCurrentPage={false} />
          <BreadCrumbItem Name={'Actividades'} path={'/library/activity'} isCurrentPage={false} />
          <BreadCrumbItem Name={'Editar Actividad'} path={`/library/activity/edit/${activity.id}`} isCurrentPage={true} />
        </BreadCrumb>
      </div>
      <div>
        {loading && !data ? <LoadingSpinner/> : <EditFormAct Selectedactivity={activity} onCancel={onCancel} /> }
        
      </div>
    </NewLayout>            
  )

}

export default EditActivityPage;