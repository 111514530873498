import { ReactElement } from 'react';

const PromptButton = ({ text, action, disabled = false, redAlert = false, width = 'w-auto' }): ReactElement => {
  return (
    <div className={' flex justify-center items-center w-full'}>
      <button
        disabled={disabled}
        onClick={action}
        type="submit"
        className={`py-2 px-6 ${width} focus:outline-none ${redAlert ? 'bg-red-error' : disabled ?  'bg-gray-disable' : 'bg-green-principal'} text-white rounded-full h-10 font-semibold`}>
        {text}
      </button>
    </div>
  )
}

export default PromptButton