import { ReactElement, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getDate, getTime } from '../../../../utils/utilsDate';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTimezone } from '../../../../slices/sessionSlice';

export interface MeetAndOrienteerDetailsProps {
  appointment: any;
}

const MeetAndOrienteerDetails = (props: MeetAndOrienteerDetailsProps): ReactElement => {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTimezone());
  }, [])
  
  const appointmentDate = new Date(props.appointment.date)
  const timeSpan = appointmentDate.setMinutes(appointmentDate.getMinutes() + props.appointment.timeSpan)
  const timeZone = useSelector((state: any) => state.session.timezone);

  return (
    <div className='flex justify-start items-start max-h-60'>
      <div className="w-1/5 h-40 border border-gray-secundary rounded-lg p-6 flex flex-col">
        <p className="title-smallCard mt-4 text-sm">Encuentro: </p>
        <p className="flex justify-start mt-4 text-black-primary cursor-pointer">Fecha: {getDate(appointmentDate)}</p>
        <p className="flex justify-start mt-4 text-black-primary cursor-pointer">Hora: {getTime(props.appointment.date, timeZone)}hs - {getTime(new Date(timeSpan), timeZone)}hs.</p>
      </div>
      <div className="w-1/5 h-40 ml-4 border border-gray-secundary rounded-lg p-6 flex flex-col justify-start">
        <p className="title-smallCard mt-4 text-sm">Orientador:</p>
        <p className="flex justify-start mt-4 text-black-primary cursor-pointer">{props.appointment.orienteers}</p>
        <Link
          className="mt-4 text-xs flex justify-start link text-gray-principal cursor-pointer"
          style={{ textDecoration: 'none' }}
          to={'/messages/new'}
        >
          Contactar orientador {'>'}
        </Link>
      </div>
      {props.appointment.googleMeetLink ? 
        <div className="w-1/5 h-40 ml-4 border border-gray-secundary rounded-lg p-6 flex flex-col">
          <p className="title-smallCard mt-4 text-sm">Acceso al encuentro: </p>
          <a
            className="mt-4 text-xs flex justify-start link text-gray-principal cursor-pointer"
            style={{ textDecoration: 'none' }}
            href={props.appointment.googleMeetLink}
            target="_blank"
          >Ir al meet {'>'}</a>
        </div> : ''
      }
    </div>
  );
}

export default MeetAndOrienteerDetails