export const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items:4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};