import { useQuery } from '@apollo/client';
import { ReactElement, useEffect } from 'react';
import { GET_ALL_ORIENTED_WITH_USER } from '../../queries/Oriented';
import LoadingSpinner from '../../utils/spinner';
import OrientedsSearch from '../UI/OrientedsSearch';
import Pagination from '../UI/Pagination';
import TittleInProgress from '../UI/TittleInProgress';
import OrientedCard from './OrientedCard';
import { useHistory } from 'react-router-dom';

const Orienteds = (): ReactElement => {
  const oriented = useQuery(GET_ALL_ORIENTED_WITH_USER)
  const orientedAndIncomings = !oriented.loading ? oriented.data.getAllOrientedsWithUser.filter((oriented)=> oriented.orienteer!==null) : []

  const history = useHistory();
  const goToAssignedOriented = (oriented) => {
    if(!oriented.id){
      console.error('OrientedId not found.')
      return
    }
    history.push(`/assigned/${oriented.id}`);
  }

  const item = (i, index): ReactElement => {
    return (
      <OrientedCard userInfo={i} key={index} select={() => {goToAssignedOriented(i)}} />
    )
  }

  useEffect(() => {
    oriented.refetch()
  }, [])

  return (
    <div className='h-auto lg:max-w-5xl md:ml-11 mb-20'>
      <div className='grid grid-cols-2'>
        <div className="pb-1">
          <TittleInProgress text={'Orientados'} width="w-36" />
        </div>
      </div>
      <div className='lg:max-w-5xl'>
        {oriented.loading ? <LoadingSpinner/> :<OrientedsSearch
          oriented={orientedAndIncomings}
          componentToShow={(data): ReactElement => <Pagination data={data} itemData={item} table={null} perPage={9} />}
        />}
      </div>
    </div>
  )
}

export default Orienteds