import { useQuery } from '@apollo/client';
import { ReactElement, useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import TittleInProgress from '../UI/TittleInProgress';
import TableActivity from './TableActivity';
import ButtonLink from '../UI/ButtonLink';
import { GET_ACTIVITIES } from '../../queries/Activity';
import LoadingSpinner from '../../utils/spinner';

const ShowActivities = (): ReactElement => {
  const { data, loading, refetch } = useQuery(GET_ACTIVITIES);
  const [required, setRequired] = useState([]);
  const [notRequired, setNotRequired] = useState([]);

  useEffect(() => {
    if (!loading && data) {
      const allActivities = data.activities;
      setRequired(allActivities.filter((i) => i.isRequired));
      setNotRequired(allActivities.filter((i) => !i.isRequired));
    }
  }, [data]);

  useEffect(() => {
    if (data || (required.length == 0 && notRequired.length == 0)) {
      refetch();
    }
  }, [required, notRequired]);


  return (
    <div className="w-full ml-11 flex flex-col">
      <div className="w-5/6 flex justify-between items-center">
        <TittleInProgress
          text={'Nuestras Actividades'}
          width={'w-auto'}
        />
        <div className="mt-8">
          <ButtonLink
            text={'Cargar actividad'}
            to="/library/activity/new"
            styles={'2xl:ml-9'}
          />
        </div>
      </div>
      <div className="w-5/6 mt-8">
        <Tabs className={'w-full flex flex-wrap items-center justify-between'} selectedTabClassName={'text-gray-800 border-b-4 border-green-secundary'}>
          <TabList className={'flex mb-0 list-none ml-1.5 '}>
            <Tab className={'text-gray-400 border-b-4 border-gray-200 px-14 pt-1 cursor-pointer text-base hover:text-black-primary  capitalize py-4 block leading-normal '}>
              Actividades requeridas
            </Tab>
            <Tab className={'text-gray-400 border-b-4 border-gray-200 px-14 pt-1 cursor-pointer text-base hover:text-black-primary  capitalize py-4 block leading-normal '}>
            Actividades opcionales
            </Tab>
          </TabList>
          <TabPanel className={'w-full flex flex-col items-center justify-between'}>
            { loading ? <LoadingSpinner/> : <TableActivity dataARG={required} /> }
          </TabPanel>
          <TabPanel className={'w-full flex flex-col items-center justify-between'}>
            { loading ? <LoadingSpinner/> : <TableActivity dataARG={notRequired} /> }
          </TabPanel>
        </Tabs>
        {/*<Tabs>
          <TabList>
            <Tab>Actividades requeridas</Tab>
            <Tab>Actividades opcionales</Tab>
          </TabList>
          <TabPanel>
            {loading ? <LoadingSpinner /> : <TableActivity data={required} />}
          </TabPanel>
          <TabPanel>
            {loading ? (
              <LoadingSpinner />
            ) : (
              <TableActivity data={notRequired} />
            )}
          </TabPanel>
            </Tabs>*/}
      </div>
    </div>
  );
};

export default ShowActivities;
