import { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ShowActivities from '../../components/Biblioteca/ShowActivities';
import NewLayout from '../../components/Layout/NewLayout';
import { setCurrentSection } from '../../slices/navSlice';
import BreadCrumb from '../../components/UI/BreadCrumb';
import BreadCrumbItem from '../../components/UI/BreadCrumbItem';

const AdminActivityPage = (): ReactElement => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentSection('Biblioteca'));
  });

  return (
    <NewLayout>
      <div className='px-12'>
        <BreadCrumb>
          <BreadCrumbItem Name={'Biblioteca'} path={'/library'} isCurrentPage={false} />
          <BreadCrumbItem Name={'Actividades'} path={'/library/activity'} isCurrentPage={true} />
        </BreadCrumb>
      </div>
      <ShowActivities />
    </NewLayout>
  );
};

export default AdminActivityPage;