import { useQuery } from '@apollo/client';
import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GET_ORIENTED } from '../../../../queries/Oriented';
import { fetchTimezone, session } from '../../../../slices/sessionSlice';
import TittleInProgress from '../../../UI/TittleInProgress';
import Activities from './Activities';
import Comments from './Comments';
import FinalMaterialView from './FinalMaterialView';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MeetAndOrienteerDetails from './MeetAndOrienteerDetails';
import { APPOINTMENTS_BY_ORIENTED_SHORT } from '../../../../queries/Appointment';
import { getTime } from '../../../../utils/utilsDate';
import { useDispatch } from 'react-redux';

type AppointmentSimpleType = {
  id: string;
  name: string;
  comments: string;
  date: Date;
};

export interface BegginingAppointmentDetailProps {
  appointment: AppointmentSimpleType;
}

const BegginingAppointmentDetail = (
  props: BegginingAppointmentDetailProps
): ReactElement => {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTimezone());
  }, [])

  const { user } = useSelector(session);
  const [dataAppointment, setDataAppointment] = useState<any>();
  const { data , loading } = useQuery(APPOINTMENTS_BY_ORIENTED_SHORT, {
    variables: { id: user.id }
  })
  const timeZone = useSelector((state: any) => state.session.timezone);

  useEffect(() => {
    if(!loading){

      const appointment = data.getAppointmentsByOriented.map(e => {
        let userArr :any = []
        if(e.confirmedOrienteers != undefined){
          for(var i = 0; i < e.confirmedOrienteers.length; i++){
            userArr.push(e.confirmedOrienteers[i].user)
          }
        }
        return {
          id: e.id,
          date: e.date,
          time: getTime(e.date, timeZone),
          timeSpan: e.timeSpan,
          orienteers: userArr.map(item => item.name).join(', '),
          googleMeetLink: e.googleMeetLink
        }
      })
      setDataAppointment(appointment)
    }
  }, [data]);

  const filteredAppointment = dataAppointment?.find((e) => {return e.id == props.appointment.id})

  const oriented = useQuery(GET_ORIENTED, {
    variables: { id: user.id },
  });
  const required = oriented.data
    ? oriented.data.orientedById.appointmentActivities.filter(
      (i) => i.appointmentId == props.appointment.id && i.activity.isRequired
    )
    : [];
  const notRequired = oriented.data
    ? oriented.data.orientedById.appointmentActivities.filter(
      (i) => i.appointmentId == props.appointment.id && !i.activity.isRequired
    )
    : [];

  useEffect(()=>{
    oriented.refetch()
  }, [])

  return (
    <div className="w-full flex flex-col items-start -ml-1 -mt-6">
      <TittleInProgress text={props.appointment.name} width={'w-auto'} />
      <div className="w-full mt-8 ">
        {dataAppointment != undefined ?
          <MeetAndOrienteerDetails appointment={filteredAppointment} />
          : ''
        }
      </div>
      <div className="w-full mt-6">
        <Tabs
          className={'w-full flex flex-wrap items-center justify-between'}
          selectedTabClassName={
            'text-gray-800 border-b-4 border-green-secundary'
          }
        >
          <TabList className={'flex mb-0 list-none ml-1.5 '}>
            <Tab
              className={
                'text-gray-400 border-b-4 border-gray-200 px-14 pt-1 cursor-pointer text-base hover:text-black-primary  capitalize py-4 block leading-normal '
              }
            >
              Actividades
            </Tab>
            <Tab
              className={
                'text-gray-400 border-b-4 border-gray-200 px-14 pt-1 cursor-pointer text-base hover:text-black-primary  capitalize py-4 block leading-normal '
              }
            >
              Comentarios del orientador
            </Tab>
            <Tab
              className={
                'text-gray-400 border-b-4 border-gray-200 px-14 pt-1 cursor-pointer text-base hover:text-black-primary  capitalize py-4 block leading-normal '
              }
            >
              Archivos Adjuntos
            </Tab>
          </TabList>
          <TabPanel className={'w-full mt-4 flex items-center justify-between'}>
            <Activities required={required} notRequired={notRequired} />
          </TabPanel>
          <TabPanel className={'w-full flex items-center justify-between'}>
            <Comments appointment={props.appointment} />
          </TabPanel>
          <TabPanel className={'w-full flex items-center justify-between'}>
            <FinalMaterialView
              userId={user.user.id}
              appointmentId={props.appointment.id}
            />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default BegginingAppointmentDetail;
