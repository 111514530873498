import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Activity from '../../components/Inicio/Oriented/AppoinmentDetail/Activity';
import NewLayout from '../../components/Layout/NewLayout';
import { setCurrentSection } from '../../slices/navSlice';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_APPOINTMENT } from '../../queries/Appointment';
import { setActivities, setPrograms, strapiData } from '../../slices/initialStrapiData';
import BreadCrumb from '../../components/UI/BreadCrumb';
import BreadCrumbItem from '../../components/UI/BreadCrumbItem';
import { session } from '../../slices/sessionSlice';
import { GET_ORIENTED } from '../../queries/Oriented';
import LoadingSpinner from '../../utils/spinner';
import { getPrograms } from '../../utils/strapiFunctions';

const ActivitiesFormPage = (): ReactElement => {
  const { appointmentId, activityId } = useParams();
  const [programName, setProgramName] = useState();
  const { user } = useSelector(session);
  const dispatch = useDispatch();
  const { activities, programs } = useSelector(strapiData);
  
  const [currentAccessLink, setAccessLink] = useState('');
  const appointmentInfo = useQuery(GET_APPOINTMENT, {
    variables: { id: appointmentId },
  });
  const orientedAppointmentActs = useQuery(GET_ORIENTED, {
    variables: { id: user.id }
  })
  const programSlug = !appointmentInfo.loading
    ? appointmentInfo.data.appointmentById.program.id
    : '';

  useEffect(() => {
    if (
      activities.length == 0 &&
      orientedAppointmentActs.data
    ) {
      let saveActs = !orientedAppointmentActs.loading
        ? orientedAppointmentActs.data.orientedById.appointmentActivities
        : '';
      dispatch(setActivities(saveActs));
    }
  });
  
  useEffect(() => {
    if (programs !== undefined && programs.length == 0) {
      getPrograms().then(res => {
        dispatch(setPrograms(res))
      })
    }
    if (programs !== undefined && programs.length > 0) {
      const filterByProgram = programs.filter((val) => {
        return val.slug == programSlug
      }
      );
      setProgramName(filterByProgram[0].name);
    }
  }, [programs]);

  useEffect(() => {
    dispatch(setCurrentSection('Agenda'));
  }, []);

  useEffect(()=> {
    let filterAccessLink = activities.filter((value) => value.activity.id.includes(activityId) && value.appointmentId.includes(appointmentId))
    filterAccessLink.length == 0 ? setAccessLink('')  : setAccessLink(filterAccessLink[0].activity.accessLink)
  })


  return (
    <NewLayout>
      <div className="w-full md:ml-11">
        <BreadCrumb>
          <BreadCrumbItem Name={'Inicio'} path={'/home'} isCurrentPage={false}/>
          <BreadCrumbItem Name={programName == undefined ? '. . . ' :  programName} path={`/my-programs/${programSlug}`} isCurrentPage={false}/>
          <BreadCrumbItem Name={'Encuentros'} path={`/my-programs/${programSlug}/appointments`} isCurrentPage={false}/>
          <BreadCrumbItem Name={'Detalle del encuentro'} path={`/my-programs/${programSlug}/appointments/${appointmentId}`} isCurrentPage={false}/>
          <BreadCrumbItem Name={'Actividad'} isCurrentPage={true} />
        </BreadCrumb>
        { !currentAccessLink ? 
          <LoadingSpinner/> : 
          <Activity appointment={appointmentId} accessLink={currentAccessLink} activity={activityId}/>
        }
      </div>
    </NewLayout>
  );
};

export default ActivitiesFormPage;
