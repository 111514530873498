import { ReactElement } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import TittleInProgress from '../UI/TittleInProgress';
import AssignActs from './AssignActs';
import Feedback from './Feedback';
import PrivateComment from './PrivateComment';
import { useParams } from 'react-router-dom'
import FinalMaterialPage from '../../pages/Oriented/FinalMaterialPage';


type OrientedAppointment = {
  oriented: any
  appointment: any
}

const AppointmentDetail = (props: OrientedAppointment): ReactElement => {
  const { orientedId, appointmentId } = useParams()
  const selectedAppointment = props.appointment
  const appointmentProgram = selectedAppointment != undefined ? props.appointment.program : {}
  const programName = selectedAppointment.program !=undefined ? props.appointment.program.name : '...'
  const appointmentGoogleMeet = selectedAppointment.googleMeetLink


  return (
    <div className="w-full -mt-4">
      <div className="flex w-5/6 flex-col items-start">
        <div className="w-full flex items-center justify-between">
          <div className= "w-1/2 ">
            <TittleInProgress text={selectedAppointment.name} width={'w-auto'} />
          </div>
          <div className="w-1/2 flex mt-10 self-start justify-end">
            {
              appointmentGoogleMeet ?
                <a href={appointmentGoogleMeet} target='_blank' className={'static py-2 px-6 w-auto border text-black-primary focus:outline-none border-black-primary rounded-full h-10 font-semibold hover:text-current'}>
                Acceso al Meet
                </a> : <></> 
            }
          </div>
        </div>
        <p className="pt-2 pl-1">Programa: {programName}</p>
      </div>

      <div className="w-full mt-8">
        <Tabs className={'w-full flex flex-wrap items-center justify-between'} selectedTabClassName={'text-gray-800 border-b-4 border-green-secundary'}>
          <TabList className={' w-5/6 flex mb-0 list-none justify-start whitespace-pre'}>
            <Tab className={'text-gray-400 border-b-4 border-gray-200 px-12 xl:px-12 2xl:px-24 pt-1 cursor-pointer text-base hover:text-black-primary  capitalize py-4 block leading-normal '}>
              Actividades
            </Tab>
            <Tab className={'text-gray-400 border-b-4 border-gray-200 px-12 xl:px-12 2xl:px-24 pt-1 cursor-pointer text-base hover:text-black-primary  capitalize py-4 block leading-normal '}>
              Comentarios del orientador
            </Tab>
            <Tab className={'text-gray-400 border-b-4 border-gray-200 px-12 xl:px-12 2xl:px-24 pt-1 cursor-pointer text-base hover:text-black-primary  capitalize py-4 block leading-normal '}>
              Comentarios privados
            </Tab>
            <Tab className={'text-gray-400 border-b-4 border-gray-200 px-12 xl:px-12 2xl:px-24 pt-1 cursor-pointer text-base hover:text-black-primary  capitalize py-4 block leading-normal '}>
              Archivos Adjuntos
            </Tab>
          </TabList>
          <TabPanel className={'w-full mt-8 flex items-center justify-between'}>
            <AssignActs program={appointmentProgram} />
          </TabPanel>
          <TabPanel className={'w-full flex items-center justify-between'}>
            <Feedback />
          </TabPanel>
          <TabPanel className={'w-full flex items-center justify-between'}>
            <PrivateComment />
          </TabPanel>
          <TabPanel className={'w-full '}>
            <FinalMaterialPage orientedId={`${orientedId}`} appointmentId={`${appointmentId}`} />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  )
}

export default AppointmentDetail