import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

type BreadcrumbProps = {
  Name: any;
  path?: any;
  isCurrentPage: boolean;
};

const BreadCrumbItem = ({ Name, path, isCurrentPage }: BreadcrumbProps): ReactElement => {
  if (isCurrentPage) {
    return (
      <p className="text-left mr-1 text-black-primary text-base font-bold">
        {Name}
      </p>
    );
  }

  return (
    <Link
      to={path}
      className={
        'text-left mr-1 cursor-pointer no-underline hover:underline hover:text-black-primary text-black-primary text-base breadcrumb'
      }
    >
      {Name + ' / '}
    </Link>
  );
};

export default BreadCrumbItem;
