export const googleLogin = async (credential: string) => {
  try {
    console.log(credential);
    const data = await fetch(
      `${process.env.REACT_APP_API}api/auth/google/login`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({
          credential: credential,
        }),
      }
    );
    console.log('result login: ', data);
  } catch (error) {
    console.error('Error');
    console.error(error);
  }
};
export interface googleBrokerEndpointProps{
  state: string
  code: string
  scope: string
  prompt: string
  hd: string
  authuser: string
}

export const googleBrokerEndpoint = async (parameters: googleBrokerEndpointProps) => {
  try {
    console.log(parameters);
    const data = await fetch(
      `${process.env.REACT_APP_AUTH}auth/realms/master/broker/google/endpoint`,
      {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(parameters),
      }
    );
    console.log('result login: ', data);
    if(data.ok){
      const dataJson = await data.json();
      console.log('result googleBrokerEndpoint POST: ', dataJson);    
    } else{
      data.text().then((txt) => console.error(`Response was not OK. Status code: ${data.status} text: ${data.statusText}.\nResponse: ${txt}`));      

    }
  } catch (error) {
    console.error('Error');
    console.error(error);
  }
};

export const googleBrokerEndpointGET = async (parameters: googleBrokerEndpointProps) => {
  try {
    console.log(parameters);

    const searchParams = convertToData(parameters).join('&')
    const data = await fetch(
      `${process.env.REACT_APP_AUTH}auth/realms/master/broker/google/endpoint?`
      + searchParams,
      {
        method: 'GET',
        mode: 'no-cors',
        credentials: 'include'
      }
    );
    console.log('result data: ', data);
    if(data.ok){
      const dataJson = await data.json();
      console.log('result googleBrokerEndpointGET: ', dataJson);
    }else{
      console.error('Error en llamada')
    }
    // console.log('result googleBrokerEndpointGET body: ', dataJson.body);
  } catch (error) {
    console.error('Error');
    console.error(error);
  }
};

export interface TokenRequest {
  code: string
  redirect_uri: string
  client_secret: string
  client_id: string
  grant_type: string
}

export const getToken = async (tokenRequest: TokenRequest) => {
  try {
    const formBody = convertToData(tokenRequest)

    const data = await fetch(
      getAppAuth(),
      {
        method: 'POST',
        // mode: 'no-cors',
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formBody.join('&'),
      }
    );
    console.log('result data: ', data);
    const dataJson = await data.json();
    console.log('result getToken: ', dataJson);
    console.log('result getToken body: ', dataJson.body);
    return dataJson;  
  } catch (error) {
    console.error('Error');
    console.error(error);
  }
};


function convertToData(obj: any){

  const formBody : Array<string> = []; 
  Object.keys(obj).forEach(function(key) {

    console.log('Key : ' + key + ', Value : ' + obj[key])
    var encodedKey = encodeURIComponent(key);
    var encodedValue = encodeURIComponent(obj[key]);      
    formBody.push(encodedKey + '=' + encodedValue);
  })
  
  return formBody
}

export function getLoginRedirectUri() {

  return `${process.env.REACT_APP_WEB}login_redirect`
}

export function getUrlRedirectApiAccess() {
  
  return `${process.env.REACT_APP_WEB}google/api-access/redirect`
}

export function getAppAuth(){
  if(!process.env.REACT_APP_AUTH) console.error('No app auth url')
  return `${process.env.REACT_APP_AUTH}auth/realms/master/protocol/openid-connect/token`  
}

export function loginUrl(): string{

  let hostnameEncoded = encodeURIComponent(process.env.REACT_APP_WEB!)
  const encode_url_google_login = `${encodeURIComponent(process.env.REACT_APP_AUTH!)}auth%2Frealms%2Fmaster%2Fprotocol%2Fopenid-connect%2Fauth%3Fresponse_type%3Dcode%26client_id%3D${process.env.REACT_APP_KEYCLOAK_GOOGLE_TOKEN_CLIENT_ID}%26redirect_uri%3D${hostnameEncoded}login_redirect%26kc_idp_hint%3Dgoogle%26prompt%3Dlogin`
  const logoutKc = `${process.env.REACT_APP_AUTH}auth/realms/master/protocol/openid-connect/logout?redirect_uri=${encode_url_google_login}`
  return logoutKc
}

const url_redirect_orienteer = `${encodeURIComponent(process.env.REACT_APP_WEB!)}%2Forienteer%2Flogin`
export const logoutURLOrienteer = `${process.env.REACT_APP_AUTH}auth/realms/master/protocol/openid-connect/logout?redirect_uri=${url_redirect_orienteer}`

const url_redirect_oriented = `${encodeURIComponent(process.env.REACT_APP_WEB!)}`
export const logoutURLOriented = `${process.env.REACT_APP_AUTH}auth/realms/master/protocol/openid-connect/logout?redirect_uri=${url_redirect_oriented}`
