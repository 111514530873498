import { checkStatus, parseJSON } from './checkStatus';

const headers = {
  'Content-Type': 'application/json',
};

export const getToken = async (): Promise<String> => {
  const data = await fetch(`${process.env.REACT_APP_STRAPI}auth/local`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      identifier: process.env.REACT_APP_STRAPI_IDENTIFIER,
      password: process.env.REACT_APP_STRAPI_PASSWORD
    })
  }).then(checkStatus)
    .then(parseJSON);
  return data.jwt
}

export const getFrecuentQuestions = async (): Promise<any | undefined> => {
  try {
    // const token = await getToken().then(res => res)
    const content = await fetch(`${process.env.REACT_APP_STRAPI}pages/9`, {
      method: 'GET',
      // headers: new Headers({
      //   'Authorization': 'Bearer ' + token 
      // })
    })
      .then(checkStatus)
      .then(parseJSON);
    return content.contentSections[1]
  } catch (error) {
    return undefined
  }
}

export const getIncriptionAvailable = async (): Promise<any | undefined> => {
  try {
    const token = await getToken().then(res => res)
    const content = await fetch(`${process.env.REACT_APP_STRAPI}sign-ups`, {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + token
      })
    })
      .then(checkStatus)
      .then(parseJSON);
    return content
  } catch (error) {
    return undefined
  }
}


export const getProfessionLabs = async (): Promise<any | undefined> => {
  try {
    const token = await getToken().then(res => res)
    const content = await fetch(`${process.env.REACT_APP_STRAPI}professions-labs`, {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + token
      })
    })
      .then(checkStatus)
      .then(parseJSON);
    return content
  } catch (error) {
    return undefined
  }
}

export const getNews = async (): Promise<any | undefined> => {
  try {
    const token = await getToken().then(res => res)
    const content = await fetch(`${process.env.REACT_APP_STRAPI}what-s-news`, {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + token
      })
    })
      .then(checkStatus)
      .then(parseJSON);
    return content
  } catch (error) {
    return undefined
  }
}


export const getPrograms = async (): Promise<any | undefined> => {
  try {
    const token = await getToken().then(res => res)
    const content = await fetch(`${process.env.REACT_APP_STRAPI}programs`, {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + token
      })
    })
      .then(checkStatus)
      .then(parseJSON);
    return content
  } catch (error) {
    return undefined
  }
}

export const getPaymentMethods = async () => {
  try {
    const token = await getToken().then(res => res)
    const content = await fetch(`${process.env.REACT_APP_STRAPI}payment-methods`, {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + token
      })
    })
      .then(checkStatus)
      .then(parseJSON);
    return content
  } catch (error) {
    return undefined
  }
}