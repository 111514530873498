import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getTimezoneByIp, getUserIP } from '../utils/utilsDate';

export const fetchTimezone = createAsyncThunk(
  'session/fetchTimezone',
  async () => {
    const ip = await getUserIP();
    const timezone = await getTimezoneByIp(ip);
    return timezone;
  }
);

const initialState = {
  isLoggedIn: !!localStorage.getItem('userInfo'),
  user: getUserInitialState(),
  formInfo: null,
  timezone: localStorage.getItem('timezone') || 'America/Argentina/Buenos_Aires',  // Valor inicial
};

export const slice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    logoutSuccess: (state): void => {
      state.user = null;
      state.timezone = 'America/Argentina/Buenos_Aires'; 
      localStorage.clear();
    },
    saveTimezone: (state, action): void => {
      state.timezone = action.payload;
      localStorage.setItem('timezone', action.payload);
    },
    saveUserInfo: (state, action): void => {
      state.user = action.payload
      localStorage.setItem('userInfo', JSON.stringify(action.payload));
    },
    loginState: (state, action): any => {
      state.isLoggedIn = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTimezone.fulfilled, (state, action) => {
      state.timezone = action.payload || 'America/Argentina/Buenos_Aires';
      localStorage.setItem('timezone', action.payload || 'America/Argentina/Buenos_Aires');
    });
  },
}
);

function getUserInitialState(): any {
  const userInfoStorage = localStorage.getItem('userInfo');
  return userInfoStorage ? JSON.parse(userInfoStorage) : null;
}

export const { logoutSuccess, saveUserInfo, loginState, saveTimezone } = slice.actions;
export const session = (state): any => state.session;
export default slice.reducer;
