import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setPrograms, strapiData } from '../../slices/initialStrapiData';
import { getPrograms } from '../../utils/strapiFunctions';
import BreadCrumbItem from './BreadCrumbItem';

type BreadcrumbProps = {
  isCurrentPage: boolean;
};

const ProgramBreadCrumb = ( props : BreadcrumbProps): ReactElement => {
  const { programSlug } = useParams()
  const [programName, setProgramName] = useState();
  const { programs } = useSelector(strapiData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (programs !== undefined && programs.length == 0) {
      getPrograms().then(res => {
        dispatch(setPrograms(res))
      })
    }
    if (programs !== undefined && programs.length > 0) {
      const filterByProgram = programs.filter((val) => {
        return val.slug == programSlug 
      }
      );
      setProgramName(filterByProgram[0].name);
    }
  }, [programs]);


  return (
    <div> 
      <BreadCrumbItem Name={programName == undefined ? '. . . ' :  programName} path={`/my-programs/${programSlug}`} isCurrentPage={props.isCurrentPage}/>
    </div>
  );
};

export default ProgramBreadCrumb;
