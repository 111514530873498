import { FC, ReactElement } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import {
  aprove,
  closeIcon,
  deleteSvg,
  downloadIcon,
  editSvg,
  search,
  uploadIcon,
  view
} from '../../assets/svg/dashboardIcons'
import { Roles } from '../../utils/Roles';
import Icon from './Icon';
import ExportToExcel from './ExportToExcelButton';
import ReactTooltip from 'react-tooltip';
import PaginationButtons from './PaginationButtons';

interface TableProps {
  data: Array<any>;
  columns: Array<{
    Header: string;
    accessor: string;
  }>;
  edit?: (row) => void;
  deleteRow?: (row) => void;
  exportFromdni?: number;
  state?: (row) => ReactElement;
  download?: Boolean;
  upload?: (row) => void
  approved?: (row) => void
  viewRow?: (row) => void
  initialRows?:number
  markAsFinished?: (row) => void
  showColumnNames?: Boolean;
  orderColumnArray?:  Array<{
    id: string;
    desc: boolean;
  }>;
  
}

const Table: FC<TableProps> = (props): ReactElement => {
  const {
    columns,
    data,
    edit,
    deleteRow,
    upload,
    approved,
    viewRow,
    markAsFinished,
    exportFromdni,
    state,
    initialRows,
    download,
    showColumnNames,
    orderColumnArray,
  } = props;

  const { 
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    pageOptions,
    canPreviousPage,
    canNextPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data,
      initialState: { 
        pageIndex: 0, 
        pageSize: initialRows || '10',
        sortBy: orderColumnArray === undefined ? [] : orderColumnArray
      },
      
    },
    useSortBy,
    usePagination
  );
  const handleView = (original) :void => {
    if (viewRow) {
      viewRow(original)
    }
  }
  const handleEdit = (original) :void => {
    if (edit) {
      edit(original)
    }
  };
  const handleDelete = (id) :void => {
    if (deleteRow) {
      deleteRow(id)
    }
  };
  const roleLogged = localStorage.getItem('role');
  const handleApproved = (original) :void => {
    if (approved) {
      approved(original)
    }
  }
  const handleUpload = (original) :void => {
    if (upload) {
      upload(original)
    }
  }
  const handleMarkAsFinished = (original) :void => {
    if (markAsFinished) {
      markAsFinished(original)
    }
  }

  return (
    <>
      <div className="h-auto w-full mb-2 pagination">
        <PaginationButtons
          className = {'w-full flex justify-end mt-7 mb-2 mr-1'}
          prevClick = {previousPage}
          nextClick = {nextPage}
          currentPage = {pageIndex + 1}
          totalPages = {pageOptions.length}
          isPreviousDisabled  = {!canPreviousPage}
          isNextDisabled = {!canNextPage}
        />
      </div>
      <table
        cellSpacing={0}
        className="w-full border border-gray-secundary border-separate rounded-lg"
        {...getTableProps()}
      >
        {showColumnNames && (
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                className="text-black-primary text-sm"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    className="py-3 px-6 text-left font-bold text-green-principal "
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? ' ▼' : ' ▲') : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        )}
        <tbody className="text-black-primary text-xs" {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr className="hover:bg-gray-100 w-full" {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      className="py-3 px-6 text-left border-t border-gray-secundary"
                      {...cell.getCellProps()}
                    >
                      {cell.column.Header == 'Estado'
                        ? state && state(cell.row.original)
                        : cell.render('Cell')}
                    </td>
                  );
                })}
                <td className="border-t border-gray-secundary flex items-center pt-2">
                  {edit && (
                    <button
                      className="focus:outline-none cursor-pointer w-4 mr-4 transform hover:scale-110 pt-1.5"
                      onClick={() :void => handleEdit(row.original)}
                    >
                      {roleLogged == Roles.ORIENTED ? (
                        <Icon
                          path={search}
                          width={20}
                          height={20}
                          color={'#2E384D'}
                        />
                      ) : (
                        <Icon
                          path={editSvg}
                          width={20}
                          height={20}
                          color={'#2E384D'}
                        />
                      )}
                    </button>
                  )}
                  {markAsFinished && (
                    <>
                      <div className='flex items-center' data-for={row.original.id} data-tip="custom show"
                        data-event="click focus">
                        <div className="w-auto rounded-sm cursor-pointer flex items-center h-auto text-black-primary text-xs p-1 bg-gray-secundary">
                          Finalizar
                        </div>
                      </div>
                      <ReactTooltip backgroundColor="white" border borderColor="#0BA4A0" textColor="#2E384D" clickable={true} id={row.original.id} globalEventOff="click">
                        <div>
                          <p className="text-black-dalfonso font-semibold">¿Seguro que queres dar por finalizado el encuentro "{row.original.name}"?</p>
                          <button onClick={() :void => handleMarkAsFinished(row.original)} className="cursor-pointer focus:outline-none px-4 py-2 rounded-lg bg-green-principal text-white font-bold mt-4">
                            Si
                          </button>
                        </div>
                      </ReactTooltip>
                    </>
                  )}
                  {deleteRow && (
                    <button
                      className="focus:outline-none cursor-pointer w-4 mr-2 transform hover:scale-110"
                      onClick={() :void => handleDelete(row.original.id)}
                    >
                      <Icon
                        path={deleteSvg}
                        width={20}
                        height={18}
                        color={'#2E384D'}
                      />
                    </button>
                  )}
                  {viewRow && <button className="focus:outline-none cursor-pointer w-4 mr-2 transform hover:scale-110" onClick={() :void => handleView(row.original)}>
                    <Icon path={view} width={28} height={18} color={'#2E384D'} />
                  </button>}
                  {upload && <button className="focus:outline-none cursor-pointer w-4 mr-4 transform hover:scale-110" onClick={() :void => handleUpload(row.original)}>
                    <Icon path={uploadIcon} width={20} height={18} color={'#2E384D'} />
                  </button>}
                  {approved && <button className="focus:outline-none cursor-pointer w-4 mr-2 transform hover:scale-110" onClick={() :void => handleApproved(row.original)}>
                    <Icon path={row.original.status === 'PAID' ? closeIcon : aprove} width={20} height={18} color={'#2E384D'} />
                  </button>}
                  {download && (
                    <a
                      href={
                        'https://drive.google.com/uc?export=download&id=' +
                        row.original.id
                      }
                      download
                      className="focus:outline-none cursor-pointer w-4 mr-2 transform hover:scale-110"
                    >
                      <Icon
                        path={downloadIcon}
                        width={20}
                        height={18}
                        color={'#2E384D'}
                      />
                    </a>
                  )}
                  {exportFromdni && (
                    <ExportToExcel
                      orientedDni={exportFromdni}
                      slug={row.original.activity.accessLink}
                      disabled={!row.original.isComplete}
                    />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
Table.defaultProps = {
  showColumnNames: true,
};
export default Table;