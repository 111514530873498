import { ReactElement, useState } from 'react'
import TittleInProgress from '../UI/TittleInProgress'
import Button from '../UI/Button'
import { uploadTicket } from '../../utils/uploadFiles'
import { useDispatch } from 'react-redux'
import { setResponse, setShowModal } from '../../slices/modalSlice'
import Icon from '../UI/Icon'
import { uploadIcon } from '../../assets/svg/dashboardIcons'


const UploadTicket = ({ ticket,goBack }): ReactElement => {
  const [file, setFiles] = useState<any>()
  const [fileName, setFileName] = useState<any>()

  const dispatch = useDispatch();


  const upload = ():void => {
    const formData = new FormData()
    formData.append('ticket', file)
    formData.append('quotaId', ticket.id)

    uploadTicket(formData).then((res): void => {
      if (res) {
        dispatch(setResponse({
          message: 'Comprobante guardado',
          error: false,
          description: 'Ya puede setear el pago de la cuota'
        }));
        dispatch(setShowModal(true));
      }
    }).catch((): void => {
      dispatch(setResponse({
        message: 'No se pudo guardar el comprobante',
        error: true,
        description: 'Intentar nuevamente más tarde.'
      }));
      dispatch(setShowModal(true));
    })
  }




  const fileUpload = (event):void => {
    setFiles(event.target.files[0])
    setFileName(URL.createObjectURL(event.target.files[0]));
  }




  return (
    <div className="w-full flex flex-col items-start">
      <a onClick={():void => goBack(goBack)} className="underline text-black-primary text-xs text-left mt-8 hover:text-black-primary cursor-pointer">{'<'}ir a pagos</a>

      <TittleInProgress text="Comprobante de pago" width={'w-1/6'} />
      <p className="mt-8 text-base text-black-primary font-medium">Cargar Comprobante</p>
      <label className="mb-2" htmlFor="file-upload">
        <Icon path={uploadIcon} width={40} height={40} color={'#2E384D'} />
        <input className='hidden' type='file' id="file-upload" onChange={fileUpload} />
      </label>
      <p className="tex-black-primary text-xss">Cargar tus archivos aquí</p>
      <p className="text-xss text-left text-black-primary">{fileName}</p>


      <div className="flex mt-10 pb-4">
        <div className="ml-2">
          <Button text="Enviar ticket" action={upload} />
        </div>
      </div>
    </div>
  )
}

export default UploadTicket