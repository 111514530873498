import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { navState } from '../../slices/navSlice';
import { switchPageState } from '../../slices/switchPageSlice';
import PaginationButtons from './PaginationButtons';


const Pagination = ({ itemData, data, table, perPage }:
  {
    itemData: ((i: any, index: number) => ReactElement) | null,
    data: any,
    table: ((data: any) => ReactElement) | null,
    perPage: number
  })
  : ReactElement => {
  const [currentPage, setCurrentPage] = useState(1)
  const [dataPerPage, setDataPerPage] = useState([data[0]])
  const [totalPages, setTotalPages] = useState(1)
  const [dataPosition, setDataPosition] = useState(0)
  const [isReactPagination, setisReactPagination ] = useState(false)
  const { toShow } = useSelector(navState)
  const { component } = useSelector(switchPageState)


  useEffect(() => {
    let totalPages = 0
    setisReactPagination(toShow==='Agenda' || toShow==='Pagos' || toShow==='Biblioteca' || component==='meetingsOriented' || component==='appointments' )
    if ((data.length % perPage) != 0) {
      const num = data.length / perPage
      const sliceNum = ('' + num).split('.');
      totalPages = parseInt(sliceNum[0]) + 1
    }
    else {
      const num = data.length / perPage
      totalPages = num
    }
    setTotalPages(totalPages)
    getDataPerPage()
  }, [data])

  //--To Do -- verificar impacto en funcionalidad respecto a lineas 25-39
  useEffect(() => {
    let totalPages = 0
    if ((data.length % perPage) != 0) {
      const num = data.length / perPage
      const sliceNum = ('' + num).split('.');
      totalPages = parseInt(sliceNum[0]) + 1
    }else {
      const num = data.length / perPage
      totalPages = num
    }
    setTotalPages(totalPages)
    getDataPerPage()
  }, []) //-----

  useEffect(() => {
    getDataPerPage()
  }, [dataPosition])

  const getDataPerPage = (): void => {
    const slice = data.slice(dataPosition, dataPosition + perPage);
    setDataPerPage(slice)
  }

  const handleNextPageClick = (): void => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
      setDataPosition(dataPosition + perPage)
    }
  };

  const handlePrevPageCLick = (): void => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
      setDataPosition(dataPosition - perPage)
    }
  }

  return (
    <div className={'h-full w-full'}>
      {<PaginationButtons 
        className = {'flex justify-end mt-2 mb-2' + ( toShow == 'Asignados' || toShow == 'Ingresos' || toShow == 'Mensajes' || toShow == 'Inicio' || toShow == 'Orientados' ? ' w-fit mr-4' : ' w-5/6')}
        prevClick = {handlePrevPageCLick}
        nextClick = {handleNextPageClick}
        currentPage = {currentPage}
        totalPages = {totalPages}
        isNextDisabled = {false}
        isPreviousDisabled = {false}
      />
      }
      {table == null && itemData != null ?
        <div className={'w-full h-auto flex flex-wrap' + ( toShow == 'Inicio' || toShow == 'Orientados' ? ' justify-start' : '')}>
          {
            data && data.length !== 0 && dataPerPage.map((i, index) => itemData(i, index))
          }
        </div> :
        data && data.length !== 0 && table != null &&  table(isReactPagination ? data : dataPerPage)}
    </div>
  )
}

export default Pagination