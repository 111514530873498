import { ApolloClient, InMemoryCache, gql, HttpLink, from, fromPromise } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

/*eslint-disable */

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API
});


const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    }
  }
});

const getNewToken = () => client
  .query({
    query: gql`
      query{
        getAccessToken
      }`}).then((response) => {
      const accessToken = response.data.getAccessToken;
      return accessToken;
    });

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.some(i => i.message === "Access denied! You don't have permission for this action!") &&
      fromPromise(
        getNewToken().catch((): void => {
          localStorage.setItem('session', 'expired')
          window.location.replace(process.env.REACT_APP_WEB + "login");
          return;
        })
      )
        .filter((value) => Boolean(value))
        .flatMap((accessToken) => {
          const oldHeaders = operation.getContext().headers;
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: `Bearer ${accessToken}`,
            },
          });
          return forward(operation);
        });
  }
});

export const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache()
});