import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import NewLayout from '../../components/Layout/NewLayout';
import { setCurrentSection } from '../../slices/navSlice';
import AppointmentDetail from '../../components/Orientados/AppointmentDetail';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { APPOINTMENTS_BY_ORIENTED } from '../../queries/Appointment';
import { ORIENTED_BY_ID } from '../../queries/Oriented';
import LoadingSpinner from '../../utils/spinner';
import BreadCrumb from '../../components/UI/BreadCrumb';
import BreadCrumbItem from '../../components/UI/BreadCrumbItem';

const OrientedDetailsPage = (): ReactElement => {
  const { orientedId, appointmentId } = useParams();
  const [selectedAppointment, setAppointment] = useState([]);
  const [currentOriented, setOriented] = useState({});
  const [nameOrientedSelected, setNameOrientedSelected] = useState('...')
  const dispatch = useDispatch();

  const { data: oriented,
    loading: loadingOriented } = useQuery(ORIENTED_BY_ID, {
    variables: { id: orientedId },
  });

  const { data, loading } = useQuery(APPOINTMENTS_BY_ORIENTED, {
    variables: { id: orientedId },
  });

  let filterSelectedAppointment = () => {
    const appointmentSelectedId = !loading
      ? data.getAppointmentsByOriented.find(
        (AppointmentSelected) => AppointmentSelected.id == appointmentId
      )
      : {};
    setAppointment(appointmentSelectedId);
  };

  let setCurrentOriented = () => {
    const orientedSelectedId = !loadingOriented
      ? oriented.orientedById
      : {};
    setOriented(orientedSelectedId);
  };

  useEffect(() => {
    dispatch(setCurrentSection('Orientados'));
    if (!loadingOriented) {
      const name = oriented.orientedById.user.name
      setNameOrientedSelected(name)
    }
  });

  useEffect(() => {
    filterSelectedAppointment();
    setCurrentOriented();
  }, [data, oriented]);

  return (
    <NewLayout>
      <div className="w-full md:ml-11">
        <BreadCrumb>
          <BreadCrumbItem Name={'Orientados'} path={'/oriented'} isCurrentPage={false}/>
          <BreadCrumbItem Name={nameOrientedSelected} path={`/oriented/${orientedId}/appointments`} isCurrentPage={false}/>
          <BreadCrumbItem Name={'Detalle del encuentro'} isCurrentPage={true}/>
        </BreadCrumb>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <AppointmentDetail
            oriented={currentOriented}
            appointment={selectedAppointment}
          />
        )}
      </div>
    </NewLayout>
  );
};

export default OrientedDetailsPage;
