import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import success from '../../assets/images/modalSuccess.png'
import error from '../../assets/images/modalError.png'
import { modalState, setShowModal } from '../../slices/modalSlice'

const Modal = ({ width }: { width: string }): ReactElement => {
  const { show, data } = useSelector(modalState)
  const dispatch = useDispatch()

  return !show ? <div /> : (
    <div
      className={`bg-gray-modal border ml-6 h-32 border-gray-secundary p-4 rounded flex justify-around items-center my-4 shadow-lg max-w-2xl ${width}`}>
      <div>
        <img className="w-24 h-24" src={data.error ? error : success} />
      </div>
      <div className="flex items-start h-full">
        <div className='h-2/5 flex justify-between flex-col'>
          <h3 className={`${data.error ? 'text-red-error' : 'text-green-principal'} text-left text-xs font-bold flex-1`}>
            {data.message}
          </h3>
          <p className='text-black-primary text-xss'>{data.description}</p>
        </div>
      </div>
      <div className="flex items-start h-full">
        <button
          onClick={(): void => {
            dispatch(setShowModal(false))
          }}
          className='inline-flex items-center border border-green-50 focus:outline-none rounded-full p-2 hover:cursor-pointer'>
          <svg xmlns='http://www.w3.org/2000/svg' className='fill-current w-4 h-4 pt-1' viewBox='0 0 24 24'>
            <path
              d='M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z' />
          </svg>
        </button>
      </div>
    </div>
  );
};
export default Modal;